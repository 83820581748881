import React from 'react'
import { convertToRaw, Modifier, SelectionState } from 'draft-js'
import { Editor, EditorState } from 'react-draft-wysiwyg'
import Joi from 'joi'
import { t } from 'i18next'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import { useDispatch } from '../store'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import {
  ErrorsState,
  notRequired,
  preventCyrillicOnInput,
  validateForm,
} from '../function/validation'
import { FormError } from './FormError'
import { sendEmail, sendNotification } from '../store/support/actionCreator'
import { Subscriber } from '../store/user/reducer'
import { clearFieldError } from '../store/common/actionCreator'

const limitTitle = 40
const limitTextNotify = 300
//const limitTextEmail = 800

const schemaBase = {
  mode: Joi.allow('notification', 'email'),
  emails: Joi.array().min(1).label(t('field.label.to')),
  title: Joi.string()
    .label(t('field.label.title'))
    .when('mode', {
      is: 'notification',
      then: Joi.string().min(6).max(limitTitle).required(),
      otherwise: notRequired,
    }),
}
const schemaNotification = Joi.object({
  ...schemaBase,
  text: Joi.string()
    .required()
    .min(6)
    .max(limitTextNotify)
    .label(t('field.label.text')),
})
const schemaEmail = Joi.object({
  ...schemaBase,
  text: Joi.string()
    .required()
    .min(6)
    //.max(limitTextEmail)
    .label(t('field.label.text')),
})

const editorOptions = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'history',
  ],
}

type Props = {
  mode: 'notification' | 'email'
  subscribers: Subscriber[]
  onClose: () => void
}

export function PopupNotify({
  mode,
  subscribers: initSubscribers,
  onClose,
}: Props) {
  const dispatch = useDispatch()

  const [subscribers, setSubscribers] =
    React.useState<Subscriber[]>(initSubscribers)
  const [title, setTitle] = React.useState('')
  const [textNotification, setTextNotification] = React.useState<string>('')
  const [textEmailObject, setTextEmailObject] = React.useState<EditorState>()
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, title, textEmailObject])

  const emailText = React.useMemo(() => {
    if (textEmailObject === undefined) return ''

    return textEmailObject.getCurrentContent().getPlainText()
  }, [textEmailObject])

  const emailHtml = React.useMemo(() => {
    if (textEmailObject === undefined) return ''

    const rawContentState = convertToRaw(textEmailObject?.getCurrentContent())
    return draftToHtml(rawContentState)
  }, [textEmailObject])

  const emails = React.useMemo(() => {
    return subscribers.map((el) => el.email)
  }, [subscribers])

  const lengthTitle = title.length

  const lengthTextEmail = emailText.length

  function handleEmailDelete(subscriber: Subscriber) {
    setSubscribers((state) => [
      ...state.filter((el) => el.id !== subscriber.id),
    ])
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const data = {
      mode,
      emails,
      title: title.trim(),
      text: mode === 'email' ? emailText.trim() : textNotification.trim(),
    }
    const validationSchema = mode === 'email' ? schemaEmail : schemaNotification

    const error = validateForm(validationSchema, data)

    if (error) setErrors(error)
    else {
      setErrors(null)

      const ids = subscribers.map((el) => el.id)

      if (mode === 'notification')
        dispatch(sendNotification(data.title, data.text, ids))
      else {
        const formData = new FormData()
        formData.append('text', emailHtml)
        formData.append('userIds', String(ids))

        dispatch(sendEmail(formData))
      }
      onClose()
    }
  }

  React.useEffect(() => {
    console.log(textEmailObject?.getCurrentContent())
  }, [textEmailObject])
  return (
    <Dialog open={true} maxWidth="md" fullWidth onClose={onClose}>
      <DialogTitle>
        {t(
          mode === 'notification' ? 'support.sendNotify' : 'support.sendEmail',
        )}
      </DialogTitle>

      <Divider variant="popup" color="primary" />

      <form onSubmit={handleSubmit} noValidate>
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={2}>
              <FormLabel required>{t('field.label.to')}</FormLabel>
            </Grid>
            <Grid item xs={10}>
              <Box className="form__chips">
                <Stack className="form__chips__container">
                  {subscribers.map((el) => (
                    <Chip
                      key={el.id}
                      label={el.email}
                      sx={{ p: 0, mr: 1, mb: 1, background: 'grey' }}
                      onDelete={() => handleEmailDelete(el)}
                    />
                  ))}
                </Stack>
                <FormError error={errors?.emails} />
              </Box>
            </Grid>
          </Grid>
          {mode === 'notification' && (
            <Grid container>
              <Grid item xs={2}>
                <FormLabel required>{t('field.label.title')}</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <FormControl fullWidth>
                  <OutlinedInput
                    value={title}
                    onChange={(e) => preventCyrillicOnInput(e, setTitle, true)}
                    onBlur={() => {
                      setTitle(title.trim())
                    }}
                  />
                </FormControl>
                <FormError error={errors?.title} />
                <Typography
                  color={lengthTitle > limitTitle ? 'error' : undefined}
                  sx={{
                    width: '100%',
                    textAlign: 'end',
                    marginTop: '5px',
                  }}
                >
                  {lengthTitle}/{limitTitle}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs={2}>
              <FormLabel required>{t('field.label.text')}</FormLabel>
            </Grid>
            <Grid item xs={10}>
              {mode === 'notification' ? (
                <>
                  <FormControl fullWidth>
                    <TextareaAutosize
                      className="form__textarea"
                      value={textNotification}
                      onChange={(e) =>
                        preventCyrillicOnInput(e, setTextNotification, true)
                      }
                      onBlur={(e) =>
                        setTextNotification(textNotification.trim())
                      }
                    />
                  </FormControl>
                  <Typography
                    color={
                      textNotification.length > limitTextNotify
                        ? 'error'
                        : undefined
                    }
                    sx={{
                      width: '100%',
                      textAlign: 'end',
                      marginTop: '5px',
                    }}
                  >
                    {textNotification.length}/{limitTextNotify}
                  </Typography>
                </>
              ) : (
                <>
                  <Box className="editor">
                    {/*EDITOR*/}
                    <Editor
                      editorClassName="editor__textarea"
                      editorState={textEmailObject}
                      onEditorStateChange={setTextEmailObject}
                      toolbar={editorOptions}
                    />
                  </Box>
                  {/*<Typography
                    color={
                      lengthTextEmail > limitTextEmail ? 'error' : undefined
                    }
                    sx={{
                      width: '100%',
                      textAlign: 'end',
                      marginTop: '10px',
                    }}
                  >
                    {lengthTextEmail}/{limitTextEmail}
                  </Typography>*/}
                </>
              )}
              <FormError error={errors?.text} />
            </Grid>
          </Grid>
        </Stack>

        <Divider variant="popup" color="primary" sx={{ marginTop: '15px' }} />

        <DialogActions>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={onClose}
          >
            {t('common.close')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
          >
            {t('common.send')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
