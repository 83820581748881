import React from 'react'
import { useTranslation } from 'react-i18next'
import { Upload as UploadIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
import {
  Avatar as MuiAvatar,
  Box,
  Grid,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { PrivatePage } from '../component/PagePrivate'
import { DataTable } from '../component/datatable'
import { useTableStyles } from '../component/datatable/hook/useStyles'
import { formatDate } from '../function/date'
import { useDispatch, useSelector } from '../store'
import { Subscriber } from '../store/user/reducer'
import { showSnackbar } from '../store/common/actionCreator'
import { startUploadVideo } from '../store/apikey/actionCreator'

export const FILE_SIZE_LIMIT = 16 * 1000 * 1000
export const FILE_FORMATS = 'video/mp4'

const Input = styled('input')({
  display: 'none',
})

interface ApiKey {
  id: number
  environment: 'live'
  key: string
  domains: string[] | null
  length: number
  user: Subscriber
  createdTime: string
}

export function ApikeyPage() {
  const { t } = useTranslation()
  const classesTable = useTableStyles()
  const dispatch = useDispatch()

  const { loaderVideo } = useSelector((state) => state.apikey)

  const tableHeaderData = [
    { key: 'user', text: t('apiKeys.table.user') },
    { key: 'key', text: t('apiKeys.table.key') },
    { key: 'allowedDomains', text: t('apiKeys.table.allowedDomains') },
    { key: 'length', text: t('apiKeys.table.length') },
    { key: 'createdTime', text: t('apiKeys.table.createdTime') },
  ]

  function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return

    const file = e.target.files[0]
    if (file.size > FILE_SIZE_LIMIT) {
      dispatch(
        showSnackbar(
          'error',
          'Maximum file size: ' + FILE_SIZE_LIMIT / 1000 / 1000 + 'MB',
        ),
      )
    } else if (file.type !== FILE_FORMATS) {
      dispatch(showSnackbar('error', 'Only mp4 video format is allowed'))
    } else {
      const formData = new FormData()
      formData.append('file', file)

      dispatch(startUploadVideo(formData))
    }
  }

  const TableBody = (row: ApiKey, idx: number) => (
    <TableRow
      key={row.id}
      className={idx % 2 !== 0 ? classesTable.rowEven : ''}
    >
      <TableCell classes={classesTable}>
        <Grid container alignItems="center">
          <Box>
            <MuiAvatar
              src={row.user.avatar || ''}
              alt={row.user.firstname || ''}
              sx={{ width: 30, height: 30 }}
            />
          </Box>
          <Box ml={1}>
            {row.user.firstname} {row.user.lastname}
          </Box>
        </Grid>
      </TableCell>
      <TableCell classes={classesTable}>{row.key}</TableCell>
      <TableCell classes={classesTable}>
        {row.domains?.map((el, idx) => (idx !== 0 ? ' • ' + el : el))}
      </TableCell>
      <TableCell classes={classesTable}>{row.length}</TableCell>
      <TableCell classes={classesTable}>
        {formatDate(row.createdTime, 'dd/MM/yyyy')}
      </TableCell>
    </TableRow>
  )

  return (
    <PrivatePage>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography variant="semiBold" fontSize={24}>
          {t('apiKeys.title')}
        </Typography>

        <label htmlFor="button-file">
          <Input
            id="button-file"
            type="file"
            onChange={handleUpload}
            onClick={(event) => ((event.target as any).value = null)}
          />
          <LoadingButton
            loading={loaderVideo}
            loadingPosition="start"
            startIcon={<UploadIcon />}
            variant="contained"
            tabIndex={-1}
            component="span"
          >
            Upload video
          </LoadingButton>
        </label>
      </Stack>

      <DataTable
        id="apiKeys"
        className={classesTable.card}
        url="/api-keys/admin"
        headerData={tableHeaderData}
        renderBody={TableBody}
        pagination
      />
    </PrivatePage>
  )
}
