import React from 'react'
import {useTranslation} from 'react-i18next'
import {Dashboard} from '../store/dashboard/reducer'
import {indexOfMax} from '../function/number'
import {HeaderData} from '../component/datatable/types'
import {Subscriber} from '../store/user/reducer'

export function useUsage(data: Dashboard | null) {
  const {t} = useTranslation()
  interface SliderInterface {title: string, amount: number, percentage: number}
  // const sliderData = [
  //   {title: t('dashboard.phones'), amount: data?.phoneCount, percentage: data?.phoneCountIncreasePercent},
  //   {title: t('dashboard.names'), amount: data?.nameCount, percentage: data?.nameCountIncreasePercent},
  //   {title: t('dashboard.posts'), amount: data?.postCount, percentage: data?.postCountIncreasePercent},
  //   {title: t('dashboard.snID'), amount: 0, percentage: 0},
  //   {title: t('dashboard.images'), amount: 0, percentage: 0},
  //   {title: t('dashboard.address'), amount: 0, percentage: 0},
  //   {title: t('dashboard.emails'), amount: 0, percentage: 0},
  // ]
  const sliderData: SliderInterface[] = data && data.counters && data.counters.length > 0 ? 
  data.counters.map((counter) => {
    return {title: counter.label, amount: counter.counter, percentage: counter.percetage || 0}
  }) : []

  const revenueTopCountry = React.useMemo(() => {
    if (data === null) return 0
    return Math.max(...data.revenueByCountries.map(o => o.total))
  }, [data])

  const tableHeaderData: HeaderData[] = React.useMemo(() => {
    return [
      {key: 'id', text: t('dashboard.table.id'), sortable: true},
      {key: 'userName', text: t('dashboard.table.userName'), sortable: true},
      {key: 'country', text: t('dashboard.table.country'), sortable: true},
      {key: 'ltv', text: t('dashboard.table.LTV')},
      {key: 'cred\'s', text: t('dashboard.table.credits')},
      {key: 'balance', text: t('dashboard.table.balance'), sortable: true},
      {key: 'renewal', text: t('dashboard.table.renewal'), sortable: true},
      {key: 'mostUsedType', text: t('dashboard.table.mostUsedType')},
      {key: 'registrationDate', text: t('dashboard.table.registrationDate'), sortable: true},
      {key: 'lastActive', text: t('dashboard.table.lastActive')},
      {key: 'webUsage', text: "Web Usage"},
      {key: 'apiUsage', text: "API Usage"},
    ]
  }, [t])

  function getMostUsedType(row: Subscriber) {
    const fullUsage = row.fullUsage
    if (!(fullUsage && 
        fullUsage.api && fullUsage.web && 
        fullUsage.api.typeCount && fullUsage.web.typeCount)){
      return 'None'
    }else {
      const apiTypeCount = fullUsage.api.typeCount
      const webTypeCount = fullUsage.web.typeCount
      const searchCategoryLabels = Object.keys(apiTypeCount)
      const highestIndex = searchCategoryLabels.map((category) => {
        return category in apiTypeCount && category in webTypeCount ? apiTypeCount[category] + webTypeCount[category] : 0
      }).reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0)
      // const highestIndex = indexOfMax(valueArray)
      console.log(highestIndex)
      return searchCategoryLabels[highestIndex]
    }
  }

  return {sliderData, revenueTopCountry, tableHeaderData, getMostUsedType}
}