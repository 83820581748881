import * as React from 'react'
import {styled} from '@mui/material/styles'
import Checkbox, {CheckboxProps} from '@mui/material/Checkbox'

const Icon = styled('span')(({theme}) => ({
  borderRadius: 3,
  width: 22,
  height: 22,
  backgroundColor: '#F9FAFB',
  border: '1px solid #C0C0C0',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  // 'input:disabled ~ &': {
  //   boxShadow: 'none',
  //   background:
  //     theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  // },
}))

const CheckedIcon = styled(Icon)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  '&:before': {
    position: 'absolute',
    content: '""',
    margin: 'auto',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 17,
    height: 17,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 17'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.primary.dark,
  },
}))

// Inspired by blueprintjs
export function InputCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': {bgcolor: 'transparent'},
      }}
      disableRipple
      checkedIcon={<CheckedIcon/>}
      icon={<Icon/>}
      {...props}
    />
  )
}
