import { call, put, takeEvery } from 'redux-saga/effects'
import { api, Response } from '../../function/axios'
import { handleError } from '../../function/error'
import * as actionType from './actionType'
import * as actionCreator from './actionCreator'
import { showSnackbar } from '../common/actionCreator'


function* get() {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: '/blacklist',
    })

    yield put(actionCreator.setBlackList(data.blackList))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* post(action: actionType.PostBlackList) {
  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/blacklist',
      body: action.data,
    })

    yield put(actionCreator.fetchBlackList)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* _delete(action: actionType.DeleteBlackList) {
  try {
    const { data }: Response = yield call(api, {
      method: 'DELETE',
      url: `/blacklist`,
      body: action.data,
    })

    yield put(actionCreator.fetchBlackList)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

//phones

function* getPhones() {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: '/blacklist/phones',
    })

    yield put(actionCreator.setBlackListPhones(data.blackListPhones))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* postPhones(action: actionType.PostBlackListPhones) {
  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/blacklist/phones',
      body: action.data,
    })

    yield put(actionCreator.fetchBlackListPhones)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* _deletePhones(action: actionType.DeleteBlackListPhones) {
  try {
    const { data }: Response = yield call(api, {
      method: 'DELETE',
      url: `/blacklist/phones`,
      body: action.data,
    })

    yield put(actionCreator.fetchBlackListPhones)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

//Ip Address

function* getIpAddress() {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: '/blacklist/ip_address',
    })

    yield put(actionCreator.setBlackListIpAddress(data.blackListIpAddress))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* postIpAddress(action: actionType.PostBlackListIpAddress) {
  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/blacklist/ip_address',
      body: action.data,
    })

    yield put(actionCreator.fetchBlackListIpAddress)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* _deleteIpAddress(action: actionType.DeleteBlackListIpAddress) {
  try {
    const { data }: Response = yield call(api, {
      method: 'DELETE',
      url: `/blacklist/ip_address`,
      body: action.data,
    })

    yield put(actionCreator.fetchBlackListIpAddress)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchBlackList() {
  yield takeEvery(actionType.GET_BLACKLIST, get)
  yield takeEvery(actionType.POST_BLACKLIST, post)
  yield takeEvery(actionType.DELETE_BLACKLIST, _delete)
  
  yield takeEvery(actionType.GET_BLACKLIST_PHONES, getPhones)
  yield takeEvery(actionType.POST_BLACKLIST_PHONES, postPhones)
  yield takeEvery(actionType.DELETE_BLACKLIST_PHONES, _deletePhones)

  yield takeEvery(actionType.GET_BLACKLIST_IP_ADDRESS, getIpAddress)
  yield takeEvery(actionType.POST_BLACKLIST_IP_ADDRESS, postIpAddress)
  yield takeEvery(actionType.DELETE_BLACKLIST_IP_ADDRESS, _deleteIpAddress)
}
