import {call, put, takeEvery, takeLatest} from 'redux-saga/effects'
import * as actionType from './actionType'
import {showSnackbar} from '../common/actionCreator'
import {api, Response} from '../../function/axios'
import { ActivateCampaign, DeleteCampaign, PostCampaign, PostTelegramCampaign, PutCampaign } from './actionType'
import {handleError} from '../../function/error'

function* postCampaign(action: PostCampaign) {
  try {
    const {data}: Response = yield call(api, {
      method: 'POST',
      url: '/marketing-campaign',
      body: action.data,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* putCampaign(action: PutCampaign) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PUT',
      url: `/marketing-campaign/${action.id}`,
      body: action.data,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* activateCampaign(action: ActivateCampaign) {
  try {
    const {data}: Response = yield call(api, {
      method: 'GET',
      url: `/marketing-campaign/activate/${action.id}`,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* deleteCampaign(action: DeleteCampaign) {
  try {
    const {data}: Response = yield call(api, {
      method: 'DELETE',
      url: `/marketing-campaign/${action.id}`,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* postTelegramCampaign(action: PostTelegramCampaign) {
  try {
    const {data}: Response = yield call(api, {
      method: 'POST',
      url: '/marketing-tg-campaign',
      body: action.data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchMarketing() {
  yield takeEvery(actionType.POST_CAMPAIGN, postCampaign)
  yield takeEvery(actionType.PUT_CAMPAIGN, putCampaign)
  yield takeLatest(actionType.ACTIVATE_CAMPAIGN, activateCampaign)
  yield takeEvery(actionType.DELETE_CAMPAIGN, deleteCampaign)
  yield takeEvery(actionType.POST_TELEGRAM_CAMPAIGN, postTelegramCampaign)
}
