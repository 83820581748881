import * as React from 'react'
import {t, TFunctionResult} from 'i18next'
import {Check as CheckIcon, InfoOutlined as InfoIcon} from '@mui/icons-material'
import {Box, Button, Card, Chip, Divider, Grid, Popover, PopoverOrigin, Stack, styled, Switch, Typography} from '@mui/material'
import sprite_ from '../../sass/sprite.module.sass'
import {usePopover} from '../../hook/usePopover'
import {colorBackground, colorLightGreen, colorPrimary, colorSuccess} from '../../mui'
import {FormState} from '../../function/validation'
import {usePackagePopup} from '../../hook/usePackagePopup'
import {IFeature, Workflow, Source} from '../../store/package/reducer'
import {useButtonStyles} from '../../style/button'

function getDuration(days: number): TFunctionResult {
  switch (days) {
    case 30:
      return t('package.duration.perMonth')
    default:
      return `${t('package.duration.per')} ${days} ${t('package.duration.days')}`
  }
}

interface RenderInfoItemProps {
  feature: IFeature
  setPopoverText: (text: React.ReactNode) => void
  setPopoverAnchor: (anchor: HTMLElement) => void
  setPopoverOpen: (value: boolean) => void
}

function RenderInfoItem({feature, setPopoverText, setPopoverAnchor, setPopoverOpen}: RenderInfoItemProps) {
  const handlePopoverEnter = (event: React.MouseEvent<HTMLElement>, text: React.ReactNode) => {
    setPopoverText(text)
    setPopoverAnchor(event.currentTarget)
    setPopoverOpen(true)
  }

  if (feature.title === '') return null

  
  const countWordsDescriptionTitle = (text : string) : number => {
    let title : number
    const arr = text.split(" ")
    if (arr.length >= 0 && arr.length < 4) {
      title = 13
    } else {
      if (arr.length >= 4 && arr.length <11) {
      title = 10
      } else { 
           title = 8
      }
    }
    return title
  }

  const countWordsDescriptionSubTitle = (text : string) : number => {
    let title : number
    const arr = text.split(" ")
    if (arr.length >= 0 && arr.length < 4) {
      title = 12
    } else {
      if (arr.length >= 4 && arr.length <11) {
      title = 8
      } else { 
           title = 6
      }
    }
    return title
  }

  return (
    <Grid container mb={1}>
      <Grid item xs={2}><CheckIcon color="primary" style={{fontSize: 16}}/></Grid>
      <Grid item xs={10}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="semiBold" fontSize={countWordsDescriptionTitle(feature.title)}>{feature.title}</Typography>
          {feature.info && (
            <Box
              onMouseEnter={(e) => handlePopoverEnter(e, feature.info)}
              onMouseLeave={() => setPopoverOpen(false)}
            >
              <InfoIcon color="secondary" sx={{fontSize: 13}}/>
            </Box>
          )}
        </Stack>
        <Typography fontSize={countWordsDescriptionSubTitle(feature.subTitle)}>{feature.subTitle}</Typography>
      </Grid>
    </Grid>
  )
}

interface RenderInfoProps {
  features: IFeature[]
}

function RenderInfo({features}: RenderInfoProps) {
  const {popoverClasses, popoverOptions} = usePopover(false)

  const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLElement>()
  const [popoverOpen, setPopoverOpen] = React.useState(false)
  const [popoverText, setPopoverText] = React.useState<React.ReactNode>()

  return (
    <>
      <RenderInfoItem
        feature={features[0]}
        setPopoverText={setPopoverText}
        setPopoverAnchor={setPopoverAnchor}
        setPopoverOpen={setPopoverOpen}
      />
      <RenderInfoItem
        feature={features[1]}
        setPopoverText={setPopoverText}
        setPopoverAnchor={setPopoverAnchor}
        setPopoverOpen={setPopoverOpen}
      />
      <RenderInfoItem
        feature={features[2]}
        setPopoverText={setPopoverText}
        setPopoverAnchor={setPopoverAnchor}
        setPopoverOpen={setPopoverOpen}
      />
      {features[3] && (
        <RenderInfoItem
          feature={features[3]}
          setPopoverText={setPopoverText}
          setPopoverAnchor={setPopoverAnchor}
          setPopoverOpen={setPopoverOpen}
        />
      )}

      <Popover
        open={popoverOpen}
        className={popoverClasses.root}
        classes={{
          paper: popoverClasses.paper,
        }}
        anchorEl={popoverAnchor}
        anchorOrigin={popoverOptions.anchorOrigin as PopoverOrigin}
        transformOrigin={popoverOptions.transformOrigin as PopoverOrigin}
        PaperProps={{onMouseEnter: () => setPopoverOpen(true), onMouseLeave: () => setPopoverOpen(false)}}
        elevation={0}
      >
        <Box width={200}>{popoverText}</Box>
      </Popover>
    </>
  )
}

interface Props {
  data: FormState
  lookups: Workflow[]
}


export function PreviewPackage({data, lookups}: Props) {
  const buttonStyles = useButtonStyles()
  const {getPhones, getSocials, getDeepWeb, getWebint} = usePackagePopup()

  const isCustom = data.type === 'custom'
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 92,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(52px)",
        '& .MuiSwitch-thumb:before': {
        backgroundImage: '../asset/icon/irbis_eye.png',
      },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#3397d5" : "#3397d5",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#04163e" : "#04163e",
      width: 32,
      height: 32,
    },
   
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#3397d5" : "#3397d5",
      borderRadius: 20 / 2,
    },
  }))
  const [isAnnual, setChecked] = React.useState(false)
  const handleClick = () => {
    setChecked(!isAnnual)
  } 

  const checkPrice = ( isAnnual : boolean, data : any) => {
    return data && data.price && isAnnual && data.annualPrice && data.annualProductId ?
    (data.annualPrice / 12).toFixed(2):
    data.price
  } 

  const checkCredits = ( isAnnual : boolean, data : any) => {
    if (data.type !== 'general' && data.type !== 'personal') {
      return
    }
    if (isAnnual && data.creditsPerUnit && data.annualPrice  && data.annualProductId) {
      return "Cred's: " + Math.round((data.price * data.creditsPerUnit)  +  ( (data.price * 12 - data.annualPrice) / 12) * data.creditsPerUnit)
    }
    if (!isAnnual && data.creditsPerUnit) {
      return "Cred's: " +  (data.price * data.creditsPerUnit) 
    }

  }

  const checkDiscount = (isAnnual : boolean, data : any ) => {
    if (isAnnual && data.creditsPerUnit && data.annualPrice) {
      return Math.round(((data.price * 12 - data.annualPrice) / 12) * data.creditsPerUnit * 12) + " bonus cred's"
    } else 
    return `${Math.round(   (((data.price * 12) - data.annualPrice) / (data.price * 12)) * 100 )  }% discount`
  }

  const sources = React.useMemo(() => {
    const output: Source[] = []

    lookups.forEach((lookup: Workflow) => {
      lookup.sources.forEach(source => {
        if (!output.includes(source)) output.push(source)
      })
    })

    return output
  }, [lookups])

  const countWordsTitle = (text : string) : number => {
    let title : number
    const arr = text.split(" ")
    if (arr.length >= 0 && arr.length < 4) {
      title = 24
    } else {
      if (arr.length >= 4 && arr.length <11) {
      title = 16
      } else { 
           title = 12
      }
    }
    return title
  }

  const countWordsSubtitle = (text : string) : number => {
    let title : number 
    const arr = text.split(" ")
    if (arr.length >= 0 && arr.length < 4) {
      title = 16
    } else {
      if (arr.length >= 4 && arr.length <11) {
      title = 12
      } else { 
           title = 9
      }
    }
    return title
  }

  const disableDisplay: string[] = ['quotaprofiler-periodic','quotaprofiler-monitoring','quotawebint-lookup','quotawebint-periodic']

  return (
    <Stack alignItems="center" mb={2} sx={{padding: 2, height: 512, background: colorBackground, overflowY: 'auto'}}>
      <Card style={{padding: '18px 14px', width: 296, minHeight: 600}}>  {/*minHeight: 573*/}
        <Stack alignItems="center" height="100%">
          <Stack height={80}>
            <Typography variant="semiBold" fontSize={countWordsTitle(data.title)} align="center">{data.title}</Typography>
            {data.subTitleType === 'status'
              ? (
                <Chip
                  label={<Typography color={ (isCustom) ? "#000" : "#FFF"} fontSize={countWordsSubtitle(data.subTitleContext)-2} align="center" style={{whiteSpace: 'normal'}}>{data.subTitleContext}</Typography>}
                  sx={{
                    marginTop: '2',
                    color: isCustom ? '#000' : '#FFF',
                    backgroundColor: isCustom ? colorLightGreen : colorPrimary,
                  }}
                  style={{height:"40%", width: "100%"}}
                />
              )
              : <Typography fontSize={countWordsSubtitle(data.subTitleContext)} align="center" sx={{marginTop: '2'}}>{data.subTitleContext}</Typography>}
          </Stack>

          <Box width="100%" maxHeight={1} mt={1} mb={2}><Divider color="primary"/></Box>

          <Stack
            justifyContent={isCustom ? 'center' : 'space-between'}
            alignItems="center"
            spacing={1}
            width="100%"
            minHeight={120}
          >
            {!isCustom && data.isAnnual && (
              <Stack direction="row" justifyContent="flex-end" width="100%" height={30}>
                  <Typography>Monthly</Typography>
                    <MaterialUISwitch sx={{ mx: 1 }} onClick={handleClick} defaultChecked={isAnnual} />
                  <Typography>Annual</Typography>
              </Stack>
            )}
            {isAnnual && (
                <Stack direction="row" spacing={1} alignItems="center" >
                  <Chip
                    sx={{ 
                      mx: 0.1, 
                      color: isCustom ? '#000' : '#FFF',
                      backgroundColor: colorSuccess,
                    }}
                    label={ <Typography color={"#000"}>{checkDiscount(isAnnual, data)} </Typography>}
                    variant="outlined"
                    size="small"
                  />
                </Stack>
              )}

            <Typography variant="semiBold" fontSize={45} align="center" style={{lineHeight: '32px'}}>
              {isCustom ? t('common.custom') : '€' + checkPrice(isAnnual, data)}
            </Typography>
            {!isCustom && <Typography fontSize={12}>{getDuration(data.activeDay)}</Typography>}
            {!isCustom && <Typography fontSize={14}>{checkCredits(isAnnual, data)}</Typography>}
            {(!isCustom && sources.length > 0) && (
              <Stack justifyContent="space-between" alignItems="center" minHeight={100}>
                <Typography variant="semiBold" fontSize={12}>{t('package.sources')}:</Typography>

                <Grid container justifyContent="center" spacing={1}>
                  {getPhones(sources).map(el => (
                    <Grid item key={el + data.id}>
                      <div className={sprite_['social__small_' + el]}/>
                    </Grid>
                  ))}
                </Grid>

                <Grid container justifyContent="center" spacing={1}>
                  {getSocials(sources).map(el => (
                    <Grid item key={el + data.id}>
                      <div className={sprite_['social__small_' + el]}/>
                    </Grid>
                  ))}
                </Grid>

                <Grid container justifyContent="center" spacing={1}>
                  {getDeepWeb(sources).map(el => (
                    <Grid item key={el + data.id}>
                      <div className={sprite_['social__small_' + el]}/>
                    </Grid>
                  ))}
                </Grid>

                <Grid container justifyContent="center" spacing={1}>
                  {getWebint(sources).map(el => (
                    (!disableDisplay.includes(el) &&  
                    <Grid item key={el + data.id}>
                      <div className={sprite_['social__small_' + el]}/>
                    </Grid>
                    )
                  ))}
                </Grid>
              </Stack>
            )}
          </Stack>

          <Box width="100%" maxHeight={1} mt={3} mb={1}><Divider color="primary"/></Box>

          <Stack width="100%" flexGrow={1} justifyContent={isCustom ? 'space-between' : 'none'}>
            <RenderInfo features={data.features}/>
          </Stack>

          {!data.isFree && (
            <Box width="100%" height={44}>
              {isCustom
                ? <Button className={buttonStyles.yellowGreen} size="large" fullWidth>{data.button}</Button>
                : <Button variant="contained" size="large" fullWidth>{data.button}</Button>}
            </Box>
          )}
        </Stack>
      </Card>
    </Stack>
  )
}