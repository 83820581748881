import { DashboardAction } from './actionType'

export const getUsage = (url: string): DashboardAction => ({
  type: 'dashboard/GET_USAGE',
  url,
})

export const setUsage = (data: object | null): DashboardAction => ({
  type: 'dashboard/SET_USAGE',
  data,
})

export const setSubscriberOffset = (offset: number): DashboardAction => ({
  type: 'dashboard/SET_SUBSCRIBER_OFFSET',
  offset,
})

export const setSubscriberSort = (
  key: string,
  value: string,
): DashboardAction => ({
  type: 'dashboard/SET_SUBSCRIBER_SORT',
  key,
  value,
})

export const patchUserPackage = (
  userId: number,
  packageId: number,
  callback: () => void,
): DashboardAction => ({
  type: 'dashboard/PATCH_USER_PACKAGE',
  userId,
  packageId,
  callback,
})

export const patchUserAdditionalPackage = (
  userId: number,
  packageId: number,
  callback: () => void,
): DashboardAction => ({
  type: 'dashboard/PATCH_USER_ADDITIONAL_PACKAGE',
  userId,
  packageId,
  callback,
})

export const putBalance = (
  id: number,
  value: number,
  callback: () => void,
): DashboardAction => ({
  type: 'dashboard/PUT_BALANCE',
  id,
  value,
  callback,
})

export const putAnnualBonus = (
  id: number,
  value: number,
  callback: () => void,
): DashboardAction => ({
  type: 'dashboard/PUT_ANNUAL_BONUS',
  id,
  value,
  callback,
})

export const putQuota = (
  uid: string,
  value: object,
  callback: () => void,
): DashboardAction => ({
  type: 'dashboard/PUT_QUOTA',
  uid,
  value,
  callback,
})

export const putRenewal = (
  id: number,
  days: number,
  callback: () => void,
): DashboardAction => ({
  type: 'dashboard/PUT_RENEWAL',
  id,
  days,
  callback,
})

export const changeTime = (
  time: 'day' | 'week' | 'month' | 'year',
): DashboardAction => ({
  type: 'dashboard/CHANGE_TIME',
  time,
})

export const changeUsage = (usage: 'all' | 'web' | 'api'): DashboardAction => ({
  type: 'dashboard/CHANGE_USAGE',
  usage,
})

export const setSubscriberIrbisProUpgrade = (id: number, upgrade: boolean | null, callback?: Function): DashboardAction => ({
  type: 'dashboard/SET_SUBSCRIBER_IRBISPRO_UPGRADE',
  id,
  upgrade,
  callback,
})