import { Avatar, Grid, Stack, Typography } from '@mui/material'

import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import { SearchResult } from '../../store/searcher/reducer'
import { preparePsychoAnalysysResult } from '../../store/searcher/service'
import { ResultPshychoTitle } from '../../style/result'

type Props = {
  apiData: SearchResult
}

export function PsychoProfileCommon(props: Props) {
  const result: any = preparePsychoAnalysysResult(props.apiData)

  const source = result.sources && result.sources[0].name ? result.sources[0].name : null
  const personName = result.data &&
    Array.isArray(result.data) && result.data[0] && result.data[0].personName ? result.data[0].personName : "Unknown"
  const levelOfDanger = result.data &&
    Array.isArray(result.data) && result.data[0] && result.data[0].levelOfDanger ? result.data[0].levelOfDanger : "Unknown"
  const predictedCharacteristics: any[] = result.data &&
    Array.isArray(result.data) && result.data[0] && result.data[0].predictedCharacteristics
    && Array.isArray(result.data[0].predictedCharacteristics) ? result.data[0].predictedCharacteristics : ["Unknown"]
  const psychologicalPortrait = result.data &&
    Array.isArray(result.data) && result.data[0] && result.data[0].psychologicalPortrait ? result.data[0].psychologicalPortrait : "Unknown"
  
  const age = result.data &&
    Array.isArray(result.data) && result.data[0] && result.data[0].age ? result.data[0].age : "Unknown"
  const country = result.data &&
    Array.isArray(result.data) && result.data[0] && result.data[0].country ? result.data[0].country : "Unknown"
  const gender = result.data &&
    Array.isArray(result.data) && result.data[0] && result.data[0].gender ? result.data[0].gender : "Unknown"
  const occupation = result.data &&
    Array.isArray(result.data) && result.data[0] && result.data[0].occupation ? result.data[0].occupation : "Unknown"
  const summary = result.data &&
    Array.isArray(result.data) && result.data[0] && result.data[0].summary ? result.data[0].summary : "Unknown"
  const image = result.data &&
    Array.isArray(result.data) && result.data[0] && result.data[0].image ? result.data[0].image : null

  return (
      <>
      {source.toString().toUpperCase() === 'PSYCH' && (
        <Stack>
          <Divider>
            <Chip label="General" size="small" style={ { marginBottom: 10} } />
          </Divider>
          <Grid container spacing={0}>
          <Grid item xs={2} container justifyContent="center" alignItems="center">
          <Avatar src={image || ''} sx={{ width: 90, height: 90 }} />
            </Grid>
            <Grid item xs={3} container justifyContent="center" alignItems="center">
              Person name:
            </Grid>
            <Grid item xs={3} container justifyContent="center" alignItems="center">
              <ResultPshychoTitle>{personName ? personName : "Unknown"}</ResultPshychoTitle>
            </Grid>
            <Grid item xs={2} container justifyContent="center" alignItems="center">
              Level of Danger:
            </Grid>
            <Grid item xs={2} container justifyContent="center" alignItems="center">
              <ResultPshychoTitle>{levelOfDanger ? levelOfDanger : "Unknown"}</ResultPshychoTitle>
            </Grid>
          </Grid>
          <Divider>
            <Chip label="Characteristics" size="small" style={ { marginTop: 5, marginBottom: 10} }/>
          </Divider>
          <Grid container spacing={3}>
            <Grid item xs={12} container justifyContent="center" alignItems="center">
              {predictedCharacteristics.map((el: any) => {
                return <ResultPshychoTitle style={ { marginRight: 5} }>{el}</ResultPshychoTitle>
              })}
            </Grid>
          </Grid>

          <Divider>
            <Chip label="Psychological Portrait" size="small" style={ { marginTop: 5, marginBottom: 10} }/>
          </Divider>
          <Grid container spacing={3}>
            <Grid item xs={12} container justifyContent="center" alignItems="center">
              {psychologicalPortrait}
            </Grid>
          </Grid>
        </Stack>
        
      )}

      {source.toString().toUpperCase() === 'SUMMARY' && (
        <Stack>
            
          <Divider>
            <Chip label="General" size="small" style={ { marginBottom: 10} } />
          </Divider>
          <Grid container spacing={0}>
          <Grid item xs={2} container justifyContent="center" alignItems="center">
          <Avatar src={image || ''} sx={{ width: 90, height: 90 }} />
            </Grid>
            <Grid item xs={1} container justifyContent="center" alignItems="center">
              Age:
            </Grid>
            <Grid item xs={2} container justifyContent="center" alignItems="center">
              <ResultPshychoTitle>{age ? age : "Unknown"}</ResultPshychoTitle>
            </Grid>
            <Grid item xs={2} container justifyContent="center" alignItems="center">
              Country:
            </Grid>
            <Grid item xs={2} container justifyContent="center" alignItems="center">
              <ResultPshychoTitle>{country ? country : "Unknown"}</ResultPshychoTitle>
            </Grid>
            <Grid item xs={1} container justifyContent="center" alignItems="center">
              Gender:
            </Grid>
            <Grid item xs={1} container justifyContent="center" alignItems="center">
              <ResultPshychoTitle>{gender ? gender : "Unknown"}</ResultPshychoTitle>
            </Grid>
          </Grid>

          <Divider>
            <Chip label="Occupation" size="small" style={ { marginTop: 5, marginBottom: 10} }/>
          </Divider>
          <Grid container spacing={3}>
            <Grid item xs={12} container justifyContent="center" alignItems="center">
            <ResultPshychoTitle>{occupation ? occupation : "Unknown"}</ResultPshychoTitle>
            </Grid>
          </Grid>

          <Divider>
            <Chip label="Summary" size="small" style={ { marginTop: 5, marginBottom: 10} }/>
          </Divider>
          <Grid container spacing={3}>
            <Grid item xs={12} container justifyContent="center" alignItems="center">
              {summary ? summary : "Unknown"}
            </Grid>
          </Grid>
        </Stack>
      )}
    </>
      
  )
}
