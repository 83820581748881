import {getAuth, onAuthStateChanged, ParsedToken, User} from 'firebase/auth'
import jwtDecode from 'jwt-decode'
import {Role} from '../../enum/role'
import {store} from '../index'
import {signOut} from './actionCreator'
import {LocalStorage} from '../../enum/storage'

export function getFirebaseUser(): Promise<User> {
  const auth = getAuth()

  return new Promise(resolve => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user)
      }
      else {
        store.dispatch(signOut())
      }
    })
  })
}

export function getToken(user: User): Promise<string> {
  return new Promise(async resolve => {
    const idToken: string = await user.getIdToken(true)
    resolve(idToken)
  })
}

export async function refreshToken(): Promise<string> {
  const user = await getFirebaseUser()
  const token = await getToken(user)

  localStorage.setItem(LocalStorage.TOKEN, token)

  return token
}

export function checkIsUserForbidden(token: string) {
  const decodedToken: ParsedToken = jwtDecode(token)

  const allowRules: string[] = [Role.ADMIN, Role.SUPER_ADMIN]

  return !allowRules.includes(decodedToken.role as string)
}