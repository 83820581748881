import {call, put, takeLeading} from 'redux-saga/effects'
import * as actionType from './actionType'
import {showSnackbar} from '../common/actionCreator'
import {api, Response} from '../../function/axios'
import {handleError} from '../../function/error'
import {setPackageRules, setPackage} from './actionCreator'
import * as commonActionCreator from '../common/actionCreator'

function* checkPackageData(action: actionType.CheckPackageData) {
  yield put(commonActionCreator.clearFieldError)

  const body: Record<string, string | number> = {}

  if (action.productId) body.productId = action.productId
  if (action.uid) body.userId = action.uid

  try {
    const {data}: Response = yield call(api, {
      method: 'POST',
      url: '/package/check',
      body: body,
    })

    if (data.productError) yield put(commonActionCreator.setFieldError('productId', data.productError))
    if (data.userError) yield put(commonActionCreator.setFieldError('uid', data.userError))

    if (!data.productError && !data.userError) yield call(action.onSuccess)
  } catch (e) {
    yield call(handleError, e)
  }
}

function* postPackage(action: actionType.PostPackage) {
  try {
    const {data}: Response = yield call(api, {
      method: 'POST',
      url: `/package`,
      body: action.data,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getPackage() {
  try {
    const {data}: Response = yield call(api, {
      method: 'GET',
      url: `/package/admin`,
    })

    yield put(setPackage(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* putPackage(action: actionType.PutPackage) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PUT',
      url: `/package/${action.id}`,
      body: action.data,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* deletePackage(action: actionType.DeletePackage) {
  try {
    const {data}: Response = yield call(api, {
      method: 'DELETE',
      url: `/package/${action.id}`,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* postWorkflow(action: actionType.PostWorkflow) {
  try {
    const {data}: Response = yield call(api, {
      method: 'POST',
      url: `/lookup`,
      body: action.data,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield put(showSnackbar('error', (e as Error).message))
  }
}

function* putWorkflow(action: actionType.PutWorkflow) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PUT',
      url: `/lookup/${action.id}`,
      body: action.data,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* deleteWorkflow(action: actionType.DeleteWorkflow) {
  try {
    const {data}: Response = yield call(api, {
      method: 'DELETE',
      url: `/lookup/${action.id}`,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getPackageRules() {
  try {
    const {data}: Response = yield call(api, {
      method: 'GET',
      url: '/package/marketing',
    })

    yield put(setPackageRules(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchPackage() {
  yield takeLeading(actionType.CHECK_PACKAGE_DATA, checkPackageData)
  yield takeLeading(actionType.POST_PACKAGE, postPackage)
  yield takeLeading(actionType.GET_PACKAGE, getPackage)
  yield takeLeading(actionType.PUT_PACKAGE, putPackage)
  yield takeLeading(actionType.DELETE_PACKAGE, deletePackage)
  yield takeLeading(actionType.POST_WORKFLOW, postWorkflow)
  yield takeLeading(actionType.PUT_WORKFLOW, putWorkflow)
  yield takeLeading(actionType.DELETE_WORKFLOW, deleteWorkflow)
  yield takeLeading(actionType.GET_PACKAGE_RULES, getPackageRules)
}