import {CountryRevenue} from './reducer'

export interface Revenue {
  country: string
  quantity: number
}

export function prepareRevenue(revenueData: Revenue[], filteredData: Revenue[]): CountryRevenue[] {
  const output: CountryRevenue[] = []

  revenueData.forEach(totalEl => {
    if (filteredData.length > 0) {
      filteredData.forEach(filteredEl => {
        if (totalEl.country === filteredEl.country) {
          output.push({
            country: totalEl.country,
            total: totalEl.quantity,
            filtered: filteredEl.quantity,
          })
        }
      })
    }
    else {
      output.push({
        country: totalEl.country,
        total: totalEl.quantity,
        filtered: 0,
      })
    }
  })

  return output
}