import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Joi from 'joi'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { useDispatch } from '../store'
import { updatePassword } from '../store/auth/actionCreator'
import { InputPassword } from '../component/InputPassword'
import { PublicPage } from '../component/auth/PagePublic'
import {
  ErrorsState,
  password,
  passwordRepeat,
  validateForm,
} from '../function/validation'
import { clearFieldError } from '../store/common/actionCreator'

const schema = Joi.object({
  password: password,
  passwordRepeat: passwordRepeat,
})

export function ChangePasswordPage() {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const oobCode = new URLSearchParams(location.search).get('oobCode') || ''

  const [password, setPassword] = React.useState('')
  const [passwordRepeat, setPasswordRepeat] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, password, passwordRepeat])
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const error = validateForm(schema, { password, passwordRepeat })

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(updatePassword(password, oobCode, navigate))
    }
  }

  return (
    <PublicPage>
      <Typography variant="bold" fontSize={30}>
        {t('settings.changePassword')}
      </Typography>

      <Grid item mt={10}>
        <form onSubmit={handleSubmit} noValidate>
          <Stack spacing={3}>
            <InputPassword
              value={password}
              error={errors?.password}
              placeholder={t('field.hint.password')}
              label={t('field.label.password')}
              required
              onInput={setPassword}
            />

            <InputPassword
              value={passwordRepeat}
              error={errors?.passwordRepeat}
              placeholder={t('field.hint.confirmPassword')}
              label={t('field.label.confirmPassword')}
              required
              onInput={setPasswordRepeat}
            />

            <Button type="submit" variant="contained" color="primary" fullWidth>
              {t('common.save')}
            </Button>

            <Button onClick={() => navigate('/auth')}>
              {t('auth.register.back')}
            </Button>
          </Stack>
        </form>
      </Grid>
    </PublicPage>
  )
}
