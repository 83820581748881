import * as React from 'react'
import {Color, ColorResult, SketchPicker} from 'react-color'
import {useTranslation} from 'react-i18next'
import {Box, FormControl, FormLabel} from '@mui/material'
import {colorSecondary, fontBold} from '../../mui'
import {Popper} from '../../component/Popper'

interface Props {
  color: Color
  onChange: (color: ColorResult) => void
}

export function InputColor({color, onChange}: Props) {
  const {t} = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  function handleClickAway() {
    setAnchorEl(null)
  }

  return (
    <FormControl fullWidth>
      <FormLabel sx={{fontSize: 14, fontFamily: fontBold}}>{t('field.label.color')}</FormLabel>
      <Box
        width={42}
        height={42}
        bgcolor={color as string}
        borderRadius={5}
        border={`1px solid ${colorSecondary}`}
        style={{borderRadius: 5, cursor: 'pointer'}}
        onClick={e => setAnchorEl(e.currentTarget)}
      />

      {anchorEl && (
        <Popper placement="top" anchorEl={anchorEl} onClickAway={handleClickAway}>
          <SketchPicker color={color} onChange={color => onChange(color)} disableAlpha/>
        </Popper>
      )}
    </FormControl>
  )
}