import * as React from 'react'
import { User as DefaultIcon } from 'react-iconly'
import { useTranslation } from 'react-i18next'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import { useDispatch } from '../../store'
import { uploadImage, deleteImage } from '../../store/settings/actionCreator'
import { showSnackbar } from '../../store/common/actionCreator'
import { blobToBase64, dataURItoBlob, resizeImage } from '../../function/image'

type Props = {
  src: string | null
  onClose: any
}

export const PopupImageUpload = ({ src, onClose }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const cropperRef = React.useRef<HTMLImageElement>(null)

  const [base64, setBase64] = React.useState(undefined)
  const [crop, setCrop] = React.useState<string | null>(null)

  const cropperSrc = React.useMemo(() => {
    return base64 || src
  }, [src, base64])

  const handleUpload = ({ target }: any) => {
    const fileTypes = ['jpg', 'jpeg', 'png']
    const file = target.files[0]

    const extension = file.name.split('.').pop().toLowerCase()
    const isSuccess = fileTypes.indexOf(extension) > -1

    const isOverWeight = file.size > 512000
    if (isOverWeight) {
      dispatch(showSnackbar('error', 'photo size should be less than 512KB'))
    } else {
      if (isSuccess) {
        const fileReader = new FileReader()

        fileReader.readAsDataURL(file)

        fileReader.onload = (e: any) => {
          if (e.target) {
            setBase64(e.target.result)
          }
        }
      } else {
        dispatch(showSnackbar('error', t('message.error.profilePicture')))
      }
    }
  }

  const handleCrop = () => {
    const imageElement: any = cropperRef?.current
    const cropper: any = imageElement?.cropper

    setCrop(cropper.getCroppedCanvas().toDataURL())
  }

  const handleSubmit = async () => {
    if (crop) {
      const imageBlob: any = dataURItoBlob(crop)
      const resizedImageBlob: any = await resizeImage(imageBlob)
      const base64: any = await blobToBase64(resizedImageBlob)
      dispatch(uploadImage(base64))
      onClose()
    } else
      dispatch(showSnackbar('error', t('message.error.profilePictureRequired')))
  }

  return (
    <Dialog open={true} maxWidth="md" onClose={onClose}>
      <DialogTitle>{t('settings.picture')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <Stack alignItems="center" spacing={3}>
        {cropperSrc ? (
          <Cropper
            ref={cropperRef}
            src={cropperSrc}
            style={{ height: 200, width: 200 }}
            viewMode={3}
            dragMode="move"
            autoCropArea={1}
            cropBoxResizable={false}
            cropBoxMovable={false}
            guides={false}
            center={false}
            minCropBoxWidth={170}
            minCropBoxHeight={170}
            crop={handleCrop}
          />
        ) : (
          <>
            <Stack
              justifyContent="center"
              alignItems="center"
              width={200}
              height={200}
              bgcolor="#EFF0F6"
            >
              <DefaultIcon
                style={{ width: 100, height: 100, color: '#D6D7E3' }}
              />
            </Stack>
            <Typography>{t('settings.pictureHint')}</Typography>
          </>
        )}
      </Stack>

      <Divider variant="popup" color="primary" />

      <DialogActions>
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" color="primary" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button
            variant="outlined"
            color="error"
            disabled={src === null}
            onClick={() => dispatch(deleteImage)}
          >
            {t('common.delete')}
          </Button>
          <input
            type="file"
            accept="image/*"
            id="uploadImage"
            multiple
            style={{ display: 'none' }}
            onChange={handleUpload}
          />
          <label htmlFor="uploadImage">
            <Button variant="outlined" color="primary" component="span">
              {t('settings.uploadNewImage')}
            </Button>
          </label>
          <Button
            variant="contained"
            color="primary"
            disabled={cropperSrc === null}
            onClick={handleSubmit}
          >
            {t('common.save')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
