import React from 'react'
import {makeStyles} from '@mui/styles'
import {useTranslation} from 'react-i18next'
import '../../sass/index.sass'
import InfiniteScroll from 'react-infinite-scroll-component'
import {FiberManualRecord as NotifyIcon} from '@mui/icons-material'
import {
  Badge, IconButton, MenuItem, Stack, Typography, Popper, ClickAwayListener, Paper, Dialog, DialogTitle, Divider,
  DialogActions, Button, DialogContent, Grid,
} from '@mui/material'
import {SvgIcon} from '../../component/SvgIcon'
import {useDispatch, useSelector} from '../../store'
import {addAuthLocalStorageItemToArray, getAuthLocalStorageArray} from '../../function/storage'
import {LocalStorage} from '../../enum/storage'
import {fetchNotification, readNotification} from '../../store/support/actionCreator'
import {clearNotifications} from '../../store/support/actionCreator'
import {Notification as INotification} from '../../store/support/reducer'
import {colorPrimary} from '../../mui'
import {getRefreshedToken} from '../../store/auth/selector'

type EventRead = React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>

const DEFAULT_LIMIT = 30
const REFRESH_INTERVAL_MINUTES = 3

const useAdminMessagePopupStyles = makeStyles(() => {
  return {
    paper: {
      minWidth: 640,
    },
  }
})

export function Notification() {
  const {t} = useTranslation()
  const adminMessagePopupStyles = useAdminMessagePopupStyles()
  const dispatch = useDispatch()

  const storageRedNotifications = getAuthLocalStorageArray(LocalStorage.RED_NOTIFICATION)

  const refreshedToken = useSelector(getRefreshedToken)
  const {notifications} = useSelector(state => state.support)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [params, setParams] = React.useState({offset: 0, needMore: false})
  const [popup, setPopup] = React.useState<{ title: string, text: string } | null>(null)

  React.useEffect(() => {
    if (refreshedToken === null) return

    dispatch(fetchNotification(params.offset, DEFAULT_LIMIT, params.needMore))
  }, [refreshedToken, dispatch, params])

  React.useEffect(() => {
    if (refreshedToken === null) return

    const intervalId = setInterval(() => {
      dispatch(fetchNotification(0, DEFAULT_LIMIT, undefined, true))
    }, REFRESH_INTERVAL_MINUTES * 60 * 1000)

    return function cleanup() {
      clearInterval(intervalId)
    }
  }, [refreshedToken, dispatch])

  const data = React.useMemo(() => {
    return notifications.map(el => ({...el, isRed: storageRedNotifications.includes(el.id)}))
  }, [notifications, storageRedNotifications])

  const redNotifications = React.useMemo(() => {
    return data.filter(el => !storageRedNotifications.includes(el.id))
  }, [data, storageRedNotifications])

  const hasNewNotifications = redNotifications.length > 0
  const hasNotifications = notifications.length > 0
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  function handleButtonClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  function handleReadNotification(e: EventRead, {id, title, text}: INotification) {
    e.stopPropagation()

    addAuthLocalStorageItemToArray(LocalStorage.RED_NOTIFICATION, id)
    dispatch(readNotification(id))

    setAnchorEl(null)
    setPopup({title, text})
  }

  function handleFetchMore() {
    setParams(prevState => {
      return {offset: prevState.offset + DEFAULT_LIMIT, needMore: true}
    })
  }

  function handleDelete() {
    dispatch(clearNotifications())
  }
  return (
    <>
      <IconButton aria-describedby={id} onClick={handleButtonClick} sx={{padding: 0, width: 40, height: 40}}>
        <Badge color="error" overlap="circular" badgeContent="" variant="dot" invisible={!hasNewNotifications}>
          <SvgIcon name="notification" size={{width: 30, height: 30}}/>
        </Badge>
      </IconButton>

      <Popper id={id} open={open} anchorEl={anchorEl} style={{zIndex: 1}}>
        <Paper className="notification">
          <ClickAwayListener onClickAway={() => setAnchorEl(null)} disableReactTree={false}>
            {hasNotifications ? (
              <InfiniteScroll
                className='scrollNotification'
                dataLength={data.length}
                next={handleFetchMore}
                hasMore={true}
                loader={null}
                endMessage={
                  <p style={{textAlign: 'center'}}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {data.map((el, idx) => (
                  <MenuItem key={el.id} onClick={e => handleReadNotification(e, el)} className={idx === data.length - 1 ? 'links' : ''}>
                    <Stack direction="row">
                      <Stack justifyContent="center" width={20}>
                        {!el.isRed && <NotifyIcon sx={{fontSize: 15, color: colorPrimary}}/>}
                      </Stack>
                      <Stack width={360}>
                        <Typography className="notification__title" variant="bold">{el.title}</Typography>
                        <Typography className="notification__text">{el.text}</Typography>
                      </Stack>
                    </Stack>
                  </MenuItem>
                ))}
                <Grid xs={12} textAlign="center" item style={{
                  position: "absolute",
                  backgroundColor: "white",
                  bottom: '0px',
                  height: '40px',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Button onClick={handleDelete}>Clear All</Button>
                </Grid>
              </InfiniteScroll>
            ) : (
              <MenuItem>
                <Stack direction="row" justifyContent="center" width="100%">
                  <Typography>{t('notification.hasNo')}</Typography>
                </Stack>
              </MenuItem>
            )}
          </ClickAwayListener>
        </Paper>
      </Popper>

      <Dialog open={Boolean(popup)} classes={{paper: adminMessagePopupStyles.paper}} onClose={() => setPopup(null)}>
        <DialogTitle>
          <Typography variant="bold" fontSize={18}>{popup?.title}</Typography>
        </DialogTitle>
        <Divider variant="popup" color="primary"/>
        <DialogContent>
          <Typography fontSize={18}>{popup?.text}</Typography>
        </DialogContent>
        <Divider variant="popup" color="primary"/>
        <DialogActions sx={{justifyContent: 'flex-end'}}>
          <Button variant="outlined" color="primary" size="small" onClick={() => setPopup(null)}>
            {t('common.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}