import * as React from 'react'
import {ExpandMore as SelectIcon} from '@mui/icons-material'
import {useTranslation} from 'react-i18next'
import {FormControl, FormLabel, MenuItem, Select} from '@mui/material'
import {useDispatch, useSelector} from '../store'
import {getDepartment} from '../store/auth/actionCreator'
import {TypeFormError} from '../function/validation'
import {FormError} from './FormError'

interface Props {
  label?: string
  required?: boolean
  value: string | number
  error: TypeFormError
  onChange: (value: string) => void
}

export const InputDepartment = ({label, required, value, error, onChange}: Props) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const {department} = useSelector(state => state.auth)

  React.useEffect(() => {
    if (department === null) dispatch(getDepartment)
  }, [department, dispatch])

  return (
    <FormControl fullWidth>
      {label && <FormLabel required={required}>{label}</FormLabel>}

      <Select
        value={value}
        IconComponent={SelectIcon}
        renderValue={(selected) => selected === 'null'
          ? <em>{t('field.hint.department')}</em>
          : department?.filter(el => el.id === selected)[0]?.name
        }
        onChange={e => onChange(e.target.value as string)}
      >
        <MenuItem disabled value="all"><em>{t('field.hint.department')}</em></MenuItem>
        {department?.map(el => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)}
      </Select>
      <FormError error={error}/>
    </FormControl>
  )
}