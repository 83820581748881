import {getMessaging, onMessage} from 'firebase/messaging'
import {initializeApp} from 'firebase/app'
import {store} from './store'
import {addNewNotification} from './store/support/actionCreator'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export const init = () => {
  initializeApp(firebaseConfig)

  const messaging = getMessaging()

  onMessage(messaging, ({notification}: any) => {
    const {body: json, title}: any = notification
    const {notificationId: id, message} = JSON.parse(json)

    console.log('notification', notification)

    store.dispatch(addNewNotification([{id, title, text: message}]))
  })
}
