import * as React from 'react'
import { Stack } from '@mui/material'
import content_ from '../sass/content.module.sass'
import { useSelector } from '../store'
import { LocalStorage } from '../enum/storage'
import { getIsRtl } from '../store/common/selector'
import { Profile } from '../container/common/Profile'
import { Menu } from '../container/common/Menu'
import { Notification } from '../container/common/Notification'

type Props = {
  children: React.ReactNode
  header?: React.ReactNode
}

const mdWidth = 960

const handleOnload = (storageMenuOpen: boolean) => {
  const width = window.innerWidth
  const isMobile = width < mdWidth

  if (isMobile) return false
  else return storageMenuOpen
}

export function PrivatePage({ children, header }: Props) {
  const storageMenuValue =
    localStorage.getItem(LocalStorage.MENU_OPEN) || 'true'
  const storageMenuOpen: boolean = storageMenuValue === 'true'

  const isRtl = useSelector(getIsRtl)

  const handleResize = React.useCallback(() => {
    const width = window.innerWidth
    const isMobile = width < mdWidth

    setIsMobile(isMobile)

    if (isMobile) setNavbarOpen(false)
    else setNavbarOpen(storageMenuOpen)
  }, [storageMenuOpen])

  const [navbarOpen, setNavbarOpen] = React.useState<boolean>(
    handleOnload(storageMenuOpen),
  )
  const [isMobile, setIsMobile] = React.useState<boolean>()

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  const handleNavbarOpen = (status: boolean) => {
    setNavbarOpen(status)
    localStorage.setItem(LocalStorage.MENU_OPEN, String(status))
  }

  let contentClassName = navbarOpen ? content_.root_narrow : content_.root_wide
  contentClassName = isMobile ? content_.root_mobile : contentClassName
  contentClassName = `${contentClassName} ${
    isRtl ? content_.root_rtl : content_.root_ltr
  }`

  return (
    <>
      <Menu navbarOpen={navbarOpen} setNavbarOpen={handleNavbarOpen} />

      <Stack className={contentClassName}>
        {header ? (
          header
        ) : (
          <Stack
            direction="row"
            justifyContent="flex-end"
            bgcolor="#FFF"
            p={2}
            spacing={1}
          >
            <Notification />
            <Profile />
          </Stack>
        )}

        <Stack alignItems="flex-start" p={4} width="100%" spacing={4}>
          {children}
        </Stack>
      </Stack>
    </>
  )
}
