import { SocialNetworkGroups } from "../enum/provider"
import { PostDataItem } from "../store/searcher/reducer"

export const getLinkToProfile = (el : PostDataItem) => {
    let result = undefined
    switch (el.from) {
      case 'vk': {
        result = SocialNetworkGroups.vk + el.profile_id
        break
      }
      case 'twitter': {
        result = SocialNetworkGroups.twitter + el.profile_id
        break
      }
      case 'ok': {
        result = SocialNetworkGroups.ok + el.profile_id 
        break
      }
      case 'instagram': {
        result = SocialNetworkGroups.instagram + el.profile_id
        break
      }
      
    }
    return result 
  }