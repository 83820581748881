import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Backdrop, CircularProgress, CssBaseline } from '@mui/material'
import { Snackbar } from './component/Snackbar'
import { useDispatch, useSelector } from './store'
import { LocalStorage } from './enum/storage'
import { getLoading } from './store/common/selector'
import { refreshToken } from './store/auth/actionCreator'
import { initNotification } from './store/support/actionCreator'
import { fetchProfile } from './store/settings/actionCreator'
import { useAuthEffect } from './hook/useAuthEffect'
import { LoginPage } from './page/AuthLoginPage'
import { RegisterPage } from './page/AuthRegisterPage'
import { RegisterSentPage } from './page/AuthRegisterSentPage'
import { TwoStepPage } from './page/Auth2StepPage'
import { ForgotPage } from './page/AuthForgotPage'
import { ForgotSentPage } from './page/AuthForgotSentPage'
import { ChangePasswordPage } from './page/AuthChangePassword'
import { DashboardPage } from './page/DashboardPage'
import { PaymentPage } from './page/PaymentPage'
import { MarketingPage } from './page/MarketingPage'
import { PackagePage } from './page/PackagePage'
import { AdminsPage } from './page/AdminsPage'
import { SubscribersPage } from './page/SubscribersPage'
import { ApikeyPage } from './page/ApikeyPage'
import { SupportPage } from './page/SupportPage'
import { SettingsPage } from './page/SettingsPage'
import { SubscriberPage } from './page/SubscriberPage'
import { ResultPage } from './page/ResultPage'

type PrivateRouteProps = {
  children: JSX.Element
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const dispatch = useDispatch()

  const token = localStorage.getItem(LocalStorage.TOKEN)

  const handleTokenOnVisibilityChange = React.useCallback(() => {
    if (!document.hidden) {
      dispatch(refreshToken())
    }
  }, [dispatch])

  React.useEffect(() => {
    const token = localStorage.getItem(LocalStorage.TOKEN)

    if (token === null) return

    dispatch(refreshToken())

    document.addEventListener('visibilitychange', handleTokenOnVisibilityChange)

    return function cleanup() {
      document.removeEventListener(
        'visibilitychange',
        handleTokenOnVisibilityChange,
      )
    }
  }, [dispatch, handleTokenOnVisibilityChange])

  useAuthEffect(() => {
    dispatch(initNotification)
    dispatch(fetchProfile)
  })

  return token ? children : <Navigate to="/auth" />
}

export function App() {
  const loading = useSelector(getLoading)

  return (
    <>
      <CssBaseline enableColorScheme />

      <div id="recaptcha" />

      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress color="primary" style={{ zIndex: 99999 }} />
      </Backdrop>

      <Snackbar />

      <BrowserRouter>
        <Routes>
          <Route path="/auth" element={<LoginPage />} />
          <Route path="/auth/register" element={<RegisterPage />} />
          <Route path="/auth/register/send" element={<RegisterSentPage />} />
          <Route path="/auth/forgot" element={<ForgotPage />} />
          <Route path="/auth/forgot/sent" element={<ForgotSentPage />} />
          <Route
            path="/auth/change/password"
            element={<ChangePasswordPage />}
          />
          <Route path="/auth/2step" element={<TwoStepPage />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/payment"
            element={
              <PrivateRoute>
                <PaymentPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/marketing"
            element={
              <PrivateRoute>
                <MarketingPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/package"
            element={
              <PrivateRoute>
                <PackagePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/admins"
            element={
              <PrivateRoute>
                <AdminsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/subscribers"
            element={
              <PrivateRoute>
                <SubscribersPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/subscriber/:id"
            element={
              <PrivateRoute>
                <SubscriberPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/result/:id"
            element={
              <PrivateRoute>
                <ResultPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/apikey"
            element={
              <PrivateRoute>
                <ApikeyPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/support"
            element={
              <PrivateRoute>
                <SupportPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <SettingsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  )
}
