import { useSelector } from '../../store'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import dashboard_ from '../../sass/dashboard.module.sass'
import { t } from 'i18next'
import React from 'react'
import { BlockProps } from './subscriberTypes'

export default function UserBlock({ data }: BlockProps) {
  const subscriber = useSelector((state) => state.common.subscriber)
  return (
    <Grid item xs>
      <Box className={dashboard_.card}>
        <Stack spacing={1} p={1}>
          <Typography variant="semiBold" fontSize={18} noWrap>
            {t('subscriber.userType')}
          </Typography>
          <Typography variant="semiBold" fontSize={24} color="primary">
            {subscriber.userType}
          </Typography>
        </Stack>
        <Divider orientation="vertical" />
        <Stack spacing={1} p={1}>
          <Typography variant="semiBold" fontSize={18} component="h2" noWrap>
            {t('subscriber.userLTV')}
          </Typography>
          <Typography variant="semiBold" fontSize={24} color="primary">
            {data.ltv ? data.ltv + '€' : '-'}
          </Typography>
        </Stack>
      </Box>
    </Grid>
  )
}