import * as React from 'react'
import CountUp from 'react-countup'
import {Card, Stack, Typography} from '@mui/material'
import {Percentage} from './Percentage'

type Props = {
  title: string
  amount: number | undefined
  amountSuffix?: string
  diff: number | undefined
}

export const TotalSingle = ({title, amount, amountSuffix, diff}: Props) => {
  return (
    <Card>
      <Stack>
        <Typography variant="semiBold" fontSize={18} noWrap>{title}</Typography>

        <Typography variant="semiBold" fontSize={38} color="primary">
          {amount ? <CountUp end={amount} separator=","/> : 0}{amountSuffix}
        </Typography>

        <Percentage value={diff}/>
      </Stack>
    </Card>
  )
}