import {AuthAction} from './actionType'

type Department = {
  id: number
  name: string
}

export type AuthState = {
  phoneVerify: boolean
  department: Department[] | null
  refreshedToken: string | null
}

const initialState: AuthState = {
  department: null,
  phoneVerify: false,
  refreshedToken: null,
}

export function authReducer(state = initialState, action: AuthAction) {
  switch (action.type) {
    case 'auth/FLUSH_STATE':
      return {
        ...state,
        ...initialState,
      }
    case 'auth/SET_DEPARTMENT':
      return {
        ...state,
        department: action.data,
      }
    case 'auth/SET_PHONE_VERIFY':
      return {
        ...state,
        phoneVerify: action.value,
      }
    case 'auth/SET_REFRESHED_TOKEN':
      return {
        ...state,
        refreshedToken: action.token,
      }
    default:
      return state
  }
}
