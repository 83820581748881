import {Theme} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'

export const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: '0 15px',
      height: 40,
    },
    delete: {
      padding: '0 20px',
      color: '#FFF',
      background: theme.palette.error.dark,
      '&:hover': {
        background: theme.palette.error.main,
      },
    },
    yellowGreen: {
      color: theme.palette.black.main,
      background: theme.palette.yellowGreen.main,
      '&:hover': {
        background: theme.palette.yellowGreen.light,
      },
    },
  }),
)
