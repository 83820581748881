import * as React from 'react'
import {Box, Stack, Typography} from '@mui/material'
import {SvgIcon} from '../SvgIcon'
import {colorError, colorSuccess} from '../../mui'
import {usePercentage} from '../../hook/usePercentage'

export type Level = 'high' | 'middle' | 'low'

type Props = {
  value: number | null | undefined
}

export const Percentage = ({value}: Props) => {
  const {color, level} = usePercentage(value)

  if (!value) return null

  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="semiBold" fontSize={16} color={color}>{value || 0}%</Typography>
      <Box ml={1}>
        {level === 'low' && <SvgIcon name="statisticArrowDown" color={colorError} size={{width: 12, height: 12}}/>}
        {level === 'middle' && null}
        {level === 'high' && <SvgIcon name="statisticArrowUp" color={colorSuccess} size={{width: 12, height: 12}}/>}
      </Box>
    </Stack>
  )
}