import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Button, Stack, Typography} from '@mui/material'
import {PublicPage} from '../component/auth/PagePublic'
import {Agreement} from '../component/auth/Agreement'

export function RegisterSentPage() {
  const navigate = useNavigate()
  const {t} = useTranslation()

  const {state: email} = useLocation()

  return (
    <PublicPage>
      <Stack spacing={3}>
        <Typography variant="bold" fontSize={30}>{t('auth.register.title')}</Typography>

        <Typography fontSize={16}>
          <span>{t('auth.register.check1')} <Typography variant="semiBold"
                                                        color="primary">{email}</Typography> {t('auth.register.check2')}
          </span>
        </Typography>

        <Button variant="contained" size="large" color="primary" onClick={() => navigate('/auth')}>Ok</Button>

        <Agreement/>
      </Stack>
    </PublicPage>
  )
}