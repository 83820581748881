import React from 'react'
import {ClickAwayListener, Popper as MuiPopper} from '@mui/material'
import popper_ from '../sass/popper.module.sass'
import {SvgIcon} from './SvgIcon'

interface Props {
  placement: 'top' | 'bottom'
  anchorEl: HTMLElement
  children: React.ReactNode
  onClickAway: (event: MouseEvent | TouchEvent) => void
}

export function Popper({placement, anchorEl, children, onClickAway}: Props) {
  const arrowDirection = placement === 'top' ? 'bottom' : 'top'
  const icon = placement === 'top' ? 'statisticArrowDown' : 'statisticArrowUp'

  return (
   <ClickAwayListener onClickAway={onClickAway}>
     <MuiPopper
       className={popper_['root_' + placement]}
       open={true}
       anchorEl={anchorEl}
       placement={placement}
     >
       <SvgIcon
         className={popper_[`arrow_${arrowDirection}`]}
         name={icon}
         color="#FFF"
         size={{width: 20, height: 20}}
       />
       {children}
     </MuiPopper>
   </ClickAwayListener>
  )
}