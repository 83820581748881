import {createTheme} from '@mui/material/styles'

export const colorPrimary = '#2E97D5'
export const colorPrimaryLight = '#F2FBFF'
export const colorPrimaryDark = '#3082B3'
export const colorSecondary = '#979797'
export const colorSecondaryLight = '#E8E8E8'
export const colorSecondaryDark = '#8B929B'
export const colorSuccess = '#2ED571'
export const colorSuccessLight = '#C5F2C7'
export const colorError = '#FF0000'
export const colorErrorLight = '#FCD0CF'
export const colorErrorDark = '#950000'
export const colorText = '#01163E'
export const colorLightGreen = '#D1EC2C'
export const colorLightGreenLight = '#E3FD4F'
export const colorBackground = '#F0F2FA'
export const colorRevenueTotal = '#E8E0FE'
export const colorRevenueFiltered = '#ACCAF3'

export const fontLight = 'Montserrat-Light, sans-serif'
export const fontRegular = 'Montserrat-Regular, sans-serif'
export const fontSemiBold = 'Montserrat-SemiBold, sans-serif'
export const fontBold = 'Montserrat-Bold, sans-serif'
export const fontBlack = 'Montserrat-Black, sans-serif'

export const boxShadow = '2px 2px 7px rgba(0, 0, 0, 0.1)'

export const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1690,
    },
  },
  typography: {
    fontFamily: `"Montserrat-Regular", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  zIndex: {
    drawer: 1,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          background: colorBackground,
          minWidth: 340,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontFamily: fontRegular,
          color: colorText,
        },
      },
      variants: [
        {
          props: {variant: 'light'},
          style: {
            fontFamily: fontLight,
          },
        }, // light
        {
          props: {variant: 'semiBold'},
          style: {
            fontFamily: fontSemiBold,
          },
        }, // semiBold 600
        {
          props: {variant: 'bold'},
          style: {
            fontFamily: fontBold,
          },
        }, // bold 700
        {
          props: {variant: 'black'},
          style: {
            fontFamily: fontBlack,
          },
        }, // black 900
        {
          props: {variant: 'title'},
          style: {
            fontSize: 26,
            fontFamily: fontBold,
          },
        }, // title (content)
        {
          props: {variant: 'text'},
          style: {
            fontSize: 16,
            fontFamily: fontLight,
          },
        }, // text (content)
        {
          props: {variant: 'underlined'},
          style: {
            textDecoration: 'underline !important',
            '&:hover': {
              color: colorPrimaryDark,
            },
          },
        }, // underlined
        {
          props: {color: 'success'},
          style: {
            color: colorSuccess,
          },
        }, // success
      ],
    },
    MuiBadge: {
      styleOverrides: {
        anchorOriginTopRightRectangular: {
          top: 7,
          right: 7,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          whiteSpace: 'nowrap',
          fontFamily: fontSemiBold,
          // '&.Mui-disabled': {
          //   color: 'inherit',
          //   WebkitTextFillColor: 'unset',
          //   background: 'inherit',
          // },
        },
        contained: {
          color: '#FFF',
        },
        sizeSmall: {
          padding: '0 25px',
          height: 34,
        },
        sizeMedium: {
          padding: '0 16px',
          height: 41,
          fontSize: 15,
        },
        sizeLarge: {
          padding: '8px 34px',
          height: 44,
        },
      },
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: {variant: 'underlined'},
          style: {
            padding: 0,
            height: 20,
            textDecoration: 'underline',
            fontSize: 14,
            fontFamily: fontSemiBold,
            color: colorPrimary,
            '&:hover': {
              color: colorPrimaryDark,
              background: 'none',
            },
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          width: 22,
          height: 22,
        },
        colorPrimary: {
          background: colorPrimary,
          '&:hover': {
            background: colorPrimaryDark,
          },
        },
      },
      variants: [
        {
          props: {color: 'error'},
          style: {
            background: colorError,
            '&:hover': {
              background: colorErrorDark,
            },
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          padding: '17px 30px',
          width: '100%',
          height: '100%',
          borderRadius: 20,
          boxSizing: 'border-box',
          boxShadow: boxShadow,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        title: {
          fontSize: 18,
          fontFamily: fontSemiBold,
          color: colorText,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          marginTop: 3,
          padding: 0,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          marginTop: 'auto',
          padding: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: 14,
          height: 29,
          fontFamily: fontBold,
          color: '#FFF',
          border: 'none',
        },
        sizeSmall: {
          padding: 5,
          height: 26,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: 24,
          borderRadius: 20,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          textAlign: 'center',
          fontSize: 26,
          fontFamily: fontBold,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
        },
      },
    },
    MuiDivider: {
      variants: [
        {
          props: {color: 'primary'},
          style: {
            background: colorPrimary,
          },
        },
        {
          props: {variant: 'popup'},
          style: {
            margin: '20px 0',
          },
        },
      ],
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: '35px',
          fontSize: 14,
          fontFamily: fontBold,
          color: '#000',
        },
        asterisk: {
          color: colorError,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          userSelect: 'none',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& button': {
            color: '#C9C9C9',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          fontSize: 14,
          fontFamily: fontRegular,
          height: 42,
          background: '#EFF0F6',
          borderRadius: 5,
          '&.Mui-disabled input': {
            color: colorText,
            WebkitTextFillColor: 'unset',
          },
        },
        input: {
          padding: 10,
          '&::placeholder': {
            fontSize: 13,
            fontFamily: fontLight,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          margin: '8px 0',
          height: 10,
          borderRadius: 4,
          background: '#C4C4C4',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          textDecoration: 'none',
          cursor: 'pointer',
          '&:hover': {
            color: colorPrimary,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontFamily: fontSemiBold,
          '&.Mui-active': {
            color: colorPrimary,
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderWidth: 2,
          borderColor: colorPrimary,
        },
      },
    },
    MuiSvgIcon: {
      variants: [
        {
          props: {fontSize: 'large'},
          style: {
            fontSize: 36,
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: colorPrimary,
      light: colorPrimaryLight,
    },
    secondary: {
      main: colorSecondary,
      light: colorSecondaryLight,
      dark: colorSecondaryDark,
    },
    success: {
      main: colorSuccess,
      light: colorSuccessLight,
    },
    error: {
      main: colorError,
      light: colorErrorLight,
    },
    warning: {
      main: '#FFBB13',
    },
    black: {
      main: colorText,
    },
    white: {
      main: '#FFF',
    },
    yellowGreen: {
      main: colorLightGreen,
      light: colorLightGreenLight,
    },
  },
})