import * as React from 'react'
import {Level} from '../component/dasboard/Percentage'

export function usePercentage(value: number | null | undefined, isPDF?: boolean) {
  const level: Level | null = React.useMemo(() => {
    if (value === undefined || value === null) return null
    if (value > 0) return 'high'
    else if (value < 0) return 'low'
    else return 'middle'
  }, [value])

  const color = React.useMemo(() => {
    if (value === undefined || value === null) return isPDF ? '#979797' : 'secondary'
    if (value > 0) return isPDF ? '#2ED571' : 'success'
    else if (value < 0) return isPDF ? '#FF0000' : 'error'
    else return isPDF ? '#979797' : 'secondary'
  }, [value, isPDF])

  return {level, color}
}