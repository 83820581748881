import * as React from 'react'
import {Visibility, VisibilityOff} from '@mui/icons-material'
import {FormControl, FormLabel, IconButton, InputAdornment, OutlinedInput} from '@mui/material'
import {preventEmptySpaceOnInput, preventEmptySpaceOnKeyDown, TypeFormError} from '../function/validation'
import {FormError} from './FormError'

interface Props {
  value: string
  onInput: React.Dispatch<React.SetStateAction<string>>
  placeholder: string
  label?: string
  error?: TypeFormError
  inputProps?: {
    [key: string]: any
  }
  required?: boolean
}

export const InputPassword = ({label, value, error, onInput, placeholder, inputProps, required}: Props) => {
  const [showPass, setShowPass] = React.useState(false)

  const handleClickShowPassword = () => {
    setShowPass(!showPass)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <FormControl fullWidth>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      <OutlinedInput
        type={showPass ? 'text' : 'password'}
        name="loginPassword"
        placeholder={placeholder}
        value={value}
        error={Boolean(error)}
        autoComplete="off"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              tabIndex={-1}
            >
              {showPass ? <VisibilityOff/> : <Visibility/>}
            </IconButton>
          </InputAdornment>
        }
        fullWidth
        onChange={e => preventEmptySpaceOnInput(e, onInput)}
        onKeyDown={preventEmptySpaceOnKeyDown}
        {...inputProps}
      />
      <FormError error={error}/>
    </FormControl>
  )
}