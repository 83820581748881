import {api, Response} from '../../function/axios'

export function getFileExtensionFromUrl(url: string) {
  return url.split(/[#?]/)[0].split('.').pop()?.trim()
}

export async function fetchS3File(url: string): Promise<BlobPart> {
  const {data}: Response = await api({
    method: 'GET',
    customApi: url,
    responseType: 'blob',
  })

  return data
}

export async function getS3Blob(url: string) {
  const extension = getFileExtensionFromUrl(url)

  const data = await fetchS3File(url)

  switch (extension) {
    case 'pdf':
      return new Blob([data], {type: 'application/pdf'})
    default:
      return new Blob([data], {type: `image/${extension}`})
  }
}

export function downloadBlob(blob: Blob, name: string) {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob)

  // Create a link element
  const link = document.createElement('a')

  // Set link's href to point to the Blob URL
  link.href = blobUrl
  link.download = name

  // Append link to the body
  document.body.appendChild(link)

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  )

  // Remove link from body
  document.body.removeChild(link)
}
