import React from 'react'
import { t } from 'i18next'
import { makeStyles } from '@mui/styles'
import { Provider as StoreProvider } from 'react-redux'
import { Email as EmailIcon } from '@mui/icons-material'
import dashboard_ from '../../sass/dashboard.module.sass'
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { formatDate } from '../../function/date'
import { getPercentSize } from '../../function/common'
import { SvgIcon } from '../../component/SvgIcon'
import { colorPrimary } from '../../mui'
import { store, useDispatch, useSelector } from '../../store'
import { SubscriberTickets } from './SubscriberTickets'
import {
  FailedSubscriberRequests,
  failedSubscriberRequestsTableId,
} from './FailedSubscriberRequests'
import { useSubscriber } from '../../hook/useSubscriber'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { SubscriberPDF } from './PDFSubscriber'
import { UsageBreakdown } from './UsageBreakdown'
import { Usage } from '../../store/dashboard/reducer'
import { toggleBlock } from '../../store/user/actionCreator'
import { PopupAsk } from '../../component/PopupAsk'
import { HeaderRef } from '../../page/DashboardPage'
import { PopupNotify } from '../../component/PopupNotify'
import { Subscriber, SubscriberStatus } from '../../store/user/reducer'
import { TableRef } from '../../component/datatable/types'
import { TableStatus } from '../../component/datatable/component'
import { EnumSubscriberStatus } from '../../enum/statusSubscriber'
import { getTables } from '../../store/common/selector'
import { PackageBlock } from './BlockPackage'
import { SubscriberType } from '../../store/marketing/reducer'
import { getSubscriber } from '../../store/common/actionCreator'

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: '1400px',
  },
}))

type MainInfoProps = {
  label: string
  value: string | number
}

function MainInfo({ label, value }: MainInfoProps) {
  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="bold" noWrap lineHeight="18px">
        {label} -
      </Typography>
      &nbsp;
      <Typography lineHeight="18px">{value}</Typography>
    </Stack>
  )
}

type LookupChartItemProps = {
  quantity: number
  name: string
  height: string
}

function LookupChartItem({ quantity, name, height }: LookupChartItemProps) {
  return (
    <Stack justifyContent="flex-end" width="100%">
      <Stack
        justifyContent="flex-end"
        alignItems="center"
        height={height}
        bgcolor="#D1E0F8"
      >
        <Typography variant="semiBold" fontSize={12} color="primary">
          {quantity}
        </Typography>
      </Stack>
      <Typography
        variant="light"
        fontSize={13}
        lineHeight="13px"
        mt={1}
        align="center"
        sx={{ color: '#000' }}
      >
        {name}
      </Typography>
    </Stack>
  )
}

interface BlockProps {
  data: Subscriber
}

function UserBlock({ data }: BlockProps) {
  const tables = useSelector(getTables)
  const time = useSelector((state) => state.dashboard.time)
  const subscriber = useSelector((state) => state.common.subscriber)
  const tableData = tables ? tables[failedSubscriberRequestsTableId] : null
  return (
    <Box className={dashboard_.card} width="23%">
      <Stack spacing={1} p={1}>
        <Typography variant="semiBold" fontSize={18} noWrap>
          {t('subscriber.userType')}
        </Typography>
        <Typography variant="semiBold" fontSize={24} color="primary">
          {subscriber.userType}
          {/*subscriber.data.userType || tableData?.userType*/}
        </Typography>
      </Stack>
      <Divider orientation="vertical" />
      <Stack spacing={1} p={1}>
        <Typography variant="semiBold" fontSize={18} component="h2" noWrap>
          {t('subscriber.userLTV')}
        </Typography>
        <Typography variant="semiBold" fontSize={24} color="primary">
          {data.ltv ? data.ltv + '€' : '-'}
        </Typography>
      </Stack>
    </Box>
  )
}

function LookupsBlock({ data }: BlockProps) {
  const { lookupChartTopValue } = useSubscriber(data)
  const subscriber = useSelector((state) => state.common.subscriber)
  return (
    <Box className={dashboard_.card} width="38%">
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        spacing={1}
      >
        <LookupChartItem
          name={t('subscriber.phone')}
          quantity={subscriber.phoneCount}
          height={getPercentSize(subscriber.phoneCount, lookupChartTopValue)}
        />
        <LookupChartItem
          name={t('subscriber.name')}
          quantity={subscriber.nameCount}
          height={getPercentSize(subscriber.nameCount, lookupChartTopValue)}
        />
        <LookupChartItem
          name={t('subscriber.post')}
          quantity={subscriber.postCount}
          height={getPercentSize(subscriber.postCount, lookupChartTopValue)}
        />
        <LookupChartItem
          name={t('subscriber.snID')}
          quantity={0}
          height={getPercentSize(0, lookupChartTopValue)}
        />
        <LookupChartItem
          name={t('subscriber.image')}
          quantity={0}
          height={getPercentSize(0, lookupChartTopValue)}
        />
        <LookupChartItem
          name={t('subscriber.address')}
          quantity={0}
          height={getPercentSize(0, lookupChartTopValue)}
        />
        <LookupChartItem
          name={t('subscriber.email')}
          quantity={0}
          height={getPercentSize(0, lookupChartTopValue)}
        />
      </Stack>
    </Box>
  )
}

interface Props {
  data: Subscriber
  usage: Usage
  time?: 'day' | 'week' | 'month' | 'year'
  onClose: () => void
  headerRef?: React.RefObject<HeaderRef>
  tableRef?: React.RefObject<TableRef>
  initialStatus?: SubscriberStatus
}

export function PopupSubscribers({
  data,
  usage,
  headerRef,
  tableRef,
  initialStatus,
  time,
  onClose,
}: Props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  console.log(data)
  const [emailOpen, setEmailOpen] = React.useState(false)
  const [blockingId, setBlockingId] = React.useState<number>()

  function blockCallback() {
    headerRef?.current?.refreshRequest()
    tableRef?.current?.refresh()
    data.isBlocked = true
    data.status = EnumSubscriberStatus.BLOCKED
  }

  React.useEffect(() => {
    dispatch(getSubscriber(data.id, 0, 100, usage || 'all', time || 'year'))
  }, [time, data.id, dispatch, usage])

  const subscriber = useSelector((state) => state.common.subscriber)
  console.log(subscriber)
  function unblockCallback() {
    headerRef?.current?.refreshRequest()
    tableRef?.current?.refresh()
    data.isBlocked = false
    if (initialStatus) data.status = initialStatus
  }

  return (
    <>
      <Dialog open={true} onClose={onClose} classes={{ paper: classes.paper }}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            mb={3}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="semiBold" fontSize={36} lineHeight={1}>
                {t('subscriber.title.popup')}
              </Typography>
              {data.status !== EnumSubscriberStatus.DELETED && (
                <TableStatus status={data.status} />
              )}
            </Stack>

            <ButtonGroup variant="outlined" color="secondary">
              {/* <Button sx={{ padding: '0 0 0 15px', borderRadius: '20px' }}>
                <PDFDownloadLink
                  document={
                    <StoreProvider store={store}>
                      <SubscriberPDF data={data} />
                    </StoreProvider>
                  }
                  fileName={`subscriber-${formatDate(
                    new Date(),
                    'dd.MM.yyyy-HH:mm',
                  )}.pdf`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                    paddingRight: 10,
                    height: '100%',
                  }}
                >
                  <SvgIcon
                    name="download"
                    color={colorPrimary}
                    size={{ width: 18, height: 18 }}
                  />
                  <Typography variant="semiBold" color="primary" ml={1}>
                    {t('common.exportReport')}
                  </Typography>
                </PDFDownloadLink>
              </Button> */}
              {data.isDeleted ? (
                <Button
                  className={dashboard_.button__deleted}
                  variant="contained"
                  color="error"
                >
                  IS DELETED
                </Button>
              ) : data.isBlocked ? (
                <Button
                  sx={{ padding: '0 15px', borderRadius: '20px' }}
                  onClick={() =>
                    dispatch(toggleBlock(data.id, false, unblockCallback))
                  }
                >
                  <SvgIcon
                    name="block"
                    color={colorPrimary}
                    size={{ width: 18, height: 18 }}
                  />
                  <Typography variant="semiBold" color="primary" ml={1}>
                    {t('common.unblock')}
                  </Typography>
                </Button>
              ) : (
                <Button
                  sx={{ padding: '0 15px', borderRadius: '20px' }}
                  onClick={() => setBlockingId(data.id)}
                >
                  <SvgIcon
                    name="block"
                    color={colorPrimary}
                    size={{ width: 18, height: 18 }}
                  />
                  <Typography variant="semiBold" color="primary" ml={1}>
                    {t('common.block')}
                  </Typography>
                </Button>
              )}
            </ButtonGroup>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Stack direction="row" spacing={3} flexWrap="wrap">
            <MainInfo label="ID" value={data.id} />
            <MainInfo
              label={t('subscriber.since')}
              value={formatDate(data.createdTime, 'dd/MM/yy')}
            />
            <MainInfo
              label={t('subscriber.name')}
              value={`${data.firstname || ''} ${data.lastname || ''}`}
            />
            <MainInfo
              label={t('subscriber.country')}
              value={data.country || ''}
            />
            <MainInfo
              label={t('subscriber.phoneNumber')}
              value={data.phone?.replace('+', '') || ''}
            />
            <Stack direction="row" alignItems="center">
              <Typography variant="bold" noWrap lineHeight="18px">
                {t('subscriber.emailAddress')} -
              </Typography>
              &nbsp;
              <Typography lineHeight="18px">{data.email || ''}</Typography>
              <IconButton onClick={() => setEmailOpen(true)}>
                <EmailIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={5}
            mt={3}
          >
            <PackageBlock data={data} />
            <UserBlock data={data} />
            <LookupsBlock data={data} />
          </Stack>

          <Grid container spacing={3} mt={1}>
            <Grid item xs={6}>
              <SubscriberTickets data={data} />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <UsageBreakdown data={data} />
                </Grid>
                <Grid item xs={12}>
                  <FailedSubscriberRequests data={data} usage={usage} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {blockingId && (
        <PopupAsk
          onYes={() => dispatch(toggleBlock(data.id, true, blockCallback))}
          onClose={() => setBlockingId(undefined)}
        />
      )}

      {emailOpen && (
        <PopupNotify
          mode="email"
          subscribers={[data]}
          onClose={() => setEmailOpen(false)}
        />
      )}
    </>
  )
}
