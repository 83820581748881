import React from 'react'
import { t } from 'i18next'
import { Add as AddIcon } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { PrivatePage } from '../component/PagePrivate'
import { DataTable } from '../component/datatable'
import { useTableStyles } from '../component/datatable/hook/useStyles'
import { PopupPackage } from '../component/package/PopupPackage'
import { PopupWorkflow } from '../component/package/PopupWorkflow'
import sprite_ from '../sass/sprite.module.sass'
import { colorLightGreen, colorPrimary, colorSecondaryDark } from '../mui'
import { Workflow, Package } from '../store/package/reducer'
import { PopupAsk } from '../component/PopupAsk'
import { useDispatch, useSelector } from '../store'
import { deletePackage, deleteWorkflow } from '../store/package/actionCreator'
import { clearFieldError, showSnackbar } from '../store/common/actionCreator'
import { SvgIcon } from '../component/SvgIcon'
import { TableRef } from '../component/datatable/types'

export function PackagePage() {
  const classes = useTableStyles()
  const dispatch = useDispatch()

  const { tables } = useSelector((state) => state.common)
  const workflowData: Workflow[] | null = tables.workflow
    ? tables.workflow.list
    : null
  const packageData: Package[] | null = tables.package?.list || null
  const refWorkflowTable = React.useRef<TableRef>(null)
  const refPackageTable = React.useRef<TableRef>(null)

  const [workflowCreateOpen, setWorkflowCreateOpen] = React.useState(false)
  const [workflowEdit, setWorkflowEdit] = React.useState<Workflow | null>(null)
  const [workflowDelete, setWorkflowDelete] = React.useState<number | null>(
    null,
  )
  const [packageCreateOpen, setPackageCreateOpen] = React.useState(false)
  const [packageEdit, setPackageEdit] = React.useState<Package | null>(null)
  const [packageDelete, setPackageDelete] = React.useState<number | null>(null)

  const affectingPackages: Package[] | null = React.useMemo(() => {
    if (packageData === null) return null

    return packageData.filter((el: Package) => {
      return el.lookups.some((l) => l.id === workflowDelete)
    })
  }, [workflowDelete, packageData])

  const tableHeaderPackage = [
    { key: 'title', text: t('package.table.title') },
    { key: 'subtitle', text: t('package.table.subtitle') },
    { key: 'price', text: t('package.table.price') },
    { key: 'features', text: t('package.table.features') },
    { key: 'actions', text: t('package.table.actions') },
  ]
  const tableHeaderLookup = [
    { key: 'title', text: t('package.table.title') },
    { key: 'subtitle', text: t('package.table.subtitle') },
    { key: 'price', text: t('package.table.price') },
    { key: 'sources', text: t('package.table.sources') },
    { key: 'actions', text: t('package.table.actions') },
  ]

  const handleClose = () => {
    setPackageCreateOpen(false)
    dispatch(clearFieldError)
  }

  const disableDisplay: string[] = ['quotaprofiler-periodic','quotaprofiler-monitoring','quotawebint-lookup','quotawebint-periodic']

  const RenderLookupTableBody = (row: Workflow, idx: number) => (
    <TableRow
      key={row.id}
      className={idx % 2 !== 0 ? classes.rowEven : ''}
      hover
      onClick={() => setWorkflowEdit(row)}
    >
      <TableCell classes={classes}>
        <Typography variant="bold" noWrap>
          {row.title}
        </Typography>
      </TableCell>
      <TableCell classes={classes}>
        <Typography>{row.subTitle}</Typography>
      </TableCell>
      { row.type === 'webint' ? <TableCell classes={classes}></TableCell> : <TableCell classes={classes}>€ {row.price}</TableCell>}
      
      <TableCell classes={classes}>
        <Stack direction="row" spacing={1}>
          {row.sources?.map( (el) => (
             (!disableDisplay.includes(el) &&  
            <Box key={el} className={  (el === 'fb_api' || el === 'instagram_api' || el === 'x_api' || el === 'linkedin_api' || el === 'vk_api') 
              ? sprite_['social__small_' + el.replace("_api","")] : sprite_['social__small_' + el] }  /> )
          ))}
        </Stack>
      </TableCell>
      <TableCell classes={classes}>
        <Tooltip title={<span>{t('common.delete')}</span>}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setWorkflowDelete(row.id)
            }}
          >
            <SvgIcon
              name="delete"
              color={colorSecondaryDark}
              size={{ width: 18, height: 16 }}
            />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )

  const RenderPackageTableBody = (row: Package, idx: number) => (
    <TableRow
      key={row.id}
      className={idx % 2 !== 0 ? classes.rowEven : ''}
      hover
      onClick={() => setPackageEdit(row)}
    >
      <TableCell classes={classes}>
        <Typography variant="bold">{row.title}</Typography>
      </TableCell>
      <TableCell classes={classes}>
        {row.subTitle?.type === 'text' ? (
          row.subTitle.context
        ) : (
          <Chip
            label={row.subTitle?.context}
            variant="outlined"
            size="small"
            sx={{
              color: row.type === 'custom' ? '#000' : '#FFF',
              backgroundColor:
                row.type === 'custom' ? colorLightGreen : colorPrimary,
            }}
          />
        )}
      </TableCell>
      <TableCell classes={classes}>
        <Typography noWrap>
          {row.type === 'custom' ? t('common.custom') : '€ ' + row.price}
        </Typography>
      </TableCell>
      <TableCell classes={classes}>
        {row.feature1?.title} • {row.feature2?.title} • {row.feature3?.title}{' '}
        {row.feature4?.title && '•' + row.feature4.title}
      </TableCell>
      <TableCell classes={classes}>
        <Tooltip title={<span>{t('common.delete')}</span>}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setPackageDelete(row.id)
            }}
          >
            <SvgIcon
              name="delete"
              color={colorSecondaryDark}
              size={{ width: 18, height: 16 }}
            />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )

  function handlePackageCreate() {
    if (workflowData?.length === 0)
      dispatch(showSnackbar('error', 'Must be at least 1 search workflow'))
    else setPackageCreateOpen(true)
  }

  function handleWorkflowDelete() {
    function callback() {
      refWorkflowTable.current?.refresh()
    }

    const isNotLastWorkflow = (workflowData as any).length > 1

    if (isNotLastWorkflow) {
      dispatch(deleteWorkflow(workflowDelete as number, callback))
    } else {
      dispatch(showSnackbar('error', 'You cannot delete the last workflow'))
    }
  }

  function handlePackageDelete() {
    function callback() {
      refPackageTable.current?.refresh()
    }

    dispatch(deletePackage(packageDelete as number, callback))
  }

  return (
    <PrivatePage>
      <Stack spacing={3} width="100%">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="bold" fontSize={26}>
            {t('package.title.lookup')}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            startIcon={
              <AddIcon
                style={{
                  position: 'relative',
                  right: -5,
                  fontSize: 11,
                }}
              />
            }
            onClick={() => setWorkflowCreateOpen(true)}
            sx={{ paddingLeft: '11px' }}
          >
            {t('package.create.lookup')}
          </Button>
        </Stack>

        <DataTable
          ref={refWorkflowTable}
          id="workflow"
          className={classes.card}
          url="/lookup/admin"
          headerData={tableHeaderLookup}
          renderBody={RenderLookupTableBody}
          quickFilter={['title', 'subTitle']}
        />
      </Stack>

      <Stack spacing={3} width="100%">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="bold" fontSize={26}>
            {t('package.title.package')}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            startIcon={
              <AddIcon
                style={{
                  position: 'relative',
                  right: -5,
                  fontSize: 11,
                }}
              />
            }
            onClick={handlePackageCreate}
            sx={{ paddingLeft: '11px' }}
          >
            {t('package.create.package')}
          </Button>
        </Stack>

        <DataTable
          ref={refPackageTable}
          id="package"
          className={classes.card}
          url="/package/admin"
          headerData={tableHeaderPackage}
          renderBody={RenderPackageTableBody}
          quickFilter={['title', 'subTitle.context']}
        />
      </Stack>

      {packageCreateOpen && (
        <PopupPackage
          mode="create"
          tableRef={refPackageTable}
          onClose={handleClose}
        />
      )}
      {packageEdit && (
        <PopupPackage
          mode="edit"
          tableRef={refPackageTable}
          data={packageEdit}
          onClose={() => setPackageEdit(null)}
        />
      )}
      {workflowCreateOpen && (
        <PopupWorkflow
          mode="create"
          tableRef={refWorkflowTable}
          onClose={() => setWorkflowCreateOpen(false)}
        />
      )}
      {workflowEdit && (
        <PopupWorkflow
          mode="edit"
          tableRef={refWorkflowTable}
          data={workflowEdit}
          onClose={() => setWorkflowEdit(null)}
        />
      )}
      {workflowDelete && (
        <PopupAsk
          onYes={handleWorkflowDelete}
          onClose={() => setWorkflowDelete(null)}
        >
          {(affectingPackages as Package[])?.length > 0 && (
            <Alert severity="warning">
              <Typography variant="bold" fontSize={22} color="inherit">
                This workflow is using for packages:
              </Typography>
              <ul>
                {(affectingPackages as Package[]).map((el) => (
                  <li key={el.id}>{el.title}</li>
                ))}
              </ul>
            </Alert>
          )}
        </PopupAsk>
      )}
      {packageDelete && (
        <PopupAsk
          onYes={handlePackageDelete}
          onClose={() => setPackageDelete(null)}
        />
      )}
    </PrivatePage>
  )
}
