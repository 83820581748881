
import { PhoneDataItem } from "../store/searcher/reducer"
  
export function validateHiyaObject(obj : PhoneDataItem) {
    return (
      (obj.data.hiya.last_time == undefined) &&
      (obj.data.hiya.name == undefined) &&
      (obj.data.hiya.names == undefined) &&
      (obj.data.hiya.online == undefined) &&
      (obj.data.hiya.displayName == undefined)
    )
}

export function validateWhatsappObject(obj : PhoneDataItem) {
    return ( (obj.data.whatsapp.last_time == undefined) && (obj.data.whatsapp.name == undefined) && 
            (obj.data.whatsapp.names == undefined) && (obj.data.whatsapp.online == undefined) &&
            (obj.data.whatsapp.status === undefined) && (obj.data.whatsapp.avatar === undefined)
    )
}

export function validateLunaObject(obj : PhoneDataItem) {
  return ( obj.data.luna.person == null && obj.data.luna.possiblePersons == null && obj.data.luna.sources == null)
}

export function validateGetContactObject(obj : PhoneDataItem){
  return (
    obj.data.getcontact.photo ||
    obj.data.getcontact.avatar ||
    obj.data.getcontact.online ||
    (obj.data.getcontact.name && !obj.data.getcontact.name.includes("undefined, null")) ||
    obj.data.getcontact.last_time ||
    obj.data.getcontact.names ||
    obj.data.getcontact.status ||
    obj.data.getcontact.displayName ||
    (obj.data.getcontact.emails && !obj.data.getcontact.emails.includes("undefined, null")) ||
    obj.data.getcontact.person ||
    obj.data.getcontact.possiblePersons ||
    obj.data.getcontact.sources ||
    obj.data.getcontact.data
    )
}