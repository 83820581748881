import { Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import { getValueAndLabel } from '../function/data'
import { fromUnix } from '../function/date'
import { isNumeric } from '../function/number'
import { store } from '../store'
import { CommonState } from '../store/common/reducer'
import { getLabel, getObjectValue } from './searcher/RenderData'

type Props = {
  dataEl: any
}

export const localData: any = [
  { imo: ['nickname'] },
  { tamtam: ['last_seen', 'names', 'description', 'link', 'birthday'] },
  {
    tc: [
      'carrier',
      'gender',
      'about',
      'companyName',
      'timeZone',
      'street',
      'phones',
      'address',
      'internetAddresses',
      'birthday',
    ],
  },
  { skype: ['skype_id'] },
  { viber: ['birthday'] },
  {
    callapp: ['names', 'birthday', 'events', 'street', 'addresses', 'websites'],//'facebookID', 'twitterScreenName', 'googlePlusID', 'linkedinPubProfileUrl', 'instagramID'
  },
  { telegram: ['nickname', 'last_seen', 'last_time', 'info'] },
  { getcontact: ['surname'] },
]

const checkSubElement = (snName : string, dataEl : any) => {
  if (dataEl.socialNetworkIds) {
    switch (snName) {
      case 'facebookID': 
        return dataEl.data.callapp.socialNetworkIDs.facebookID

      case 'twitterScreenName': 
        return dataEl.data.callapp.socialNetworkIDs.twitterScreenName.id

      case 'googlePlusID': 
        return dataEl.data.callapp.socialNetworkIDs.googlePlusID.id
  
      case 'linkedinPubProfileUrl': 
        return  dataEl.data.callapp.socialNetworkIDs.linkedinPubProfileUrl.id

      case 'instagramID': 
        return  dataEl.data.callapp.socialNetworkIDs.instagramID.id
      }
  }
}

export const LocalData = ({ dataEl }: Props) => {
  return localData.map((el: any) => {
    const key = Object.keys(el)[0]

    if (dataEl.from !== key) return ''
    return el[key].map((subEl: any) => {
      const { value, label } = getValueAndLabel(dataEl, subEl)
      if (subEl === 'birthday') {
      }
      if (value !== '-')
      return (
        <Grid item xs={6} key={key + label}>
          <Grid container={value.length < 35}>
            <Grid item xs={4}>
              <Typography variant="text" fontSize={14}>
                {label}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="bold" fontSize={14}>{value}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )
    })
  })
}
