import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  TableContainer,
  Box,
  Grid,
} from '@mui/material'
import { useTableStyles } from '../../component/datatable/hook/useStyles'
import { useDispatch, useSelector } from '../../store'
import { BlackList } from '../../store/blacklist/reducer'
import { useAuthEffect } from '../../hook/useAuthEffect'
import { fetchBlackList } from '../../store/blacklist/actionCreator'
import { Navigation } from '../../component/datatable/component'
import { PopupCRUDEmails } from './PopupCRUDEmails'
import BlackListTableWithPagination from './BlackListTableWithPagination'

export function BlackListTableEmails() {
    
  const { t } = useTranslation()
  const tableClasses = useTableStyles()
  const dispatch = useDispatch()

  const { blackList } = useSelector((state) => state.blackList)

  useAuthEffect(() => {
    dispatch(fetchBlackList)
  })
  console.log("blackList = ", blackList)
  const [openCreate, setOpenCreate] = React.useState(false)


  return (
    <>

      <TableContainer className={tableClasses.card} sx={ {maxWidth : 500} } >
      <Grid container wrap='nowrap' justifyContent="flex-start"> 
          <Box display="flex" justifyContent="flex-end" mx={4} my={1} mb={1}>
          
          <Navigation title='Emails Black List'/>
          </Box>
          <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenCreate(true)}
          >
            Add E-mail
          </Button>
          </Box>
       </Grid> 
       {blackList && (<BlackListTableWithPagination rows={blackList} type='email' />)}
      </TableContainer>
      
      {openCreate && (
        <PopupCRUDEmails mode="create" onClose={() => setOpenCreate(false)} />
      )}
    </>
  )
}
