import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonGroup, Card, CircularProgress, Grid, IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { store, useDispatch, useSelector } from '../store'
import { PrivatePage } from '../component/PagePrivate'
import { PopupNotify } from '../component/PopupNotify'
import { Subscriber, SubscriberStatus } from '../store/user/reducer'
import { useParams } from 'react-router-dom'
import { getSingleSubscriber, toggleBlock } from '../store/user/actionCreator'
import { EnumSubscriberStatus } from '../enum/statusSubscriber'
import { TableStatus } from '../component/datatable/component'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Provider as StoreProvider } from 'react-redux'
import { SubscriberPDF } from '../container/dashboard/PDFSubscriber'
import { formatDate } from '../function/date'
import { SvgIcon } from '../component/SvgIcon'
import { colorPrimary } from '../mui'
import dashboard_ from '../sass/dashboard.module.sass'
import { Email as EmailIcon } from '@mui/icons-material'
import { PackageBlock } from '../container/dashboard/BlockPackage'
import { SubscriberTickets } from '../container/dashboard/SubscriberTickets'
import { UsageBreakdown } from '../container/dashboard/UsageBreakdown'
import {
  FailedSubscriberRequests,
} from '../container/dashboard/FailedSubscriberRequests'
import MainInfo from '../component/subscriber/MainInfo'
import UserBlock from '../component/subscriber/UserBlock'
import LookupsBlock from '../component/subscriber/LookupsBlock'
import { getSubscriber } from '../store/common/actionCreator'
import { PopupAsk } from '../component/PopupAsk'
import { SubscriberRequests } from '../component/subscriber/SubscriberRequests'
import { AdditionalPackage } from '../container/dashboard/AdditionalPackage'

export function SubscriberPage() {
  //states
  const { t } = useTranslation()
  const { id } = useParams() as {
    id: string;
  }
  const dispatch = useDispatch()

  const [subscriber, setSubscriber] = React.useState<Subscriber>({} as Subscriber)
  const [loading, setLoading] = React.useState<boolean>(false)

  const [emailOpen, setEmailOpen] = React.useState(false)
  const [blockingId, setBlockingId] = React.useState<number>()
  const [initialStatus, setInitialStatus] = React.useState<SubscriberStatus>()


  //callbacks
  const dispatchSingleSubscriber = React.useCallback((id: number) => {
    setLoading(true)
    dispatch(getSingleSubscriber(id, (data: Subscriber) => {
      setSubscriber(data)
      setLoading(false)
      setInitialStatus(data.status)
    }))
  }, [dispatch])

  function blockCallback() {
    setSubscriber({...subscriber, isBlocked : true, status : EnumSubscriberStatus.BLOCKED} )
  }

  interface StateProperties {
    isBlocked : boolean,
    status : SubscriberStatus
  }
  
  function unblockCallback() {
    const updatedData : StateProperties = {isBlocked : false, status : EnumSubscriberStatus.TRIAL}
    if (initialStatus) {
      updatedData.status = initialStatus
    } 
    setSubscriber({...subscriber, ...updatedData} )
  }

  //effects
  React.useEffect(() => {
    dispatchSingleSubscriber(Number(id))
  }, [id, dispatchSingleSubscriber])

  React.useEffect(() => {
    dispatch(getSubscriber(Number(id), 0, 100, 'all', 'year'))
  }, [id, dispatch])


  //handlers


  return (
    <PrivatePage>
      {
        loading && <CircularProgress />
      }
      {
        subscriber.id && <Card>
          <Stack direction="column" width="100%">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              mb={3}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="semiBold" fontSize={36} lineHeight={1}>
                  {t('subscriber.title.popup')}
                </Typography>
                {subscriber.status !== EnumSubscriberStatus.DELETED && (
                  <TableStatus status={subscriber.status} />
                )}
              </Stack>

              <ButtonGroup variant="outlined" color="secondary">
                {subscriber.isDeleted ? (
                  <Button
                    className={dashboard_.button__deleted}
                    variant="contained"
                    color="error"
                  >
                    IS DELETED
                  </Button>
                ) : subscriber.isBlocked ? (
                  <Button
                    sx={{ padding: '0 15px', borderRadius: '20px' }}
                    onClick={() =>
                      dispatch(toggleBlock(subscriber.id, false, unblockCallback))
                    }
                  >
                    <SvgIcon
                      name="block"
                      color={colorPrimary}
                      size={{ width: 18, height: 18 }}
                    />
                    <Typography variant="semiBold" color="primary" ml={1}>
                      {t('common.unblock')}
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    sx={{ padding: '0 15px', borderRadius: '20px' }}
                    onClick={() => setBlockingId(subscriber.id)}
                  >
                    <SvgIcon
                      name="block"
                      color={colorPrimary}
                      size={{ width: 18, height: 18 }}
                    />
                    <Typography variant="semiBold" color="primary" ml={1}>
                      {t('common.block')}
                    </Typography>
                  </Button>
                )}
              </ButtonGroup>
            </Stack>

            <Stack direction="column">
              <Stack direction="row" spacing={3} flexWrap="wrap">
                <MainInfo label="ID" value={subscriber.id} />
                <MainInfo
                  label={t('subscriber.since')}
                  value={formatDate(subscriber.createdTime, 'dd/MM/yy')}
                />
                <MainInfo
                  label={t('subscriber.name')}
                  value={`${subscriber.firstname || ''} ${subscriber.lastname || ''}`}
                />
                <MainInfo
                  label={t('subscriber.country')}
                  value={subscriber.country || ''}
                />
                <MainInfo
                  label={t('subscriber.phoneNumber')}
                  value={subscriber.phone?.replace('+', '') || ''}
                />
                <Stack direction="row" alignItems="center">
                  <Typography variant="bold" noWrap lineHeight="18px">
                    {t('subscriber.emailAddress')} -
                  </Typography>
                  &nbsp;
                  <Typography lineHeight="18px">{subscriber.email || ''}</Typography>
                  <IconButton onClick={() => setEmailOpen(true)}>
                    <EmailIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </Stack>
                
                {subscriber.registerInfo && (
                  <>
                    <MainInfo
                      label={t('subscriber.email_deliverability')}
                      value={subscriber.registerInfo?.email_deliverability || ''}
                    />
                    <MainInfo
                      label={t('subscriber.email_is_disposable_email')}
                      value={subscriber.registerInfo?.email_is_disposable_email.toString() || ''}
                    />
                    <MainInfo
                      label={t('subscriber.email_is_smtp_valid')}
                      value={subscriber.registerInfo?.email_is_smtp_valid ? subscriber.registerInfo?.email_is_smtp_valid.toString() : "Unknown"}
                    />
                    <MainInfo
                      label={t('subscriber.email_quality_score')}
                      value={subscriber.registerInfo?.email_quality_score || ''}
                    />
                    <MainInfo
                      label={t('subscriber.phone_type')}
                      value={subscriber.registerInfo?.phone_type || ''}
                    />
                    <MainInfo
                      label={t('subscriber.phone_valid')}
                      value={subscriber.registerInfo?.phone_valid.toString() || ''}
                    />
                  </>
                )
                }
                {subscriber.userAgent && (
                  <>
                    <MainInfo
                      label={t('subscriber.utmCampaign')}
                      value={subscriber.userAgent?.utmCampaign || ''}
                    />
                    <MainInfo
                      label={t('subscriber.utmSource')}
                      value={subscriber.userAgent?.utmSource.toString() || ''}
                    />
                    <MainInfo
                      label={t('subscriber.utmTerm')}
                      value={subscriber.userAgent?.utmTerm ? subscriber.userAgent?.utmTerm.toString() : "Unknown"}
                    />
                    <MainInfo
                      label={t('subscriber.utmContent')}
                      value={subscriber.userAgent?.utmContent || ''}
                    />
                    <MainInfo
                      label={t('subscriber.utmMedium')}
                      value={subscriber.userAgent?.utmMedium || ''}
                    />
                    <MainInfo
                      label={t('subscriber.handlCustom1')}
                      value={subscriber.userAgent?.handlCustom1.toString() || ''}
                    />
                    <MainInfo
                      label={t('subscriber.gaClientId')}
                      value={subscriber.userAgent?.gaClientId || ''}
                    />
                    <MainInfo
                      label={t('subscriber.handlIP')}
                      value={subscriber.userAgent?.handlIP.toString() || ''}
                    />
                    <MainInfo
                      label={t('subscriber.isIpPhoneMatches')}
                      value={subscriber.userAgent?.isIpPhoneMatches 
                        ? subscriber.userAgent?.isIpPhoneMatches.toString() : 'false'}
                    />
                    <MainInfo
                      label={t('subscriber.isProxy')}
                      value={subscriber.userAgent?.isProxy 
                        ? subscriber.userAgent?.isProxy.toString() : 'false'}
                    />
                    <MainInfo
                      label={t('subscriber.ipMatchesCount')}
                      value={subscriber.userAgent?.ipMatchesCount 
                        ? subscriber.userAgent?.ipMatchesCount.toString() : 'Unknown'}
                    />
                  </>
                )
                }
                <MainInfo
                  label={t('Autorenewal')}
                  value={subscriber.isAutoRenewal.toString() || ''}
                />
                <MainInfo
                  label={t('Autorefill')}
                  value={subscriber.isAutoRefill.toString() || ''}
                />
              </Stack>
              <Grid
                container
                //spacing={10}
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                mt={3}
              >
                <PackageBlock data={subscriber} />
                <AdditionalPackage data={subscriber} />
                <UserBlock data={subscriber} />
                <LookupsBlock data={subscriber} />
              </Grid>

              <Grid container spacing={3} mt={1}>
                <Grid item xs={6}>
                  <SubscriberTickets data={subscriber} />
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <UsageBreakdown data={subscriber} />
                    </Grid>
                    <Grid item xs={12}>
                      <FailedSubscriberRequests data={subscriber} usage={'all'} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3} mt={1}>
                <Grid item xs={12}>
                  <SubscriberRequests data={subscriber} />
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Card>
      }
      {blockingId && (
        <PopupAsk
          onYes={() => dispatch(toggleBlock(subscriber.id, true, blockCallback))}
          onClose={() => setBlockingId(undefined)}
        />
      )}
      {emailOpen && (
        <PopupNotify
          mode="email"
          subscribers={[subscriber]}
          onClose={() => setEmailOpen(false)}
        />
      )}
    </PrivatePage>
  )
}
