import { FirebaseError } from 'firebase/app'
import { setFieldError, showSnackbar } from '../store/common/actionCreator'
import { store } from '../store'
import { signOut } from '../store/auth/actionCreator'

const invisibleErrors = [
  'auth/popup-closed-by-user',
  'messaging/permission-blocked',
]

export function getErrorMessage(e: FirebaseError) {
  switch (e.code) {
    case 'auth/email-already-in-use':
      return 'Sorry, this email already in use'
    case 'auth/invalid-verification-code':
      return 'Invalid verification code'
    case 'auth/user-not-found':
      return 'Sorry, account not found'
    case 'auth/wrong-password':
      return 'Sorry, incorrect password. Try again'
    case 'auth/code-expired':
      return 'Sorry, verification code has been expired. Please press Send again button'
    case 'auth/too-many-requests':
      return 'Sorry, too many requests. Try again later'
    case 'auth/account-exists-with-different-credential':
      return 'Sorry, account exists with different credential. Please use another account'
    case 'auth/network-request-failed':
      return 'Sorry, request has failed. Please, try registering again'
    case 'auth/user-token-expired':
      return 'Sorry, token has expired. Please, try sign up again'
    case 'auth/invalid-action-code':
      return 'Error! Confirmation link has already expired'
    case 'auth/invalid-email':
      return 'Error! Email address is incorrect'
    case 'auth/requires-recent-login':
      return 'Error! Please login again and repeat'
    case 'messaging/token-subscribe-failed': {
      store.dispatch(signOut())
      return 'Sorry, something went wrong'
    }
    default:
      return e.message
  }
}

export function handleError(e: any) {
  if (e instanceof FirebaseError) {
    if (e?.code === 'auth/invalid-verification-code') {
      store.dispatch(setFieldError('phoneCode', 'Invalid verification code'))
      return
    }
    if (!invisibleErrors.includes(e.code)) {
      store.dispatch(showSnackbar('error', getErrorMessage(e)))
    }
  } else if (e.response) {
    store.dispatch(showSnackbar('error', e.response.data.message))
  } else if (e instanceof Error) {
    store.dispatch(showSnackbar('error', e.message))
  } else {
    console.error('Hidden error: ', e)
  }
}
