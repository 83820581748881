import {createStyles, makeStyles} from '@mui/styles'
import {fontRegular} from '../mui'
import {Theme} from '@mui/material'

export const useMarketingCheckboxStyles2 = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      label: {
        fontFamily: fontRegular,
      },
    },
    checkbox: {},
    label: {
      fontFamily: fontRegular,
    },
  })
})

export const useMarketingCheckboxStyles = makeStyles({
  root: {
    margin: 0,
    '& .MuiFormControlLabel-label': {
      fontFamily: fontRegular + ' !important',
    },
    '& .MuiCheckbox-root': {
      padding: 5,
      '& span': {
        width: 14,
        height: 14,
      },
    },
  },
})