import * as React from 'react'
import { useTablePaginationStyles } from '../../component/datatable/hook/useStyles'
import { useDispatch, useSelector } from '../../store'
import { Comment } from '../../store/support/reducer'
import { EventSourcePolyfill, MessageEvent } from 'event-source-polyfill'
import { showSnackbar } from '../../store/common/actionCreator'
import { sendChatMessage } from '../../store/support/actionCreator'
import {
  Avatar,
  Box,
  Button,
  Pagination,
  PaginationItem,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import { t } from 'i18next'
import support_ from '../../sass/support.module.sass'
import {
  ArrowBack as IconLeft,
  ArrowForward as IconRight,
  SupportAgent as SupportIcon,
} from '@mui/icons-material'
import { formatDate } from '../../function/date'
import { FilePreview } from '../../component/support/FilePreview'
import { SvgIcon } from '../../component/SvgIcon'
import { styled } from '@mui/material/styles'
import { refreshToken } from '../../store/auth/service'

const COMMENTS_LIMIT = 3
export const FILE_SIZE_LIMIT = 512 * 1000
export const FILE_FORMATS = ['pdf', 'png', 'jpeg', 'jpg', 'gif', 'tiff']

let prevCommentsLength = 0

const Input = styled('input')({
  display: 'none',
})

interface Props {
  ticketId: number
}

export function Chat({ ticketId }: Props) {
  const paginationClasses = useTablePaginationStyles()
  const dispatch = useDispatch()

  const { timeFormat } = useSelector((state) => state.common)
  const { avatar } = useSelector((state) => state.settings)

  const [connection, setConnection] = React.useState<any>(null)
  const [comments, setComments] = React.useState<Comment[]>([])
  const [commentsPage, setCommentsPage] = React.useState(1)
  const [input, setInput] = React.useState('')
  const [attach, setAttach] = React.useState<File>()

  const typedInput = input.length

  const attachmentObjectUrl = React.useMemo(() => {
    if (!attach) return null

    const file = new Blob([attach], { type: attach.type })
    return {
      fileName: attach.name,
      url: URL.createObjectURL(file),
      isObjectUrl: true,
    }
  }, [attach])

  const handleSseConnect = React.useCallback(async () => {
    const token = await refreshToken()

    const eventSource = new EventSourcePolyfill(
      `${process.env.REACT_APP_API_BASE_URL}/sse/ticket/${ticketId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    eventSource.onmessage = function (event: MessageEvent) {
      const data: Comment[] = JSON.parse(event.data)

      if (prevCommentsLength !== data.length) setComments(data)

      prevCommentsLength = data.length
    }

    setConnection(eventSource)
  }, [ticketId])

  React.useEffect(() => {
    handleSseConnect()
  }, [handleSseConnect])

  React.useEffect(() => {
    const pageCount = Math.ceil(comments.length / COMMENTS_LIMIT)
    setCommentsPage(pageCount)
  }, [comments.length, setCommentsPage])

  React.useEffect(() => {
    return () => {
      connection?.close()
      prevCommentsLength = 0
    }
  }, [connection])

  const pageComments = React.useMemo(() => {
    const offset = (commentsPage - 1) * COMMENTS_LIMIT
    return comments.slice(offset, offset + COMMENTS_LIMIT)
  }, [comments, commentsPage])

  function handleAttach(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return

    const file = e.target.files[0]
    const type = file.type.split('/')[1] || ''
    const typeOutput = type ? `"${type}" file format` : 'This file format'

    if (file.size > FILE_SIZE_LIMIT)
      dispatch(
        showSnackbar(
          'error',
          'Maximum file size: ' + FILE_SIZE_LIMIT / 1000 + 'KB',
        ),
      )
    else if (!FILE_FORMATS.includes(type))
      dispatch(
        showSnackbar(
          'error',
          `Only ${FILE_FORMATS.map((el) => ' ' + el)} formats are allowed`,
        ),
      )
    else setAttach(file)

    e.target.value = ''
  }

  function handleMessageSend() {
    if (input.trim() === '') {
      dispatch(showSnackbar('error', 'You cannot send empty message'))
      return
    }

    const formData = new FormData()

    formData.append('ticketId', String(ticketId) as string)
    formData.append('context', input)
    if (attach) formData.append('file', attach)

    dispatch(sendChatMessage(formData))
    setInput('')
    setAttach(undefined)
  }

  function handleMessageKeydown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleMessageSend()
    }
  }

  function handleFileDelete() {
    setAttach(undefined)
  }

  return (
    <>
      <Typography variant="bold">{t('support.comments')}</Typography>

      {pageComments.map((el, idx) => (
        <Stack key={idx} direction="row" spacing={1}>
          {el.user.avatar ? (
            <Avatar src={el.user.avatar} sx={{ width: 32, height: 32 }} />
          ) : el.user.roleId !== 1 ? (
            <Box className={support_.avatar__bg}>
              <SupportIcon />
            </Box>
          ) : (
            <Avatar src={undefined} sizes="10" sx={{ width: 32, height: 32 }} />
          )}
          <Stack alignItems="flex-start">
            <Typography variant="semiBold">
              {!el.user.firstname && !el.user.lastname ? (
                'Support agent'
              ) : (
                <span>
                  {el.user.firstname} {el.user.lastname}
                </span>
              )}
            </Typography>
            <Typography color="secondary" fontSize={12}>
              {formatDate(el.createdTime, `MMM d, yyyy ${timeFormat}`)}
            </Typography>
            <Typography>{el.context}</Typography>
            {el.attachment && <FilePreview attachment={el.attachment} />}
          </Stack>
        </Stack>
      ))}

      {comments.length > COMMENTS_LIMIT && (
        <Pagination
          classes={paginationClasses}
          count={Math.ceil(comments.length / COMMENTS_LIMIT)}
          page={commentsPage}
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              components={{ previous: IconLeft, next: IconRight }}
              {...item}
            />
          )}
          onChange={(e, page) => setCommentsPage(page)}
        />
      )}
      <Stack direction="row" spacing={1}>
        <Avatar
          src={avatar || undefined}
          sizes="10"
          sx={{ width: 40, height: 40 }}
        />
        <Stack width="100%">
          <TextareaAutosize
            className="form__textarea"
            placeholder={t('field.hint.comments')}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleMessageKeydown}
          />
          {attachmentObjectUrl && (
            <Stack direction="row" flexWrap="wrap" p={1} pb={0}>
              <FilePreview
                attachment={attachmentObjectUrl}
                onDelete={handleFileDelete}
              />
            </Stack>
          )}
        </Stack>
        <Button
          variant="contained"
          sx={{ padding: 0, minWidth: 40, height: 40 }}
          onClick={handleMessageSend}
        >
          <SvgIcon name="send" size={{ width: 15, height: 15 }} />
        </Button>
        <label htmlFor="icon-button-file">
          <Input id="icon-button-file" type="file" onChange={handleAttach} />
          <Button
            variant="contained"
            sx={{ padding: 0, minWidth: 40, height: 40 }}
            component="span"
          >
            <SvgIcon
              name="attach"
              size={{ width: 15, height: 15 }}
              color="white"
            />
          </Button>
        </label>
      </Stack>
    </>
  )
}
