import React from 'react'
import {useNavigate} from 'react-router-dom'
import auth_ from '../../sass/auth.module.sass'
import {Grid} from '@mui/material'

type Props = {
  children: React.ReactNode
}

export function PublicPage({children}: Props) {
  const navigate = useNavigate()

  return (
    <Grid container className={auth_.root}>
      <Grid item xs={12} lg={3} className={auth_.info}>
        <div className={auth_.info__logo} onClick={() => navigate('/auth')}/>
      </Grid>

      <Grid item xs={12} lg={9}>
        <Grid className={auth_.content} container direction="column">
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}