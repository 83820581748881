import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Joi from 'joi'
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material'
import auth_ from '../sass/auth.module.sass'
import { useDispatch, useSelector } from '../store'
import { requestForgot } from '../store/auth/actionCreator'
import { PublicPage } from '../component/auth/PagePublic'
import { FormError } from '../component/FormError'
import {
  email,
  ErrorsState,
  preventEmptySpaceOnKeyDown,
  validateForm,
} from '../function/validation'
import { clearFieldError } from '../store/common/actionCreator'

const schema = Joi.object({
  email: email,
})

export function ForgotPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { fieldErrors } = useSelector((state) => state.common)

  const [email, setEmail] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, email])
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(clearFieldError)

    const error = validateForm(schema, { email })

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(requestForgot(email, navigate))
    }
  }

  return (
    <PublicPage>
      <Grid className={auth_.title} item>
        {t('auth.forgot.title')}
      </Grid>

      <Grid item mt={3}>
        <Typography variant="semiBold" color="secondary">
          {t('auth.forgot.hint')}
        </Typography>
      </Grid>

      <Grid item mt={5}>
        <form onSubmit={handleSubmit} noValidate>
          <Stack spacing={3}>
            <FormControl>
              <FormLabel required>{t('field.label.email')}</FormLabel>
              <OutlinedInput
                name="email"
                value={email}
                placeholder={t('field.hint.email')}
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={preventEmptySpaceOnKeyDown}
              />
              <FormError error={fieldErrors.forgot || errors?.email} />
            </FormControl>

            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
            >
              {t('auth.forgot.resetPassword')}
            </Button>

            <Button onClick={() => navigate('/auth')}>
              {t('auth.register.back')}
            </Button>
          </Stack>
        </form>
      </Grid>
    </PublicPage>
  )
}
