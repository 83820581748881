import {MarketingAction} from './actionType'

export type CampaignType = 'bottom-right' | 'center-overlay'
export type CampaignPage = 'all' | '/' | '/search' | '/packages' | '/developer' | '/apikey' | '/support'
export type SubscriberType = 'all' | 'gold' | 'silver' | 'bronze'
export type CampaignFrequency = 'once-a-day' | 'every-login'

export interface ICampaign {
  id: number
  name: string
  title: string
  paragraph: string
  actionName: string
  actionUrl: string
  type: CampaignType
  showCountdown: boolean
  duration: {hours: number, minutes: number}
  subscribers: SubscriberType[]
  packages: string[]
  pages: CampaignPage[] | null
  ltvRange: {from: number; to: number} | null
  balanceRange: {from: number; to: number} | null
  lastActive: number | null
  frequency: CampaignFrequency
  actionCount: number
  viewCount: number
  percent: number
  createdTime: string
}

export interface ITelegramCampaign {
  id: number
  name: string
  title: string
  paragraph: string
  actionName: string
  actionUrl: string
  image: string
  createdTime: string
  usersCount: number
}

export interface MarketingState {}

const initialState: MarketingState = {}

export function marketingReducer(state = initialState, action: MarketingAction) {
  switch (action.type) {
    default:
      return state
  }
}
