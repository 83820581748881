import Typography from '@mui/material/Typography'
import { Box, Grid, Link, Stack } from '@mui/material'
import { KYCItem, SearchResult } from '../../store/searcher/reducer'
import { prepareKYCResult } from '../../store/searcher/service'
import { ResultNotVerified, ResultTitle, ResultVerified } from '../../style/result'
import { LinkPreview } from '../../component/searcher/LinkPreview'

type Props = {
  apiData: SearchResult
}

export function KYCCommon(props: Props) {
  const result: any = prepareKYCResult(props.apiData)
  return (
    <>
      <Stack sx={{ height: 'auto', width: '100%' }}>
        {
          (result.data && result.data.length > 0) ?
            result.data?.map((el: KYCItem, idx: number) => {
              if (el.source_type === 'kyc_company_enrich') {
                return (
                  <Stack>
                    <Grid container spacing={2}>
                      {
                        [
                          {title: 'Country', field: 'country'},
                          {title: 'Location', field: 'locality'},
                          {title: 'Name', field: 'name'},
                          {title: 'Employee Count', field: 'employees_count'},
                          {title: 'Industry', field: 'industry'},
                          {title: 'Founded at', field: 'year_founded'},
                        ].map(({title, field}) => (el as any)[field] && <Grid key={field} item xs={6}>
                          <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                            <ResultTitle>{title}:</ResultTitle>
                            <Typography fontSize={12} fontWeight={200} color={'#000000'} sx={{ wordBreak: 'break-all' }}>{(el as any)[field]}</Typography>
                          </Stack>
                        </Grid>)
                      }
                      <Grid item xs={6}>
                        <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                          <ResultTitle>Domain:</ResultTitle>
                          <Link key={idx} target="_blank" href={"https://" + el.domain}>{el.domain}</Link>
                        </Stack>
                      </Grid>
                      {
                        el.linkedin_url && <Grid item xs={6}>
                          <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                            <ResultTitle>LinkedIn:</ResultTitle>
                            <LinkPreview link={"https://" + el.linkedin_url} title={el.linkedin_url} />
                          </Stack>
                        </Grid>
                      }
                    </Grid>
                  </Stack>
                )
              }
              if (el.source_type === 'kyc_iban') {
                return (
                  <Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box sx={{ height: 'auto', width: '100%' }}>
                          <Stack direction={'column'} sx={{ margin: '11px 50px' }}>
                            <ResultTitle>{`IBAN: ${el.iban}`}</ResultTitle>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ height: 'auto', width: '100%' }}>
                          <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                            {
                              el.is_valid ?
                                <ResultVerified sx={{ margin: '0px 50', width: '250' }}>IBAN Number Is Valid</ResultVerified> :
                                <ResultNotVerified sx={{ margin: '0px 50px', width: '250px' }}>IBAN Number Is Not Valid:</ResultNotVerified>
                            }
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                )
              }
              if (el.source_type === 'kyc_vat_validate') {
                return (
                  <Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box sx={{ height: 'auto', width: '100%' }}>
                          <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                            <ResultTitle>VAT Number:</ResultTitle>
                            <Typography fontSize={12} fontWeight={200} color={'#000000'} sx={{ wordBreak: 'break-all' }}>{el.vat_number}</Typography>
                          </Stack>
                          <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                            <ResultTitle>Company Name:</ResultTitle>
                            <Typography fontSize={12} fontWeight={200} color={'#000000'} sx={{ wordBreak: 'break-all' }}>{el.company_name}</Typography>
                          </Stack>
                          <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                            <ResultTitle>Company Address:</ResultTitle>
                            <Typography fontSize={12} fontWeight={200} color={'#000000'} sx={{ wordBreak: 'break-all' }}>{el.comapany_address}</Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ height: 'auto', width: '100%' }}>
                          <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                            <ResultTitle>Country:</ResultTitle>
                            <Typography fontSize={12} fontWeight={200} color={'#000000'} sx={{ wordBreak: 'break-all' }}>{el.company_country_name}</Typography>
                          </Stack>
                          <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                            <ResultTitle>Country Code:</ResultTitle>
                            <Typography fontSize={12} fontWeight={200} color={'#000000'} sx={{ wordBreak: 'break-all' }}>{el.company_country_code}</Typography>
                          </Stack>
                          <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                            {
                              el.vat_valid ?
                                <ResultVerified sx={{ margin: '0px 0px', width: '200px' }}>Vat Number Is Valid</ResultVerified> :
                                <ResultNotVerified sx={{ margin: '0px 0px', width: '250px' }}>Vat Number Is Not Valid:</ResultNotVerified>
                            }
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                )
              }
            }) : (
              <Stack>
                <div style={{
                  position: 'absolute', left: '50%', top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}>
                  <ResultNotVerified>Results not found</ResultNotVerified>
                </div>
              </Stack>
            )
        }
      </Stack>
    </>
  )
}
