import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import { FileCopy as CopyIcon } from '@mui/icons-material'
import apikey_ from '../../sass/apikey.module.sass'
import { useDispatch, useSelector } from '../../store'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  useTheme,
} from '@mui/material'
import { ErrorsState, validateForm } from '../../function/validation'

import { clearFieldError } from '../../store/common/actionCreator'
import {  BlackListIpAddress, BlackListPhones } from '../../store/blacklist/reducer'
import {  editBlackListIpAddress, editBlackListPhones, postBlackListIpAddress, postBlackListPhones } from '../../store/blacklist/actionCreator'

const schemaEdit = Joi.object({
  length: Joi.number().required().min(16).max(32).integer(),
  key: Joi.string().required(),
})

type Props = {
  mode: 'create' | 'edit'
  onClose: () => void
  data?: BlackListIpAddress
}

export const PopupCRUDIpAddress = ({ mode, onClose, data }: Props) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const [ipAddress, setIpAddress] = React.useState(data?.ipAddress || "")
  
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const [disableGenerate, setDisableGenerate] = React.useState(false)
  const [disableSave, setDisableSave] = React.useState(true)

  function handleSubmit() {
    if (mode === 'create') {
        const requestData = {
          ipAddress: ipAddress,
        }
        dispatch(postBlackListIpAddress(requestData))
        onClose()
    } else {
      const error = validateForm(schemaEdit, { ipAddress })
        const requestData = {
          id: data?.id as number,
          ipAddress: ipAddress,
        }
        dispatch(editBlackListIpAddress(requestData))
        onClose()
    }
  }

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, ipAddress])

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog open={true} fullWidth onClose={onClose} maxWidth="md">
        {mode === 'create' && (
          <DialogTitle>Add to Black List</DialogTitle>
        )}
        {mode === 'edit' && <DialogTitle>Edit Black List</DialogTitle>}

        <Divider variant="popup" color="primary" />

        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={5}>
              <FormLabel>Ip Address</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <OutlinedInput
                  type="string"
                  value={ipAddress}
                  onChange={(e) => setIpAddress(e.target.value)}
                />
               
              </FormControl>
            </Grid>
          </Grid>

          {mode === 'edit' && (
            <Grid container>
              <Grid item xs={5}>
                <FormLabel>{t('apiKey.key')}</FormLabel>
              </Grid>
              <Grid item xs={7}>
                <FormControl fullWidth>
                  <OutlinedInput
                    value={ipAddress}
                    placeholder={t('apiKey.hint')}
                    readOnly
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Stack>

        <Divider variant="popup" color="primary" />

        <DialogActions sx={{
          [theme.breakpoints.only("xs")]: {
            //flexDirection: 'column',
          },
        }}>
          <Button
            color="primary"
            size="large"
            onClick={onClose}
            variant="outlined"
          >
            {t('common.close')}
          </Button>
          {mode === 'edit' && (
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={disableGenerate}
              sx={{
                [theme.breakpoints.only("xs")]: {
                  fontSize: '10px',
                },
              }}
            >
              {t('common.generate')}
            </Button>
          )}

          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleSubmit}
            disabled={mode === 'edit' ? disableSave : false}
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}
