import * as React from 'react'

function calcValues(unixEnd: number) {
  const unixNow = new Date().getTime()
  const distance = unixEnd - unixNow
  const isExpired = distance < 0

  let days
  let hours
  let minutes

  if (isExpired) {
    days = Math.ceil(distance / (1000 * 60 * 60 * 24))
    hours = Math.ceil((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    minutes = Math.ceil((distance % (1000 * 60 * 60)) / (1000 * 60))
  }
  else {
    days = Math.floor(distance / (1000 * 60 * 60 * 24))
    hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  }

  const seconds = Math.floor((distance % (1000 * 60)) / 1000)

  return {isExpired, distance, days, hours, minutes, seconds}
}

export function useCountdown(unixEnd: number, stopWhenExpire?: boolean) {
  const initValues = calcValues(unixEnd)

  const [intervalID, setIntervalID] = React.useState<ReturnType<typeof setTimeout>>()
  const [data, setData] = React.useState(initValues)

  const initInterval = React.useCallback(() => {
    const id = setInterval(() => {
      const calcData = calcValues(unixEnd)
      setData(calcData)
    }, 1000)

    setIntervalID(id)
    return id
  }, [unixEnd])

  React.useEffect(() => {
    const id = initInterval()

    return () => {
      clearInterval(id)
    }
  }, [initInterval])

  if (stopWhenExpire && data.isExpired && intervalID) {
    clearInterval(intervalID)
  }

  return {
    intervalID,
    isExpired: data.isExpired,
    days: data.days,
    hours: data.hours,
    minutes: data.minutes,
    seconds: data.seconds,
  }
}
