import {  Box, Button, Grid, Stack } from '@mui/material'
import { SearchResult, WebCollectionItem } from '../../store/searcher/reducer'
import { prepareWebCollectionResult } from '../../store/searcher/service'
import { ResultNotVerified, ResultTitle } from '../../style/result'

type Props = {
  apiData: SearchResult
}

export function WebCollectionCommon(props: Props) {
  const result: any = prepareWebCollectionResult(props.apiData)

  function downloadTxtFile(htmlCode: string) {
    const d = new Date()
    const fName = 'webcollection_scrape_' + `${d.getHours()}${d.getMinutes()}${d.getSeconds()}` + '.html'
    const element = document.createElement("a")
    const file = new Blob([htmlCode], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = fName
    document.body.appendChild(element)
    element.click()
  }

  return (
    <>
      <Stack>
        {
          (result.data && result.data.length > 0) ?
            result.data?.map((el: WebCollectionItem, idx: number) => {
              if (el.source_type === 'web_collection_screenshot') {
                return (
                  <Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box sx={{ height: 'auto', width: '100%' }}>
                          <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                            <ResultTitle>Screenshot:</ResultTitle>
                            <img alt={''} src={el.image && el.image.startsWith("http") ? el.image : `data:image/jpeg;base64,${el.image}`} />
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                )
              }

              if (el.source_type === 'web_collection_scrape') {
                return (
                  <Stack>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <Box sx={{ height: 'auto', width: '100%' }}>
                          <Stack direction={'column'} sx={{ margin: '0px 5px' }}>
                            <ResultTitle>HTML Code:</ResultTitle>
                            <Button variant="contained" onClick={(e) => downloadTxtFile(el.htmlCode)}>Download</Button>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                )
              }
            }) : (
              <Stack>
                <div style={{
                  position: 'absolute', left: '50%', top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}>
                  <ResultNotVerified>Results not found</ResultNotVerified>
                </div>
              </Stack>
            )

        }
      </Stack>
    </>
  )
}
