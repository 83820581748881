import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material'
import { FormCampaign, FormRef } from '../../../component/marketing/FormCampaign'
import { ICampaign } from '../../../store/marketing/reducer'
import { FormState } from '../../../function/validation'
import { TableRef } from '../../../component/datatable/types'

type Props = {
  data: ICampaign
  setPreview: React.Dispatch<React.SetStateAction<FormState | null>>
  tableRef: React.RefObject<TableRef>
  onClose: () => void
}

export function PopupCampaign({ data, setPreview, tableRef, onClose }: Props) {
  const { t } = useTranslation()

  const formRef = React.useRef<FormRef>(null)

  const handlePreview = () => formRef.current?.preview()
  const handleActivate = () => formRef.current?.activate()
  const handleSubmit = () => formRef.current?.submit()

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xl">
      <DialogTitle>{t('marketing.edit') + ': ' + data.name}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <DialogContent>
        <FormCampaign
          ref={formRef}
          mode="edit"
          data={data}
          tableRef={tableRef}
          onClose={onClose}
          setPreview={setPreview}
        />
      </DialogContent>

      <Divider variant="popup" color="primary" />

      <DialogActions>
        <Button variant="outlined" size="small" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button variant="outlined" size="small" onClick={handlePreview}>
          {t('marketing.previewPopup')}
        </Button>
        <Button variant="outlined" size="small" onClick={handleActivate}>
          {t('common.activate')}
        </Button>
        <Button variant="contained" size="small" onClick={handleSubmit}>
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
