import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import table_ from '../../../sass/table.module.sass'
import { SvgIcon } from '../../SvgIcon'
import { TicketPriority, TicketStatus } from '../../../store/support/reducer'
import { SubscriberStatus } from '../../../store/user/reducer'
import { Body } from './Body'
import { Header } from './Header'
import { Navigation } from './Navigation'
import { Pagination } from './Pagination'
import { EnumTicketStatus } from '../../../enum/statusTicket'
import { EnumSubscriberStatus } from '../../../enum/statusSubscriber'

export interface TableStatusProps {
  status: TicketStatus | SubscriberStatus
}

export function TableStatus({ status }: TableStatusProps) {
  const { t } = useTranslation()

  return (
    <>
      {status === EnumSubscriberStatus.TRIAL && (
        <Box className={table_.status_trial}>{t('status.user.trial')}</Box>
      )}
      {status === EnumSubscriberStatus.PAID && (
        <Box className={table_.status_paid}>{t('status.user.paid')}</Box>
      )}
      {status === EnumSubscriberStatus.BLOCKED && (
        <Box className={table_.status_blocked}>{t('status.user.blocked')}</Box>
      )}
      {status === EnumSubscriberStatus.DELETED && (
        <Box className={table_.status_deleted}>{t('status.user.deleted')}</Box>
      )}
      {status === EnumTicketStatus.CLOSED && (
        <Box className={table_.status_close}>{t('status.ticket.closed')}</Box>
      )}
      {status === EnumTicketStatus.OPEN && (
        <Box className={table_.status_open}>{t('status.ticket.open')}</Box>
      )}
      {status === EnumTicketStatus.IN_PROGRESS && (
        <Box className={table_.status_inProgress}>
          {t('status.ticket.inProgress')}
        </Box>
      )}
    </>
  )
}

export interface TablePriorityProps {
  value: TicketPriority
}

export function TablePriority({ value }: TablePriorityProps) {
  return (
    <>
      {value === 'low' && (
        <SvgIcon
          name="priorityDown"
          size={{ width: 20, height: 20 }}
          color="#4E9E41"
        />
      )}
      {value === 'medium' && (
        <SvgIcon
          name="priorityUp"
          size={{ width: 20, height: 20 }}
          color="#E58615"
        />
      )}
      {value === 'high' && (
        <SvgIcon
          name="priorityUp"
          size={{ width: 20, height: 20 }}
          color="#CB281E"
        />
      )}
    </>
  )
}

export { Header, Navigation, Body, Pagination }
