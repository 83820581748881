import { useSubscriber } from '../../hook/useSubscriber'
import { useSelector } from '../../store'
import { Box, Grid, Stack } from '@mui/material'
import dashboard_ from '../../sass/dashboard.module.sass'
import LookupChartItem from './LookupChartItem'
import { t } from 'i18next'
import { getPercentSize } from '../../function/common'
import React from 'react'
import { BlockProps } from './subscriberTypes'
import { SearchCategoryCounter } from '../../store/user/reducer'

export default function LookupsBlock({ data }: BlockProps) {
  const { lookupChartTopValue } = useSubscriber(data)
  const counters = data.counters
  console.log("counters", counters)
  // const counters: SearchCategoryCounter[] = subscriber.counters ? subscriber.counters : []
  return (
    <Grid item xs>
      <Box className={dashboard_.card} sx={{height: '100%', minHeight: '113px'}}>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          spacing={1}
          //alignItems={'baseline'}
        >
          {counters.map((counter: SearchCategoryCounter) => {
            return <LookupChartItem
            name={counter.label}
            quantity={counter.counter}
            height={getPercentSize(counter.counter, lookupChartTopValue)}
          />
          })}
        </Stack>
      </Box>
    </Grid>
  )
}