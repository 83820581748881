import { AlertColor } from '@mui/material'
import { CommonAction } from './actionType'
import { LocalStorage } from '../../enum/storage'
import { getLocaleJson } from '../../i18n'
import { Workflow, Package } from '../package/reducer'
import { FailedSubscriberRequest } from '../dashboard/reducer'
import { Ticket } from '../support/reducer'
import { Subscriber } from '../user/reducer'

export type Translation = {
  message: {
    error: {
      notVerifiedEmail: string
      notAuthAdmin: string
      emailAlreadyInUse: string
    }
  }
  settings: {
    twoFATurnedOff: string
    twoFATurnedOn: string
    pictureHint2: string
    pictureHint3: string
  }
}

export type TableBodyRow = Subscriber &
  Ticket &
  FailedSubscriberRequest &
  Package &
  Workflow

export type TableBodyData = {
  list: TableBodyRow[]
  count: number | null
  userType?: 'Bronze' | 'Gold' | 'Silver'
  apiUsagesCount?: number
  webUsagesCount?: number
}

const localLang = localStorage.getItem(LocalStorage.LANG) || 'en'

export type CommonState = {
  loading: boolean,
  tableLoading: boolean,
  // language: string
  translation: Translation
  timeFormat: string
  timeFormatWithSeconds: string
  isRtl: boolean
  snackbar: {
    isOpen: boolean
    severity: undefined | AlertColor
    message: undefined | string
  }
  fieldErrors: Record<string, string | undefined>
  tables: any
  subscriber: any
}

const initialState: CommonState = {
  loading: false,
  tableLoading: false,
  // language: localLang,
  translation: getLocaleJson(localLang),
  timeFormat: 'h:mm a',
  timeFormatWithSeconds: 'h:mm:ss a',
  isRtl: localStorage.getItem(LocalStorage.LANG) === 'he',
  snackbar: {
    isOpen: false,
    severity: undefined,
    message: undefined,
  },
  fieldErrors: {},
  tables: {},
  subscriber: {},
}

export function commonReducer(state = initialState, action: CommonAction) {
  switch (action.type) {
    case 'common/SET_LOADING':
      return {
        ...state,
        loading: action.status,
      }
    case 'common/SET_TABLE_LOADING':
      return {
        ...state,
        tableLoading: action.status,
      }
    // case 'common/SET_LANGUAGE':
    //   localStorage.setItem(LocalStorage.LANG, action.language)
    //   return {
    //     ...state,
    //     language: action.language,
    //     isRtl: action.language === 'he',
    //   }
    case 'common/SHOW_SNACKBAR':
      return {
        ...state,
        snackbar: {
          isOpen: true,
          severity: action.severity,
          message: action.message,
        },
      }
    case 'common/HIDE_SNACKBAR':
      return {
        ...state,
        snackbar: { ...state.snackbar, isOpen: false },
      }
    case 'common/SET_FIELD_ERROR':
      return {
        ...state,
        fieldErrors: {
          ...state.fieldErrors,
          [action.key]: action.error,
        },
      }
    case 'common/CLEAR_FIELD_ERROR':
      return {
        ...state,
        fieldErrors: {},
      }
    case 'common/SET_TABLE':
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableId]: action.data,
        },
      }
    case 'common/GET_SUBSCRIBER_SUCCEEDED':
      return {
        ...state,
        subscriber: action.payload,
      }
    default:
      return state
  }
}
