import * as React from 'react'
import {t} from 'i18next'
import {Button, DialogActions} from '@mui/material'

interface Props {
  activeStep: number
  setActiveStep: (step: number) => void
  handleNext: () => void
  handleSubmit: () => void
  onClose: () => void
}

export function PopupActions({activeStep, setActiveStep, handleNext, handleSubmit, onClose}: Props) {
  return (
    <DialogActions sx={{justifyContent: 'flex-end'}}>
      {activeStep === 0 && (
        <Button variant="outlined" size="small" color="primary" onClick={onClose}>{t('common.cancel')}</Button>
      )}
      {activeStep === 1 && (
        <Button variant="outlined" size="small" color="primary" onClick={() => setActiveStep(0)}>
          {t('common.back')}
        </Button>
      )}
      {activeStep === 0 && (
        <Button variant="contained" size="small" color="primary" onClick={handleNext}>{t('common.next')}</Button>
      )}
      {activeStep === 1 && (
        <Button variant="contained" size="small" color="primary" onClick={handleSubmit}>{t('common.save')}</Button>
      )}
    </DialogActions>
  )
}