import * as firebaseMessaging from 'firebase/messaging'
import {call, put, takeEvery} from 'redux-saga/effects'
import {handleError} from '../../function/error'
import * as actionType from './actionType'
import * as actionCreator from './actionCreator'
import {api, Response} from '../../function/axios'
import {showSnackbar} from '../common/actionCreator'
import {EditPriority, EditStatus, StartTimer} from './actionType'
import {addNotification, setNotification} from './actionCreator'
import {refreshToken} from '../auth/service'

function* initNotification() {
  const messaging = firebaseMessaging.getMessaging()

  try {
    const currentToken: string = yield call(firebaseMessaging.getToken, messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    })

    yield call(api, {
      method: 'PUT',
      url: '/users/firebase-token',
      body: {
        firebaseToken: currentToken,
      },
    })
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getNotification(action: actionType.GetNotification) {
  try {
    if (action.refreshToken) yield call(refreshToken)

    const {data} = yield call(api, {
      method: 'GET',
      url: `/notifications?offset=${action.offset}&limit=${action.limit}`,
    })

    if (action.fetchMore) yield put(addNotification(data.list))
    else yield put(setNotification(data.list))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* postTicketType(action: actionType.PostTicketType) {
  try {
    const {data}: Response = yield call(api, {
      method: 'POST',
      url: `/ticket-type`,
      body: {
        name: action.name,
        color: action.color,
      },
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getTicketTypes() {
  try {
    const {data}: Response = yield call(api, {
      method: 'GET',
      url: '/ticket-type',
    })

    yield put(actionCreator.setTicketTypes(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* putTicketType(action: actionType.PutTicketType) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PUT',
      url: `/ticket-type/${action.data.id}`,
      body: {
        name: action.data.name,
        color: action.data.color,
      },
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* deleteTicketType(action: actionType.DeleteTicketType) {
  try {
    const {data}: Response = yield call(api, {
      method: 'DELETE',
      url: `/ticket-type/${action.id}`,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* edit(action: actionType.EditTicket) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PUT',
      url: `/ticket/${action.id}`,
      body: action.data,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* remove(action: actionType.DeleteTicket) {
  try {
    const {data}: Response = yield call(api, {
      method: 'DELETE',
      url: `/ticket/${action.id}`,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* editStatus(action: EditStatus) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PATCH',
      url: `/ticket/status/${action.id}`,
      body: {
        status: action.value,
      },
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* editPriority(action: EditPriority) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PATCH',
      url: `/ticket/priority/${action.id}`,
      body: {
        priority: action.value,
      },
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* startTimer(action: StartTimer) {
  try {
    yield call(api, {
      method: 'PATCH',
      url: `/ticket/time/${action.id}`,
    })

    yield put(actionCreator.editTicket(action.id, action.data, action.callback))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* sendChatMessage(action: actionType.SendChatMessage) {
  try {
    yield call(api, {
      method: 'POST',
      url: '/comment',
      body: action.formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e) {
    yield call(handleError, e)
  }
}

function* sendNotification(action: actionType.SendNotification) {
  try {
    const {data}: Response = yield call(api, {
      method: 'POST',
      url: '/notifications',
      body: {
        title: action.title,
        text: action.text,
        userIds: action.ids,
      },
    })

    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* sendEmail(action: actionType.SendEmail) {
  try {
    const {data}: Response = yield call(api, {
      method: 'POST',
      url: '/email',
      body: action.formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* clearNotifications() {
  try {
    const {data}: Response = yield call(api, {
      method: 'DELETE',
      url: "/notifications/",
    })
    yield put(actionCreator.clearNotificationsSuccess())
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchSupport() {
  yield takeEvery(actionType.INIT_NOTIFICATION, initNotification)
  yield takeEvery(actionType.GET_NOTIFICATION, getNotification)
  yield takeEvery(actionType.POST_TICKET_TYPE, postTicketType)
  yield takeEvery(actionType.GET_TICKET_TYPES, getTicketTypes)
  yield takeEvery(actionType.PUT_TICKET_TYPE, putTicketType)
  yield takeEvery(actionType.DELETE_TICKET_TYPE, deleteTicketType)
  yield takeEvery(actionType.EDIT_TICKET, edit)
  yield takeEvery(actionType.DELETE_TICKET, remove)
  yield takeEvery(actionType.EDIT_STATUS, editStatus)
  yield takeEvery(actionType.EDIT_PRIORITY, editPriority)
  yield takeEvery(actionType.START_TIMER, startTimer)
  yield takeEvery(actionType.SEND_CHAT_MESSAGE, sendChatMessage)
  yield takeEvery(actionType.SEND_NOTIFICATION, sendNotification)
  yield takeEvery(actionType.SEND_EMAIL, sendEmail)
  yield takeEvery(actionType.CLEAR_NOTIFICATIONS, clearNotifications)
}