import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AddUser as UploadIcon} from 'react-iconly'
import {Avatar, Box, Button, Card, Grid, IconButton, Typography} from '@mui/material'
import {useDispatch, useSelector} from '../store'
import {deleteUser} from '../store/settings/actionCreator'
import {FormPersonalInfo} from '../container/settings/FormPersonalInfo'
import {FormOther} from '../container/settings/FormOther'
import {PrivatePage} from '../component/PagePrivate'
import {PopupAsk} from '../component/PopupAsk'
import {Popup2FAInput} from '../container/settings/Popup2FAInput'
import {useSettingsStyles} from '../style/settings'
import {PopupImageUpload} from '../container/settings/PopupImageUpload'
import {useAvatarStyles} from '../style/common'

export function SettingsPage() {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const classes = useSettingsStyles()
  const classesAvatar = useAvatarStyles()
  const navigate = useNavigate()

  const user = useSelector(state => state.settings)

  const [sureDeleteOpen, setSureDeleteOpen] = React.useState(false)
  const [twoFAInputOpen, setTwoFAInputOpen] = React.useState(false)

  const [uploadOpen, setUploadOpen] = React.useState(false)

  const imgUrl = user.avatar
  let fullName = ''

  if (user.avatar === null) {
    if (user.firstname && user.lastname) {
      fullName = `${user.firstname} ${user.lastname}`
    }
  }

  const handleDelete = () => {
    if (user.is2FAEnabled) setTwoFAInputOpen(true)
    else setSureDeleteOpen(true)
  }

  return (
    <PrivatePage>
      <Typography variant="semiBold" fontSize={24}>{t('settings.title')}</Typography>

      <Card>
        <Grid container>
          {/** LEFT SIDE*/}
          <Grid item xs={12} lg={7}>
            <Grid container direction="column">
              <Typography variant="semiBold" fontSize={16}>{t('settings.editProfile')}</Typography>

              <Grid item>
                <Box my={2}>
                  <IconButton size="large" disableRipple onClick={() => setUploadOpen(true)}>
                    {imgUrl ? (
                      <Avatar
                        classes={classes}
                        className={classesAvatar.uploadAvatar}
                        src={imgUrl}
                        alt={fullName}
                      />
                    ) : (
                      <Box classes={classes} className={classesAvatar.uploadBtn}>
                        <UploadIcon set="light"/>
                      </Box>
                    )}
                  </IconButton>
                  {uploadOpen && <PopupImageUpload src={imgUrl} onClose={() => setUploadOpen(false)}/>}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/** RIGHT SIDE*/}
          <Grid item xs={12} lg={5}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button variant="outlined" color="error" onClick={handleDelete}>
                  {t('settings.deleteAccount')}
                </Button>
                {sureDeleteOpen && (
                  <PopupAsk
                    onYes={() => dispatch(deleteUser(navigate))}
                    onClose={() => setSureDeleteOpen(false)}
                  />
                )}
                {twoFAInputOpen && <Popup2FAInput mode="deleteUser" onClose={() => setTwoFAInputOpen(false)}/>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          {/** LEFT SIDE*/}
          <Grid item xs={12} lg={7}>
            <FormPersonalInfo/>
          </Grid>
          {/** RIGHT SIDE*/}
          <Grid className={classes.other} item xs={12} lg={5}>
            <FormOther/>
          </Grid>
        </Grid>
      </Card>
    </PrivatePage>
  )
}