import {NavigateFunction} from 'react-router-dom'
import {AuthAction} from './actionType'
import {E164Number} from '../../component/InputPhone'

export const flushState: AuthAction = {type: 'auth/FLUSH_STATE'}

export const getDepartment: AuthAction = {type: 'auth/GET_DEPARTMENT'}

export const setDepartment = (data: object[]): AuthAction => ({type: 'auth/SET_DEPARTMENT', data})

export const getPhoneCode = (phone: E164Number): AuthAction => ({type: 'auth/GET_PHONE_CODE', phone})

export const setPhoneVerify = (value: boolean): AuthAction => ({type: 'auth/SET_PHONE_VERIFY', value})

export const signUp = (email: string, password: string, department: string, navigate: NavigateFunction): AuthAction => ({
  type: 'auth/SIGN_UP',
  email,
  password,
  department,
  navigate,
})

export const signIn = (email: string, password: string, navigate: NavigateFunction): AuthAction => ({
  type: 'auth/SIGN_IN',
  email,
  password,
  navigate,
})

export const requestForgot = (email: string, navigate: NavigateFunction): AuthAction => ({
  type: 'auth/REQUEST_FORGOT',
  email,
  navigate,
})

export const signBy2Fa = (code: string, navigate: NavigateFunction): AuthAction => ({
  type: 'auth/SIGN_BY_2FA',
  code,
  navigate,
})

export const updatePassword = (password: string, oobCode: string, navigate: NavigateFunction): AuthAction => ({
  type: 'auth/UPDATE_PASSWORD',
  password,
  oobCode,
  navigate,
})

export const refreshToken = (token?: string, redirect?: () => void): AuthAction => ({
  type: 'auth/REFRESH_TOKEN',
  token,
  redirect,
})

export const setRefreshedToken = (token: string): AuthAction => ({type: 'auth/SET_REFRESHED_TOKEN', token})

export const signOut = (navigate?: NavigateFunction): AuthAction => ({type: 'auth/SIGN_OUT', navigate})
