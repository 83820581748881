import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import {
  DeleteOutline as DeleteIcon,
  ExpandMore,
  ExpandLess,
  
} from '@mui/icons-material'
import {
  Button,
  TableCell,
  IconButton,
  TableRow,
  Stack,
  Typography,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  Checkbox,
  Box,
  Grid,
} from '@mui/material'
import { useTableStyles } from '../../component/datatable/hook/useStyles'
import { useDispatch, useSelector } from '../../store'
import {  BlackListPhones } from '../../store/blacklist/reducer'
import { useAuthEffect } from '../../hook/useAuthEffect'
import { fetchBlackListIpAddress, fetchBlackListPhones } from '../../store/blacklist/actionCreator'
import { Header, Navigation } from '../../component/datatable/component'
import { PopupCRUDPhones } from './PopupCRUDPhones'
import BlackListTableWithPagination from './BlackListTableWithPagination'
import { PopupCRUDIpAddress } from './PopupCRUDIpAddress'


export function BlackListTableIpAddress() {
    
  const { t } = useTranslation()
  const tableClasses = useTableStyles()
  const dispatch = useDispatch()

  const { blackListIpAddress } = useSelector((state) => state.blackListIpAddress)

  console.log(blackListIpAddress)
  useAuthEffect(() => {
    dispatch(fetchBlackListIpAddress)
  })
 
  const [openCreate, setOpenCreate] = React.useState(false)

  return (
    <>
       <TableContainer className={tableClasses.card} sx={ {maxWidth : 500} } >
        <Grid container wrap='nowrap' justifyContent="flex-start"> 
            <Box display="flex" justifyContent="flex-end" mx={4} my={1}>
            
            <Navigation title='Ip Address Black List'/>
            </Box>
            <Box> 
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCreate(true)}
            >
              Add IP
            </Button>
            </Box>
        </Grid>
        {blackListIpAddress && <BlackListTableWithPagination rows={blackListIpAddress} type='ipAddress' />}
      
      </TableContainer>
      
      {openCreate && (
        <PopupCRUDIpAddress mode="create" onClose={() => setOpenCreate(false)} />
      )} 
    </>
  )
}
