import {useSelector} from '../store'
import {Department} from '../store/settings/reducer'
import {Role} from '../enum/role'

interface Props {
  permissions: Department[]
  children: any
}

export function PermissionProvider({permissions, children}: Props) {
  const {department, role} = useSelector(state => state.settings)

  if (!department || !role) return null

  const isAllow = permissions.includes(department.name) || role === Role.SUPER_ADMIN

  return isAllow ? children : null
}
