import {Radio, RadioProps} from '@mui/material'
import {styled} from '@mui/material/styles'

const CustomIcon = styled('span')(({theme}) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  backgroundColor: '#E7E8EA',
  outline: '#A0A4A8',
  border: '1px solid #A0A4A8',
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  // '.Mui-focusVisible &': {
  //   outline: '2px auto rgba(19,124,189,.6)',
  //   outlineOffset: 2,
  // },
  // 'input:disabled ~ &': {
  //   boxShadow: 'none',
  //   background:
  //     theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  // },
}))

const CustomCheckedIcon = styled(CustomIcon)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  '&:before': {
    position: 'absolute',
    content: '""',
    margin: 'auto',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 10,
    height: 10,
    backgroundColor: '#FFF',
    borderRadius: '50%',
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.primary.dark,
  },
}))

export function InputRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<CustomCheckedIcon/>}
      icon={<CustomIcon/>}
      {...props}
    />
  )
}