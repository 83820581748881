import {
  Workflow,
  WorkflowType,
  PackageType,
  Phone,
  Social,
  Source,
  DeepWeb,
  WebInt,
  CombinedPhone,
  CombinedEmail,
  CombinedNames,
  CombinedId,
  FaceSearch,
  AdvancedCollectionFacebook,
  SentimentAnalysys,
  FindClone,
  KYC,
  WEB_Collection,
  IPGeo,
  NameByCountry,
  SniperAPI,
  PhoneList,
  EmailList,
  AdvCollectionFbPersonWall,
  IPGeoNumber,
  PsychoProfile,
  APIv2X,
  APIv2Instagram,
  APIv2LinkedIn,
  SNApiProfile,
  LookupPhoneVerification,
  FacebookMobileCollector,
  NameApi,
  CroloX,
  InstagramMobileCollector,
  CroloVk,
  IrbisTikTok,
  CroloOk,
  IrbisCriminal,
  SNApiWebintGroupProfile,
  IrbisBlueSky,
  SsnTrace,
  NatCrimCheck,
  Pep,
  CourtRecords,
} from '../store/package/reducer'

export const phones: Phone[] = [
  'getcontact',
  'drupe',
  'skype',
  'viber',
  'telegram',
  'whatsapp',
  'eyecon',
  'tc',
  'callapp',
  'hiya',
  'imo',
  'tamtam',
  'botim',
]
export const socials: Social[] = [
  'fb',
  'instagram',
  'linkedin',
  'twitter',
  'ok',
  'vk',
]

export const nameApi: NameApi[] = [
  'fb',
  'instagram',
  'linkedin',
  'x',
  'vk',
  'ok',
  'tiktok',
]

export const postLookups: Social[] = [
  'fb',
  'instagram',
  'linkedin',
  'twitter',
  'ok',
  'vk',
]

export const deepWebLookups : DeepWeb[] = [
  'email', 
  'phone', 
  'facebookid',
  'telegramid', 
  'password', 
  'linkedinid', 
  'vkid', 
  'fullname',
  'twitterid',
  'instagramid',   
]

export const webIntLookups : WebInt[] = [
  'quotaprofiler-lookup',
  'quotaprofiler-crawler',
  'quotaprofiler-cases',
  'quotaprofiler-periodic',
  'quotaprofiler-monitoring',

  'quotawebint-lookup',
  'quotawebint-crawler',
  'quotawebint-cases',
  'quotawebint-periodic',
  'quotawebint-monitoring',
]

export const combinedPhones : CombinedPhone[] = [
  'getcontact',
  'drupe',
  'skype',
  'viber',
  'telegram',
  'whatsapp',
  'eyecon',
  'tc',
  'callapp',
  'hiya',
  'imo',
  'tamtam',
  'phone',
  'botim',
  'luna',
  'taro',
  'web_collection_phone',
  'viewcaller',
  'kraken',
]

export const combinedEmails : CombinedEmail[] = [
  'email',
  'skype',
  'luna',
  'taro',
  'web_collection_email',
  'eyes',
  'mail_reveal',
]

export const combinedNames : CombinedNames[] = [
  'fb', 'instagram', 'linkedin', 'twitter', 'ok', 'vk', 'fullname', 'webmii',  'fb_api' , 'instagram_api', 'vk_api', 'ok_api', 'x_api', 'linkedin_api',
  'tiktok_api', 'blsk_api',
]

export const combinedIds : CombinedId[] = [
  'fb', 'instagram', 'linkedin', 'twitter', 'ok', 'vk', 'facebookid', 'telegramid', 'linkedinid', 'vkid', 'twitterid', 'instagramid', 'instanavigation_person',
]

export const faceSearches : FaceSearch[] = [
  'ok', 'vk', 'pluto', 'tiktok', 'sniper', 'sniper_2', 
]

export const advancedCollectionLookups : AdvancedCollectionFacebook[] = [
 'search_users', 'search_posts',
 
'person_id_resolving' , 'person_intro' , 'person_about' , 'person_groups' , 'person_friends' , 'person_wall' , 'person_post_and_feedbacks', 'person_reactions', 'person_feedbacks', 'person_comment_reactions', 'person_photo_albums',
'person_content_data' , 'person_content' , 'person_video_albums' , 'person_specific_comment' , 'person_specific_media',

'group_id_resolving', 'group_wall', 'group_post_and_feedbacks', 'group_feedbacks', 'group_reactions', 'group_photo_albums', 'group_photos', 'group_members', 'group_admins', 'group_intro', 
'group_about', 'group_content_data', 'group_comment_reactions', 'group_specific_comment', 'group_specific_media',

'page_id_resolving', 'page_wall', 'page_community_wall', 'page_post_and_feedbacks', 'page_reactions', 'page_feedbacks', 'page_photo_albums', 'page_content_data', 'page_content', 'page_intro', 'page_about', 'page_transparency',
'page_linked_profile', 'page_video_albums', 'page_comment_reactions', 'page_specific_comment', 'page_specific_media',
]

export const advCollectionPersonWallLookups : AdvCollectionFbPersonWall[] = [
  'person_wall',
]

export const fbMobileCollectorLookups : FacebookMobileCollector[] = [ 
  'general_id_resolving', 'general_reactions', 'general_feedbacks', 'general_comment_reactions', 'general_shares', 'general_specific_comment', 'general_specific_media',
  'group_intro', 'group_about', 'group_admins', 'group_members', 'group_photo_albums', 'group_photos', 'group_wall', 
  'page_intro', 'page_linked_profile', 'page_about', 'page_photo_albums', 'page_video_albums', 'page_wall', 'page_community_wall', 'page_transparency',
  'post_post_and_feedbacks',
  'user_intro', 'user_about', 'user_friends', 'user_video_albums', 'user_photo_albums', 'user_wall', 'user_groups',
  'album_metadata', 'album_content', 'album_content_data',
  'keyword_posts', 'keyword_users']

export const instaMobileCollectorLookups : InstagramMobileCollector[] = [
  'url_resolving', 'media_likers', 'userid_by_username_url', 'user_info_by_username_url', 'about',
  'highlights', 'full_highlights', 'feed', 'tagged', 'following', 'followers', 'story', 'data', 'likers',
  'comments', 'comment_likers', 'replies', 'tags', 'tags_sections']

export const sentimentAnalysys : SentimentAnalysys[] =  ['sentiment_analysys']

export const findClone : FindClone[] = ['irbis_eye']

export const sniperAPI : SniperAPI[] = ['sniper_api']

export const kyc : KYC[] = ['kyc_iban','kyc_company_enrich','kyc_vat_validate']
export const web_collection : WEB_Collection[] = ['web_collection_screenshot','web_collection_scrape']
export const ip_geo : IPGeo[] = ['geolocation']
export const geolocation_by_number : IPGeoNumber[] = ['geolocation_number', 'email_domain_validator']

export const api_v2_x : APIv2X[] = ['people','top','latest','photos','videos','followers','details','feed','tweets','following',
'highlights','likes','media','replies','memberships']

export const api_v2_instagram: APIv2Instagram[] = ['hashtags','location','users','places','top','account','followers','reels','feed',
'highlights_tray','reels_media_stream','info','tagsfeed','story','usernameinfo','following']

export const api_v2_linkedin: APIv2LinkedIn[] = ['locations','groups','jobs','companies','posts','people','honors-and-awards' , 
'interests','recommendations','projects','events','url-resolver','licenses-and-certifications','images','experience', 
'people-also-viewed','education','contact-info','languages','activity','publications','organizations', 
'overview','skills','test-scores','courses','skill-endorsers','patents','volunteering-experience']

export const sn_api_profile: SNApiProfile[] = ['fbProfile','fbPage','instaProfile',
'linkedinProfile','linkedinPosts','xProfile','vkProfile','okProfile','tiktokProfile']

export const sn_api_group_profile: SNApiWebintGroupProfile[] = ['fbGroupProfile','vkWebintGroup','okWebintGroup','xWebintGroup','keywords']

export const phoneList: PhoneList[] = [
  'getcontact',
  'drupe',
  'skype',
  'viber',
  'telegram',
  'whatsapp',
  'eyecon',
  'tc',
  'callapp',
  'hiya',
  'imo',
  'tamtam',
  'phone',
  'botim',
  'luna',
  'taro',
  'web_collection_phone']

export const emailList: EmailList[] = ['mail_reveal']

export const nameByCountry : NameByCountry[] = ['country_fullname']
export const psychoProfile : PsychoProfile[] = ['summary' , 'psych']

export const lookup_phone_verification : LookupPhoneVerification[] = ['VERIFICATION']

export const crolo_x : CroloX[] = ['verify_credentials', 'get_user_by_id', 'search_users_x', 'people_by_query', 'people_by_geo', 'list_by_query', 'list_by_geo',
'latest_tweets_by_query', 'latest_tweets_by_geo', 'top_tweets_by_query', 'top_tweets_by_geo', 'tweet_highlights_for_user', 'tweets_for_user', 'tweet_media_for_user',
'tweet_media_by_query', 'tweet_with_discussion', 'tweet_media_by_geo', 'subscriptions_for_user', 'superfollows_for_user', 'retweets_for_tweet', 'followers_for_user']

export const crolo_vk: CroloVk[] = ['friends_get_id', 'friends_get_name', 'friends_get_user_query', 'groups_getbyid_group_query', 'groups_getbyid_groupid', 'groups_getbyid_name', 
'groups_getbyid_post_query', 'groups_getmembers_group_query', 'groups_getmembers_groupid', 'groups_search_group_query', 'likes_getlist_groupid', 'newsfeed_search_post_query', 'photos_get_group_query', 
'photos_get_groupid', 'photos_get_id', 'photos_get_name', 'photos_get_user_query', 'users_get_groupid', 'users_get_id', 'users_get_name', 'users_get_post_query', 'users_get_user_query', 'users_phone', 
'users_search_id', 'users_search_name', 'users_search_user_query', 'wall_get_groupid', 'wall_getcomments_groupid', 'wall_getcomments_post_query']

export const crolo_ok: CroloOk[] = ['badges', 'communities', 'discussions', 'friends', 'group', 'hobbies', 'interests',
'market', 'media_topic', 'moments', 'photos', 'places', 'search', 'stream', 'url', 'users', 'entity']

export const irbis_criminal: IrbisCriminal[] = ['ssn_trace','nat_crim_check','pep_nm_wls']

export const ssn_trace : SsnTrace[] = ['soc_sec_num_trace']

export const nat_crim_check : NatCrimCheck[] = ['national_crime']

export const pep : Pep[] = ['political_exposed']

export const court_records : CourtRecords[] = ['court_rec']

export const bluesky: IrbisBlueSky[] = ['get_profile', 'search_actors', 'get_followers', 'get_follows', 'search_posts', 'get_suggestions']

export const irbis_tiktok: IrbisTikTok[] = ['user_info', 'user_following', 'user_followers', 'user_videos', 'video_info']

export function usePackagePopup() {
  function initLookups(
    lookupList?: Workflow[],
    packageLookups?: Array<{ id: number; title: string }>,
  ) {
    if (packageLookups === undefined) return []
    else {
      const packageLookupIDs = packageLookups.map((el) => el.id)
      return lookupList?.filter((el) => packageLookupIDs.includes(el.id)) || []
    }
  }

  function initFaceSearch(    
    sources: Source[] | undefined,
    type: PackageType | WorkflowType) {
    if (type === 'personal') return faceSearches
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => faceSearches.includes(el as FaceSearch)) as FaceSearch[])
      : []
  }

  function initCombineId(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return combinedIds
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => combinedIds.includes(el as CombinedId)) as CombinedId[])
      : []
  }

  function initCombineNames(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return combinedNames
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => combinedNames.includes(el as CombinedNames)) as CombinedNames[])
      : []
  }

  function initCombineEmails(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return combinedEmails
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => combinedEmails.includes(el as CombinedEmail)) as CombinedEmail[])
      : []
  }
  
  function initCombinePhones(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return combinedPhones
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => combinedPhones.includes(el as CombinedPhone)) as CombinedPhone[])
      : []
  }

  function initSourcePhone(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return phones
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => phones.includes(el as Phone)) as Phone[])
      : []
  }

  function initSourceSocials(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return socials
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => socials.includes(el as Social)) as Social[])
      : []
  }
  
  function initSourceNameApi(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return nameApi
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => nameApi.includes(el as NameApi)) as NameApi[])
      : []
  }

  function initSourceCroloX(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return crolo_x
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => crolo_x.includes(el as CroloX)) as CroloX[])
      : []
  }

  function initSourceCroloVk(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return crolo_vk
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => crolo_vk.includes(el as CroloVk)) as CroloVk[])
      : []
  }

  function initSourceCroloOk(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return crolo_ok
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => crolo_ok.includes(el as CroloOk)) as CroloOk[])
      : []
  }

  function initSourceIrbisCriminal(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return irbis_criminal
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => irbis_criminal.includes(el as IrbisCriminal)) as IrbisCriminal[])
      : []
  }

  function initSourceSsnTrace(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return ssn_trace
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => ssn_trace.includes(el as SsnTrace)) as SsnTrace[])
      : []
  }

  function initSourceNatCrimCheck(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return nat_crim_check
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => nat_crim_check.includes(el as NatCrimCheck)) as NatCrimCheck[])
      : []
  }

  function initSourcePep(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return pep
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => pep.includes(el as Pep)) as Pep[])
      : []
  }

  function initSourceCourtRecords(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return court_records
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => court_records.includes(el as CourtRecords)) as CourtRecords[])
      : []
  }

  function initSourceIrbisTikTok(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return irbis_tiktok
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => irbis_tiktok.includes(el as IrbisTikTok)) as IrbisTikTok[])
      : []
  }

  function initSourceBlueSky(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return bluesky
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => bluesky.includes(el as IrbisBlueSky)) as IrbisBlueSky[])
      : []
  }

  function initSourceDeepWeb(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,
  ) {
    if (type === 'personal') return deepWebLookups
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => deepWebLookups.includes(el as DeepWeb)) as DeepWeb[])
      : []
  }

  function initSourceWebInt(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType,  
  ) {
    if (type === 'personal') return webIntLookups
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => webIntLookups.includes(el as WebInt)) as WebInt[])
      : []
  }

  function initAdvancedCollection(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return advancedCollectionLookups
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => advancedCollectionLookups.includes(el as AdvancedCollectionFacebook)) as AdvancedCollectionFacebook[])
      : []
  }

  function initFbMobileCollector(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return fbMobileCollectorLookups
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => fbMobileCollectorLookups.includes(el as FacebookMobileCollector)) as FacebookMobileCollector[])
      : []
  }

  function initInstaMobileCollector(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return instaMobileCollectorLookups
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => instaMobileCollectorLookups.includes(el as InstagramMobileCollector)) as InstagramMobileCollector[])
      : []
  }

  function initAdvCollectionFbPersonWall(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return advancedCollectionLookups
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => advancedCollectionLookups.includes(el as AdvancedCollectionFacebook)) as AdvancedCollectionFacebook[])
      : []
  }

  function initSentimentAnalysys(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return sentimentAnalysys
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => sentimentAnalysys.includes(el as SentimentAnalysys)) as SentimentAnalysys[])
      : []
  }

  function initFindClone(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return findClone
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => findClone.includes(el as FindClone)) as FindClone[])
      : []
  }

  function initSniperApi(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return sniperAPI
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => sniperAPI.includes(el as SniperAPI)) as SniperAPI[])
      : []
  }

  function initKYC(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return kyc
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => kyc.includes(el as KYC)) as KYC[])
      : []
  }

  function initPhoneList(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return phoneList
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => phoneList.includes(el as PhoneList)) as PhoneList[])
      : []
  }

  function initEmailList(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return emailList
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => emailList.includes(el as EmailList)) as EmailList[])
      : []
  }

  function initWebCollection(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return web_collection
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => web_collection.includes(el as WEB_Collection)) as WEB_Collection[])
      : []
  }

  function initAPIv2X(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return api_v2_x
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => api_v2_x.includes(el as APIv2X)) as APIv2X[])
      : []
  }

  function initAPIv2Instagram( 
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return api_v2_instagram
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => api_v2_instagram.includes(el as APIv2Instagram)) as APIv2Instagram[])
      : []
  }

  function initAPIv2LinkedIn( 
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return api_v2_linkedin
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => api_v2_linkedin.includes(el as APIv2LinkedIn)) as APIv2LinkedIn[])
      : []
  }

  function initSourceSnAPIProfiler(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return sn_api_profile
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => sn_api_profile.includes(el as SNApiProfile)) as SNApiProfile[])
      : []
  }

  function initSourceSNApiWebintGroupProfile(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return sn_api_group_profile
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => sn_api_group_profile.includes(el as SNApiWebintGroupProfile)) as SNApiWebintGroupProfile[])
      : []
  }

  function initSourceLookupPhoneValidator(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return lookup_phone_verification
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => lookup_phone_verification.includes(el as LookupPhoneVerification)) as LookupPhoneVerification[])
      : []
  }

  function initIPGeo(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return ip_geo
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => ip_geo.includes(el as IPGeo)) as IPGeo[])
      : []
  }

  function initIPGeoByNumber(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return geolocation_by_number
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => geolocation_by_number.includes(el as IPGeoNumber)) as IPGeoNumber[])
      : []
  }

  function initNameByCountry(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return nameByCountry
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => nameByCountry.includes(el as NameByCountry)) as NameByCountry[])
      : []
  }

  function initPsychoProfile(
    sources: Source[] | undefined,
    type: PackageType | WorkflowType, 
  ){
    if (type === 'personal') return psychoProfile
    if (type === 'custom') return []
    return sources
      ? (sources.filter((el) => psychoProfile.includes(el as PsychoProfile)) as PsychoProfile[])
      : []
  }

  function getSentimenAnalysys(sources: Source[]) {
    return sources.filter((el) => sentimentAnalysys.includes(el as SentimentAnalysys))
  }

  function getCombinedId(sources: Source[]) {
    return sources.filter((el) => combinedIds.includes(el as CombinedId))
  }

  function getCombinedNames(sources: Source[]) {
    return sources.filter((el) => combinedNames.includes(el as CombinedNames))
  }

  function getCombinedEmails(sources: Source[]) {
    return sources.filter((el) => combinedEmails.includes(el as CombinedEmail))
  }

  function getCombinedPhones(sources: Source[]) {
    return sources.filter((el) => combinedPhones.includes(el as CombinedPhone))
  }

  function getPhones(sources: Source[]) {
    return sources.filter((el) => phones.includes(el as Phone))
  }

  function getSocials(sources: Source[]) {
    return sources.filter((el) => socials.includes(el as Social))
  }

  function getSourceNameApi(sources: Source[]) {
    return sources.filter((el) => nameApi.includes(el as NameApi))
  }

  function getSourceCroloX(sources: Source[]) {
    return sources.filter((el) => crolo_x.includes(el as CroloX))
  }

  function getSourceIrbisTikTok(sources: Source[]) {
    return sources.filter((el) => irbis_tiktok.includes(el as IrbisTikTok))
  }

  function getSourceBlueSkyV3(sources: Source[]) {
    return sources.filter((el) => bluesky.includes(el as IrbisBlueSky))
  }
  
  function getSourceCroloVk(sources: Source[]) {
    return sources.filter((el) => crolo_vk.includes(el as CroloVk))
  }

  function getSourceCroloOk(sources: Source[]) {
    return sources.filter((el) => crolo_ok.includes(el as CroloOk))
  }

  function getSourceIrbisCriminal(sources: Source[]) {
    return sources.filter((el) => irbis_criminal.includes(el as IrbisCriminal))
  }

  function getSourceSsnTrace(sources: Source[]) {
    return sources.filter((el) => ssn_trace.includes(el as SsnTrace))
  }

  function getSourceNatCrimCheck(sources: Source[]) {
    return sources.filter((el) => nat_crim_check.includes(el as NatCrimCheck))
  }

  function getSourcePep(sources: Source[]) {
    return sources.filter((el) => pep.includes(el as Pep))
  }

  function getDeepWeb(sources: Source[]) {
    return sources.filter((el) => deepWebLookups.includes(el as DeepWeb))
  }

  function getWebint(sources: Source[]) {
    return sources.filter((el) => webIntLookups.includes(el as WebInt))
  }

  function getFaceSearch(sources: Source[]) {
    return sources.filter((el) => faceSearches.includes(el as FaceSearch))
  }

  function getAdvancedCollection(sources: Source[]) {
    return sources.filter((el) => advancedCollectionLookups.includes(el as AdvancedCollectionFacebook))
  }

  function getFacebookMobileCollector(sources: Source[]) {
    return sources.filter((el) => fbMobileCollectorLookups.includes(el as FacebookMobileCollector))
  }

  function getInstagramMobileCollector(sources: Source[]) {
    return sources.filter((el) => instaMobileCollectorLookups.includes(el as InstagramMobileCollector))
  }

  function getAdvCollectionFbPersonWall(sources: Source[]) {
    return sources.filter((el) => advCollectionPersonWallLookups.includes(el as AdvCollectionFbPersonWall))
  }

  function getFindClone(sources: Source[]) {
    return sources.filter((el) => findClone.includes(el as FindClone))
  }

  function getPhoneList(sources: Source[]) {
    return sources.filter((el) => phoneList.includes(el as PhoneList))
  }

  function getKYC(sources: Source[]) {
    return sources.filter((el) => kyc.includes(el as KYC))
  }

  function getWebCollection(sources: Source[]) {
    return sources.filter((el) => web_collection.includes(el as WEB_Collection))
  }

  function getAPIv2X(sources: Source[]) {
    return sources.filter((el) => api_v2_x.includes(el as APIv2X))
  }

  function getAPIv2Instagram(sources: Source[]) {
    return sources.filter((el) => api_v2_instagram.includes(el as APIv2Instagram))
  }

  function getAPIv2LinkedIn(sources: Source[]) {
    return sources.filter((el) => api_v2_linkedin.includes(el as APIv2LinkedIn))
  }

  function getSnApiProfiler(sources: Source[]) {
    return sources.filter((el) => sn_api_profile.includes(el as SNApiProfile))
  }

  function getSNApiWebintGroupProfile(sources: Source[]) {
    return sources.filter((el) => sn_api_group_profile.includes(el as SNApiWebintGroupProfile))
  }

  function getSourceLookupPhoneValidator(source: Source[]) {
    return source.filter((el) => lookup_phone_verification.includes(el as LookupPhoneVerification))
  }

  function getIPGeo(sources: Source[]) {
    return sources.filter((el) => ip_geo.includes(el as IPGeo))
  }

  function getIPGeoByNumber(sources: Source[]) {
    return sources.filter((el) => geolocation_by_number.includes(el as IPGeoNumber))
  }

  function getPsychoProfile(sources: Source[]) {
    return sources.filter((el) => psychoProfile.includes(el as PsychoProfile))
  }

  function isSourceChecked(el: Source, sourcesData: Source[] | undefined) {
    return sourcesData?.includes(el)
  }
  

  return {
    initLookups,
    initSourcePhone,
    initSourceSocials,
    initSourceNameApi,
    initSourceCroloX,
    initSourceCroloVk,
    initSourceCroloOk,
    initSourceIrbisCriminal,
    initSourceSsnTrace, 
    initSourceNatCrimCheck, 
    initSourcePep,
    initSourceCourtRecords,
    initSourceIrbisTikTok,
    initSourceBlueSky,
    initSourceDeepWeb,
    initSourceWebInt,
    initCombinePhones,
    initPhoneList,
    initEmailList,
    initCombineEmails,
    initCombineNames,
    initCombineId,
    initFaceSearch,
    initAdvancedCollection,
    initFbMobileCollector,
    initInstaMobileCollector,
    initAdvCollectionFbPersonWall,
    initSentimentAnalysys,
    initFindClone,
    initSniperApi,
    initKYC,
    initWebCollection,
    initAPIv2X,
    initAPIv2Instagram,
    initAPIv2LinkedIn,
    initSourceSnAPIProfiler,
    initSourceSNApiWebintGroupProfile,
    initSourceLookupPhoneValidator,
    initIPGeo,
    initIPGeoByNumber,
    initNameByCountry,
    initPsychoProfile,

    getSentimenAnalysys,
    getPhoneList,
    getCombinedId,
    getPhones,
    getSocials,
    getSourceNameApi,
    getSourceCroloX,
    getSourceIrbisTikTok,
    getSourceBlueSkyV3,
    getSourceCroloVk,
    getSourceCroloOk,
    getSourceIrbisCriminal,
    getSourceSsnTrace, 
    getSourceNatCrimCheck, 
    getSourcePep,
    getDeepWeb,
    getWebint,
    getFaceSearch,
    getCombinedPhones,
    getCombinedEmails,
    getCombinedNames,
    getAdvancedCollection,
    getFacebookMobileCollector,
    getInstagramMobileCollector,
    getAdvCollectionFbPersonWall,
    getFindClone,
    getKYC,
    getWebCollection,
    getAPIv2X,
    getAPIv2Instagram,
    getAPIv2LinkedIn,
    getSnApiProfiler,
    getSNApiWebintGroupProfile,
    getSourceLookupPhoneValidator,
    getIPGeo,
    getIPGeoByNumber,
    getPsychoProfile,
    isSourceChecked,
  }
}
