import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {Box, Button, Collapse, IconButton, Stack, TableCell, TableRow, Tooltip, Typography} from '@mui/material'
import {DataTable} from '../../component/datatable'
import {TableRef} from '../../component/datatable/types'
import {TablePriority, TableStatus} from '../../component/datatable/component'
import {SvgIcon} from '../../component/SvgIcon'
import {colorSecondaryDark} from '../../mui'
import {useTableStyles} from '../../component/datatable/hook/useStyles'
import {Popper} from '../../component/Popper'
import {PopupAsk} from '../../component/PopupAsk'
import {useDispatch} from '../../store'
import {getPriorityName} from '../../function/common'
import {useSubscriber} from '../../hook/useSubscriber'
import dashboard_ from '../../sass/dashboard.module.sass'
import {deleteTicket, editPriority, editStatus, toggleViewOne} from '../../store/support/actionCreator'
import {Ticket, TicketPriority, TicketStatus} from '../../store/support/reducer'
import {EnumTicketStatus} from '../../enum/statusTicket'
import {Subscriber} from '../../store/user/reducer'

const statusArr: TicketStatus[] = [EnumTicketStatus.OPEN, EnumTicketStatus.IN_PROGRESS, EnumTicketStatus.CLOSED]
const priorityArr: TicketPriority[] = ['low', 'medium', 'high']

const styleSubscriberTicketDrop = {padding: '5px 16px 0', borderTop: '1px solid rgba(46, 151, 213, 0.3)'}
export const subscriberTicketsTableId = 'subscriberTickets'

interface Props {
  data: Subscriber
}

export function SubscriberTickets({data}: Props) {
  const {t} = useTranslation()
  const classes = useTableStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {subscriberTickets} = useSubscriber(data)
  const tableRef = React.useRef<TableRef>(null)

  const [activeRow, setActiveRow] = React.useState<Ticket | null>(null)
  const [statusAnchorEl, setStatusAnchorEl] = React.useState<null | HTMLElement>(null)
  const [priorityAnchorEl, setPriorityAnchorEl] = React.useState<null | HTMLElement>(null)
  const [deleteTicketId, setDeleteTicketId] = React.useState<number | null>(null)
  const [rowCollapsed, setRowCollapsed] = React.useState<Record<number, boolean>>({})

  function handleToggleRow(idx: number, status: boolean) {
    setRowCollapsed(state => ({...state, [idx]: status}))
  }

  const RenderTableBody = (row: Ticket, idx: number) => {
    const className = idx % 2 !== 0 ? classes.rowEven : ''

    return (
      <>
        <TableRow key={row.id} onClick={() => handleToggleRow(idx, !rowCollapsed[idx])} hover className={className}>
          <TableCell classes={classes}>{row.id}</TableCell>
          <TableCell classes={classes}>{row.title}</TableCell>
          <TableCell classes={classes}>
            <TableStatus status={row.status}/>
            <IconButton onClick={e => handleStatusPopper(e, row)}>
              <SvgIcon name="edit" color={colorSecondaryDark} size={{width: 16, height: 14}}/>
            </IconButton>
          </TableCell>
          <TableCell classes={classes}>
            <Stack direction="row" alignItems="center">
              <TablePriority value={row.priority}/>
              <IconButton onClick={e => handlePriorityPopper(e, row)}>
                <SvgIcon name="edit" color={colorSecondaryDark} size={{width: 16, height: 14}}/>
              </IconButton>
            </Stack>
          </TableCell>
          <TableCell classes={classes}>
            <Tooltip title={<span>{t('common.delete')}</span>}>
              <IconButton onClick={e => handleDelete(e, row.id)}>
                <SvgIcon name="delete" color={colorSecondaryDark} size={{width: 18, height: 16}}/>
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={5} sx={{padding: 0}}>
            <Collapse in={rowCollapsed[idx]} timeout="auto" unmountOnExit sx={styleSubscriberTicketDrop}>
              <Typography variant="light" fontSize={16}>{row.summary}</Typography>
              <Stack alignItems="flex-end">
                <Button
                  variant="underlined"
                  sx={{fontSize: 10, color: '#01163E'}}
                  onClick={() => handleViewInSupport(row)}
                >
                  {t('subscriber.viewInSupport')}
                </Button>
              </Stack>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  function updateTable() {
    tableRef.current?.refresh()
  }

  function handleStatusPopper(event: React.MouseEvent<HTMLElement>, row: Ticket) {
    event.stopPropagation()
    setActiveRow(row)
    setStatusAnchorEl(statusAnchorEl ? null : event.currentTarget)
  }

  function handleStatusChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setStatusAnchorEl(null)
    dispatch(editStatus(activeRow?.id as number, event.target.value, updateTable))
  }

  function handlePriorityPopper(event: React.MouseEvent<HTMLElement>, row: Ticket) {
    event.stopPropagation()
    setActiveRow(row)
    setPriorityAnchorEl(priorityAnchorEl ? null : event.currentTarget)
  }

  function handlePriorityChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setPriorityAnchorEl(null)
    dispatch(editPriority(activeRow?.id as number, event.target.value, updateTable))
  }

  function handleViewInSupport(ticket: Ticket) {
    navigate('/support')
    dispatch(toggleViewOne(ticket))
  }

  function handleDelete(event: React.MouseEvent<HTMLElement>, id: number) {
    event.stopPropagation()
    setDeleteTicketId(id)
  }

  return (
    <>
      <Typography variant="semiBold" fontSize={18}>{t('subscriber.tickets')}</Typography>

      <Box className={dashboard_.card_column} mt={1}>
        <DataTable
          ref={tableRef}
          id={subscriberTicketsTableId}
          url={`/ticket/${data.id}`}
          headerData={subscriberTickets.tableHeader}
          renderBody={RenderTableBody}
          size="small"
          limit={6}
          pagination
          emptyText="Sorry, no tickets from this subscriber"
        />
      </Box>

      {statusAnchorEl && (
        <Popper placement="bottom" anchorEl={statusAnchorEl} onClickAway={() => setStatusAnchorEl(null)}>
          <select value={activeRow?.status} onChange={handleStatusChange}>
            {statusArr.map(el => <option key={el} value={el}>{el.toUpperCase()}</option>)}
          </select>
        </Popper>
      )}
      {priorityAnchorEl && (
        <Popper placement="bottom" anchorEl={priorityAnchorEl} onClickAway={() => setPriorityAnchorEl(null)}>
          <select value={activeRow?.priority} onChange={handlePriorityChange}>
            {priorityArr.map(el => <option key={el} value={el}>{getPriorityName(el)}</option>)}
          </select>
        </Popper>
      )}
      {deleteTicketId && (
        <PopupAsk
          onYes={() => dispatch(deleteTicket(deleteTicketId, updateTable))}
          onClose={() => setDeleteTicketId(null)}
        />
      )}
    </>
  )
}