import * as firebase from 'firebase/auth'
import { t } from 'i18next'
import { FirebaseError } from 'firebase/app'
import { call, put, takeEvery, select } from 'redux-saga/effects'
import jwtDecode from 'jwt-decode'
import { State } from '../index'
import { CommonState } from '../common/reducer'
import { AuthState } from './reducer'
import { LocalStorage } from '../../enum/storage'
import { api, Response } from '../../function/axios'
import { getErrorMessage, handleError } from '../../function/error'
import * as service from './service'
import * as actionType from './actionType'
import * as actionCreator from './actionCreator'
import * as commonActionCreator from '../common/actionCreator'
import * as settingsActionCreator from '../settings/actionCreator'

function* getDepartment() {
  yield put(commonActionCreator.setLoading(true))

  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: '/department',
    })

    yield put(actionCreator.setDepartment(data))
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(commonActionCreator.setLoading(false))
  }
}

function* signUp(action: actionType.SignUp) {
  const auth = firebase.getAuth()

  yield put(commonActionCreator.setLoading(true))

  try {
    const { data: isEmailExist }: Response = yield call(api, {
      method: 'GET',
      url: `/users/email?email=${action.email}`,
    })

    if (isEmailExist) {
      yield put(
        commonActionCreator.setFieldError(
          'registerEmail',
          t('message.error.emailAlreadyInUse'),
        ),
      )
      return
    } else {
      yield put(commonActionCreator.setFieldError('registerEmail', undefined))
    }

    const result: [] = yield call(
      firebase.fetchSignInMethodsForEmail,
      auth,
      action.email,
    )
    const emailExistOnFirebase = result.length > 0

    if (emailExistOnFirebase) {
      yield call(api, {
        method: 'DELETE',
        url: `/users/email?email=${action.email}`,
      })
    }

    const userCredential: firebase.UserCredential = yield call(
      firebase.createUserWithEmailAndPassword,
      auth,
      action.email,
      action.password,
    )
    const actionCodeSettings: firebase.ActionCodeSettings = {
      url: `${process.env.REACT_APP_HOST_URL}/auth?uid=${userCredential.user.uid}&email=${action.email}&departmentId=${action.department}`,
    }
    yield call(
      firebase.sendEmailVerification,
      userCredential.user,
      actionCodeSettings,
    )

    yield put(
      commonActionCreator.showSnackbar('success', 'Verification email sent'),
    )

    action.navigate('/auth/register/sent', { state: action.email })
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(commonActionCreator.setLoading(false))
  }
}

function* signIn(action: actionType.SignIn) {
  const auth = firebase.getAuth()

  yield put(commonActionCreator.setLoading(true))

  try {
    const { translation }: CommonState = yield select((state) => state.common)

    const { user }: firebase.UserCredential = yield call(
      firebase.signInWithEmailAndPassword,
      auth,
      action.email,
      action.password,
    )

    yield put(commonActionCreator.setFieldError('loginEmail', undefined))

    if (!user.emailVerified) {
      yield put(
        commonActionCreator.showSnackbar(
          'error',
          translation.message.error.notVerifiedEmail,
        ),
      )
      return
    }

    const token: string = yield call(service.getToken, user)
    const isUserForbidden: boolean = yield call(
      service.checkIsUserForbidden,
      token,
    )

    if (isUserForbidden) {
      yield put(
        commonActionCreator.showSnackbar(
          'error',
          translation.message.error.notAuthAdmin,
        ),
      )
      return
    }

    const response: Response = yield call(api, {
      method: 'GET',
      url: '/auth/get2FA',
      token,
    })

    const is2FA = response.data.result

    if (is2FA) {
      localStorage.setItem(LocalStorage.TOKEN_TMP, token)
      action.navigate('/auth/2step')
      return
    }

    yield put(commonActionCreator.hideSnackbar)
    yield put(actionCreator.refreshToken(token, () => action.navigate('/')))
  } catch (e) {
    if (e instanceof FirebaseError) {
      if (e.code === 'auth/wrong-password')
        yield put(
          commonActionCreator.setFieldError(
            'loginPassword',
            getErrorMessage(e),
          ),
        )
      else
        yield put(
          commonActionCreator.setFieldError('loginEmail', getErrorMessage(e)),
        )
      return
    }
    yield call(handleError, e)
  } finally {
    yield put(commonActionCreator.setLoading(false))
  }
}

function* signBy2Fa(action: actionType.SignBy2Fa) {
  const auth = firebase.getAuth()

  yield put(commonActionCreator.setLoading(true))

  const tmpToken = localStorage.getItem(LocalStorage.TOKEN_TMP)

  if (tmpToken === null) {
    yield put(
      commonActionCreator.showSnackbar(
        'error',
        `Something went wrong. Please try again`,
      ),
    )
    yield put(commonActionCreator.setLoading(false))
    action.navigate('/auth')
    return
  }

  try {
    const { translation }: CommonState = yield select((state) => state.common)

    const response: Response = yield call(api, {
      method: 'POST',
      url: '/auth/authenticate',
      body: {
        twoFACode: action.code,
      },
      token: tmpToken,
    })

    const { user }: firebase.UserCredential = yield call(
      firebase.signInWithCustomToken,
      auth,
      response.data.token,
    )
    const token: string = yield call(service.getToken, user)

    const isUserForbidden: boolean = yield call(
      service.checkIsUserForbidden,
      token,
    )

    if (isUserForbidden) {
      yield put(
        commonActionCreator.showSnackbar(
          'error',
          translation.message.error.notAuthAdmin,
        ),
      )
      return
    }

    localStorage.removeItem(LocalStorage.TOKEN_TMP)

    yield put(commonActionCreator.hideSnackbar)
    yield put(actionCreator.refreshToken(token, () => action.navigate('/')))
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(commonActionCreator.setLoading(false))
  }
}

function* requestForgot(action: actionType.RequestForgot) {
  const auth = firebase.getAuth()

  yield put(commonActionCreator.setLoading(true))

  const actionCodeSettings: firebase.ActionCodeSettings = {
    url: `${process.env.REACT_APP_HOST_URL}/auth/change/password`,
  }

  try {
    const { data: isEmailExist }: Response = yield call(api, {
      method: 'GET',
      url: `/users/email?email=${action.email}`,
    })

    if (isEmailExist) {
      yield call(
        firebase.sendPasswordResetEmail,
        auth,
        action.email,
        actionCodeSettings,
      )

      action.navigate('/auth/forgot/sent', { state: action.email })
    } else {
      yield put(
        commonActionCreator.setFieldError('forgot', 'Sorry, account not found'),
      )
    }
  } catch (e) {
    if (e instanceof FirebaseError) {
      yield put(commonActionCreator.setFieldError('forgot', getErrorMessage(e)))
      return
    }
    yield call(handleError, e)
  } finally {
    yield put(commonActionCreator.setLoading(false))
  }
}

function* updatePassword(action: actionType.UpdatePassword) {
  const auth = firebase.getAuth()

  try {
    yield call(firebase.verifyPasswordResetCode, auth, action.oobCode)
    yield call(
      firebase.confirmPasswordReset,
      auth,
      action.oobCode,
      action.password,
    )

    yield put(commonActionCreator.showSnackbar('success'))
    action.navigate('/auth')
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getPhoneCode(action: actionType.GetPhoneCode) {
  const auth = firebase.getAuth()
  const phone = action.phone.replace('+', '%2B')

  yield put(commonActionCreator.setLoading(true))

  try {
    const { data: phoneExist }: Response = yield call(api, {
      method: 'GET',
      url: `/users/phone?phone=${phone}`,
    })

    if (phoneExist) {
      yield put(
        commonActionCreator.setFieldError(
          'phone',
          t('message.error.phoneAlreadyRegistered'),
        ),
      )
      return
    }

    if (window.recaptchaVerifier === undefined) {
      window.recaptchaVerifier = new firebase.RecaptchaVerifier(
        'recaptcha',
        { size: 'invisible' },
        auth,
      )
    }

    window.confirmationResult = yield call(
      firebase.signInWithPhoneNumber,
      auth,
      action.phone as string,
      window.recaptchaVerifier,
    )

    yield put(actionCreator.setPhoneVerify(true))

    yield put(
      commonActionCreator.showSnackbar('info', t('message.info.phoneCodeSent')),
    )
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(commonActionCreator.setLoading(false))
  }
}

function* refreshToken(action: actionType.RefreshToken) {
  let token = action.token

  const { refreshedToken }: AuthState = yield select((state) => state.auth)

  try {
    const user: firebase.User = yield call(service.getFirebaseUser)
    if (token === undefined) token = yield call(service.getToken, user)
    const parsedToken: firebase.ParsedToken = jwtDecode(token as string)

    localStorage.setItem(LocalStorage.TOKEN, token as string)
    localStorage.setItem(LocalStorage.UID, user.uid)

    yield call(api, {
      method: 'POST',
      url: '/users/session',
      body: { uid: user.uid },
    })

    if (refreshedToken) return

    yield put(actionCreator.setRefreshedToken(token as string))
    yield put(settingsActionCreator.setState('uid', user.uid))
    yield put(
      settingsActionCreator.setState(
        'provider',
        parsedToken.firebase?.sign_in_provider,
      ),
    )
    yield put(settingsActionCreator.setState('role', parsedToken.role))

    if (action.redirect) action.redirect()
  } catch (e) {
    yield call(handleError, e)
  }
}

function* signOut(action: actionType.SignOut) {
  const auth = firebase.getAuth()

  try {
    const { is2FAEnabled } = yield select((state: State) => state.settings)

    if (is2FAEnabled) {
      yield put(settingsActionCreator.setState('is2FAEnabled', false))

      yield call(api, {
        method: 'PUT',
        url: '/auth/logout2FA',
      })
    } else {
      yield call([auth, auth.signOut])
    }

    localStorage.removeItem(LocalStorage.UID)
    localStorage.removeItem(LocalStorage.TOKEN)

    yield put(actionCreator.flushState)
    yield put(settingsActionCreator.flushState)

    if (action.navigate) action.navigate('/auth')
    else window.location.replace('/auth')
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(commonActionCreator.setLoading(false))
  }
}

export function* watchAuth() {
  yield takeEvery(actionType.GET_DEPARTMENT, getDepartment)
  yield takeEvery(actionType.SIGN_UP, signUp)
  yield takeEvery(actionType.SIGN_IN, signIn)
  yield takeEvery(actionType.SIGN_BY_2FA, signBy2Fa)
  yield takeEvery(actionType.REQUEST_FORGOT, requestForgot)
  yield takeEvery(actionType.UPDATE_PASSWORD, updatePassword)
  yield takeEvery(actionType.GET_PHONE_CODE, getPhoneCode)
  yield takeEvery(actionType.REFRESH_TOKEN, refreshToken)
  yield takeEvery(actionType.SIGN_OUT, signOut)
}
