import React from 'react'
import {useTranslation} from 'react-i18next'
import {Box, Button, Grid, Typography} from '@mui/material'
import auth_ from '../sass/auth.module.sass'
import {PublicPage} from '../component/auth/PagePublic'
import {useLocation, useNavigate} from 'react-router-dom'

export function ForgotSentPage() {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const {state: email} = useLocation()

  return (
    <PublicPage>
      <Grid className={auth_.title} item>{t('auth.forgot.title')}</Grid>

      <Grid item>
        <Box className={auth_.hint_large} mt={10} mb={3}>
          <Typography fontSize={16}>
            {t('auth.forgot.doneHint1')} <Typography variant="semiBold" color="primary">{email}</Typography> {t('auth.forgot.doneHint2')}
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        <Button variant="contained" color="primary" size="large" fullWidth onClick={() => navigate('/auth')}>
          Ok
        </Button>
      </Grid>
    </PublicPage>
  )
}