import {Color} from 'react-color'
import {SupportAction} from './actionType'
import {Ticket, TicketType} from './reducer'
import {Notification} from './reducer'

export const initNotification: SupportAction = {type: 'support/INIT_NOTIFICATION'}

export const fetchNotification = (offset: number, limit: number, fetchMore?: boolean, refreshToken?: boolean): SupportAction => ({
  type: 'support/GET_NOTIFICATION',
  offset,
  limit,
  fetchMore,
  refreshToken,
})

export const setNotification = (data: Notification[]): SupportAction => ({type: 'support/SET_NOTIFICATION', data})

export const addNotification = (data: Notification[]): SupportAction => ({type: 'support/ADD_NOTIFICATION', data})

export const addNewNotification = (data: Notification[]): SupportAction => ({type: 'common/ADD_NEW_NOTIFICATION', data})

export const readNotification = (id: number): SupportAction => ({type: 'support/READ_NOTIFICATION', id})

export const fetchTicketTypes: SupportAction = {type: 'support/GET_TICKET_TYPES'}

export const setTicketTypes = (data: TicketType[]): SupportAction => ({type: 'support/SET_TICKET_TYPES', data})

export const putTicketType = (data: TicketType, callback: () => void): SupportAction => ({
  type: 'support/PUT_TICKET_TYPE',
  data,
  callback,
})

export const deleteTicketType = (id: number, callback: () => void): SupportAction => ({
  type: 'support/DELETE_TICKET_TYPE',
  id,
  callback,
})

export const editTicket = (id: number, data: object, callback: () => void): SupportAction => ({
  type: 'support/EDIT_TICKET',
  id,
  data,
  callback,
})

export const deleteTicket = (id: number, callback: () => void): SupportAction => ({
  type: 'support/DELETE_TICKET',
  id,
  callback,
})

export const toggleViewOne = (data: Ticket | null): SupportAction => ({type: 'support/TOGGLE_VIEW_ONE', data})

export const editStatus = (id: number, value: string, callback: () => void): SupportAction => ({
  type: 'support/EDIT_STATUS',
  id,
  value,
  callback,
})

export const editPriority = (id: number, value: string, callback: () => void): SupportAction => ({
  type: 'support/EDIT_PRIORITY',
  id,
  value,
  callback,
})

export const startTimer = (id: number, data: object, callback: () => void): SupportAction => ({
  type: 'support/START_TIMER',
  id,
  data,
  callback,
})

export const sendChatMessage = (formData: FormData): SupportAction => ({type: 'support/SEND_CHAT_MESSAGE', formData})

export const postTicketType = (name: string, color: Color, callback: () => void): SupportAction => ({
  type: 'support/POST_TICKET_TYPE',
  name,
  color,
  callback,
})

export const sendNotification = (title: string, text: string, ids: number[]): SupportAction => ({
  type: 'support/SEND_NOTIFICATION',
  title,
  text,
  ids,
})

export const sendEmail = (formData: FormData): SupportAction => ({type: 'support/SEND_EMAIL', formData})

export const clearNotifications = (): SupportAction => ({
  type: 'common/CLEAR_NOTIFICATIONS',
})

export const clearNotificationsSuccess = (): SupportAction => ({
  type: 'common/CLEAR_NOTIFICATIONS_SUCCESS',
})