export function isNumeric(str: string) {
  return /^-?\d+$/.test(str)
}

export function getRandomNumber(minCount: number, maxCount: number) {
  return Math.floor(Math.random() * (maxCount - minCount + 1) + minCount)
}

export function kFormatter(num: number | undefined): number | string {
  if (num === undefined) return 0
  return Math.abs(num) > 999
    ? Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1)) + 'K'
    : Math.sign(num) * Math.abs(num)
}

export function formatWithCommas(num: number) {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function indexOfMax(arr: number[]) {
  if (arr.length === 0) {
    return -1
  }

  let max = arr[0]
  let maxIndex = 0

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      maxIndex = i
      max = arr[i]
    }
  }

  return maxIndex
}

export function pad(num: number, size: number) {
  let strNum = num.toString()
  while (strNum.length < size) strNum = '0' + strNum
  return strNum
}

export function initNumber(number: number | null | undefined): string {
  if (number === 0) return '0'
  else if (number === undefined) return ''
  else return String(number) || ''
}

export function addLeadingZeros(num: number, totalLength: number) {
  return String(num).padStart(totalLength, '0')
}