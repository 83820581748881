import {createStyles, makeStyles} from '@mui/styles'
import {Theme} from '@mui/material'
import {boxShadow, fontSemiBold, colorBackground, fontBlack, fontRegular} from '../../../mui'

export const useSearchStyles = makeStyles({
  root: {
    width: 180,
    height: 38,
    background: 'none',
    '& input': {
      paddingRight: 0,
    },
    '& input::placeholder': {
      fontSize: 13,
      fontFamily: fontSemiBold,
    },
    '& .MuiInputAdornment-root': {
      marginLeft: 0,
    },
  },
})

export const useTableStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      fontSize: 14,
      fontFamily: fontRegular,
      '& thead': {
        background: colorBackground,
        '& th': {
          fontFamily: fontBlack,
          color: '#8B929B',
          whiteSpace: 'nowrap',
        },
      },
      '& tbody': {
        '& tr.MuiTableRow-hover': {
          '&:hover': {
            backgroundColor: theme.palette.secondary.light + ' !important',
            cursor: 'pointer',
          },
        },
        '& tr': {
          backgroundColor: '#FFF',
        },
      },
      '& th, td': {
        border: 'none',
      },
      '& td:first-child, & th:first-child': {
        paddingLeft: '24px !important',
      },
      '& td:last-child, & th:last-child': {
        paddingRight: '24px !important',
      },
    },
    card: {
      padding: '16px 0',
      background: '#FFF',
      borderRadius: 15,
      boxShadow: boxShadow,
      '& td, th': {
        padding: '0 16px',
        height: 52,
      },
    },
    rowEven: {
      backgroundColor: colorBackground + ' !important',
    },
    sizeSmall: {
      padding: '0 8px !important',
      height: 42,
    },
    sizeMedium: {
      padding: '0 16px !important',
      height: 53,
    },
  })
})

export const useTablePaginationStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaginationItem-root': {
      fontFamily: fontSemiBold,
      color: theme.palette.secondary.dark,
      background: 'none',
    },
    '& .MuiPaginationItem-previousNext': {
      color: '#FFF',
      background: theme.palette.primary.main,
    },
    '& .Mui-selected': {
      color: theme.palette.black.main,
    },
    '& .Mui-disabled': {
      color: theme.palette.secondary.dark,
      background: '#FFF',
      opacity: 1,
    },
  },
}))

export const useSliderStyle = makeStyles((theme: Theme) => ({
  root: {
    '& .slick-prev:before, & .slick-next:before': {
      color: theme.palette.secondary.dark,
    },
  },
}))