import { BlackList, BlackListIpAddress, BlackListPhones } from "./reducer"

export const GET_BLACKLIST = 'blacklist/GET_BLACKLIST'
export interface GetBlackList {
  type: typeof GET_BLACKLIST
}

export const SET_BLACKLIST= 'blacklist/SET_BLACKLIST'
export interface SetBlackList {
  type: typeof SET_BLACKLIST
  data: BlackList | null
}

export const POST_BLACKLIST = 'blacklist/POST_BLACKLIST'
export interface PostBlackList {
  type: typeof POST_BLACKLIST
  data: object
}

export type EditData = {
    id : number
  email: string
}

export const EDIT_BLACKLIST = 'blacklist/EDIT_BLACKLIST'
export interface EditBlackList {
  type: typeof EDIT_BLACKLIST
  data: EditData
}

export const DELETE_BLACKLIST = 'blacklist/DELETE_BLACKLIST'
export interface DeleteBlackList {
  type: typeof DELETE_BLACKLIST
  data: object
}

//Phones

export const GET_BLACKLIST_PHONES = 'blacklist/GET_BLACKLIST_PHONES'
export interface GetBlackListPhones {
  type: typeof GET_BLACKLIST_PHONES
}

export const SET_BLACKLIST_PHONES = 'blacklist/SET_BLACKLIST_PHONES'
export interface SetBlackListPhones {
  type: typeof SET_BLACKLIST_PHONES
  data: BlackListPhones | null
}

export const POST_BLACKLIST_PHONES = 'blacklist/POST_BLACKLIST_PHONES'
export interface PostBlackListPhones {
  type: typeof POST_BLACKLIST_PHONES
  data: object
}

export type EditDataPhones = {
    id : number
  phone: string
}

export const EDIT_BLACKLIST_PHONES = 'blacklist/EDIT_BLACKLIST_PHONES'
export interface EditBlackListPhones {
  type: typeof EDIT_BLACKLIST_PHONES
  data: EditDataPhones
}

export const DELETE_BLACKLIST_PHONES = 'blacklist/DELETE_BLACKLIST_PHONES'
export interface DeleteBlackListPhones {
  type: typeof DELETE_BLACKLIST_PHONES
  data: object
}

//Ip Address
export const SET_BLACKLIST_IP_ADDRESS = 'blacklist/SET_BLACKLIST_IP_ADDRESS'
export interface SetBlackListIpAddress {
  type: typeof SET_BLACKLIST_IP_ADDRESS
  data: BlackListIpAddress | null
}

export const POST_BLACKLIST_IP_ADDRESS = 'blacklist/POST_BLACKLIST_IP_ADDRESS'
export interface PostBlackListIpAddress {
  type: typeof POST_BLACKLIST_IP_ADDRESS
  data: object
}

export type EditDataIpAddress = {
  id : number
  ipAddress: string
}

export const EDIT_BLACKLIST_IP_ADDRESS = 'blacklist/EDIT_BLACKLIST_IP_ADDRESS'
export interface EditBlackListIpAddress {
  type: typeof EDIT_BLACKLIST_IP_ADDRESS
  data: EditDataIpAddress
}

export const GET_BLACKLIST_IP_ADDRESS = 'blacklist/GET_BLACKLIST_IP_ADDRESS'
export interface GetBlackListIpAddress {
  type: typeof GET_BLACKLIST_IP_ADDRESS
}


export const DELETE_BLACKLIST_IP_ADDRESS = 'blacklist/DELETE_BLACKLIST_IP_ADDRESS'
export interface DeleteBlackListIpAddress {
  type: typeof DELETE_BLACKLIST_IP_ADDRESS
  data: object
}

export type BlackListAction =
  | GetBlackList
  | SetBlackList
  | PostBlackList
  | EditBlackList
  | DeleteBlackList
  | GetBlackListPhones
  | SetBlackListPhones
  | PostBlackListPhones
  | EditBlackListPhones
  | DeleteBlackListPhones
  | GetBlackListIpAddress
  | SetBlackListIpAddress
  | PostBlackListIpAddress
  | EditBlackListIpAddress
  | DeleteBlackListIpAddress
