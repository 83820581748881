import React from 'react'
import Joi, { Schema } from 'joi'
import { t } from 'i18next'
import { addDays, differenceInSeconds } from 'date-fns'
import { Subscriber } from '../../store/user/reducer'
import { useDispatch, useSelector } from '../../store'
import { useRenewal } from '../../hook/useRenewal'
import { validateForm } from '../../function/validation'
import { showSnackbar } from '../../store/common/actionCreator'
import {
  Box,
  Divider, Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Stack,
  Switch,
  Tooltip,
  Typography, useTheme,
} from '@mui/material'
import dashboard_ from '../../sass/dashboard.module.sass'
import { SvgIcon } from '../../component/SvgIcon'
import { colorError, colorSecondaryDark, colorSuccess } from '../../mui'
import { Popper } from '../../component/Popper'
import { fetchPackage } from '../../store/package/actionCreator'
import {
  patchUserAdditionalPackage,
  patchUserPackage,
  putBalance,
  putQuota,
  putRenewal,
  setSubscriberIrbisProUpgrade,
} from '../../store/dashboard/actionCreator'
import { Package } from '../../store/package/reducer'

import quotaprofiler_lookup from '../../../src/asset/icon/quotaprofiler-lookup.png'
import quotaprofiler_crawler from '../../../src/asset/icon/quotaprofiler-crawler.png'
import quotaprofiler_cases from '../../../src/asset/icon/quotaprofiler-cases.png'
import quotawebint_cases from '../../../src/asset/icon/quotawebint-cases.png'
import quotawebint_crawler from '../../../src/asset/icon/quotawebint-crawler.png'
import quotawebint_monitoring from '../../../src/asset/icon/quotawebint-monitoring.png'

const schemaQoutas= Joi.object({
  value: Joi.number()
    // .integer()
    .min(0)
    .max(99999)
    .label(t('field.label.balance')),
 })
 
const schemaRenewal = Joi.object({
  value: Joi.number().integer().min(0).max(365).label(t('field.label.renewal')),
})

interface PopperPackageProps {
  subscriber: Subscriber
  packageAnchorEl: HTMLElement
  setPackageAnchorEl: (anchor: null | HTMLElement) => void
  setPackageTitle: (title: string) => void
}

function PopperPackage({
  subscriber,
  packageAnchorEl,
  setPackageAnchorEl,
  setPackageTitle,
}: PopperPackageProps) {
  const dispatch = useDispatch()

  const { packages } = useSelector((state) => state.package)

  React.useEffect(() => {
    dispatch(fetchPackage)
  }, [dispatch])

  function handleSelect(packageData: Package) {
    dispatch(patchUserAdditionalPackage(subscriber.id, packageData.id, callback))
    setPackageAnchorEl(null)

    function callback() {
      setPackageTitle(packageData.title)
      if (subscriber.package) {
        subscriber.package.title = packageData.title
        subscriber.package.id = packageData.id
        subscriber.package.price = packageData.price as number
      }
    }
  }

  function handlePackageClickAway() {
    setPackageAnchorEl(null)
  }

  return (
    <Popper
      placement="bottom"
      anchorEl={packageAnchorEl}
      onClickAway={handlePackageClickAway}
    >
      <Box minWidth={108} minHeight={36}>
        {packages?.map((el) => {
          const isWebInt = el.type === 'webinthub' || (el.type === 'webinthub_demo' && el.uid === subscriber.uid)

          return (
            isWebInt &&
            (
              <MenuItem
                key={el.id}
                onClick={() => handleSelect(el)}
                disabled={false}
              >
                {el.title}
              </MenuItem>
            )
          )
        })}
      </Box>
    </Popper>
  )
}

interface Props {
  data: Subscriber
}

export function AdditionalPackage({ data }: Props) {
  console.log(data)
  const dispatch = useDispatch()
  const theme = useTheme()

  const initRenewal = useRenewal(data.additionalPackageEndDate, 'number')

  const initIrbisproUpgrade = data.irbisproUpgrade ? true : false

  const [packageTitle, setPackageTitle] = React.useState(data.additionalPackage?.title || "")
  const [packageAnchorEl, setPackageAnchorEl] =
    React.useState<null | HTMLElement>(null)
  
//==============//
  const [quotaProfilerLookups, setQuotaProfilerLookups] = React.useState(String(data.additionalPackageQouta?.quotaProfilerLookups))
  const [quotaProfilerCrawlers, setQuotaProfilerCrawlers] = React.useState(String(data.additionalPackageQouta?.quotaProfilerCrawlers))
  const [quotaProfilerCases, setQuotaProfilerCases] = React.useState(String(data.additionalPackageQouta?.quotaProfilerCases))
  const [quotaProfilerPeriodic, setQuotaProfilerPeriodic] = React.useState(String(data.additionalPackageQouta?.quotaProfilerPeriodic))

  const [quotaWebintCases, setQuotaWebintCases] = React.useState(String(data.additionalPackageQouta?.quotaWebintCases))
  const [quotaWebintCrawlers, setQuotaWebintCrawlers] = React.useState(String(data.additionalPackageQouta?.quotaWebintCrawlers))
  const [quotaWebintMonitoring, setQuotaWebintMonitoring] = React.useState(String(data.additionalPackageQouta?.quotaWebintMonitoring))
  const [quotaWebintPeriodic, setQuotaWebintPeriodic] =  React.useState(String(data.additionalPackageQouta?.quotaWebintPeriodic))

//==============//
  const [quotaProfilerLookupsAnchorEl, setQuotaProfilerLookupsAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [quotaProfilerCrawlersAnchorEl, setQuotaProfilerCrawlersAnchorEl] =
    React.useState<null | HTMLElement>(null)
    const [quotaProfilerCasesAnchorEl, setQuotaProfilerCasesAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [quotaWebintCasesAnchorEl, setQuotaWebintCasesAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [quotaWebintCrawlersAnchorEl, setQuotaWebintCrawlersAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [quotaWebintMonitoringAnchorEl, setQuotaWebintMonitoringAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [quotaWebintPeriodicAnchorEl, setQuotaWebintPeriodicAnchorEl] =
    React.useState<null | HTMLElement>(null)
//==============//

  const [renewal, setRenewal] = React.useState(initRenewal)
  const [irbisproUpgrade, setIrbisproUpgrade] = React.useState(initIrbisproUpgrade)
  const [renewalAnchorEl, setRenewalAnchorEl] =
    React.useState<null | HTMLElement>(null)

  function handlePackagePopper(event: React.MouseEvent<HTMLElement>) {
    setPackageAnchorEl(packageAnchorEl ? null : event.currentTarget)
  }

  function validatePopper(schema: Schema, value: string) {
    const error = validateForm(schema, { value: Number(value) })

    if (error) {
      dispatch(showSnackbar('error', String(error.value)))
      return false
    }
    return true
  }
//1 ==============//
  function handleQuotaProfilerCrawlersPopper(event: React.MouseEvent<HTMLElement>) {
    setQuotaProfilerCrawlersAnchorEl(quotaProfilerCrawlersAnchorEl ? null : event.currentTarget)
  }

  function handleQuotaProfilerCrawlersSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return
    const isValid = validatePopper(schemaQoutas, quotaProfilerCrawlers)
    if (!isValid) return
    function callback() {
      if (data.additionalPackageQouta) 
      data.additionalPackageQouta.quotaProfilerCrawlers = quotaProfilerCrawlers
      setQuotaProfilerCrawlersAnchorEl(null)
    }
    dispatch(putQuota(data.uid, 
      {"quotaProfilerLookups" : quotaProfilerLookups, 
      "quotaProfilerCrawlers" : quotaProfilerCrawlers,
      "quotaProfilerCases" : quotaProfilerCases,
      "quotaProfilerPeriodic": quotaProfilerPeriodic, 
      "quotaWebintMonitoring" : quotaWebintMonitoring,
      "quotaWebintCases" : quotaWebintCases, 
      "quotaWebintCrawlers" : quotaWebintCrawlers, 
      "quotaWebIntPeriodic":quotaWebintPeriodic} , callback))
  }

  function handleQuotaProfilerCrawlersClickAway() {
    //setQuotaProfilerCrawlers(String(data.additionalPackageQouta.quotaProfilerCrawlers))
    setQuotaProfilerCrawlersAnchorEl(null)
  }
//2 ===========//
  function handleQuotaProfilerLookupsPopper(event: React.MouseEvent<HTMLElement>) {
    setQuotaProfilerLookupsAnchorEl(quotaProfilerLookupsAnchorEl ? null : event.currentTarget)
  }

  function handleQuotaProfilerLookupsSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return
    const isValid = validatePopper(schemaQoutas, quotaProfilerLookups)
    if (!isValid) return
    function callback() {
      if (data.additionalPackageQouta) 
      data.additionalPackageQouta.quotaProfilerLookups = quotaProfilerLookups
      setQuotaProfilerLookupsAnchorEl(null)
    }
    dispatch(putQuota(data.uid, 
      {"quotaProfilerLookups" : quotaProfilerLookups, 
      "quotaProfilerCrawlers" : quotaProfilerCrawlers,
      "quotaProfilerCases" : quotaProfilerCases,
      "quotaProfilerPeriodic": quotaProfilerPeriodic, 
      "quotaWebintMonitoring" : quotaWebintMonitoring,
      "quotaWebintCases" : quotaWebintCases, 
      "quotaWebintCrawlers" : quotaWebintCrawlers, 
      "quotaWebIntPeriodic":quotaWebintPeriodic} , callback))
  }

  function handleQuotaProfilerLookupsClickAway() {
    //setQuotaProfilerLookups(String(data.additionalPackageQouta.quotaProfilerLookups))
    setQuotaProfilerLookupsAnchorEl(null)
  }
//3 ======== //
  function handleQuotaWebintCasesPopper(event: React.MouseEvent<HTMLElement>) {
    setQuotaWebintCasesAnchorEl(quotaWebintCasesAnchorEl ? null : event.currentTarget)
  }

  function handleQuotaWebintCasesSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return
    const isValid = validatePopper(schemaQoutas, quotaWebintCases)
    if (!isValid) return
    function callback() {
      if (data.additionalPackageQouta) 
      data.additionalPackageQouta.quotaWebintCases = quotaWebintCases
      setQuotaWebintCasesAnchorEl(null)
    }
    dispatch(putQuota(data.uid, 
      {"quotaProfilerLookups" : quotaProfilerLookups, 
      "quotaProfilerCrawlers" : quotaProfilerCrawlers,
      "quotaProfilerCases" : quotaProfilerCases,
      "quotaProfilerPeriodic": quotaProfilerPeriodic, 
      "quotaWebintMonitoring" : quotaWebintMonitoring,
      "quotaWebintCases" : quotaWebintCases, 
      "quotaWebintCrawlers" : quotaWebintCrawlers, 
      "quotaWebIntPeriodic":quotaWebintPeriodic} , callback))
  }

  function handleQuotaWebintCasesClickAway() {
    //setQuotaWebintCases(String(data.additionalPackageQouta.quotaWebintCases))
    setQuotaWebintCasesAnchorEl(null)
  }

  //4 ======== //
  function handleQuotaWebintCrawlersPopper(event: React.MouseEvent<HTMLElement>) {
    setQuotaWebintCrawlersAnchorEl(quotaWebintCasesAnchorEl ? null : event.currentTarget)
  }

  function handleQuotaWebintCrawlersSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return
    const isValid = validatePopper(schemaQoutas, quotaWebintCrawlers)
    if (!isValid) return
    function callback() {
      if (data.additionalPackageQouta) 
      data.additionalPackageQouta.quotaWebintCrawlers = quotaWebintCrawlers
      setQuotaWebintCrawlersAnchorEl(null)
    }
    dispatch(putQuota(data.uid, 
      {"quotaProfilerLookups" : quotaProfilerLookups, 
      "quotaProfilerCrawlers" : quotaProfilerCrawlers,
      "quotaProfilerCases" : quotaProfilerCases,
      "quotaProfilerPeriodic": quotaProfilerPeriodic, 
      "quotaWebintMonitoring" : quotaWebintMonitoring,
      "quotaWebintCases" : quotaWebintCases, 
      "quotaWebintCrawlers" : quotaWebintCrawlers, 
      "quotaWebIntPeriodic":quotaWebintPeriodic} , callback))
  }
  function handleQuotaWebintCrawlersClickAway() {
    setQuotaWebintCrawlersAnchorEl(null)
  }

  //5 ======== //
  function handleQuotaProfilerCasesPopper(event: React.MouseEvent<HTMLElement>) {
    setQuotaProfilerCasesAnchorEl(quotaProfilerCasesAnchorEl ? null : event.currentTarget)
  }

  function handleQuotaProfilerCasesSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return
    const isValid = validatePopper(schemaQoutas, quotaProfilerCases)
    if (!isValid) return
    function callback() {
      if (data.additionalPackageQouta) 
      data.additionalPackageQouta.quotaProfilerCases = quotaProfilerCases
      setQuotaProfilerCasesAnchorEl(null)
    }
    dispatch(putQuota(data.uid, 
      {"quotaProfilerLookups" : quotaProfilerLookups, 
      "quotaProfilerCrawlers" : quotaProfilerCrawlers,
      "quotaProfilerCases" : quotaProfilerCases,
      "quotaProfilerPeriodic": quotaProfilerPeriodic, 
      "quotaWebintMonitoring" : quotaWebintMonitoring,
      "quotaWebintCases" : quotaWebintCases, 
      "quotaWebintCrawlers" : quotaWebintCrawlers, 
      "quotaWebIntPeriodic":quotaWebintPeriodic} , callback))
  }

  function handleQuotaProfilerCasesClickAway() {
    setQuotaProfilerCasesAnchorEl(null)
  }
  //6 ======== //
  function handleQuotaWebintMonitoringPopper(event: React.MouseEvent<HTMLElement>) {
    setQuotaWebintMonitoringAnchorEl(quotaWebintMonitoringAnchorEl ? null : event.currentTarget)
  }

  function handleQuotaWebintMonitoringSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return
    const isValid = validatePopper(schemaQoutas, quotaWebintMonitoring)
    if (!isValid) return
    function callback() {
      if (data.additionalPackageQouta) 
      data.additionalPackageQouta.quotaWebintMonitoring = quotaWebintMonitoring
      setQuotaWebintMonitoringAnchorEl(null)
    }
    dispatch(putQuota(data.uid, 
      {"quotaProfilerLookups" : quotaProfilerLookups, 
      "quotaProfilerCrawlers" : quotaProfilerCrawlers,
      "quotaProfilerCases" : quotaProfilerCases,
      "quotaProfilerPeriodic": quotaProfilerPeriodic, 
      "quotaWebintMonitoring" : quotaWebintMonitoring,
      "quotaWebintCases" : quotaWebintCases, 
      "quotaWebintCrawlers" : quotaWebintCrawlers, 
      "quotaWebIntPeriodic":quotaWebintPeriodic} , callback))
  }

  function handleQuotaWebintMonitoringClickAway() {
    setQuotaWebintMonitoringAnchorEl(null)
  }
  // ======== //
//7 ======== //
function handleQuotaWebintPeriodicPopper(event: React.MouseEvent<HTMLElement>) {
  setQuotaWebintPeriodicAnchorEl(quotaWebintPeriodicAnchorEl ? null : event.currentTarget)
}

function handleQuotaWebintPeriodicSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
  // if (event.key !== 'Enter') return
  // const isValid = validatePopper(schemaQoutas, quotaWebintPeriodic)
  // if (!isValid) return
  function callback() {
    if (data.additionalPackageQouta) 
    data.additionalPackageQouta.quotaWebintPeriodic = quotaWebintPeriodic
    setQuotaWebintMonitoringAnchorEl(null)
  }
  dispatch(putQuota(data.uid, 
    {"quotaProfilerLookups" : quotaProfilerLookups, 
    "quotaProfilerCrawlers" : quotaProfilerCrawlers,
    "quotaProfilerCases" : quotaProfilerCases,
    "quotaProfilerPeriodic": quotaProfilerPeriodic, 
    "quotaWebintMonitoring" : quotaWebintMonitoring,
    "quotaWebintCases" : quotaWebintCases, 
    "quotaWebintCrawlers" : quotaWebintCrawlers, 
    "quotaWebIntPeriodic":quotaWebintPeriodic} , callback))
}

function handleQuotaWebintPeriodicClickAway() {
  setQuotaWebintPeriodicAnchorEl(null)
}
// ======== //


  function handleRenewalPopper(event: React.MouseEvent<HTMLElement>) {
    setRenewalAnchorEl(renewalAnchorEl ? null : event.currentTarget)
  }

  function handleRenewalSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return

    const isValid = validatePopper(schemaRenewal, renewal)

    if (!isValid) return

    const diff = Number(renewal) - Number(initRenewal)
    const newEndDate = addDays(new Date(data.packageEndDate), diff)

    function callback() {
      setRenewalAnchorEl(null)
      data.packageEndDate = newEndDate
      setRenewal(String(newEndDate))
    }

    dispatch(putRenewal(data.id, diff, callback))
  }

  function handleRenewalClickAway() {
    setRenewal(initRenewal)
    setRenewalAnchorEl(null)
  }

  function handleIrbisProUpgradeSwitch(event: React.ChangeEvent<HTMLInputElement>){
    function callback(upgrade: boolean){
      setIrbisproUpgrade(upgrade)
    }
    dispatch(setSubscriberIrbisProUpgrade(data.id, event.target.checked, callback))
  }

  const isOverloadText = React.useMemo(() => {
    const secondsDiff = differenceInSeconds(
      new Date(data.packageEndDate),
      new Date(),
    )
    const days = Math.ceil(secondsDiff / 60 / 60 / 24)
    return data.packageBalance.toString().length + String(days).length > 6
  }, [data.packageBalance, data.packageEndDate])
  return (
    <Grid item xs>
      <Box className={dashboard_.card}>
        <Stack justifyContent="center" p={1} spacing={1}>
          <Typography variant="semiBold" fontSize={18} component="h2">
            {t('subscriber.package')}
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="semiBold"
              fontSize={12}
              color="primary"
              width={isOverloadText ? 100 : 80}
              className="text_ellipsis"
            >
              {packageTitle || 'No package'}
            </Typography>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              <IconButton onClick={handlePackagePopper}>
                <SvgIcon
                  name="edit"
                  color={colorSecondaryDark}
                  size={{ width: 16, height: 14 }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Divider orientation="vertical" />
        
        <Stack justifyContent="center" p={1} spacing={1}>
          <Typography variant="semiBold" fontSize={18} component="h2">
            {/* {t('subscriber.balance')} */}
            Profiler
          </Typography>
          <Stack direction="row" alignItems="center">
          <img src={quotaprofiler_lookup} width={14} height={14}/>
            <Typography variant="semiBold" fontSize={12} color="primary">
              { quotaProfilerLookups }
            </Typography>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              {/* <IconButton onClick={handleBalancePopper}> */}
              <IconButton onClick={handleQuotaProfilerLookupsPopper}>
                <SvgIcon
                  name="edit"
                  color={colorSecondaryDark}
                  size={{ width: 14, height: 14 }}
                />
              </IconButton>
            </Tooltip>
            <img src={quotaprofiler_crawler} width={14} height={14}/>
            <Typography variant="semiBold" fontSize={12} color="primary">
              {quotaProfilerCrawlers || "0"}
            </Typography>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              <IconButton onClick={handleQuotaProfilerCrawlersPopper}>
                <SvgIcon
                  name="edit"
                  color={colorSecondaryDark}
                  size={{ width: 14, height: 14 }}
                />
              </IconButton>
            </Tooltip>
            <img src={quotaprofiler_cases} width={14} height={14}/>
            <Typography variant="semiBold" fontSize={12} color="primary">
              { quotaProfilerCases }
            </Typography>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              {/* <IconButton onClick={handleBalancePopper}> */}
              <IconButton onClick={handleQuotaProfilerCasesPopper}>
                <SvgIcon
                  name="edit"
                  color={colorSecondaryDark}
                  size={{ width: 14, height: 14 }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>

        <Stack justifyContent="center" p={1} spacing={1}>
          <Typography variant="semiBold" fontSize={18} component="h2">
            {/* {t('subscriber.balance')} */}
            Webint
          </Typography>
          <Stack direction="row" alignItems="center">
          <img src={quotawebint_cases} width={14} height={14}/>
            <Typography variant="semiBold" fontSize={12} color="primary">
              {quotaWebintCases || "0"}
            </Typography>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              <IconButton onClick={handleQuotaWebintCasesPopper}>
                <SvgIcon
                  name="edit"
                  color={colorSecondaryDark}
                  size={{ width: 14, height: 14 }}
                />
              </IconButton>
            </Tooltip>
            <img src={quotawebint_crawler} width={14} height={14}/>
            <Typography variant="semiBold" fontSize={12} color="primary">
              {quotaWebintCrawlers || "0"}
            </Typography>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              <IconButton onClick={handleQuotaWebintCrawlersPopper}>
                <SvgIcon
                  name="edit"
                  color={colorSecondaryDark}
                  size={{ width: 14, height: 14 }}
                />
              </IconButton>
            </Tooltip>
            <img src={quotawebint_monitoring} width={14} height={14}/>
            <Typography variant="semiBold" fontSize={12} color="primary">
              {quotaWebintMonitoring || "0"}
            </Typography>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              <IconButton onClick={handleQuotaWebintMonitoringPopper}>
                <SvgIcon
                  name="edit"
                  color={colorSecondaryDark}
                  size={{ width: 14, height: 14 }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>

        <Divider orientation="vertical" />
        <Stack justifyContent="center" p={1} spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="semiBold" fontSize={18} component="h2">
              {t('subscriber.renewal')}
            </Typography>
            <Box
              width={11}
              height={11}
              bgcolor={data.isAutoRenewal ? colorSuccess : colorError}
              borderRadius={6}
            />
          </Stack>
          <Stack direction="row" alignItems="center">
          <Typography variant="semiBold" fontSize={12} color="primary">
              {quotaWebintPeriodic || "0"}
            </Typography>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              <IconButton onClick={handleQuotaWebintPeriodicPopper}>
                <SvgIcon
                  name="edit"
                  color={colorSecondaryDark}
                  size={{ width: 14, height: 14 }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>

        <Divider orientation="vertical" />
        <Stack justifyContent="center" p={1} spacing={1}>
          <Typography variant="semiBold" fontSize={18} component="h2">
              {t('subscriber.irbis-pro-upgrade')}
          </Typography>
          <Switch checked={irbisproUpgrade} onChange={handleIrbisProUpgradeSwitch} inputProps={{ 'aria-label': 'controlled' }}/>
        </Stack>
      </Box>

      {packageAnchorEl && (
        <PopperPackage
          subscriber={data}
          packageAnchorEl={packageAnchorEl}
          setPackageAnchorEl={setPackageAnchorEl}
          setPackageTitle={setPackageTitle}
        />
      )}
      
      {quotaProfilerLookupsAnchorEl && (
        <Popper
          placement="bottom"
          anchorEl={quotaProfilerLookupsAnchorEl}
          onClickAway={handleQuotaProfilerLookupsClickAway}
        >
          <OutlinedInput
            type="number"
            value={quotaProfilerLookups}
            onChange={(event) => setQuotaProfilerLookups(event.target.value)}
            readOnly={false}
            onKeyDown={handleQuotaProfilerLookupsSubmit}
            autoFocus
            inputProps={{
              min: 0,
            }}
          />
        </Popper>
      )}
      {quotaProfilerCrawlersAnchorEl && (
        <Popper
          placement="bottom"
          anchorEl={quotaProfilerCrawlersAnchorEl}
          onClickAway={handleQuotaProfilerCrawlersClickAway}
        >
          <OutlinedInput
            type="number"
            value={quotaProfilerCrawlers}
            onChange={(event) => setQuotaProfilerCrawlers(event.target.value)}
            readOnly={false}
            onKeyDown={handleQuotaProfilerCrawlersSubmit}
            autoFocus
            inputProps={{
              min: 0,
            }}
          />
        </Popper>
      )}
      {quotaProfilerCasesAnchorEl && (
        <Popper
          placement="bottom"
          anchorEl={quotaProfilerCasesAnchorEl}
          onClickAway={handleQuotaProfilerCasesClickAway}
        >
          <OutlinedInput
            type="number"
            value={quotaProfilerCases}
            onChange={(event) => setQuotaProfilerCases(event.target.value)}
            readOnly={false}
            onKeyDown={handleQuotaProfilerCasesSubmit}
            autoFocus
            inputProps={{
              min: 0,
            }}
          />
        </Popper>
      )}
      {quotaWebintCasesAnchorEl && (
        <Popper
          placement="bottom"
          anchorEl={quotaWebintCasesAnchorEl}
          onClickAway={handleQuotaWebintCasesClickAway}
        >
          <OutlinedInput
            type="number"
            value={quotaWebintCases}
            onChange={(event) => setQuotaWebintCases(event.target.value)}
            readOnly={false}
            onKeyDown={handleQuotaWebintCasesSubmit}
            autoFocus
            inputProps={{
              min: 0,
            }}
          />
        </Popper>
      )}
      {quotaWebintCrawlersAnchorEl && (
        <Popper
          placement="bottom"
          anchorEl={quotaWebintCrawlersAnchorEl}
          onClickAway={handleQuotaWebintCrawlersClickAway}
        >
          <OutlinedInput
            type="number"
            value={quotaWebintCrawlers}
            onChange={(event) => setQuotaWebintCrawlers(event.target.value)}
            readOnly={false}
            onKeyDown={handleQuotaWebintCrawlersSubmit}
            autoFocus
            inputProps={{
              min: 0,
            }}
          />
        </Popper>
      )}
      {quotaWebintMonitoringAnchorEl && (
        <Popper
          placement="bottom"
          anchorEl={quotaWebintMonitoringAnchorEl}
          onClickAway={handleQuotaWebintMonitoringClickAway}
        >
          <OutlinedInput
            type="number"
            value={quotaWebintMonitoring}
            onChange={(event) => setQuotaWebintMonitoring(event.target.value)}
            readOnly={false}
            onKeyDown={handleQuotaWebintMonitoringSubmit}
            autoFocus
            inputProps={{
              min: 0,
            }}
          />
        </Popper>
      )}
      {quotaWebintPeriodicAnchorEl && (
        <Popper
          placement="bottom"
          anchorEl={quotaWebintPeriodicAnchorEl}
          onClickAway={handleQuotaWebintPeriodicClickAway}
        >
          <OutlinedInput
            type="date"
            value={quotaWebintPeriodic}
            onChange={(event) => setQuotaWebintPeriodic(event.target.value)}
            readOnly={false}
            onKeyDown={handleQuotaWebintPeriodicSubmit}
            autoFocus
            inputProps={{
              min: 0,
            }}
          />
        </Popper>
      )}

      {renewalAnchorEl && (
        <Popper
          placement="bottom"
          anchorEl={renewalAnchorEl}
          onClickAway={handleRenewalClickAway}
        >
          <OutlinedInput
            type="number"
            value={renewal}
            onChange={(event) => setRenewal(event.target.value)}
            onKeyDown={handleRenewalSubmit}
            autoFocus
            inputProps={{
              min: 0,
            }}
          />
        </Popper>
      )}
    </Grid>
  )
}