import {FormState} from '../../function/validation'

export const POST_CAMPAIGN = 'marketing/POST_CAMPAIGN'
export interface PostCampaign {
  type: typeof POST_CAMPAIGN
  data: FormState
  callback: () => void
}

export const PUT_CAMPAIGN = 'marketing/PUT_CAMPAIGN'
export interface PutCampaign {
  type: typeof PUT_CAMPAIGN
  id: number
  data: FormState
  callback: () => void
}

export const ACTIVATE_CAMPAIGN = 'marketing/ACTIVATE_CAMPAIGN'
export interface ActivateCampaign {
  type: typeof ACTIVATE_CAMPAIGN
  id: number
  callback: () => void
}

export const DELETE_CAMPAIGN = 'marketing/DELETE_CAMPAIGN'
export interface DeleteCampaign {
  type: typeof DELETE_CAMPAIGN
  id: number
  callback: () => void
}

export const POST_TELEGRAM_CAMPAIGN = 'marketing/POST_TELEGRAM_CAMPAIGN'
export interface PostTelegramCampaign {
  type: typeof POST_TELEGRAM_CAMPAIGN
  data: FormData
  callback: () => void
}

export type MarketingAction =
  | PostCampaign
  | PutCampaign
  | ActivateCampaign
  | DeleteCampaign
  | PostTelegramCampaign
