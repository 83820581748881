import {Color} from 'react-color'
import {Ticket, TicketType, Notification} from './reducer'

export const INIT_NOTIFICATION = 'support/INIT_NOTIFICATION'
export interface InitNotification {
  type: typeof INIT_NOTIFICATION
}

export const GET_NOTIFICATION = 'support/GET_NOTIFICATION'
export interface GetNotification {
  type: typeof GET_NOTIFICATION
  offset: number
  limit: number
  fetchMore?: boolean
  refreshToken?: boolean
}

export const SET_NOTIFICATION = 'support/SET_NOTIFICATION'
export interface SetNotification {
  type: typeof SET_NOTIFICATION
  data: Notification[]
}

export const ADD_NOTIFICATION = 'support/ADD_NOTIFICATION'
export interface AddNotification {
  type: typeof ADD_NOTIFICATION
  data: Notification[]
}

export const ADD_NEW_NOTIFICATION = 'common/ADD_NEW_NOTIFICATION'
export interface AddNewNotification {
  type: typeof ADD_NEW_NOTIFICATION
  data: Notification[]
}

export const READ_NOTIFICATION = 'support/READ_NOTIFICATION'
export interface ReadNotification {
  type: typeof READ_NOTIFICATION
  id: number
}

export const POST_TICKET_TYPE = 'support/POST_TICKET_TYPE'
export interface PostTicketType {
  type: typeof POST_TICKET_TYPE
  name: string
  color: Color
  callback: () => void
}

export const GET_TICKET_TYPES = 'support/GET_TICKET_TYPES'
export interface GetTicketTypes {
  type: typeof GET_TICKET_TYPES
}

export const SET_TICKET_TYPES = 'support/SET_TICKET_TYPES'
export interface SetTicketTypes {
  type: typeof SET_TICKET_TYPES
  data: TicketType[]
}

export const PUT_TICKET_TYPE = 'support/PUT_TICKET_TYPE'
export interface PutTicketType {
  type: typeof PUT_TICKET_TYPE
  data: TicketType
  callback: () => void
}

export const DELETE_TICKET_TYPE = 'support/DELETE_TICKET_TYPE'
export interface DeleteTicketType {
  type: typeof DELETE_TICKET_TYPE
  id: number
  callback: () => void
}

export const EDIT_TICKET = 'support/EDIT_TICKET'
export interface EditTicket {
  type: typeof EDIT_TICKET
  id: number
  data: object
  callback: () => void
}

export const DELETE_TICKET = 'support/DELETE_TICKET'
export interface DeleteTicket {
  type: typeof DELETE_TICKET
  id: number
  callback: () => void
}

export const TOGGLE_VIEW_ONE = 'support/TOGGLE_VIEW_ONE'
export interface ToggleViewOne {
  type: typeof TOGGLE_VIEW_ONE
  data: Ticket | null
}

export const EDIT_STATUS = 'support/EDIT_STATUS'
export interface EditStatus {
  type: typeof EDIT_STATUS
  id: number
  value: string
  callback: () => void
}

export const EDIT_PRIORITY = 'support/EDIT_PRIORITY'
export interface EditPriority {
  type: typeof EDIT_PRIORITY
  id: number
  value: string
  callback: () => void
}

export const START_TIMER = 'support/START_TIMER'
export interface StartTimer {
  type: typeof START_TIMER
  id: number
  data: object
  callback: () => void
}

export const SEND_CHAT_MESSAGE = 'support/SEND_CHAT_MESSAGE'
export interface SendChatMessage {
  type: typeof SEND_CHAT_MESSAGE
  formData: FormData
}

export const SEND_NOTIFICATION = 'support/SEND_NOTIFICATION'
export interface SendNotification {
  type: typeof SEND_NOTIFICATION
  title: string
  text: string
  ids: number[]
}

export const SEND_EMAIL = 'support/SEND_EMAIL'
export interface SendEmail {
  type: typeof SEND_EMAIL
  formData: FormData
}

export const CLEAR_NOTIFICATIONS = 'common/CLEAR_NOTIFICATIONS'
export interface ClearNotifications {
  type: typeof CLEAR_NOTIFICATIONS
}

export const CLEAR_NOTIFICATIONS_SUCCESS = 'common/CLEAR_NOTIFICATIONS_SUCCESS'
export interface ClearNotificationsSuccess {
  type: typeof CLEAR_NOTIFICATIONS_SUCCESS
}


export type SupportAction =
  | InitNotification
  | GetNotification
  | SetNotification
  | AddNotification
  | AddNewNotification
  | ReadNotification
  | PostTicketType
  | GetTicketTypes
  | SetTicketTypes
  | PutTicketType
  | DeleteTicketType
  | EditTicket
  | DeleteTicket
  | ToggleViewOne
  | EditStatus
  | EditPriority
  | StartTimer
  | SendChatMessage
  | SendNotification
  | SendEmail
  | ClearNotifications
  | ClearNotificationsSuccess