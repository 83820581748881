type Direction = 'ASC' | 'DESC'

type Arr = any[] | undefined

export function sortByKey(arr: Arr, key: string, direction: Direction) {
  arr?.sort(function (a: any, b: any) {
    const keyA = a[key]
    const keyB = b[key]

    if (direction === 'ASC') {
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
    } else {
      if (keyA > keyB) return -1
      if (keyA < keyB) return 1
    }

    return 0
  })

  return arr
}