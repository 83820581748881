import { Stack, Typography } from '@mui/material'
import React from 'react'

type MainInfoProps = {
  label: string
  value: string | number
}

export default function MainInfo({ label, value }: MainInfoProps) {
  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="bold" noWrap lineHeight="18px">
        {label} -
      </Typography>
      &nbsp;
      <Typography lineHeight="18px">{value}</Typography>
    </Stack>
  )
}