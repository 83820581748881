import axios, { AxiosRequestConfig } from "axios"
import { LocalStorage } from "../enum/storage"
import { store } from "../store"
import { signOut } from "../store/auth/actionCreator"

export type ApiProps = {
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH"
  url?: string
  customApi?: string
  token?: string
  body?: any
  params?: any
  responseType?: any
  headers?: any
}

export type Response = {
  data?: any
  code?: number
}

const instance = axios.create()
const baseUrl = process.env.REACT_APP_API_BASE_URL

export const api = function ({
  method,
  url,
  body,
  params,
  token: newToken,
  responseType,
  customApi,
}: ApiProps): Promise<Response> {
  const token = newToken || localStorage.getItem(LocalStorage.TOKEN)

  if (newToken) localStorage.removeItem(LocalStorage.TOKEN)

  if (baseUrl === undefined || (customApi === undefined && url === undefined)) {
    return new Promise((resolve) => {
      resolve({
        data: { message: "Axios error" },
      })
    })
  }

  const finalUrl = customApi ? customApi : baseUrl + url

  const config: AxiosRequestConfig = {
    method: method,
    url: finalUrl,
    data: body,
    responseType: responseType,
    params: params,
  }

  const headers = {
    Authorization: `Bearer ${token}`,
  }

  if (token) config.headers = headers

  return instance
    .request(config)
    .then((response) => {
      return {
        code: response.status,
        data: response.data,
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          store.dispatch(signOut())
        }
      }

      return Promise.reject(error)
    })
}
