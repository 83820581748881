import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {Button, CircularProgress, Dialog, DialogActions, DialogTitle, Divider, Grid} from '@mui/material'
import {getQr, setQr} from '../../store/settings/actionCreator'
import {useDispatch} from '../../store'

type Props = {
  qrCode: string | null
  handleClose: any
  handle2FAInputOpen: () => void
}

export const Popup2FAQr = ({qrCode, handleClose, handle2FAInputOpen}: Props) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getQr)

    return () => {
      dispatch(setQr(null))
    }
  }, [dispatch])

  const handleQrCodeScanned = () => {
    handleClose()
    handle2FAInputOpen()
    dispatch(setQr(null))
  }

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>{t('auth.2step.title')}</DialogTitle>

      <Divider variant="popup" color="primary"/>

      <p>{t('settings.2factorHint')}</p>
      <p>{t('settings.2factorHint2')}</p>
      <Grid container direction="column" alignItems="center">
        <Grid item><h2>{t('settings.2factorScan')}</h2></Grid>
        <Grid item>
          {qrCode ? <img src={qrCode} width={200} height={200} alt="Your QR code"/> : <CircularProgress/>}
        </Grid>
      </Grid>

      <Divider variant="popup" color="primary"/>

      <DialogActions>
        <Button variant="outlined" size="large" color="primary" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        <Button variant="contained" size="large" color="primary" onClick={handleQrCodeScanned}>
          {t('common.done')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}