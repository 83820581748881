import { TableBodyData } from "./reducer"

export const SET_LOADING = "common/SET_LOADING"
export interface SetLoading {
  type: typeof SET_LOADING
  status: boolean
}

export const SET_TABLE_LOADING = "common/SET_TABLE_LOADING"
export interface SetTableLoading {
  type: typeof SET_TABLE_LOADING
  status: boolean
}

export const SET_LANGUAGE = "common/SET_LANGUAGE"
export interface SetLanguage {
  type: typeof SET_LANGUAGE
  language: string
}

export const SHOW_SNACKBAR = "common/SHOW_SNACKBAR"
export interface ShowSnackbar {
  type: typeof SHOW_SNACKBAR
  severity: string
  message?: string
}

export const HIDE_SNACKBAR = "common/HIDE_SNACKBAR"
export interface HideSnackbar {
  type: typeof HIDE_SNACKBAR
}

export const SET_FIELD_ERROR = "common/SET_FIELD_ERROR"
export interface SetFieldError {
  type: typeof SET_FIELD_ERROR
  key: string
  error: string | undefined
}

export const CLEAR_FIELD_ERROR = "common/CLEAR_FIELD_ERROR"
export interface ClearFieldError {
  type: typeof CLEAR_FIELD_ERROR
}

export const FETCH_TABLE = "common/FETCH_TABLE"
export interface FetchTable {
  type: typeof FETCH_TABLE
  url: string
  params: Record<string, any>
  tableId: string
}

export const SET_TABLE = "common/SET_TABLE"
export interface SetTable {
  type: typeof SET_TABLE
  data: TableBodyData
  tableId: string
}

export const DELETE_TABLE_DATA = "common/DELETE_TABLE_DATA"
export interface DeleteTableData {
  type: typeof DELETE_TABLE_DATA
  url: string
  ids: string[]
  callback: () => void
}

export const GET_SUBSCRIBER_SUCCEEDED = "common/GET_SUBSCRIBER_SUCCEEDED"

export interface GetSubscriberSucceeded {
  type: typeof GET_SUBSCRIBER_SUCCEEDED
  payload: any
}

export const GET_SUBSCRIBER = "common/GET_SUBSCRIBER_REQUEST"

export interface GetSubscriber {
  type: typeof GET_SUBSCRIBER
  id: number
  offset?: number
  limit?: number
  usage?: string
  time?: "day" | "week" | "month" | "year"
}

export type CommonAction =
  | SetLoading
  | SetTableLoading
  | SetLanguage
  | ShowSnackbar
  | HideSnackbar
  | SetFieldError
  | ClearFieldError
  | FetchTable
  | SetTable
  | DeleteTableData
  | GetSubscriberSucceeded
  | GetSubscriber
