import {ApikeyAction} from './actionType'

export type ApikeyState = {
  loaderVideo: boolean
}

const initialState: ApikeyState = {
  loaderVideo: false,
}

export function apikeyReducer(state = initialState, action: ApikeyAction) {
  switch (action.type) {
    case 'apikey/UPLOAD_VIDEO_START':
      return {
        ...state,
        loaderVideo: true,
      }
    case 'apikey/UPLOAD_VIDEO_END':
      return {
        ...state,
        loaderVideo: false,
      }
    default:
      return state
  }
}
