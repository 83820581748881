import React from 'react'
import Joi, { Schema } from 'joi'
import { t } from 'i18next'
import { addDays, differenceInSeconds } from 'date-fns'
import { Subscriber } from '../../store/user/reducer'
import { useDispatch, useSelector } from '../../store'
import { useRenewal } from '../../hook/useRenewal'
import { validateForm } from '../../function/validation'
import { showSnackbar } from '../../store/common/actionCreator'
import {
  Box,
  Divider, Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography, useTheme,
} from '@mui/material'
import dashboard_ from '../../sass/dashboard.module.sass'
import { SvgIcon } from '../../component/SvgIcon'
import { colorError, colorSecondaryDark, colorSuccess } from '../../mui'
import { Popper } from '../../component/Popper'
import { fetchPackage } from '../../store/package/actionCreator'
import {
  patchUserPackage,
  putAnnualBonus,
  putBalance,
  putRenewal,
} from '../../store/dashboard/actionCreator'
import { Package } from '../../store/package/reducer'

const schemaBalance = Joi.object({
  value: Joi.number()
    .min(0)
    .max(99999)
    .label(t('field.label.balance')),
 })

 const schemaAnnualBonus = Joi.object({
  value: Joi.number()
    .min(0)
    .max(99999)
    .label(t('field.label.annualBonus')),
 })
 
const schemaRenewal = Joi.object({
  value: Joi.number().integer().min(0).max(365).label(t('field.label.renewal')),
})

interface PopperPackageProps {
  subscriber: Subscriber
  packageAnchorEl: HTMLElement
  setPackageAnchorEl: (anchor: null | HTMLElement) => void
  setPackageTitle: (title: string) => void
}

function PopperPackage({
  subscriber,
  packageAnchorEl,
  setPackageAnchorEl,
  setPackageTitle,
}: PopperPackageProps) {
  const dispatch = useDispatch()

  const { packages } = useSelector((state) => state.package)

  React.useEffect(() => {
    dispatch(fetchPackage)
  }, [dispatch])

  function handleSelect(packageData: Package) {
    dispatch(patchUserPackage(subscriber.id, packageData.id, callback))
    setPackageAnchorEl(null)

    function callback() {
      setPackageTitle(packageData.title)
      if (subscriber.package) {
        subscriber.package.title = packageData.title
        subscriber.package.id = packageData.id
        subscriber.package.price = packageData.price as number
      }
    }
  }

  function handlePackageClickAway() {
    setPackageAnchorEl(null)
  }

  return (
    <Popper
      placement="bottom"
      anchorEl={packageAnchorEl}
      onClickAway={handlePackageClickAway}
    >
      <Box minWidth={108} minHeight={36}>
        {packages?.map((el) => {
          const userPackagePrice = subscriber.package?.price || 0
          const isCheaper = (el.price || 0) < userPackagePrice
          const isGeneral = el.type === 'general' || el.type === 'demo' || (el.type === 'personal' && el.uid === subscriber.uid)
          const isTelegramUser = subscriber.uid.length === 36 
          const isTelegramPackage = el.type === 'telegrambt'
          //const isNotFree = !el.isFree
          const isNotExistingPackage = el.id !== subscriber.package?.id

          return (
            <>
            {
            isTelegramUser && isTelegramPackage &&   (
              <MenuItem
                key={el.id}
                onClick={() => handleSelect(el)}
                //disabled={isCheaper}
                disabled={false}
              >
                {el.title}
              </MenuItem>
            ) 
          }
          {
            isGeneral && !isTelegramUser &&
            isNotExistingPackage &&
            //isNotFree && 
            (
              <MenuItem
                key={el.id}
                onClick={() => handleSelect(el)}
                //disabled={isCheaper}
                disabled={false}
              >
                {el.title}
              </MenuItem>
            )
            }
            </>
          )
        })}
      </Box>
    </Popper>
  )
}

interface Props {
  data: Subscriber
}

export function PackageBlock({ data }: Props) {
  const dispatch = useDispatch()
  const theme = useTheme()

  const initRenewal = useRenewal(data.packageEndDate, 'number')

  const [packageTitle, setPackageTitle] = React.useState(data.package?.title)
  const [packageAnchorEl, setPackageAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [balance, setBalance] = React.useState(String(data.packageBalance))

  const [annualBonus, setAnnualBonus] = React.useState(String(data.annualBonus) || "0")

  const [balanceAnchorEl, setBalanceAnchorEl] =
    React.useState<null | HTMLElement>(null)
  
  const [annualBonusAnchorEl, setAnnualBonusAnchorEl] =
    React.useState<null | HTMLElement>(null)

  const [renewal, setRenewal] = React.useState(initRenewal)
  const [renewalAnchorEl, setRenewalAnchorEl] =
    React.useState<null | HTMLElement>(null)

  function handlePackagePopper(event: React.MouseEvent<HTMLElement>) {
    setPackageAnchorEl(packageAnchorEl ? null : event.currentTarget)
  }

  function validatePopper(schema: Schema, value: string) {
    const error = validateForm(schema, { value: Number(value) })

    if (error) {
      dispatch(showSnackbar('error', String(error.value)))
      return false
    }
    return true
  }

  function handleBalancePopper(event: React.MouseEvent<HTMLElement>) {
    setBalanceAnchorEl(balanceAnchorEl ? null : event.currentTarget)
  }

  function handleAnnualBonusPopper(event: React.MouseEvent<HTMLElement>) {
    setAnnualBonusAnchorEl(annualBonusAnchorEl ? null : event.currentTarget)
  }

  function handleBalanceSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return

    const isValid = validatePopper(schemaBalance, balance)

    if (!isValid) return

    function callback() {
      data.packageBalance = Number(balance)
      setBalanceAnchorEl(null)
    }

    dispatch(putBalance(data.id, Number(balance), callback))
  }

  function handleAnnualBonusSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
    console.log("handleAnnualBonusSubmit")
    if (event.key !== 'Enter') return

    // const isValid = validatePopper(schemaAnnualBonus, annualBonus)

    // if (!isValid) return

    function callback() {
      data.annualBonus = Number(annualBonus)
      setAnnualBonusAnchorEl(null)
    }

    dispatch(putAnnualBonus(data.id, Number(annualBonus), callback))
  }

  function handleBalanceClickAway() {
    setBalance(String(data.packageBalance))
    setBalanceAnchorEl(null)
  }

  function handleAnnualBonusClickAway() {
    setAnnualBonus(String(data.annualBonus))
    setAnnualBonusAnchorEl(null)
  }

  function handleRenewalPopper(event: React.MouseEvent<HTMLElement>) {
    setRenewalAnchorEl(renewalAnchorEl ? null : event.currentTarget)
  }

  function handleRenewalSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return

    const isValid = validatePopper(schemaRenewal, renewal)

    if (!isValid) return

    const diff = Number(renewal) - Number(initRenewal)
    const newEndDate = addDays(new Date(data.packageEndDate), diff)

    function callback() {
      setRenewalAnchorEl(null)
      data.packageEndDate = newEndDate
      setRenewal(String(newEndDate))
    }

    dispatch(putRenewal(data.id, diff, callback))
  }

  function handleRenewalClickAway() {
    setRenewal(initRenewal)
    setRenewalAnchorEl(null)
  }

  const isOverloadText = React.useMemo(() => {
    const secondsDiff = differenceInSeconds(
      new Date(data.packageEndDate),
      new Date(),
    )
    const days = Math.ceil(secondsDiff / 60 / 60 / 24)
    return data.packageBalance.toString().length + String(days).length > 6
  }, [data.packageBalance, data.annualBonus, data.packageEndDate])
  return (
    <Grid item xs>
      <Box className={dashboard_.card}>
        <Stack justifyContent="center" p={1} spacing={1}>
          <Typography variant="semiBold" fontSize={18} component="h2">
            {t('subscriber.package')}
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="semiBold"
              fontSize={24}
              color="primary"
              width={isOverloadText ? 120 : 150}
              className="text_ellipsis"
            >
              {packageTitle || '-'}
            </Typography>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              <IconButton onClick={handlePackagePopper}>
                <SvgIcon
                  name="edit"
                  color={colorSecondaryDark}
                  size={{ width: 16, height: 14 }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Divider orientation="vertical" />
        <Stack justifyContent="center" p={1} spacing={1}>
          <Typography variant="semiBold" fontSize={18} component="h2">
            {t('subscriber.balance')}
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="semiBold" fontSize={24} color="primary">
              {data.packageBalance}€
            </Typography>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              <IconButton onClick={handleBalancePopper}>
                <SvgIcon
                  name="edit"
                  color={colorSecondaryDark}
                  size={{ width: 16, height: 14 }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>

        {/* <Divider orientation="vertical" />
        <Stack justifyContent="center" p={1} spacing={1}>
          <Typography variant="semiBold" fontSize={18} component="h2">
            {t('subscriber.annualBonus')}
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="semiBold" fontSize={24} color="primary">
              {data.annualBonus}€
            </Typography>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              <IconButton onClick={handleAnnualBonusPopper}>
                <SvgIcon
                  name="edit"
                  color={colorSecondaryDark}
                  size={{ width: 16, height: 14 }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack> */}

        <Divider orientation="vertical" />
        <Stack justifyContent="center" p={1} spacing={1}>
          <Typography variant="semiBold" fontSize={18} component="h2">
            {t('subscriber.credits')}
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="semiBold" fontSize={24} color="primary">
            {(data.package?.creditsPerUnit && balance) ? (data.package?.creditsPerUnit * Number(balance)) : "-"}
            </Typography>
          </Stack>
        </Stack>

        <Divider orientation="vertical" />
        <Stack justifyContent="center" p={1} spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="semiBold" fontSize={18} component="h2">
              {t('subscriber.renewal')}
            </Typography>
            <Box
              width={11}
              height={11}
              bgcolor={data.isAutoRenewal ? colorSuccess : colorError}
              borderRadius={6}
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography variant="semiBold" fontSize={24} color="primary" noWrap>
              {useRenewal(data.packageEndDate, 'text')}
            </Typography>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              <IconButton onClick={handleRenewalPopper}>
                <SvgIcon
                  name="edit"
                  color={colorSecondaryDark}
                  size={{ width: 16, height: 14 }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Box>

      {packageAnchorEl && (
        <PopperPackage
          subscriber={data}
          packageAnchorEl={packageAnchorEl}
          setPackageAnchorEl={setPackageAnchorEl}
          setPackageTitle={setPackageTitle}
        />
      )}
      {balanceAnchorEl && (
        <Popper
          placement="bottom"
          anchorEl={balanceAnchorEl}
          onClickAway={handleBalanceClickAway}
        >
          <OutlinedInput
            type="number"
            value={balance}
            onChange={(event) => setBalance(event.target.value)}
            readOnly={false}
            onKeyDown={handleBalanceSubmit}
            autoFocus
            inputProps={{
              min: 0,
            }}
          />
        </Popper>
      )}

      {annualBonusAnchorEl && (
        <Popper
          placement="bottom"
          anchorEl={annualBonusAnchorEl}
          onClickAway={handleAnnualBonusClickAway}
        >
          <OutlinedInput
            type="number"
            value={annualBonus}
            onChange={(event) => setAnnualBonus(event.target.value)}
            readOnly={false}
            onKeyDown={handleAnnualBonusSubmit}
            autoFocus
            inputProps={{
              min: 0,
            }}
          />
        </Popper>
      )}

      {renewalAnchorEl && (
        <Popper
          placement="bottom"
          anchorEl={renewalAnchorEl}
          onClickAway={handleRenewalClickAway}
        >
          <OutlinedInput
            type="number"
            value={renewal}
            onChange={(event) => setRenewal(event.target.value)}
            onKeyDown={handleRenewalSubmit}
            autoFocus
            inputProps={{
              min: 0,
            }}
          />
        </Popper>
      )}
    </Grid>
  )
}
