import moment from 'moment'

export function capitalize(string: string | undefined) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}

export function truncate(str: string, n: number, useWordBoundary = false) {
  if (str.length <= n) {
    return str
  }
  const subString = str.substr(0, n - 1) // the original check
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString) + '...'
  ) // &hellip;
}

export function isTextLimitPassed(text: string, limit: number) {
  return text.replaceAll(/\.|"/g, '').length > limit
}

export const parseText = (text: string): string => {
  const textArr = text
    .replace(/\s+(?=[^[\]]*\])/g, '-')
    .split(' ')
    .map((word) => {
      if (
        word.startsWith('[id') ||
        word.startsWith('[club') ||
        word.startsWith('[https')
      ) {
        let [link, name] = word.split('|')
        link = link.replace(/-/g, '').replace('[', '')
        name = name.replace(/-/g, ' ').replace(']', '')
        return name
      }
      return word
    })
  return textArr.join(' ')
}

export const getTitle = (text: string) => {
  text = String(text)
  let finalResult = text
  if (text) {
    if (text.indexOf("_") > 0) {
      finalResult = text
        .split("_")
        .map(
          (name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        )
        .join(" ")
    } else {
      const result = text.replace(/([A-Z])/g, " $1")
      finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    }
  }

  return finalResult
}

export const normalizeSocialNetwork = (sn: string) => {
  switch (sn) {
    case 'facebook.com':
      return 'fb'
    case 'vk.com':
      return 'vk'
    default:
      return sn
  }
}

export const getLinkToProfile = (snName : string, profileId : string) => {
  profileId = profileId + ''
  switch (snName?.toLowerCase()) {
    case "facebook_id":
    case "facebookid":
    case "facebook":
    {
      return `https://www.facebook.com/profile.php?id=${profileId}`
    }
    case "telegram_id": {
      return `https://telegram.me/${profileId}`
    }
    case "vk_id":
    case "vkid": {
      return `https://vk.com/id${profileId.replace('id', '')}`
    }
    case "linkedinusername":
    case "linkedin_id":
    case "linkedin":
    {
      return `https://www.linkedin.com/in/${profileId}`
    }
    case "twitter_username":
    case "twitterusername":
    case "twitterscreenname":
    case "twitter_id":
    case "twitter":
    {
      return `https://twitter.com/${profileId}`
    }
    case "instagramusername":
    case "instagram":
    {
      return `https://www.instagram.com/${profileId}`
    }
    /*case "googleplus": {
      return `https://plus.google.com/${profileId}`
    }*/
    default : {
      return ""
    }
  }
}
  

export function isHttpUrl(string: string): boolean {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}

export function getUrl(url: string): string {
  if (url) {
    if (url.indexOf("http") === 0) {
      return url
    }
    if (url.indexOf("//") === 0) {
      return url
    }
    return "https://" + url
  }
  return url
}

function extractSocialMediaInfo(url: string) {
  // Regular expression to match social media URL patterns
  const linkedInRegex = /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:in\/([^\/?]+)\/?|company\/([^\/?]+)\/?)/
  const instagramRegex = /(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p\/([^\/]+)\/?|(?:[^\/]+\/)?([^\/?]+))/
  const flickrRegex = /(?:https?:\/\/)?(?:www\.)?flickr\.com\/photos\/([^\/]+)\/?/
  const tiktokRegex = /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@([^\/?]+)\/?/
  const facebookRegex = /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:[^\/?]+\/)?(?:posts\/([^\/?]+)\/|(?:[^\/?]+\/)?([^\/?]+)\/?)/
  const twitterRegex = /(?:https?:\/\/)?(?:www\.)?twitter\.com\/([^\/?]+)\/?/
  const githubRegex = /(?:https?:\/\/)?(?:www\.)?github\.com\/([^\/?]+)\/?/


  // Extract matches from the LinkedIn URL
  const linkedInMatches = url.match(linkedInRegex)

  if (linkedInMatches) {
    const userId = linkedInMatches[1]
    const companyId = linkedInMatches[2]

    if (userId) {
      return {
        network: 'linkedin',
        user_id: userId,
      }
    } else if (companyId) {
      return {
        network: 'linkedin',
        user_id: companyId,
      }
    }
  }

  // Extract matches from the Instagram URL
  const instagramMatches = url.match(instagramRegex)

  if (instagramMatches) {
    const postId = instagramMatches[1]
    const instaUserId = instagramMatches[2]

    if (postId) {
      return {
        network: 'instagram',
        user_id: postId,
      }
    } else if (instaUserId) {
      return {
        network: 'instagram',
        user_id: instaUserId,
      }
    }
  }

  // Extract matches from the Flickr URL
  const flickrMatches = url.match(flickrRegex)

  if (flickrMatches) {
    const flickrUserId = flickrMatches[1]

    if (flickrUserId) {
      return {
        network: 'flickr',
        user_id: flickrUserId,
      }
    }
  }

  const tiktokMatches = url.match(tiktokRegex)

  if (tiktokMatches) {
    const tiktokUserId = tiktokMatches[1]

    if (tiktokUserId) {
      return { network: 'tiktok', user_id: tiktokUserId }
    }
  }

  const facebookMatches = url.match(facebookRegex)

  if (facebookMatches) {
    const postId = facebookMatches[1]
    const facebookUserId = facebookMatches[2]

    if (postId) {
      return { network: 'fb', user_id: postId }
    } else if (facebookUserId) {
      return { network: 'fb', user_id: facebookUserId }
    }
  }

  const twitterMatches = url.match(twitterRegex)

  if (twitterMatches) {
    const twitterUserId = twitterMatches[1]

    if (twitterUserId) {
      return { network: 'twitter', user_id: twitterUserId }
    }
  }

  const githubMatches = url.match(githubRegex)

  if (githubMatches) {
    const githubUserId = githubMatches[1]

    if (githubUserId) {
      return { network: 'github', user_id: githubUserId }
    }
  }

  // URL doesn't match the expected patterns
  return {
    network: 'web',
    user_id: 'Link',
  }
}

export function getImageCardInfo(imgObj: any): any {
  let item = {...imgObj}
  if (item.base64 && !item.face) {
    item.face = item.base64
    delete item.base64
    if (!item.source) {
      item.source = item.face
    }
  }
  //face
  if (!item.face && item.thumbnail) {
    item.face = item.thumbnail
    delete item.thumbnail
    if (!item.source) {
      item.source = item.face
    }
  }
  if (!item.face && item.s3Image) {
    item.face = item.s3Image
    if (!item.source) {
      item.source = item.face
    }
  }

  //photo id
  if (!item.photo_id && item.guid) {
    item.photo_id = item.guid
  }
  if (!item.photo_id && item.userid) {
    item.photo_id = item.userid
  }
  if (!item.photo_id && item.s3Image) {
    item.photo_id = item.s3Image
  }

  //title
  if (!item.first_name && item.title) {
    item.first_name = item.title
    delete item.title
  }
  if (!item.first_name && item.firstname) {
    item.first_name = item.firstname
    delete item.firstname
  }

  if (!item.network && item.details?.length > 0) {
    if (item.details[0]?.url && item.details[0]?.url?.indexOf('pp.userapi') > 0) {
      item.network = 'vk'
      item.user_id = item.userid
      item.profile = getLinkToProfile('vkid', item.user_id)
    }
  }
  if (item.url) {
    const snInfo = extractSocialMediaInfo(item.url)
    if (snInfo) item = {...item, ...snInfo}

    if (item.url?.indexOf('iafd.com') > 0) {
      item.network = 'iafd'
      item.user_id = item.url.split('/')?.[6]?.replace('.htm', '')
    }
    item.profile = item.url
  }
  if (!item.profile && item.source && isHttpUrl(item.source)) {
    const snInfo = extractSocialMediaInfo(item.source)
    if (snInfo) item = {...item, ...snInfo}
    item.profile = item.source
  }
  if (!item.profile && item.link && isHttpUrl(item.link)) {
    item.profile = item.link
    item.network = 'web'
    item.user_id = item.source || 'Link'

    if (item.source_icon) {
      item.network_icon = item.source_icon
    }
  }

  if (item?.profileData && item?.profileData.image_url) {
    item.face = item?.profileData.image_url
    item.network = 'sniper_2'
    item.user_id = extractDomainFromString(item?.profileData.website_url)
    item.source = item?.profileData.website_url
    item.profile = item?.profileData.website_url
    item.photo_id = item?.profileData.website_url
  }

  return item
}

function extractDomainFromString(inputString: string): string {
  let result = 'no source link'
  const domainRegex = /(?:https?:\/\/)?(?:www\.)?([^\/]+)(?:\/|$)/i
  const match = inputString.match(domainRegex)
  if (match && match[1]) {
    
    match[1].length > 16 ? 
    result = `${match[1].substring(0, 15)}...` :
    result = match[1]

  } 
  return result
}

export function renderComplexItem(item: any, title: string, dateExceptions: string[] = []) {
  if (typeof item === 'string' && dateExceptions.includes(title)) {
    return moment(item).format("YYYY-DD-MM")
  }
  if (typeof item === 'string') {
    return item
  } else {
    return JSON.stringify(item).replace(/[\[\]}{"]/g, ' ')
  }
}
