// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popper_root__FGj2b, .popper_root_bottom__2Nphm, .popper_root_top__JgLLL {\n  padding: 10px;\n  background: #FFFFFF;\n  border-radius: 8px;\n  filter: drop-shadow(0 0 5px grey);\n  z-index: 10000;\n}\n.popper_root_top__JgLLL {\n  bottom: 15px !important;\n}\n.popper_root_bottom__2Nphm {\n  top: 15px !important;\n}\n\n.popper_arrow__1UaZf, .popper_arrow_bottom__1BbKc, .popper_arrow_top__2mFoq {\n  position: absolute;\n  margin: 0 auto;\n  left: 0;\n  right: 0;\n}\n.popper_arrow_top__2mFoq {\n  top: -15px;\n}\n.popper_arrow_bottom__1BbKc {\n  bottom: -15px;\n}", "",{"version":3,"sources":["webpack://src/sass/popper.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,iCAAA;EACA,cAAA;AACF;AACE;EAEE,uBAAA;AAAJ;AAEE;EAEE,oBAAA;AADJ;;AAIA;EACE,kBAAA;EACA,cAAA;EACA,OAAA;EACA,QAAA;AADF;AAGE;EAEE,UAAA;AAFJ;AAIE;EAEE,aAAA;AAHJ","sourcesContent":[".root\n  padding: 10px\n  background: #FFFFFF\n  border-radius: 8px\n  filter: drop-shadow(0 0 5px grey)\n  z-index: 10000\n\n  &_top\n    @extend .root\n    bottom: 15px !important\n\n  &_bottom\n    @extend .root\n    top: 15px !important\n\n\n.arrow\n  position: absolute\n  margin: 0 auto\n  left: 0\n  right: 0\n\n  &_top\n    @extend .arrow\n    top: -15px\n\n  &_bottom\n    @extend .arrow\n    bottom: -15px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "popper_root__FGj2b",
	"root_bottom": "popper_root_bottom__2Nphm",
	"root_top": "popper_root_top__JgLLL",
	"arrow": "popper_arrow__1UaZf",
	"arrow_bottom": "popper_arrow_bottom__1BbKc",
	"arrow_top": "popper_arrow_top__2mFoq"
};
export default ___CSS_LOADER_EXPORT___;
