import * as React from 'react'
import {t} from 'i18next'
import {
  Box, Button, Card, CardHeader, FormControl, Grid, OutlinedInput, Popover, PopoverOrigin, Stack, Typography,
} from '@mui/material'
import sprite_ from '../../sass/sprite.module.sass'
import {FormState} from '../../function/validation'
import {usePopover} from '../../hook/usePopover'
import {useCardStyles} from '../../style/card'
import {phones} from '../../hook/usePackagePopup'
import {Phone, Source} from '../../store/package/reducer'
import { useDispatch } from '../../store'
import { showSnackbar } from '../../store/common/actionCreator'
import DeleteIcon from '@mui/icons-material/Delete'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'


interface LookupTitleProps {
  name: string
  price: number
}

const countWordsWorkflowTitle = (text : string) : number => {
  let length : number
  const arr = text.split(" ")
  if (arr.length >= 0 && arr.length < 4) {
    length = 18
  } else {
    if (arr.length >= 4 && arr.length <11) {
      length = 15
    } else { 
      length = 10
    }
  }
  return length
}

const countWordsWorkflowSubTitle = (text : string) : number => {
  let length : number
  const arr = text.split(" ")
  if (arr.length >= 0 && arr.length < 4) {
    length = 16
  } else {
    if (arr.length >= 4 && arr.length <11) {
      length = 13
    } else { 
      length = 10
    }
  }
  return length
}

function LookupTitle({name, price}: LookupTitleProps) {
  return (
    <Stack direction="row" alignItems="center" spacing={2} lineHeight="21px">
      <Typography variant="semiBold" fontSize={countWordsWorkflowTitle(name)} style={{flexWrap: 'wrap-reverse'}}>{name}</Typography>
      <Typography variant="bold" color="primary" fontSize={25} style={{alignContent: 'flex-start'}}>€{price}</Typography>
    </Stack>
  )
}

function getPopoverText(apps: Phone[], source: Source) {
  return apps.includes(source as Phone) ? t('package.tooltip.app') : t('package.tooltip.platforms')
}

interface Props {
  data: FormState
  type: string
}

export function PreviewLookup({data}: Props) {
  const unique = data.sources.filter(function(elem : any, index : any, self : any) {
    return index === self.indexOf(elem)
  })
  data.sources = unique
  const cardClasses = useCardStyles()
  const {popoverClasses, popoverOptions} = usePopover(false)

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [popoverText, setPopoverText] = React.useState('')
  const [popoverOpen, setPopoverOpen] = React.useState(false)

  const handlePopoverEnter = (event: React.MouseEvent<HTMLElement>, source: Source) => {
    setPopoverText(getPopoverText(phones, source))
    setAnchorEl(event.currentTarget)
    setPopoverOpen(true)
  }

  const handlePopoverLeave = () => {
    setPopoverOpen(false)
  }

  
  const [base64, setBase64] = React.useState(undefined)
  
  const cropperSrc = React.useMemo(() => {
    return base64 
  }, [base64])

  const dispatch = useDispatch()
  
  const handleUpload = ({ target }: any) => {
    const fileTypes = ['jpg', 'jpeg', 'png']
    const file = target.files[0]
    console.log('file', file)
    console.log('target', target)
    const extension = file.name.split('.').pop().toLowerCase()
    const isSuccess = fileTypes.indexOf(extension) > -1
    const isOverWeight = file.size > 512000
    if (isOverWeight) {
      dispatch(showSnackbar('error', 'Photo size should be less than 512KB.'))
    } else {
      if (isSuccess) {
        const fileReader = new FileReader()

        fileReader.readAsDataURL(file)

        fileReader.onload = (e: any) => {
          if (e.target) {
            setBase64(e.target.result)
          }
        }
      } else {
        dispatch(showSnackbar('error', t('message.error.profilePicture')))
      }
    }
  }
  const handleDelete = ({ target }: any) => {
    console.log(base64)
  }

  return (
    <Card classes={cardClasses} sx={{width: 498}}>
      <Box position="absolute" top={0} left={0} width={10} height="100%" bgcolor={data.color}/>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <CardHeader title={<LookupTitle name={data.title} price={data.price}/>}/>
        <Stack direction="row" justifyContent="flex-end" spacing={1} flexWrap="wrap" ml={1}>
          {data.sources.map((el: Source) => (
            <Box
              sx={{marginBottom: '5px !important'}}
              key={el}
              className={ (el === 'fb_api' || el === 'instagram_api' || el === 'x_api' || el === 'linkedin_api') ? sprite_['social__small_' + el.replace("_api","")] : sprite_['social__small_' + el]}
              onMouseEnter={(e) => handlePopoverEnter(e, el)}
              onMouseLeave={handlePopoverLeave}
            />
          ))}
        </Stack>
      </Stack>

      <Typography sx={{marginTop: 'auto'}} fontSize={countWordsWorkflowSubTitle(data.subTitle)}>{data.subTitle}</Typography>
      {data.type !== 'webint' && data.type !== 'face_search' &&
      <Stack direction="row" justifyContent="space-between" spacing={3} mt={1}>
        <FormControl>
          <OutlinedInput placeholder={data.input} readOnly/>
        </FormControl>
        <Button type="submit" variant="contained">{data.button}</Button>
      </Stack>
      }

    {data.type === 'webint' &&
      <Stack direction="row" justifyContent="right" spacing={2} mt={1}>
        <FormControl>
        <Button type="submit" variant="contained">{data.button}</Button>
        </FormControl>
      </Stack>
      }

    {data.type === 'face_search' &&
        <FormControl>
              <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                <Grid item >
                  <img src={cropperSrc} width={75} height={75} />
                  <label htmlFor="uploadImage" >
                      <AddPhotoAlternateIcon color='primary'/>
                    </label>
                    <label htmlFor="deleteImage" >
                      <DeleteIcon color='primary'/>
                    </label>
                </Grid>
                    <Grid item >
                    <Button type="submit" variant="contained">{data.button}</Button>
                    </Grid>
                </Grid>
          <input
          type="file"
          accept="image/*"
          id="uploadImage"
          style={{ display: 'none' }}
          onChange={handleUpload}
          />

          <input
          style={{ display: 'none' }}
          id="deleteImage"
          onChange={handleDelete}
          />
        
        </FormControl>

      }

      <Popover
        open={popoverOpen}
        className={popoverClasses.root}
        classes={{
          paper: popoverClasses.paper,
        }}
        anchorEl={anchorEl}
        anchorOrigin={popoverOptions.anchorOrigin as PopoverOrigin}
        transformOrigin={popoverOptions.transformOrigin as PopoverOrigin}
        PaperProps={{onMouseEnter: () => setPopoverOpen(true), onMouseLeave: handlePopoverLeave}}
        elevation={0}
      >
        {popoverText}
      </Popover>
    </Card>
  )
}