export enum Provider {
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
  MICROSOFT = 'microsoft.com',
  EMAIL = 'password',
}

export enum SocialNetworkNames {
  facebook = 'fb',
  twitter = 'twitter',
  googlePlus = 'googlePlus',
  linkedin = 'linkedin',
  instagram = 'instagram',
}

export enum SocialNetwoksRealNames {
  facebook = 'Facebook',
  twitter = 'Twitter',
  googlePlus = 'Google+',
  linkedin = 'LinkedIn',
  instagram = 'Instagram',
}

export enum SocialNetworkLinks {
  facebook = "https://www.facebook.com/profile.php?id=",
  twitter = "https://twitter.com/",
  googlePlus = "https://currents.google.com/",
  linkedin = '',
  instagram = "https://www.instagram.com/" ,    
}

export enum SocialNetworkGroups {
  twitter = 'https://twitter.com/',
  ok = 'https://ok.ru/group/',
  vk = 'https://m.vk.com/wall',
  instagram = 'https://www.instagram.com/',
}
