import React from 'react'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { getLabel, RenderImage } from '../../../component/searcher/RenderData'
import { isHttpUrl } from '../../../function/string'

type Props = {
  el?: any
}
type objType = {
  [key: string]: object;
};

const CallappItem = (props: Props) => {
  const {el} = props
  const callappItem = el.data[el.from]
  const {gravatarProfile} = callappItem
  //console.log('CallappDATa', callappItem)

  if (callappItem && Object.keys(callappItem).length > 0) {
    return <>
      {
        callappItem?.emails?.length > 0 && <Grid item xs={6}>
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={4}>
              <Typography variant="text" fontSize={14} sx={{ wordBreak: 'break-all'}}>Emails</Typography>
            </Grid>
            <Grid item xs={8}>
              {
                callappItem.emails.map((email: objType, idx: number) => <div key={idx}>
                  <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{email.email}</Typography>
                </div>)
              }
            </Grid>
          </Grid>
        </Grid>
      }
      {
        callappItem?.gravatar && <Grid item xs={6}>
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={4}>
              <Typography variant="text" fontSize={14} sx={{ wordBreak: 'break-all'}}>Avatar</Typography>
            </Grid>
            <Grid item xs={8}>
              <Box sx={{width: '50px', height: '50px'}}><RenderImage _key={'value'} imgHeight={50} val={callappItem.gravatar} /></Box>
            </Grid>
          </Grid>
        </Grid>
      }
      {
        gravatarProfile && Object.keys(gravatarProfile).length > 0 && <Grid item xs={12}>
          <Divider sx={{
            width: "100%",
            margin: '15px 0px',
          }} />
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}>
              <Typography variant="text" fontSize={14} sx={{ wordBreak: 'break-all'}}>Gravatar Profile</Typography>
            </Grid>
            <Grid item xs={10}>
              {
                ['aboutMe', 'preferredUsername', 'profileUrl'].map(field => gravatarProfile?.[field] &&
                  <Grid container sx={{display: 'flex'}}>
                    <Grid item xs={4}>
                      <Typography variant="text" fontSize={14} sx={{ wordBreak: 'break-all'}}>{getLabel(field)}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {
                        isHttpUrl(gravatarProfile[field])
                          ? <a target="_blank" href={gravatarProfile[field]}>{gravatarProfile[field]}</a>
                          : <Typography variant="bold" sx={{ wordBreak: 'break-all', whiteSpace: "pre-line" }}>{gravatarProfile[field]}</Typography>
                      }
                    </Grid>
                  </Grid>,
                )
              }
              {
                gravatarProfile?.name && Object.entries(gravatarProfile.name).map(([nameLabel, nameValue]) => <Grid container sx={{display: 'flex'}}>
                  <Grid item xs={4}>
                    <Typography variant="text" fontSize={14} sx={{ wordBreak: 'break-all'}}>{getLabel(nameLabel)}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{nameValue + ''}</Typography>
                  </Grid>
                </Grid>)
              }
              {
                gravatarProfile?.photos && gravatarProfile.photos.length > 0 && <Grid container sx={{display: 'flex'}}>
                  <Grid item xs={4}>
                    <Typography variant="text" fontSize={14} sx={{ wordBreak: 'break-all'}}>Photos</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {
                      gravatarProfile.photos.map((photo: any) => <Box sx={{width: '50px', height: '50px'}}><RenderImage _key={'ph_value'} imgHeight={50} val={photo?.value + ''} /></Box>)
                    }
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      }
    </>
  }
  return null
}

export default CallappItem