import React, { useState } from 'react'
import ReactSlick from 'react-slick'
import { t } from 'i18next'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  ArrowForward as IconRight,
  ArrowBack as IconLeft,
} from '@mui/icons-material'
import { Chart } from 'chart.js'
import {
  Box,
  Card,
  CardHeader,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
} from '@mui/material'
import { colorRevenueTotal, colorRevenueFiltered } from '../mui'
import { PrivatePage } from '../component/PagePrivate'
import { TotalSingle } from '../component/dasboard/TotalSingle'
import { useDispatch, useSelector } from '../store'
import { HeaderDashboard } from '../container/dashboard/Header'
import { LookupChart } from '../container/dashboard/ChartLookup'
import { formatWithCommas, kFormatter } from '../function/number'
import { Percentage } from '../component/dasboard/Percentage'
import { TableBodyData, TableBodyRow } from '../store/common/reducer'
import { formatDate } from '../function/date'
import {
  useTablePaginationStyles,
  useTableStyles,
} from '../component/datatable/hook/useStyles'
import { useTable } from '../component/datatable/hook/useTable'
import { sortByKey } from '../function/array'
import { PopupSubscribers } from '../container/dashboard/PopupSubscriber'
import {
  setSubscriberOffset,
  setSubscriberSort,
} from '../store/dashboard/actionCreator'
import { getPercentSize } from '../function/common'
import { useUsage } from '../hook/useUsage'
import { CountryRevenue, Usage } from '../store/dashboard/reducer'
import { SortDirections } from '../component/datatable/types'
import { DEFAULT_ORDER } from '../component/datatable'
import { Header, Navigation, Body } from '../component/datatable/component'
import { Subscriber } from '../store/user/reducer'
import { SubscriberType } from '../store/marketing/reducer'
import { BlackListTableEmails } from '../container/dashboard/BlackListEmails'
import { BlackListTablePhones } from '../container/dashboard/BlackListPhones'
import { api } from '../function/axios'
import { PopupAsk as MaintenanceConfirmation } from '../component/PopupAsk'
import { PopupAsk as WelkomeDelayConfirmation } from '../component/PopupAsk'
import Slider from 'react-slick'
import { useSliderStyle } from '../component/datatable/hook/useStyles'
import { BlackListTableIpAddress } from '../container/dashboard/BlackListIpAddress'
// import {SubscriberPDF} from '../container/dashboard/PDFSubscriber'
// import {PDFViewer} from '@react-pdf/renderer'
// import {Provider} from 'react-redux'
// import tmpJson from '../json/tmp.json'

export type Time = 'day' | 'week' | 'month' | 'year'

interface CountryRevenueItemProps {
  data: CountryRevenue
}

export interface HeaderRef {
  refreshRequest: () => void
}

function CountryRevenueItem({ data }: CountryRevenueItemProps) {
  const { dashboard } = useSelector((state) => state.dashboard)

  const { revenueTopCountry } = useUsage(dashboard)

  const [value, setValue] = React.useState(data.total)

  const totalWidth = getPercentSize(data.total, revenueTopCountry)
  const filteredWidth = getPercentSize(data.filtered, data.total)

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      <Stack
        width={totalWidth}
        height={27}
        bgcolor={colorRevenueTotal}
        borderRadius="4px"
        overflow="hidden"
      >
        <Box
          width={filteredWidth}
          height="100%"
          bgcolor={colorRevenueFiltered}
          onMouseEnter={() => setValue(data.filtered)}
          onMouseOut={() => setValue(data.total)}
          style={{ zIndex: 1, cursor: 'auto' }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        position="absolute"
        width="100%"
      >
        <Typography
          noWrap
          variant="semiBold"
          sx={{
            paddingLeft: '8px',
            color: '#000',
            textTransform: 'uppercase',
            zIndex: 1,
            pointerEvents: 'none',
          }}
        >
          {data.country}
        </Typography>
        <Typography variant="semiBold" sx={{ color: '#000', zIndex: 1 }}>
          €{formatWithCommas(value)}
        </Typography>
      </Stack>
    </Stack>
  )
}

export function DashboardPage() {
  const classesTable = useTableStyles()
  const classesTablePagination = useTablePaginationStyles()
  const sliderStyle = useSliderStyle()

  const dispatch = useDispatch()
  const usage = useSelector((state) => state.dashboard.usage)
  const headerRef = React.useRef<HeaderRef>(null)
  // ** Maintenance work button
  const [isMaintenanceEnabled, setIsMaintenanceEnabled] = React.useState(false)
  const [isPopupOpen, setIsPopupOpen] = React.useState(false)
  // GET request in DB coloumn admin_maintenance
  const fetchMaintenanceStatus = async () => {
    try {
      const response = await api({
        method: 'GET',
        url: '/admin_settings/maintenance',
      })
      setIsMaintenanceEnabled(response.data)
    } catch (error) {
      console.error('Failed to fetch maintenance status:', error)
    }
  }
  
  React.useEffect(() => {
    fetchMaintenanceStatus()
  }, [])
  const handleMaintenance = async (switchStatus: boolean | ((prevState: boolean) => boolean)) => {
    if (switchStatus !== isMaintenanceEnabled) {
      setIsPopupOpen(true)
    }
  }
  
  const confirmMaintenance = async () => {
    try {
      const response = await api({
        method: 'POST',
        url: '/admin_settings/maintenance',
        body: { status: !isMaintenanceEnabled },
      })
      setIsMaintenanceEnabled(!isMaintenanceEnabled)
      setIsPopupOpen(false)
    } catch (error) {
      console.error('Failed to update maintenance status:', error)
    }
  }
  // ** END Maintenance work button
  // ** Welcome delay button
  const [isWelcomeDelayEnabled, setWelcomeDelayEnabled] = React.useState(false)
  const [isWelcomeDelayPopupOpen, setWelcomeDelayPopupOpen] = React.useState(false)
  // GET request in DB coloumn admin_maintenance
  const fetchWelcomeDelayStatus = async () => {
    try {
      const response = await api({
        method: 'GET',
        url: '/admin_settings/welcomedelay',
      })
      setWelcomeDelayEnabled(response.data)
    } catch (error) {
      console.error('Failed to fetch maintenance status:', error)
    }
  }
  
  React.useEffect(() => {
    fetchWelcomeDelayStatus()
  }, [])
  const handleWelcomeDelay = async (switchStatus: boolean | ((prevState: boolean) => boolean)) => {
    if (switchStatus !== isWelcomeDelayEnabled) {
      setWelcomeDelayPopupOpen(true)
    }
  }
  
  const confirmWelcomeDelay = async () => {
    try {
      const response = await api({
        method: 'POST',
        url: '/admin_settings/welcomedelay',
        body: { status: !isWelcomeDelayEnabled },
      })
      setWelcomeDelayEnabled(!isWelcomeDelayEnabled)
      setWelcomeDelayPopupOpen(false)
    } catch (error) {
      console.error('Failed to update maintenance status:', error)
    }
  }
  // ** END Maintenance work button
  const lookupChartRef = React.useRef<Chart | null>(null)

  const { dashboard, subscribersOffset } = useSelector(
    (state) => state.dashboard,
  )

  const { sliderData, tableHeaderData, getMostUsedType } = useUsage(dashboard)
  const _dashboard = useSelector((state) => state.dashboard)
  const time = useSelector((state) => state.dashboard.time)
  const tableParams = React.useMemo(() => {
    return { offset: subscribersOffset, limit: 7 }
  }, [subscribersOffset])

  const [subscriber, setSubscriber] = React.useState<Subscriber | null>()

  const bodyData: TableBodyData = React.useMemo(() => {
    return {
      list: (dashboard?.list as TableBodyRow[]) || [],
      count: dashboard?.count || 0,
    }
  }, [dashboard])

  const { page, setPage, sortKey, setSortKey, cellCount } = useTable({
    headerData: tableHeaderData,
    bodyData,
    params: tableParams,
    selectable: false,
  })

  const initialSortDirections = React.useMemo(() => {
    const initObj: SortDirections = {}

    tableHeaderData.forEach((el) => {
      initObj[el.key] = DEFAULT_ORDER

      if (dashboard?.list && el.defaultSort) {
        sortByKey(dashboard?.list, el.key, DEFAULT_ORDER)

        setSortKey(el.key)
      }
    })

    return initObj
  }, [tableHeaderData, dashboard?.list, setSortKey])

  const handleRowClick = (row: Subscriber) => {
    window.open(`/users/subscriber/${row.id}`, '_blank', 'noopener,noreferrer')
    return false
  }

  const TableBodyRender = (row: Subscriber, idx: number) => {
    const className = idx % 2 !== 0 ? classesTable.rowEven : ''

    return (
      <TableRow
        key={row.id}
        hover
        //onClick={() => setSubscriber(row)}
        onClick={() => handleRowClick(row)}
        className={className}
      >
        <TableCell classes={classesTable}>
          <Typography variant="bold">{row.id}</Typography>
        </TableCell>
        <TableCell classes={classesTable}>{`${row.firstname || ''} ${
          row.lastname || ''
        }`}</TableCell>
        <TableCell classes={classesTable}>{row.country}</TableCell>
        <TableCell classes={classesTable}>{row.ltv} €</TableCell>
        <TableCell classes={classesTable}>{(row.package && row.package.creditsPerUnit ? Math.round(row.packageBalance * row.package.creditsPerUnit) : '-')}</TableCell>
        <TableCell classes={classesTable}>{row.packageBalance} €</TableCell>
        <TableCell classes={classesTable}>
          {formatDate(row.packageEndDate, 'dd/MM/yyyy')}
        </TableCell>
        <TableCell classes={classesTable}>{getMostUsedType(row)}</TableCell>
        <TableCell classes={classesTable}>
          {formatDate(row.createdTime, 'dd/MM/yyyy')}
        </TableCell>
        <TableCell classes={classesTable}>
          {formatDate(row.sessions[0]?.lastActiveDate, 'dd/MM/yyyy')}
        </TableCell>
        <TableCell >
          {row.fullUsage && row.fullUsage.web && row.fullUsage.web.total ? row.fullUsage.web.total : 0}
        </TableCell>
        <TableCell>
          {row.fullUsage && row.fullUsage.api && row.fullUsage.api.total ? row.fullUsage.api.total : 0}
        </TableCell>
      </TableRow>
    )
  }

  const pageCount = React.useMemo(() => {
    if (dashboard) return Math.ceil(dashboard.count / tableParams.limit)
    else return 1
  }, [dashboard, tableParams])

  const [sortDirections, setSortDirections] = React.useState<SortDirections>(
    initialSortDirections,
  )

  const sliderSettings = {
    slidesToShow: 5,
    dots: true,
    slidesToScroll: 5,
  }

  const handleSort = (key: string) => {
    if (dashboard?.list === null) return

    const newDirection = sortDirections[key] === 'ASC' ? 'DESC' : 'ASC'

    dispatch(setSubscriberSort(key, newDirection))

    setSortKey(key)
    setSortDirections({ ...initialSortDirections, [key]: newDirection })
  }

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page)
    dispatch(setSubscriberOffset((page - 1) * tableParams.limit))
  }
  const [_subscriber, _setSubscriber] = React.useState<SubscriberType>('all')

  return (
    <PrivatePage
      header={
        <HeaderDashboard
          ref={headerRef}
          usage={usage}
          lookupChartRef={lookupChartRef}
        />
      }
    >
      {/** Uncomment for Subscriber PDF development */}
      {/*{bodyData.list[0] && (
        <PDFViewer width="1000" height="600" showToolbar={false}>
          <Provider store={store}>
            <SubscriberPDF data={bodyData.list[0]}/>
          </Provider>
        </PDFViewer>
      )}*/}
      {/* <Grid container spacing={4}> */}
      {/* </Grid> */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} xl={2}>
          <TotalSingle
            title={t('dashboard.totalRevenue')}
            amount={dashboard?.totalRevenue}
            amountSuffix="€"
            diff={dashboard?.totalRevenueIncreasePercent}
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={2}>
          <TotalSingle
            title={t('dashboard.totalSubscribers')}
            amount={dashboard?.totalSubscribers}
            diff={dashboard?.totalSubscribersIncreasePercent}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={2}>
          <TableContainer className={classesTable.card} >
              <Stack alignItems="center" direction="row" width="100%" style={{padding: '3px 30px'}}>
                <Typography variant="semiBold" fontSize={18} style={{padding: '7px 0', flexBasis: '50%'}}>Welcome Delay</Typography>
                <Button
                  style={{padding: '6px 0', minWidth: 'unset', width: 44}}
                  variant={isWelcomeDelayEnabled ? 'contained' : 'text'}
                  size="large"
                  fullWidth
                  onClick={() => handleWelcomeDelay(true)}
                >{t('common.on')}</Button>
                <Button
                  style={{padding: '6px 0', minWidth: 'unset', width: 44}}
                  variant={!isWelcomeDelayEnabled ? 'contained' : 'text'}
                  size="large"
                  fullWidth
                  onClick={() => handleWelcomeDelay(false)}
                >{t('common.off')}</Button>
              </Stack>
              <Stack alignItems="center" direction="row" width="100%" style={{padding: '3px 30px'}}>
                <Typography variant="semiBold" fontSize={18} style={{padding: '7px 0', flexBasis: '50%'}}>Maintenance Work</Typography>
                <Button
                  style={{padding: '6px 0', minWidth: 'unset', width: 44, marginTop: '10px'}}
                  variant={isMaintenanceEnabled ? 'contained' : 'text'}
                  size="large"
                  fullWidth
                  onClick={() => handleMaintenance(true)}
                >{t('common.on')}</Button>
                <Button
                  style={{padding: '6px 0', minWidth: 'unset', width: 44, marginTop: '10px'}}
                  variant={!isMaintenanceEnabled ? 'contained' : 'text'}
                  size="large"
                  fullWidth
                  onClick={() => handleMaintenance(false)}
                >{t('common.off')}</Button>
              </Stack>
          </TableContainer>
        </Grid>
        <Grid item xs={12} xl={8}>
          <Card classes={sliderStyle}>
            <Slider {...sliderSettings}>
              {sliderData.map((el, idx) => (
                <div key={idx}>
                  <Stack alignItems="center" borderRight="1px solid #6A6A6A">
                    <Stack>
                      <Typography variant="semiBold" fontSize={18}>
                        {el.title}
                      </Typography>

                      <Typography
                        variant="semiBold"
                        fontSize={35}
                        color="primary"
                      >
                        {kFormatter(el.amount)}
                      </Typography>

                      <Percentage value={el.percentage} />
                    </Stack>
                  </Stack>
                </div>
              ))}
            </Slider>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <TableContainer classes={classesTable} className={classesTable.card}>
            <Navigation title={t('dashboard.table.title')} />

            <Table>
              <Header
                headerData={tableHeaderData}
                sortKey={sortKey}
                sortDirections={sortDirections}
                onSort={handleSort}
              />

              <Body
                bodyData={bodyData}
                renderBody={TableBodyRender}
                cellCount={cellCount}
              />
            </Table>
          </TableContainer>

          <Stack direction="row" justifyContent="flex-end" mt={2}>
            <Pagination
              classes={classesTablePagination}
              count={pageCount}
              page={page}
              shape="rounded"
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: IconLeft, next: IconRight }}
                  {...item}
                />
              )}
              onChange={handlePageChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={4}>
           <BlackListTableEmails />  
          {/* <LookupChart chartRef={lookupChartRef} /> */}
        </Grid>

        <Grid item xs={4}>
           <BlackListTablePhones />  
          {/* <LookupChart chartRef={lookupChartRef} /> */}
        </Grid>

        <Grid item xs={4}>
           <BlackListTableIpAddress />  
          {/* <LookupChart chartRef={lookupChartRef} /> */}
        </Grid>

        <Grid item xs={4}>
          <Card>
            <CardHeader title={t('dashboard.revenueByCountry')} />

            <Stack spacing={1} mt={2}>
              {dashboard?.revenueByCountries.map((el, idx) => (
                <CountryRevenueItem key={idx} data={el} />
              ))}
              {dashboard?.filteredRevenueByCountries.length === 0 && (
                <Typography align="center">
                  Sorry, no statistics for chosen filters
                </Typography>
              )}
              <Stack direction="row" spacing={7}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography>{t('dashboard.total')}</Typography>
                  <Box width={42} height={20} bgcolor={colorRevenueTotal} />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography>{t('dashboard.filtered')}</Typography>
                  <Box width={42} height={20} bgcolor={colorRevenueFiltered} />
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Grid>

      </Grid>

      {subscriber && (
        <PopupSubscribers
          data={subscriber}
          usage={usage}
          headerRef={headerRef}
          time={time}
          onClose={() => setSubscriber(null)}
        />
      )}
      {isPopupOpen && (
          <MaintenanceConfirmation
            onClose={() => setIsPopupOpen(false)}
            onYes={confirmMaintenance}
            header={t('common.sure')}
          />
        )}
      
      {isWelcomeDelayPopupOpen && (
          <WelkomeDelayConfirmation
            onClose={() => setWelcomeDelayPopupOpen(false)}
            onYes={confirmWelcomeDelay}
            header={t('common.sure')}
          />
        )}
    </PrivatePage>
  )
}
