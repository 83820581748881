import React from 'react'
import ReactDOM from 'react-dom'
import {Provider as StoreProvider} from 'react-redux'
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import * as Mui from './mui'
import * as Firebase from './firebase'
import './sass/index.sass'
import {store} from './store'
import {App} from './App'
import reportWebVitals from './reportWebVitals'

if (process.env.REACT_APP_SENTRY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
  })
}

Firebase.init()

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <MuiThemeProvider theme={Mui.customTheme}>
        <App/>
      </MuiThemeProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
