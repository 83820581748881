import {Close as CloseIcon} from '@mui/icons-material'
import {
  Dialog, DialogTitle, Divider, IconButton, Grid, Chip, Avatar, Typography,
} from '@mui/material'
import { formatDate } from '../../../function/date'
import {FormState} from '../../../function/validation'


interface Props {
  data: FormState
  onClose: () => void
}
// id: number
// name: string
// title: string
// paragraph: string
// actionName: string
// actionUrl: string
// image: string
// createdTime: string
// usersCount: number
export function PopupTgPreview({data, onClose}: Props) {

  return (
    <Dialog open={true} onClose={onClose} disableScrollLock={true}> 
      <DialogTitle>
      <Divider>
          <Chip label="Telegram campaign properties:" size="small" style={ { marginBottom: 10, color: 'black', backgroundColor: 'skyblue'} } />
      </Divider>
        <IconButton className="button__close" onClick={onClose}><CloseIcon/></IconButton>
      </DialogTitle>
      <Grid container spacing={5}>
          <Grid item xs={6} container justifyContent="center" alignItems="center">
          <Avatar src={data.image? data.image : ''} sx={{ width: 90, height: 90 }} />
          </Grid>
          <Grid item xs={6} container justifyContent="center" alignItems="center">
          <Typography>{data.name? "Name: " + data.name : 'Name: unknown' }</Typography>
          </Grid>
          <Grid item xs={6} container justifyContent="center" alignItems="center">
          <Typography>{data.title? "Title: " + data.title : 'Title: unknown' }</Typography>
          </Grid>
          <Grid item xs={6} container justifyContent="center" alignItems="center">
          <Typography>{data.createdTime && data.createdTime as Date ? "Created at: " + formatDate(data.createdTime, 'dd/MM/yyyy HH:mm:ss') : 'Date and Time: unknown' }</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
          <Typography>{data.paragraph ? data.paragraph : 'No data' }</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
          <Typography>{data.actionUrl ? data.actionUrl : 'No data' }</Typography>
          </Grid>

      </Grid>
      
    </Dialog>
  )
}
