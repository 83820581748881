import {call, put, takeEvery} from 'redux-saga/effects'
import {api, Response} from '../../function/axios'
import * as actionType from './actionType'
import {setUsage} from './actionCreator'
import {handleError} from '../../function/error'
import {prepareRevenue} from './service'
import {CountryRevenue} from './reducer'
import {showSnackbar} from '../common/actionCreator'

function* getUsage(action: actionType.GetUsage) {
  yield put(setUsage(null))

  try {
    const {data}: Response = yield call(api, {
      method: 'GET',
      url: action.url,
    })

    const revenue: CountryRevenue[] = yield call(prepareRevenue, data.revenueByCountries, data.filteredRevenueByCountries)

    data.revenueByCountries = revenue

    yield put(setUsage(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* patchUserPackage(action: actionType.PatchUserPackage) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PATCH',
      url: `/package/${action.userId}`,
      body: {
        packageId: action.packageId,
      },
    })

    action.callback()

    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* patchUserAdditionalPackage(action: actionType.PatchUserAdditionalPackage) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PATCH',
      url: `/package/additional/${action.userId}`,
      body: {
        packageId: action.packageId,
      },
    })

    action.callback()

    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* putBalance(action: actionType.PutBalance) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PUT',
      url: '/users/balance',
      body: {
        id: action.id,
        balance: action.value,
      },
    })

    action.callback()

    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* putAnnualBonus(action: actionType.PutAnnualBonus) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PUT',
      url: '/users/annual_bonus',
      body: {
        id: action.id,
        annualBonus: action.value,
      },
    })

    action.callback()

    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* putQuota(action: actionType.PutQuota) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PUT',
      url: '/users/setquota',
      body: {
        uid: action.uid,
        quota: action.value,
      },
    })

    action.callback()

    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}


function* putRenewal(action: actionType.PutRenewal) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PUT',
      url: '/users/renewal',
      body: {
        id: action.id,
        days: action.days,
      },
    })

    action.callback()

    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* setSubscriberIrbisProUpgrade(action: actionType.SetSubscriberIrbisProUpgrade) {
  try {
    const {data}: Response = yield call(api, {
      method: 'PUT',
      url: '/users/irbispro-upgrade',
      body: {
        id: action.id,
        upgrade: action.upgrade,
      },
    })
    if (action.callback){
      action.callback(action.upgrade)
    }
    yield put(showSnackbar('success', data.message))
  } catch (e){
    yield call(handleError, e)
  }
}

export function* watchDashboard() {
  yield takeEvery(actionType.GET_USAGE, getUsage)
  yield takeEvery(actionType.PATCH_USER_PACKAGE, patchUserPackage)
  yield takeEvery(actionType.PUT_BALANCE, putBalance)
  yield takeEvery(actionType.PUT_ANNUAL_BONUS, putAnnualBonus)
  yield takeEvery(actionType.PUT_QUOTA, putQuota)
  yield takeEvery(actionType.PUT_RENEWAL, putRenewal)
  yield takeEvery(actionType.PATCH_USER_ADDITIONAL_PACKAGE, patchUserAdditionalPackage)
  yield takeEvery(actionType.SET_SUBSCRIBER_IRBISPRO_UPGRADE, setSubscriberIrbisProUpgrade)
  
}