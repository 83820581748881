import { DashboardAction } from './actionType'
import { SearchCategoryCounter, Subscriber } from '../user/reducer'

export interface ChartData {
  time: Date
  count: string
}

export interface CountryRevenue {
  country: string
  total: number
  filtered: number
}

export type Usage = 'all' | 'web' | 'api'

export interface FailedSubscriberRequest {
  id: string
  requestMonitor: {
    type: string
    usage: Usage
  }
  from: string
  criteria: string
  failReason: string
  requestId: string
}

export interface Dashboard {
  totalRevenue?: number
  totalRevenueIncreasePercent?: number
  totalSubscribers: number
  totalSubscribersIncreasePercent?: number
  counters: SearchCategoryCounter[]
  phoneGraph: ChartData[]
  nameGraph: ChartData[]
  postGraph: ChartData[]
  revenueByCountries: CountryRevenue[]
  filteredRevenueByCountries: []
  list: Subscriber[]
  count: number
}

export type DashboardState = {
  dashboard: Dashboard | null
  subscribersOffset: number
  subscribersSort: string
  usage: 'all' | 'web' | 'api'
  time: 'day' | 'week' | 'month' | 'year'
}

const initialState: DashboardState = {
  dashboard: null,
  subscribersOffset: 0,
  subscribersSort: '',
  usage: 'all',
  time: 'year',
}

export function dashboardReducer(
  state = initialState,
  action: DashboardAction,
) {
  switch (action.type) {
    case 'dashboard/SET_USAGE':
      return {
        ...state,
        dashboard: action.data,
      }
    case 'dashboard/SET_SUBSCRIBER_OFFSET':
      return {
        ...state,
        subscribersOffset: action.offset,
      }
    case 'dashboard/SET_SUBSCRIBER_SORT':
      return {
        ...state,
        subscribersSort: `&sort=${action.key}${action.value}`,
      }
    case 'dashboard/CHANGE_TIME':
      return {
        ...state,
        time: action.time,
      }
    case 'dashboard/CHANGE_USAGE':
      return {
        ...state,
        usage: action.usage,
      }
    default:
      return state
  }
}
