import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormLabel,
  Grid,
  Stack,
} from '@mui/material'
import { useDispatch } from '../../store'
import { InputPassword } from '../../component/InputPassword'
import { updatePassword } from '../../store/settings/actionCreator'
import {
  ErrorsState,
  password,
  passwordRepeat,
  validateForm,
} from '../../function/validation'
import { clearFieldError } from '../../store/common/actionCreator'

type Props = {
  onClose: () => void
}

const schema = Joi.object({
  oldPassword: password.label(t('field.label.oldPassword')),
  password: password,
  passwordRepeat: passwordRepeat,
})

export const PopupChangePassword = ({ onClose }: Props) => {
  const dispatch = useDispatch()

  const [oldPassword, setOldPassword] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [passwordRepeat, setPasswordRepeat] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, oldPassword, password, passwordRepeat])
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const error = validateForm(schema, {
      oldPassword,
      password,
      passwordRepeat,
    })

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(updatePassword(oldPassword, password))
      onClose()
    }
  }

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle>{t('settings.changePassword')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <form onSubmit={handleSubmit} noValidate>
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={5}>
              <FormLabel required>{t('field.label.oldPassword')}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <InputPassword
                value={oldPassword}
                error={errors?.oldPassword}
                onInput={setOldPassword}
                placeholder={t('field.hint.oldPassword')}
                inputProps={{
                  autoFocus: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <FormLabel required>{t('field.label.password')}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <InputPassword
                value={password}
                error={errors?.password}
                onInput={setPassword}
                placeholder={t('field.hint.password')}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <FormLabel required>{t('field.label.confirmPassword')}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <InputPassword
                value={passwordRepeat}
                error={errors?.passwordRepeat}
                onInput={setPasswordRepeat}
                placeholder={t('field.hint.confirmPassword')}
              />
            </Grid>
          </Grid>
        </Stack>

        <Divider variant="popup" color="primary" />

        <DialogActions>
          <Button color="primary" size="large" onClick={onClose}>
            {t('common.close')}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
