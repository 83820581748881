import {PackageAction} from './actionType'

export const checkPackageData = (productId: number, uid: string, apiRequestsLimits: number, apiRequestsInterval: number, onSuccess: () => void): PackageAction => ({
  type: 'package/CHECK_PACKAGE_DATA',
  productId,
  uid,
  apiRequestsLimits,
  apiRequestsInterval,
  onSuccess,
})

export const postPackage = (data: object, callback: () => void): PackageAction => ({
  type: 'package/POST_PACKAGE',
  data,
  callback,
})

export const fetchPackage: PackageAction = {type: 'package/GET_PACKAGE'}

export const setPackage = (data: object[]): PackageAction => ({type: 'package/SET_PACKAGE', data})

export const deletePackage = (id: number, callback: () => void): PackageAction => ({
  type: 'package/DELETE_PACKAGE',
  id,
  callback,
})

export const putPackage = (data: object, id: number, callback: () => void): PackageAction => ({
  type: 'package/PUT_PACKAGE',
  data,
  id,
  callback,
})

export const postWorkflow = (data: object, callback: () => void): PackageAction => ({
  type: 'package/POST_WORKFLOW',
  data,
  callback,
})

export const putWorkflow = (data: object, id: number, callback: () => void): PackageAction => ({
  type: 'package/PUT_WORKFLOW',
  data,
  id,
  callback,
})

export const deleteWorkflow = (id: number, callback: () => void): PackageAction => ({
  type: 'package/DELETE_WORKFLOW',
  id,
  callback,
})

export const getPackageRules: PackageAction = {type: 'package/GET_PACKAGE_RULES'}

export const setPackageRules = (data: object[]): PackageAction => ({type: 'package/SET_PACKAGE_RULES', data})
