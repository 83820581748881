import {MarketingAction} from './actionType'

export const postCampaign = (data: object, callback: () => void): MarketingAction => ({
  type: 'marketing/POST_CAMPAIGN',
  data,
  callback,
})

export const putCampaign = (id: number, data: object, callback: () => void): MarketingAction => ({
  type: 'marketing/PUT_CAMPAIGN',
  id,
  data,
  callback,
})

export const activateCampaign = (id: number, callback: () => void): MarketingAction => ({
  type: 'marketing/ACTIVATE_CAMPAIGN',
  id,
  callback,
})

export const deleteCampaign = (id: number, callback: () => void): MarketingAction => ({
  type: 'marketing/DELETE_CAMPAIGN',
  id,
  callback,
})

export const postTelegramCampaign = (data: FormData, callback: () => void): MarketingAction => ({
  type: 'marketing/POST_TELEGRAM_CAMPAIGN',
  data,
  callback,
})