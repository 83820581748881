import { call, put, takeEvery } from 'redux-saga/effects'
import { api, Response } from '../../function/axios'
import * as actionType from './actionType'
import { handleError } from '../../function/error'

function* getResultById(action: actionType.FetchResultBuId) {
  try {
    const {id, callback} = action
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/request-monitor/${id}`,
    })
    yield call(callback, data)
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getPDFData(action: actionType.GetPDFData) {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/pdf/request-monitor/?id=${action.requestMonitorId}&apiKey=${action.apiKey}`,
    })
    yield put({ type: 'searcher/SET_PDF_DATA', pdfData: data })
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getPDF(action: actionType.GetPDF) {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/pdf/request-monitor/download/?id=${action.requestMonitorId}`,
    })
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getLinkPreview(action: actionType.GetLinkPreview) {
  try {
    const {link, callback} = action
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/searcher/url?url=${link}`,
    })
    callback(data)
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchSearcher() {
  yield takeEvery(actionType.FETCH_RESULT_BY_ID, getResultById)
  yield takeEvery(actionType.GET_PDF_DATA, getPDFData)
  yield takeEvery(actionType.GET_PDF, getPDF)
  yield takeEvery(actionType.GET_LINK_PREVIEW, getLinkPreview)
}
