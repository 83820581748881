import {call, put, takeEvery} from 'redux-saga/effects'
import {api, Response} from '../../function/axios'
import * as actionType from './actionType'
import * as actionCreator from './actionCreator'
import {handleError} from '../../function/error'
import {showSnackbar} from '../common/actionCreator'

function* uploadVideo(action: actionType.UploadVideoStart) {
  try {
    const {data}: Response = yield call(api, {
      method: 'POST',
      url: '/developer-information',
      body: action.formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    yield put(actionCreator.endUploadVideo)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchApikey() {
  yield takeEvery(actionType.UPLOAD_VIDEO_START, uploadVideo)
}