import { Stack, Typography } from '@mui/material'
import React from 'react'

type LookupChartItemProps = {
  quantity: number
  name: string
  height: string
}

export default function LookupChartItem({ quantity, name, height }: LookupChartItemProps) {
  return (
    <Stack justifyContent="flex-end" width="100%">
      <Stack
        justifyContent="flex-end"
        alignItems="center"
        height={height}
        bgcolor="#D1E0F8"
      >
        <Typography variant="semiBold" fontSize={12} color="primary">
          {quantity}
        </Typography>
      </Stack>
      <Typography
        variant="light"
        fontSize={13}
        lineHeight="13px"
        mt={1}
        align="center"
        sx={{ color: '#000' }}
      >
        {name}
      </Typography>
    </Stack>
  )
}