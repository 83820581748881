import React from 'react'
import {useTranslation} from 'react-i18next'
import NumberFormat from 'react-number-format'
import {useNavigate} from 'react-router-dom'
import {Button, FormControl, FormLabel, Grid, Stack} from '@mui/material'
import auth_ from '../sass/auth.module.sass'
import {PublicPage} from '../component/auth/PagePublic'
import {signBy2Fa} from '../store/auth/actionCreator'
import {useDispatch} from '../store'

export function TwoStepPage() {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [input, setInput] = React.useState('')
  const [focused, setFocused] = React.useState(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(signBy2Fa(input, navigate))
  }

  return (
    <PublicPage>
      <Grid className={auth_.title} item>{t('auth.2step.title')}</Grid>

      <Grid className={auth_.hint_light} item mt={3}>{t('auth.register.hint')}</Grid>

      <Grid item mt={5}>
        <form onSubmit={handleSubmit} noValidate>
          <Stack spacing={4}>
            <FormControl className="form__phone">
              <FormLabel className={focused ? 'Mui-focused' : ''}>{t('field.label.verificationCode')}</FormLabel>
              <NumberFormat
                value={input}
                onValueChange={values => setInput(values.value)}
                format="### ###"
                mask="_"
                required
                autoFocus
                style={{textAlign: 'center'}}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            </FormControl>

            <Button type="submit" variant="contained" color="primary" disabled={input.length !== 6}>
              {t('common.done')}
            </Button>
          </Stack>
        </form>
      </Grid>
    </PublicPage>
  )
}