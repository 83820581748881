import React from 'react'
import { useTranslation } from 'react-i18next'
import { Edit as EditIcon } from '@mui/icons-material'
import {
  Avatar as MuiAvatar,
  Box,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from '../store'
import { toggleAuthorize, toggleBlock } from '../store/user/actionCreator'
import { getDepartment } from '../store/auth/actionCreator'
import { PrivatePage } from '../component/PagePrivate'
import { Admin } from '../store/user/reducer'
import { DataTable } from '../component/datatable'
import { Role } from '../enum/role'
import { PopupEditAdmin } from '../container/user/PopupEditAdmin'
import { PermissionProvider } from '../component/ProviderPermision'
import { useTableStyles } from '../component/datatable/hook/useStyles'
import { Filter, TableRef } from '../component/datatable/types'
import { useAuthEffect } from '../hook/useAuthEffect'

export function AdminsPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classesTable = useTableStyles()

  const { department: departmentList } = useSelector((state) => state.auth)

  const tableRef = React.useRef<TableRef>(null)

  const [editPopupData, setEditPopupData] = React.useState<Admin | null>(null)

  useAuthEffect(() => {
    dispatch(getDepartment)
  })

  const tableHeaderData = [
    { key: 'user', text: t('users.table.user') },
    { key: 'contact', text: t('users.table.contact') },
    { key: 'department', text: t('users.table.department') },
    { key: 'authorized', text: t('users.table.authorized') },
    { key: 'blocked', text: t('users.table.blocked') },
    { key: 'action', text: t('users.table.action'), style: { width: 70 } },
  ]

  function updateTable() {
    tableRef.current?.refresh()
  }

  function TableBody(row: Admin, idx: number) {
    const isAuthorized = row.role.name === Role.ADMIN

    const handleAuthorize = () => {
      dispatch(toggleAuthorize(row.id, !isAuthorized, updateTable))
    }

    function handleBlock() {
      dispatch(toggleBlock(row.id, !row.isBlocked, updateTable))
    }

    return (
      <TableRow
        key={row.id}
        className={idx % 2 !== 0 ? classesTable.rowEven : ''}
      >
        <TableCell classes={classesTable}>
          <Grid container alignItems="center">
            <Box>
              <MuiAvatar
                src={row.avatar || ''}
                alt={row.firstname || ''}
                sx={{ width: 30, height: 30 }}
              />
            </Box>
            <Box ml={1}>
              {row.firstname} {row.lastname}
            </Box>
          </Grid>
        </TableCell>
        <TableCell classes={classesTable}>{row.phone || row.email}</TableCell>
        <TableCell classes={classesTable}>{row.department?.name}</TableCell>
        <TableCell classes={classesTable}>
          <Checkbox
            checked={isAuthorized}
            onChange={handleAuthorize}
            readOnly
          />
        </TableCell>
        <TableCell classes={classesTable}>
          <Checkbox checked={row.isBlocked} onChange={handleBlock} readOnly />
        </TableCell>
        <TableCell classes={classesTable}>
          <PermissionProvider permissions={['HR']}>
            <Tooltip title={<span>{t('common.edit')}</span>}>
              <IconButton onClick={() => setEditPopupData(row)} size="small">
                <EditIcon />
              </IconButton>
            </Tooltip>
          </PermissionProvider>
        </TableCell>
      </TableRow>
    )
  }

  const tableFilter: Filter[] = [
    {
      key: 'departmentId',
      options: departmentList?.map((el) => ({ id: el.id, text: el.name })),
      placeholder: 'Department',
    },
  ]

  return (
    <PrivatePage>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography variant="semiBold" fontSize={24}>
          {t('users.title')}
        </Typography>
      </Stack>

      <DataTable
        id="user"
        className={classesTable.card}
        ref={tableRef}
        url="/users/users"
        headerData={tableHeaderData}
        renderBody={TableBody}
        search
        searchPlaceholder="Search by name"
        filters={tableFilter}
        pagination
        emptyText="No admins with selected filters"
      />

      {editPopupData && (
        <PopupEditAdmin
          data={editPopupData}
          tableRef={tableRef}
          onClose={() => setEditPopupData(null)}
        />
      )}
    </PrivatePage>
  )
}
