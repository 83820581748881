import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import { makeStyles } from '@mui/styles'
import {
  Add as AddIcon,
  Close as CloseIcon,
  ExpandMore as SelectIcon,
  Info as InfoIcon,
  SupportAgent as SupportIcon,
} from '@mui/icons-material'
import support_ from '../../sass/support.module.sass'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Typography,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Tooltip,
} from '@mui/material'
import { SvgIcon } from '../../component/SvgIcon'
import { colorSecondaryDark } from '../../mui'
import { formatDate } from '../../function/date'
import { initNumber } from '../../function/number'
import { useButtonStyles } from '../../style/button'
import { useDispatch, useSelector } from '../../store'
import { FormError } from '../../component/FormError'
import { ErrorsState, select, validateForm } from '../../function/validation'
import {
  Ticket,
  TicketPriority,
  TicketStatus,
  TicketType,
} from '../../store/support/reducer'
import {
  deleteTicketType,
  editTicket,
  fetchTicketTypes,
  startTimer,
} from '../../store/support/actionCreator'
import { fetchAdmins } from '../../store/user/actionCreator'
import { TableRef } from '../../component/datatable/types'
import { PopupTicketTypeCRUD } from './PopupTicketTypeCRUD'
import { PopupAsk } from '../../component/PopupAsk'
import { EnumTicketStatus } from '../../enum/statusTicket'
import { getRefreshedToken } from '../../store/auth/selector'
import { FilePreview } from '../../component/support/FilePreview'
import { Chat } from './Chat'

const useStyles = makeStyles(() => {
  return {
    paper: {
      minWidth: 640,
    },
  }
})

const optionsStatus: Array<{ id: TicketStatus; label: string }> = [
  { id: EnumTicketStatus.OPEN, label: t('status.ticket.open') },
  { id: EnumTicketStatus.IN_PROGRESS, label: t('status.ticket.inProgress') },
  { id: EnumTicketStatus.CLOSED, label: t('status.ticket.closed') },
]
const optionsPriority: Array<{ id: TicketPriority; label: string }> = [
  { id: 'low', label: t('common.low') },
  { id: 'medium', label: t('common.medium') },
  { id: 'high', label: t('common.high') },
]

const schema = Joi.object({
  type: select.label(t('field.label.ticketType')),
  assignee: select.label(t('field.label.assignee')),
  estimateDays: Joi.number()
    .integer()
    .required()
    .min(0)
    .max(90)
    .label(t('field.label.estimateDays')),
  estimateHours: Joi.number()
    .integer()
    .required()
    .min(0)
    .max(23)
    .label(t('field.label.estimateHours')),
  status: select.label(t('field.label.status')),
  priority: select.label(t('field.label.priority')),
})

const notEditableTypes = ['general', 'technical', 'financial']

type Props = {
  data: Ticket
  tableRef: React.RefObject<TableRef>
  onDelete: (id: number) => void
  onClose: () => void
}

export const PopupTicket = ({ data, tableRef, onDelete, onClose }: Props) => {
  const popupStyles = useStyles()
  const buttonStyles = useButtonStyles()
  const dispatch = useDispatch()

  const refreshedToken = useSelector(getRefreshedToken)
  const { admins } = useSelector((state) => state.user)
  const { ticketTypes } = useSelector((state) => state.support)

  const [type, setType] = React.useState<number | 'null'>(
    data.ticketType?.id || 'null',
  )
  const [assignee, setAssignee] = React.useState<string | 'null'>(
    data.assignee?.uid || 'null',
  )
  const [_estimateDays, setEstimateDays] = React.useState(
    initNumber(data.due?.days),
  )
  const [_estimateHours, setEstimateHours] = React.useState(
    initNumber(data.due?.hours),
  )
  const [status, setStatus] = React.useState<TicketStatus>(
    data.status || 'null',
  )
  const [priority, setPriority] = React.useState(data.priority || 'null')
  const [errors, setErrors] = React.useState<ErrorsState>(null)
  const [ticketTypeSelectOpen, setTicketTypeSelectOpen] = React.useState(false)
  const [ticketTypeCreate, setTicketTypeCreate] = React.useState(false)
  const [ticketTypeEdit, setTicketTypeEdit] = React.useState<TicketType | null>(
    null,
  )
  const [ticketTypeDelete, setTicketTypeDelete] = React.useState<number | null>(
    null,
  )

  React.useEffect(() => {
    if (refreshedToken === null) return

    dispatch(fetchTicketTypes)
    dispatch(fetchAdmins)
  }, [dispatch, data.id, refreshedToken])

  function updateTable() {
    tableRef.current?.refresh()
  }

  function validateFields() {
    const estimateDays = _estimateDays === '' ? null : Number(_estimateDays)
    const estimateHours = _estimateHours === '' ? null : Number(_estimateHours)

    const validationData = {
      type,
      assignee,
      estimateDays,
      estimateHours,
      status,
      priority,
    }

    const error = validateForm(schema, validationData)

    if (error) setErrors(error)
    else {
      setErrors(null)

      return {
        ticketTypeId: type,
        assigneeUid: assignee,
        due: {
          days: estimateDays,
          hours: estimateHours,
        },
        status,
        priority,
      }
    }
  }

  function handleStartTimer() {
    const validatedData = validateFields()

    if (validatedData) {
      dispatch(startTimer(data.id, validatedData, updateTable))
      onClose()
    }
  }

  function handleTypeEdit(
    e: React.MouseEvent<HTMLButtonElement>,
    el: TicketType,
  ) {
    e.stopPropagation()
    setTicketTypeSelectOpen(false)
    setTicketTypeEdit(el)
  }

  function handleTypeDelete(
    e: React.MouseEvent<HTMLButtonElement>,
    id: number,
  ) {
    e.stopPropagation()
    setTicketTypeSelectOpen(false)
    setTicketTypeDelete(id)
  }

  function handleTypeDeleteCb() {
    dispatch(
      deleteTicketType(ticketTypeDelete as number, () =>
        dispatch(fetchTicketTypes),
      ),
    )
  }

  function handleDelete() {
    onDelete(data.id)
    onClose()
  }

  function handleSubmit() {
    const validatedData = validateFields()

    if (validatedData) dispatch(editTicket(data.id, validatedData, updateTable))
  }
  return (
    <>
      <Dialog
        open={true}
        classes={{ paper: popupStyles.paper }}
        fullWidth
        onClose={onClose}
      >
        <DialogTitle sx={{ textAlign: 'left' }}>
          {data.title}
          <IconButton className="button__close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Typography fontSize={12} color="secondary">
          {formatDate(data.createdTime, 'dd.MM.yy')}
        </Typography>

        <Stack spacing={2}>
          {/** Summary */}
          <FormControl>
            <FormLabel>{t('field.label.summary')}</FormLabel>
            <Typography>{data.summary}</Typography>
          </FormControl>
          {/** Ticket type */}
          <FormControl>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FormLabel>{t('field.label.ticketType')}</FormLabel>
              <IconButton
                color="primary"
                size="small"
                onClick={() => setTicketTypeCreate(true)}
              >
                <AddIcon color="white" fontSize="small" />
              </IconButton>
            </Stack>
            <Select
              open={ticketTypeSelectOpen}
              value={type}
              error={Boolean(errors?.type)}
              IconComponent={SelectIcon}
              renderValue={(selected) => {
                const ticket = ticketTypes?.find(
                  (el) => el.id === Number(selected),
                )
                if (ticket) {
                  return (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Box
                        className={support_.ticket}
                        sx={{ background: ticket.color as string }}
                      />
                      <Typography sx={{ textTransform: 'capitalize' }}>
                        {ticket.name}
                      </Typography>
                    </Stack>
                  )
                }
              }}
              onClick={() => setTicketTypeSelectOpen(false)}
              onOpen={() => setTicketTypeSelectOpen(true)}
              onChange={(e) => setType(Number(e.target.value))}
            >
              <MenuItem disabled value="null">
                <em>{t('field.hint.ticketType')}</em>
              </MenuItem>
              {ticketTypes?.map((el) => {
                const isConstant = notEditableTypes.includes(el.name)

                return (
                  <MenuItem key={el.id} value={el.id}>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent={isConstant ? 'initial' : 'space-between'}
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        className={support_.ticket}
                        sx={{ background: el.color as string }}
                      />
                      <Typography textTransform="capitalize">
                        {el.name}
                      </Typography>

                      {!isConstant && (
                        <>
                          <Tooltip
                            title={<span>{t('common.edit')}</span>}
                            style={{ marginLeft: 'auto' }}
                          >
                            <IconButton onClick={(e) => handleTypeEdit(e, el)}>
                              <SvgIcon
                                name="edit"
                                color={colorSecondaryDark}
                                size={{ width: 16, height: 14 }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={<span>{t('common.delete')}</span>}>
                            <IconButton
                              onClick={(e) => handleTypeDelete(e, el.id)}
                            >
                              <SvgIcon
                                name="delete"
                                color={colorSecondaryDark}
                                size={{ width: 18, height: 16 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </Stack>
                  </MenuItem>
                )
              })}
            </Select>
            <FormError error={errors?.type} />
          </FormControl>
          {/** Assignee */}
          <FormControl>
            <FormLabel required>{t('field.label.assignee')}</FormLabel>
            <Select
              value={assignee}
              error={Boolean(errors?.assignee)}
              IconComponent={SelectIcon}
              onChange={(e) => setAssignee(e.target.value)}
            >
              <MenuItem disabled value="null">
                <em>{t('field.hint.assignee')}</em>
              </MenuItem>
              {admins?.map(
                (el) =>
                  el.status !== 'BLOCKED' &&
                  el.status !== 'DELETED' && (
                    <MenuItem key={el.uid} value={el.uid}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        {el?.avatar ? (
                          <Avatar
                            src={el?.avatar}
                            sx={{ width: 32, height: 32 }}
                          />
                        ) : (
                          <Box className={support_.avatar__bg}>
                            <SupportIcon />
                          </Box>
                        )}
                        <Typography>
                          {el.firstname} {el.lastname}
                        </Typography>
                        {!el.firstname && !el.lastname && (
                          <em>{el.email} (No name)</em>
                        )}
                      </Stack>
                    </MenuItem>
                  ),
              )}
            </Select>
            <FormError error={errors?.assignee} />
          </FormControl>
          {/** Estimate */}
          <FormLabel required>{t('field.label.estimate')}</FormLabel>
          <Stack direction="row" spacing={2}>
            <Stack width="100%">
              <FormControl>
                <OutlinedInput
                  type="number"
                  value={_estimateDays}
                  endAdornment={
                    <InputAdornment position="end">Days</InputAdornment>
                  }
                  inputProps={{
                    min: 0,
                    max: 90,
                  }}
                  onChange={(e) => setEstimateDays(e.target.value)}
                />
                <FormError error={errors?.estimateDays} />
              </FormControl>
            </Stack>
            <Stack width="100%">
              <FormControl>
                <OutlinedInput
                  type="number"
                  value={_estimateHours}
                  endAdornment={
                    <InputAdornment position="end">Hours</InputAdornment>
                  }
                  inputProps={{
                    min: 0,
                    max: 23,
                  }}
                  onChange={(e) => setEstimateHours(e.target.value)}
                />
                <FormError error={errors?.estimateHours} />
              </FormControl>
            </Stack>
          </Stack>
          {/** Attachment */}
          {data.attachments && (
            <FormControl>
              <FormLabel>{t('field.label.attachment')}</FormLabel>
              <Stack direction="row" flexWrap="wrap" spacing={1}>
                {data.attachments?.map((el) => (
                  <FilePreview key={el.url} attachment={el} />
                ))}
              </Stack>
            </FormControl>
          )}
          {/** Status & Priority */}
          <Stack direction="row" spacing={2}>
            <Stack width="100%">
              <FormControl>
                <FormLabel>{t('field.label.status')}</FormLabel>
                <Select
                  value={status}
                  error={Boolean(errors?.status)}
                  IconComponent={SelectIcon}
                  onChange={(e) => setStatus(e.target.value as TicketStatus)}
                >
                  <MenuItem disabled value="null">
                    <em>{t('field.hint.status')}</em>
                  </MenuItem>
                  {optionsStatus.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      <Typography sx={{ textTransform: 'capitalize' }}>
                        {el.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <FormError error={errors?.status} />
              </FormControl>
            </Stack>
            <Stack width="100%">
              <FormControl>
                <FormLabel required>{t('field.label.priority')}</FormLabel>
                <Select
                  value={priority}
                  error={Boolean(errors?.priority)}
                  IconComponent={SelectIcon}
                  onChange={(e) =>
                    setPriority(e.target.value as TicketPriority)
                  }
                >
                  <MenuItem disabled value="null">
                    <em>{t('field.hint.priority')}</em>
                  </MenuItem>
                  {optionsPriority.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      <Typography sx={{ textTransform: 'capitalize' }}>
                        {el.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <FormError error={errors?.priority} />
              </FormControl>
            </Stack>
          </Stack>

          <Divider variant="popup" />

          {/** Comments */}
          <Chat ticketId={data.id} />
        </Stack>

        <Divider variant="popup" color="primary" />

        <DialogActions sx={{ justifyContent: 'right' }}>
          <Button className={buttonStyles.delete} onClick={handleDelete}>
            {t('support.deleteTicket')}
          </Button>
          {data.status !== EnumTicketStatus.CLOSED && (
            <Button variant="contained" onClick={handleStartTimer}>
              {t('support.startTimer')}
            </Button>
          )}
          <Button variant="contained" onClick={handleSubmit}>
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>

      {ticketTypeCreate && (
        <PopupTicketTypeCRUD
          mode="create"
          onSubmit={() => dispatch(fetchTicketTypes)}
          onClose={() => setTicketTypeCreate(false)}
        />
      )}
      {ticketTypeEdit && (
        <PopupTicketTypeCRUD
          mode="edit"
          data={ticketTypeEdit}
          onSubmit={() => dispatch(fetchTicketTypes)}
          onClose={() => setTicketTypeEdit(null)}
        />
      )}

      {ticketTypeDelete && (
        <PopupAsk
          onYes={handleTypeDeleteCb}
          onClose={() => setTicketTypeDelete(null)}
        />
      )}
    </>
  )
}
