import * as React from 'react'
import PhoneInput, { Value } from 'react-phone-number-input'

export type E164Number = Value

interface Props {
  value: E164Number | undefined
  onChange: (value: E164Number | undefined) => void
  autoFocus?: boolean
  error?: any
}

const ForwardedInput = React.forwardRef<
  any,
  React.InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  const { onChange, value } = props

  return <input type="text" ref={ref} onChange={onChange} value={value} />
})

export function InputPhone({ value, error, onChange }: Props) {
  const ref = React.useRef<any>(null)

  React.useEffect(() => {
    ref.current?.focus()
  }, [])

  return (
    <PhoneInput
      ref={ref}
      className={error ? 'form__phone_error' : 'form__phone'}
      inputComponent={ForwardedInput}
      value={value}
      onChange={(value) => onChange(value)}
      international
    />
  )
}
