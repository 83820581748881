import { BlackListAction, EditData, EditDataIpAddress, EditDataPhones } from "./actionType"
import { BlackList, BlackListIpAddress, BlackListPhones } from "./reducer"

export const postBlackList = (data: object): BlackListAction => ({
    type: 'blacklist/POST_BLACKLIST',
    data,
  })
  
  export const fetchBlackList: BlackListAction = { type: 'blacklist/GET_BLACKLIST' }
  
  export const setBlackList = (data: BlackList | null): BlackListAction => ({
    type: 'blacklist/SET_BLACKLIST',
    data,
  })
  
  export const editBlackList = (data: EditData): BlackListAction => ({
    type: 'blacklist/EDIT_BLACKLIST',
    data,
  })
  
  export const deleteBlackList = (data: object): BlackListAction => ({
    type: 'blacklist/DELETE_BLACKLIST',
    data,
  })

//Phones
  export const postBlackListPhones = (data: object): BlackListAction => ({
    type: 'blacklist/POST_BLACKLIST_PHONES',
    data,
  })
  
  export const fetchBlackListPhones: BlackListAction = { type: 'blacklist/GET_BLACKLIST_PHONES' }
  
  export const setBlackListPhones = (data: BlackListPhones | null): BlackListAction => ({
    type: 'blacklist/SET_BLACKLIST_PHONES',
    data,
  })
  
  export const editBlackListPhones = (data: EditDataPhones): BlackListAction => ({
    type: 'blacklist/EDIT_BLACKLIST_PHONES',
    data,
  })
  
  export const deleteBlackListPhones = (data: object): BlackListAction => ({
    type: 'blacklist/DELETE_BLACKLIST_PHONES',
    data,
  })

// IP Address
export const postBlackListIpAddress = (data: object): BlackListAction => ({
    type: 'blacklist/POST_BLACKLIST_IP_ADDRESS',
    data,
  })
  
  export const fetchBlackListIpAddress: BlackListAction = { type: 'blacklist/GET_BLACKLIST_IP_ADDRESS' }
  
  export const setBlackListIpAddress = (data: BlackListIpAddress | null): BlackListAction => ({
    type: 'blacklist/SET_BLACKLIST_IP_ADDRESS',
    data,
  })
  
  export const editBlackListIpAddress = (data: EditDataIpAddress): BlackListAction => ({
    type: 'blacklist/EDIT_BLACKLIST_IP_ADDRESS',
    data,
  })
  
  export const deleteBlackListIpAddress = (data: object): BlackListAction => ({
    type: 'blacklist/DELETE_BLACKLIST_IP_ADDRESS',
    data,
  })