import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Joi from 'joi'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
} from '@mui/material'
import { useDispatch } from '../../store'
import { sendEmailUpdateVerification } from '../../store/settings/actionCreator'
import { InputPassword } from '../../component/InputPassword'
import {
  email,
  ErrorsState,
  password,
  preventEmptySpaceOnInput,
  validateForm,
} from '../../function/validation'
import { FormError } from '../../component/FormError'
import { clearFieldError } from '../../store/common/actionCreator'

const schema = Joi.object({
  email: email,
  password: password,
})

type Props = {
  onClose: () => void
}

export const PopupChangeEmail = ({ onClose }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, email, password])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const error = validateForm(schema, { email, password })

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(sendEmailUpdateVerification(email, password, navigate))
      onClose()
    }
  }

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle>{t('settings.changeEmail')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <form onSubmit={handleSubmit} noValidate>
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={5}>
              <FormLabel required>{t('field.label.newEmail')}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <OutlinedInput
                  value={email}
                  placeholder={t('field.hint.newEmail')}
                  onChange={(e) => preventEmptySpaceOnInput(e, setEmail)}
                  autoFocus
                />
                <FormError error={errors?.email} />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <FormLabel required>{t('field.label.password')}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <InputPassword
                value={password}
                error={errors?.password}
                onInput={setPassword}
                placeholder={t('field.hint.password')}
              />
            </Grid>
          </Grid>
        </Stack>

        <Divider variant="popup" color="primary" />

        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={onClose}
          >
            {t('common.close')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
