import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Stack,
  Typography,
  Link,
  Grid,
  useTheme,
} from '@mui/material'

import sprite_ from '../../sass/sprite.module.sass'
import { FaceSearchIdItem } from '../../store/searcher/reducer'
import { SearchResult } from '../../store/searcher/reducer'
import { CompareButton, ResultTitle } from '../../style/result'
import { PreparedCommonResult } from '../../store/searcher/service'
import { getTitle } from '../../function/string'
import { RenderImage } from '../../component/searcher/RenderData'


type Props = {
  viewData: PreparedCommonResult,
  apiData?: SearchResult | null,
  compareImage?: FaceSearchIdItem,
  handleCompareImage: (image: FaceSearchIdItem) => void,
}

export function ImageCommon(props: Props) {
  const { t } = useTranslation()
  const theme = useTheme()

  const [galleryImage, setGalleryImage] = useState()

  const {
    viewData,
    apiData,
    compareImage,
    handleCompareImage,
  } = props

  useEffect(() => {
    setGalleryImage(undefined)
  }, [compareImage])

  return (
    <Stack sx={{padding: '10px'}}>
      <Stack direction={'column'} alignItems={'center'} justifyContent={'space-between'} sx={{height: '100%', width: '100%'}}>
        <Stack direction={'column'} sx={{margin: '10px 0'}}>
          {
            viewData.createdDate && <Stack direction={'row'} sx={{marginTop: '10px'}}>
              <Typography fontSize={16} color={'#214A98'} fontWeight={700} sx={{marginRight: '10px'}}>Retrieved:</Typography>
              <Typography fontSize={16} color={'#747474'} fontWeight={400}>{viewData.createdDate}</Typography>
            </Stack>
          }
        </Stack>
      </Stack>

      <Grid sx={{ flexGrow: 1 }} container justifyContent="center" spacing={2}>
        <Grid item>
          <Stack direction={'column'} sx={{height: '30px'}}></Stack>
          <Stack direction={'column'} sx={{margin: '10px 0', width: '325px', height: '400px'}}>
            <img
              src={apiData?.criteria}
              alt=""
              width="100%"
              height="100%"
              style={{ objectFit: 'cover' }}
            />
          </Stack>
        </Grid>
        {
          compareImage && <Grid item>
            <Stack direction={'column'} sx={{justifyContent: 'center', alignItems: 'center', height: '100%'}}>
              <Box className={sprite_[`common__compare`]} sx={{margin: '0 10px', width: '40px', height: '40px'}} />
            </Stack>
          </Grid>
        }
        {
          compareImage && <Grid item>
            <Stack direction={'column'} sx={{height: '30px'}}>
              <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'} justifyContent={'center'}>
                <Box className={sprite_[`common__similarity`]} sx={{marginRight: '8px'}} />
                {
                  compareImage.score && <Typography fontSize={16} fontWeight={700} color={'#2ED571'} sx={{wordBreak: 'break-all'}}>{compareImage.score}%</Typography>
                }
              </Stack>
            </Stack>
            <Stack direction={'column'} sx={{margin: '10px 0', width: '325px', height: '400px'}}>
              <img
                src={galleryImage || compareImage.face}
                alt=""
                width="100%"
                height="100%"
                style={{ objectFit: 'cover' }}
              />
            </Stack>
            <Stack direction={'column'} sx={{alignItems: 'center', width: '325px', overflow: 'hidden'}}>
              <Typography fontSize={16} fontWeight={700} color={'#2E97D5'} sx={{whiteSpace: 'nowrap', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>{compareImage.first_name} {compareImage.last_name}</Typography>
              <Stack direction={'row'} sx={{}} alignItems={'center'}>
                {
                  compareImage?.network_icon
                    ? <Box sx={{width: '16px', height: '16px', marginRight: '8px'}}><RenderImage _key={'value'} imgHeight={16} val={compareImage.network_icon} /></Box>
                    : <Box className={sprite_[`social__small_${compareImage.network}`]} sx={{marginRight: '8px', width: '20px'}} />
                }
                <Link target="_blank" href={compareImage.profile}>
                  <Typography fontSize={16} fontWeight={700} color={'#747474'} sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{compareImage.user_id}</Typography>
                </Link>
              </Stack>
              {
                compareImage.details && Array.isArray(compareImage.details) && <Stack direction={'row'} sx={{gap: '5px', marginTop: '10px'}} alignItems={'center'}>
                  {
                    compareImage.details.map(image => <img
                      src={image.url}
                      alt=""
                      width="100%"
                      height="100%"
                      style={{ width: '50px', height: '50px', objectFit: 'cover', cursor: 'pointer' }}
                      key={image.photoid}
                      onClick={() => setGalleryImage(image.url)}
                    />)
                  }
                </Stack>
              }
            </Stack>
          </Grid>
        }

      </Grid>

      {
        viewData && viewData.identicalImages && viewData.identicalImages?.length > 0 && <Stack direction={'column'} sx={{margin: '10px 0'}}>
          <ResultTitle>Identical</ResultTitle>
          <Grid container spacing={2}>
            {
              viewData.identicalImages.map((image, idx) => <Grid key={idx} item xs={6}>
                <Stack
                  direction={'column'}
                  sx={{
                    width: '100%',
                    backgroundColor: compareImage?.photo_id === image.photo_id ? '#2E97D5' : 'rgba(242, 244, 251, 0.7)',
                    padding: '10px',
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      flex: '0 0 100%',
                      [theme.breakpoints.only("xs")]: {
                        flexDirection: 'column',
                      },
                    }}
                  >
                    <Grid item sx={{flex: "0 0 100px"}}>
                      <Box sx={{width: '100px', height: '140px', border: '1px solid lightgray'}}>
                        <img
                          src={image?.face}
                          alt=""
                          width="100%"
                          height="100%"
                          style={{ objectFit: 'cover' }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs sx={{flex: '1', overflow: 'hidden', justifyContent: 'space-between'}}>
                      <Stack direction={'column'} sx={{padding: '0 10px', height: '100%', justifyContent: 'space-between'}}>
                        <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'}>
                          <Box className={sprite_[`common__similarity${compareImage?.photo_id === image.photo_id ? '_white' : ''}`]} sx={{marginRight: '8px'}} />
                          {
                            image.score && <Typography fontSize={16} fontWeight={700} color={compareImage?.photo_id === image.photo_id ? '#000' : '#2ED571'} sx={{wordBreak: 'break-all'}}>{image.score}%</Typography>
                          }
                        </Stack>
                        <Typography fontSize={16} fontWeight={700} color={compareImage?.photo_id === image.photo_id ? '#FFFFFF' : '#2E97D5'} sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{image.first_name} {image.last_name}</Typography>
                        <Typography fontSize={10} color={compareImage?.photo_id === image.photo_id ? '#FFFFFF' : '#747474'} sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{image.country} {image.city}</Typography>

                        <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'}>
                          {
                            image.network_icon
                              ? <Box sx={{width: '16px', height: '16px', marginRight: '8px'}}><RenderImage _key={'value'} imgHeight={16} val={image.network_icon} /></Box>
                              : <Box className={sprite_[`social__small_${image.network}`]} sx={{marginRight: '8px', width: '20px'}} />
                          }
                          <Link target="_blank" href={image.profile}>
                            <Typography fontSize={16} fontWeight={700} color={compareImage?.photo_id === image.photo_id ? '#FFFFFF' : '#747474'} sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{getTitle(image.user_id)}</Typography>
                          </Link>
                        </Stack>

                        <CompareButton
                          onClick={() => handleCompareImage(image)}
                          isActive={compareImage?.photo_id === image.photo_id}
                        >Show image</CompareButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>)
            }
          </Grid>
        </Stack>
      }

      {
        viewData && viewData.similarImages && viewData.similarImages?.length > 0 && <Stack direction={'column'} sx={{margin: '10px 0'}}>
          <ResultTitle sx={{color: '#747474', border: '1px solid #747474'}}>Similar</ResultTitle>
          <Grid container spacing={2}>
            {
              viewData.similarImages.map((image, idx) => <Grid key={idx} item xs={6}>
                <Stack
                  direction={'column'}
                  sx={{
                    width: '100%',
                    backgroundColor: compareImage?.photo_id === image.photo_id ? '#2E97D5' : 'rgba(242, 244, 251, 0.7)',
                    padding: '10px',
                }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      flex: '0 0 100%',
                      [theme.breakpoints.only("xs")]: {
                        flexDirection: 'column',
                      },
                    }}
                  >
                    <Grid item sx={{flex: "0 0 100px"}}>
                      <Box sx={{width: '100px', height: '140px', border: '1px solid lightgray'}}>
                        <img
                          src={image?.face || image?.base64}
                          alt=""
                          width="100%"
                          height="100%"
                          style={{ objectFit: 'contain' }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs sx={{flex: '1', overflow: 'hidden', justifyContent: 'space-between'}}>
                      <Stack direction={'column'} sx={{padding: '0 10px', height: '100%', justifyContent: 'space-between'}}>
                        <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'}>
                          <Box className={sprite_[`common__similarity${compareImage?.photo_id === image.photo_id ? '_white' : ''}`]} sx={{marginRight: '8px'}} />
                          {
                            image.score && <Typography fontSize={16} fontWeight={700} color={compareImage?.photo_id === image.photo_id ? '#000' : '#d5922e'} sx={{wordBreak: 'break-all'}}>{image.score}%</Typography>
                          }
                        </Stack>
                        <Typography fontSize={16} fontWeight={700} color={compareImage?.photo_id === image.photo_id ? '#FFFFFF' : '#2E97D5'} sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{image.first_name} {image.last_name}</Typography>
                        <Typography fontSize={10} color={compareImage?.photo_id === image.photo_id ? '#FFFFFF' : '#747474'} sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{image.country} {image.city}</Typography>

                        <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'}>
                          {
                            image.network_icon
                              ? <Box sx={{width: '16px', height: '16px', marginRight: '8px'}}><RenderImage _key={'value'} imgHeight={16} val={image.network_icon} /></Box>
                              : <Box className={sprite_[`social__small_${image.network}`]} sx={{marginRight: '8px', width: '20px'}} />
                          }
                          <Link target="_blank" href={image.profile}>
                            <Typography fontSize={16} fontWeight={700} color={compareImage?.photo_id === image.photo_id ? '#FFFFFF' : '#747474'} sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{getTitle(image.user_id)}</Typography>
                          </Link>
                        </Stack>

                        <CompareButton
                          onClick={() => handleCompareImage(image)}
                          isActive={compareImage?.photo_id === image.photo_id}
                        >Show image</CompareButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>)
            }
          </Grid>
        </Stack>
      }
    </Stack>
  )
}
