import { UserAction } from './actionType'
import { Subscriber } from './reducer'

export const fetchAdmins: UserAction = { type: 'user/GET_ADMINS' }

export const setAdmins = (data: object[]): UserAction => ({
  type: 'user/SET_ADMINS',
  data,
})

export const edit = (
  id: number,
  data: object,
  callback: () => void,
): UserAction => ({
  type: 'user/EDIT',
  id,
  data,
  callback,
})

export const toggleAuthorize = (
  id: number,
  value: boolean,
  callback: () => void,
): UserAction => ({
  type: 'user/TOGGLE_AUTHORIZE',
  id,
  value,
  callback,
})

export const toggleBlock = (
  id: number,
  value: boolean,
  callback: () => void,
): UserAction => ({
  type: 'user/TOGGLE_BLOCK',
  id,
  value,
  callback,
})

export const deleteUsers = (ids: string, callback: () => void): UserAction => ({
  type: 'user/DETELE_USERS',
  ids,
  callback,
})

export const getAllUserTickets = (id: number): UserAction => ({
  type: 'user/GET_ALL_USER_TICKETS',
  id,
})

export const setAllUserTickets = (tickets: any): UserAction => ({
  type: 'user/SET_ALL_USER_TICKETS',
  tickets,
})

export const getAllUserFailed = (id: number): UserAction => ({
  type: 'user/GET_ALL_USER_FAILED',
  id,
})

export const setAllUserFailed = (failedRequests: any): UserAction => ({
  type: 'user/SET_ALL_USER_FAILED',
  failedRequests,
})

export const getSingleSubscriber = (id: number, callback: (data: Subscriber) => void): UserAction => ({
  type: 'user/GET_SINGLE_SUBSCRIBER',
  id,
  callback,
})
