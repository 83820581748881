import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import Joi from 'joi'
import {t} from 'i18next'
import NumberFormat from 'react-number-format'
import {useTranslation} from 'react-i18next'
import {Button, Dialog, DialogActions, DialogTitle, Divider, FormControl, FormLabel, Grid} from '@mui/material'
import {delete2FAUser, toggle2step} from '../../store/settings/actionCreator'
import {useDispatch, useSelector} from '../../store'
import {ErrorsState, validateForm} from '../../function/validation'
import {FormError} from '../../component/FormError'

const schema = Joi.object({
  code: Joi.string().required().min(6).label(t('field.label.verificationCode')),
})

type Props = {
  mode: 'deleteUser' | 'toggle2FA'
  onClose: () => void
}

export const Popup2FAInput = ({mode, onClose}: Props) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {is2FAEnabled} = useSelector(state => state.settings)

  const [code, setCode] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const error = validateForm(schema, {code})

    if (error) setErrors(error)
    else {
      if (mode === 'deleteUser') dispatch(delete2FAUser(code, navigate))
      else dispatch(toggle2step(code, !is2FAEnabled, navigate))
      onClose()
    }
  }

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle>{t('auth.2step.title')}</DialogTitle>

      <Divider variant="popup" color="primary"/>

      <form onSubmit={handleSubmit} noValidate>
        <Grid container>
          <Grid item xs={5}>
            <FormLabel required>{t('field.label.verificationCode')}</FormLabel>
          </Grid>
          <Grid item xs={7}>
            <FormControl className="form__phone" fullWidth>
              <NumberFormat
                value={code}
                onValueChange={(values) => setCode(values.value)}
                mask="_"
                format="### ###"
                style={{textAlign: 'center'}}
                autoFocus
              />
              <FormError error={errors?.code}/>
            </FormControl>
          </Grid>
        </Grid>

        <Divider variant="popup" color="primary"/>

        <DialogActions>
          <Button variant="outlined" size="large" color="primary" onClick={onClose}>{t('common.close')}</Button>
          <Button type="submit" variant="contained" size="large" color="primary">{t('common.save')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}