export const UPLOAD_VIDEO_START = 'apikey/UPLOAD_VIDEO_START'
export interface UploadVideoStart {
  type: typeof UPLOAD_VIDEO_START
  formData: FormData
}

export const UPLOAD_VIDEO_END = 'apikey/UPLOAD_VIDEO_END'
export interface UploadVideoEnd {
  type: typeof UPLOAD_VIDEO_END
}

export type ApikeyAction =
  | UploadVideoStart
  | UploadVideoEnd
