import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const useTransparentAccordionStyles = makeStyles((theme: Theme) => ({
  root: {
    background: 'transparent',
    borderRadius: 0,
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      '&:hover': {
        background: '#FFF',
      },
    },
  },
  disabled: {
    '&.MuiPaper-root': {
      background: 'transparent',
      '& .Mui-disabled': {
        opacity: 1,
      },
    },
  },
}))
