import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Info as InfoIcon,
  SupportAgent as SupportIcon,
} from '@mui/icons-material'
import support_ from '../sass/support.module.sass'
import {
  Avatar,
  Box,
  Chip,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { PrivatePage } from '../component/PagePrivate'
import { Filter, HeaderData, TableRef } from '../component/datatable/types'
import { DataTable } from '../component/datatable'
import { useTableStyles } from '../component/datatable/hook/useStyles'
import { TablePriority, TableStatus } from '../component/datatable/component'
import { formatDate } from '../function/date'
import { PopupTicket } from '../container/support/PopupTicket'
import { PopupAsk } from '../component/PopupAsk'
import { useDispatch, useSelector } from '../store'
import { deleteTicket, toggleViewOne } from '../store/support/actionCreator'
import { Ticket, TicketEstimate } from '../store/support/reducer'
import { useCountdown } from '../hook/useCountdown'
import { EnumTicketStatus } from '../enum/statusTicket'
import { PopupSubscribers } from '../container/dashboard/PopupSubscriber'
import { Subscriber } from '../store/user/reducer'

export interface EstimateProps {
  startedAt: string
  estimate: TicketEstimate
  isDone: boolean
}

function Estimate({ startedAt, estimate, isDone }: EstimateProps) {
  const [date, setDate] = React.useState<Record<string, number> | null>(null)

  const days = estimate.days
  const hours = estimate.hours
  const seconds = days * 24 * 60 * 60 + hours * 60 * 60

  const dateStarted = new Date(startedAt)
  const unixEnd = dateStarted.setSeconds(dateStarted.getSeconds() + seconds)

  const countdown = useCountdown(unixEnd)

  React.useEffect(() => {
    if (isDone) {
      setDate(null)
      return
    }

    setDate({
      days: countdown.days,
      hours: countdown.hours,
      minutes: countdown.minutes,
    })
  }, [countdown.days, countdown.hours, countdown.minutes, isDone])

  const label = React.useMemo(() => {
    if (date === null) return null

    if (Math.abs(date.days) > 0) return `${date.days}d`
    else if (Math.abs(date.hours) > 0) return `${date.hours}h ${date.minutes}m`
    else return `${date.minutes}m`
  }, [date])

  return (
    <>
      {!isDone && countdown.isExpired ? (
        <Chip
          label={label}
          size="small"
          color="error"
          deleteIcon={<InfoIcon />}
        />
      ) : (
        <Typography variant="semiBold" noWrap>
          {label}
        </Typography>
      )}
    </>
  )
}

export function SupportPage() {
  const { t } = useTranslation()
  const classesTable = useTableStyles()
  const dispatch = useDispatch()

  const { viewOne } = useSelector((state) => state.support)

  const tableRef = React.useRef<TableRef>(null)

  const [ticketOpen, setTicketOpen] = React.useState<Ticket | null>(null)
  const [ticketDelete, setTicketDelete] = React.useState<number>()
  const [subscriberOpen, setSubscriberOpen] = React.useState<Subscriber | null>(
    null,
  )

  React.useEffect(() => {
    if (viewOne) setTicketOpen(viewOne)
  }, [viewOne])

  const tableHeaderData: HeaderData[] = [
    { key: 'id', text: t('support.table.id') },
    { key: 'name', text: t('support.table.name') },
    { key: 'title', text: t('support.table.title'), sortable: true },
    { key: 'createdTime', text: t('support.table.created'), sortable: true },
    { key: 'type', text: t('support.table.type'), sortable: true },
    { key: 'summary', text: t('support.table.summary') },
    { key: 'status', text: t('support.table.status'), sortable: true },
    { key: 'assignee', text: t('support.table.assignee') },
    { key: 'due', text: t('support.table.due'), sortable: true },
    { key: 'priority', text: 'P', sortable: true },
  ]

  const tableFilter: Filter[] = [
    {
      key: 'priority',
      options: [
        { id: 'low', text: 'Low' },
        { id: 'medium', text: 'Medium' },
        { id: 'high', text: 'High' },
      ],
      placeholder: 'Priority',
    },
  ]

  const TableBody = (row: Ticket, idx: number) => (
    <TableRow
      key={row.id}
      className={idx % 2 !== 0 ? classesTable.rowEven : ''}
      hover
      onClick={() => setTicketOpen(row)}
    >
      <TableCell classes={classesTable}>{row.id}</TableCell>
      <TableCell classes={classesTable}>
        <Typography
          variant="underlined"
          color="primary"
          fontWeight={600}
          noWrap
          onClick={(e) => handleSubscriberOpen(e, row.user)}
        >
          {row.user.firstname} {row.user.lastname}
        </Typography>
      </TableCell>
      <TableCell classes={classesTable}>{row.title}</TableCell>
      <TableCell classes={classesTable}>
        {formatDate(row.createdTime, 'dd.MM.yy')}
      </TableCell>
      <TableCell classes={classesTable}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box
            className={support_.ticket}
            sx={{ background: row.ticketType?.color as string }}
          />
          <Typography sx={{ textTransform: 'capitalize' }}>
            {row.ticketType?.name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell classes={classesTable} width={400}>
        <Box className={support_.table__summary}>{row.summary}</Box>
      </TableCell>
      <TableCell classes={classesTable}>
        <TableStatus status={row.status} />
      </TableCell>
      <TableCell classes={classesTable}>
        {row.assignee ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            {row.assignee.avatar ? (
              <Avatar
                src={row.assignee.avatar}
                sx={{ width: 32, height: 32 }}
              />
            ) : (
              <Box className={support_.avatar__bg}>
                <SupportIcon />
              </Box>
            )}
            <Typography>
              {row.assignee.firstname} {row.assignee.lastname}
            </Typography>
            {!row.assignee.firstname && !row.assignee.lastname && (
              <em>{row.assignee.email}</em>
            )}
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box className={support_.avatar__bg}>
              <SupportIcon />
            </Box>
            <Typography>Support agent</Typography>
          </Stack>
        )}
      </TableCell>
      <TableCell classes={classesTable}>
        {row.due && row.startedTime && (
          <Estimate
            startedAt={row.startedTime}
            estimate={row.due}
            isDone={row.status === EnumTicketStatus.CLOSED}
          />
        )}
      </TableCell>
      <TableCell classes={classesTable}>
        <TablePriority value={row.priority} />
      </TableCell>
    </TableRow>
  )

  function handleTicketPopupClose() {
    dispatch(toggleViewOne(null))
    setTicketOpen(null)
  }

  function handleSubscriberOpen(
    e: React.MouseEvent<HTMLSpanElement>,
    subscriber: Subscriber,
  ) {
    e.stopPropagation()
    setSubscriberOpen(subscriber)
  }

  function handleTicketDelete() {
    dispatch(
      deleteTicket(ticketDelete as number, () => tableRef?.current?.refresh()),
    )
  }

  return (
    <PrivatePage>
      <Typography variant="semiBold" fontSize={24}>
        {t('support.title')}
      </Typography>

      <DataTable
        ref={tableRef}
        id="ticketDesk"
        className={classesTable.card}
        url="/ticket"
        headerData={tableHeaderData}
        renderBody={TableBody}
        filters={tableFilter}
        pagination
        search
        emptyText="No support requests yet"
      />

      {ticketOpen && (
        <PopupTicket
          data={ticketOpen}
          tableRef={tableRef}
          onDelete={setTicketDelete}
          onClose={handleTicketPopupClose}
        />
      )}

      {subscriberOpen && (
        <PopupSubscribers
          data={subscriberOpen}
          usage="all"
          time="year"
          onClose={() => setSubscriberOpen(null)}
        />
      )}

      {ticketDelete && (
        <PopupAsk
          onYes={handleTicketDelete}
          onClose={() => setTicketDelete(undefined)}
        />
      )}
    </PrivatePage>
  )
}
