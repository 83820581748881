// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboard_card__1jBUm, .dashboard_card_column__OMmTa {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 13px 20px;\n  background: #F0F2FA;\n  border-radius: 5px;\n  overflow: hidden;\n}\n.dashboard_card_column__OMmTa {\n  flex-direction: column;\n  padding: 10px 16px;\n}\n\n.dashboard_button__deleted__2-dnM {\n  background: #FF0000;\n  border-radius: 0 20px 20px 0 !important;\n  box-shadow: none !important;\n  pointer-events: none;\n}", "",{"version":3,"sources":["webpack://src/sass/dashboard.module.sass","webpack://src/sass/variable.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,mBCKQ;EDJR,kBAAA;EACA,gBAAA;AADF;AAGE;EAEE,sBAAA;EACA,kBAAA;AAFJ;;AAKE;EACE,mBCDS;EDET,uCAAA;EACA,2BAAA;EACA,oBAAA;AAFJ","sourcesContent":["@import \"variable\"\n\n.card\n  display: flex\n  flex-direction: row\n  justify-content: space-between\n  padding: 13px 20px\n  background: $colorBg\n  border-radius: 5px\n  overflow: hidden\n\n  &_column\n    @extend .card\n    flex-direction: column\n    padding: 10px 16px\n\n.button\n  &__deleted\n    background: $colorError\n    border-radius: 0 20px 20px 0 !important\n    box-shadow: none!important\n    pointer-events: none\n","$fontRegular: Montserrat-Regular, sans-serif\n$fontSemiBold: Montserrat-SemiBold, sans-serif\n$fontBold: Montserrat-Bold, sans-serif\n$fontBlack: Montserrat-Black, sans-serif\n\n$colorPrimary: #2E97D5\n$colorPrimaryLight: #F2FBFF\n$colorPrimaryDark: #3082B3\n\n$colorSecondary: #787878\n$colorSecondaryLight: #E8E8E8\n\n$colorBg: #F0F2FA\n\n$colorSuccess: #2ED571\n$colorWarning: #FFBB13\n$colorWarningLight: #FFBB131B\n$colorError: #FF0000\n$colorErrorLight: #FCD0CF\n\n$colorTitle: #01163E\n$colorText: #5A6781\n\n$colorHintLight: #BABABA\n\n$boxShadow: 2px 2px 7px rgba(0, 0, 0, 0.1)\n\n$breakpointSm: 600px\n$breakpointMd: 900px\n$breakpointLg: 1200px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "dashboard_card__1jBUm",
	"card_column": "dashboard_card_column__OMmTa",
	"button__deleted": "dashboard_button__deleted__2-dnM"
};
export default ___CSS_LOADER_EXPORT___;
