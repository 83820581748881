import { call, put, takeEvery, select } from 'redux-saga/effects'
import * as firebase from 'firebase/auth'
import { t } from 'i18next'
import { handleError } from '../../function/error'
import { encrypt } from '../../function/storage'
import { api, Response } from '../../function/axios'
import { State } from '../index'
import * as authService from '../auth/service'
import * as actionType from './actionType'
import * as actionCreator from './actionCreator'
import * as authActionCreator from '../auth/actionCreator'
import { setLoading, showSnackbar } from '../common/actionCreator'

import { SettingsState } from './reducer'
import { CommonState } from '../common/reducer'
import { parsePhoneNumber } from 'react-phone-number-input'

function* getProfile() {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: '/users/profile',
    })

    yield put(actionCreator.setProfile(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* uploadImage(action: actionType.UploadImage) {
  yield put(setLoading(true))

  try {
    const { translation }: CommonState = yield select((state) => state.common)

    yield call(api, {
      method: 'PUT',
      url: '/users/avatar',
      body: {
        url: action.base64,
      },
    })

    yield put(actionCreator.setState('avatar', action.base64))

    yield put(showSnackbar('success', translation.settings.pictureHint2))
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* deleteImage() {
  yield put(setLoading(true))

  try {
    const { translation }: CommonState = yield select((state) => state.common)

    yield call(api, {
      method: 'PUT',
      url: '/users/avatar',
      body: {
        url: null,
      },
    })

    yield put(actionCreator.setState('avatar', null))
    yield put(showSnackbar('success', translation.settings.pictureHint3))
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* putData(action: actionType.PutData) {
  try {
    const { data }: Response = yield call(api, {
      method: 'PUT',
      url: '/users/profile',
      body: {
        [action.key]: action.value,
      },
    })

    yield put(actionCreator.setState(action.key, action.value))

    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* sendEmailUpdateVerification(
  action: actionType.SendEmailUpdateVerification,
) {
  const auth = firebase.getAuth()

  if (auth.currentUser === null) {
    console.error('sendEmailUpdateVerification: auth.currentUser is null')
    return
  }

  yield put(setLoading(true))

  try {
    const { email: oldEmail }: SettingsState = yield select(
      (state: State) => state.settings,
    )

    const result: [] = yield call(
      firebase.fetchSignInMethodsForEmail,
      auth,
      action.newEmail,
    )

    if (result.length > 0) {
      yield put(showSnackbar('error', t('message.error.emailAlreadyInUse')))
      return
    }

    yield call(
      firebase.signInWithEmailAndPassword,
      auth,
      oldEmail,
      action.password,
    )

    const url = new URL(`${process.env.REACT_APP_HOST_URL}`)
    url.searchParams.set('newEmail', action.newEmail)
    url.searchParams.set('password', encrypt(action.password))

    const actionCodeSettings: firebase.ActionCodeSettings = { url: url.href }

    yield call(
      firebase.verifyBeforeUpdateEmail,
      auth.currentUser,
      action.newEmail,
      actionCodeSettings,
    )

    yield put(showSnackbar('info', t('message.info.confirmationEmailSent')))

    yield put(authActionCreator.signOut(action.navigate))
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* updatePassword(action: actionType.UpdatePassword) {
  const auth = firebase.getAuth()

  if (auth.currentUser === null) return

  const email: string = yield select((state: State) => state.settings.email)

  yield put(setLoading(true))

  try {
    const { user }: firebase.UserCredential = yield call(
      firebase.signInWithEmailAndPassword,
      auth,
      email,
      action.oldPassword,
    )
    yield call(authService.getToken, user)

    yield call(firebase.updatePassword, auth.currentUser, action.password)
    yield put(actionCreator.setState('password', action.password))
    yield put(showSnackbar('success'))
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* updatePhone(action: actionType.UpdatePhone) {
  const auth = firebase.getAuth()

  yield put(setLoading(true))

  if (auth.currentUser === null) {
    console.error('updatePhone: auth.currentUser is null')
    return
  }

  try {
    if (window.confirmationResult === undefined) {
      yield put(showSnackbar('info', t('message.info.clickSendAgain')))
      return
    }

    const authCredential = firebase.PhoneAuthProvider.credential(
      window.confirmationResult.verificationId,
      action.code,
    )

    yield call(firebase.updatePhoneNumber, auth.currentUser, authCredential)

    const response: Response = yield call(api, {
      method: 'PUT',
      url: '/users/phone',
      body: {
        phone: action.phone,
        countryCode: parsePhoneNumber(action.phone)?.country,
      },
    })

    yield put(actionCreator.setState('phone', action.phone))

    if (action.callback) {
      yield call(action.callback)
    }

    yield put(showSnackbar('success', response.data.message))
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* getQr() {
  try {
    const response: Response = yield call(api, {
      method: 'POST',
      url: '/auth/generate',
      responseType: 'blob',
    })

    const imgSrc = URL.createObjectURL(response.data)

    yield put(actionCreator.setQr(imgSrc))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* toggle2step(action: actionType.Toggle2step) {
  const isTurnedOn = action.is2FA

  try {
    yield call(api, {
      method: 'POST',
      url: '/auth/set2FA',
      body: {
        twoFACode: action.code,
        is2FA: isTurnedOn,
      },
    })

    if (isTurnedOn) yield put(authActionCreator.signOut(action.navigate))
    else yield put(actionCreator.setState('is2FAEnabled', action.is2FA))

    const { translation }: CommonState = yield select((state) => state.common)

    const message =
      translation.settings[isTurnedOn ? 'twoFATurnedOn' : 'twoFATurnedOff']

    yield put(showSnackbar('success', message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* deleteUser(action: actionType.DeleteUser) {
  yield put(setLoading(true))

  try {
    yield call(api, {
      method: 'PUT',
      url: '/users/profile/delete',
    })

    localStorage.clear()

    yield put(authActionCreator.signOut(action.navigate))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* delete2FAUser(action: actionType.Delete2FAUser) {
  const user = firebase.getAuth().currentUser

  yield put(setLoading(true))

  try {
    if (user === null) return

    yield call(api, {
      method: 'POST',
      url: '/auth/authenticate',
      body: {
        twoFACode: action.code,
      },
    })

    yield call(api, {
      method: 'PUT',
      url: '/auth/logout2FA',
    })

    yield call(api, {
      method: 'PUT',
      url: '/users/profile/delete',
    })

    localStorage.clear()

    yield put(authActionCreator.flushState)

    action.navigate('/auth')
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

export function* watchSettings() {
  yield takeEvery(actionType.GET_PROFILE, getProfile)
  yield takeEvery(actionType.UPLOAD_IMAGE, uploadImage)
  yield takeEvery(actionType.DELETE_IMAGE, deleteImage)
  yield takeEvery(actionType.PUT_DATA, putData)
  yield takeEvery(
    actionType.SEND_EMAIL_UPDATE_VERIFICATION,
    sendEmailUpdateVerification,
  )
  yield takeEvery(actionType.UPDATE_PASSWORD, updatePassword)
  yield takeEvery(actionType.UPDATE_PHONE, updatePhone)
  yield takeEvery(actionType.GET_QR, getQr)
  yield takeEvery(actionType.TOGGLE_2STEP, toggle2step)
  yield takeEvery(actionType.DELETE_USER, deleteUser)
  yield takeEvery(actionType.DELETE_2FA_USER, delete2FAUser)
}
