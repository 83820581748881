import * as React from 'react'
import {usePopoverStyles} from '../style/popover'

export interface PopoverClasses {
  root: string
  paper: string
}

export interface PopoverOptions {
  anchorOrigin: object
  transformOrigin: object
}

export function usePopover(
  leftPopover: boolean | undefined,
  isMini: boolean | undefined = false,
) {
  const classes = usePopoverStyles()

  const popoverClasses: PopoverClasses = React.useMemo(() => {
    if (isMini) {
      if (leftPopover) {
        return {
          root: classes.popoverLeft,
          paper: classes.miniPaperLeft,
        }
      }

      return {
        root: classes.popoverRight,
        paper: classes.miniPaperRight,
      }
    } else {
      if (leftPopover) {
        return {
          root: classes.popoverLeft,
          paper: classes.paperLeft,
        }
      }

      return {
        root: classes.popoverRight,
        paper: classes.paperRight,
      }
    }
  }, [
    isMini,
    leftPopover,
    classes.popoverRight,
    classes.miniPaperRight,
    classes.popoverLeft,
    classes.miniPaperLeft,
    classes.paperRight,
    classes.paperLeft,
  ])

  const popoverOptions: PopoverOptions = React.useMemo(() => {
    if (leftPopover) {
      return {
        anchorOrigin: { vertical: 'top', horizontal: 'left' },
        transformOrigin: { vertical: 'bottom', horizontal: 'right' },
      }
    }

    return {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      transformOrigin: { vertical: 'bottom', horizontal: 'left' },
    }
  }, [leftPopover])

  return { popoverClasses, popoverOptions }
}
