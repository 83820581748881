import * as React from 'react'
import Joi, { string } from 'joi'
import { t } from 'i18next'
import {
  Divider,
  FormControl,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  TextareaAutosize,
} from '@mui/material'
import { FormError } from '../FormError'
import {
  ErrorsState,
  FormState,
  latinOnly,
  validateForm,
} from '../../function/validation'
import {
  ICampaign,
} from '../../store/marketing/reducer'
import { useDispatch, useSelector } from '../../store'
import {
  activateCampaign,
  postCampaign, postTelegramCampaign,
  putCampaign,
} from '../../store/marketing/actionCreator'
import { initNumber } from '../../function/number'
import { TableRef } from '../datatable/types'
import { clearFieldError } from '../../store/common/actionCreator'
import { MutableRefObject, useRef } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

const commonValidation = {
  name: Joi.string().max(55).label(t('field.label.campaignName')),
  title: Joi.string().max(255).label(t('field.label.mainTitle')),
  paragraph: Joi.string().max(500).label(t('field.label.paragraph')),
  actionName: Joi.string().max(25).label(t('field.label.callToActionName')),
  actionUrl: Joi.string().uri({allowQuerySquareBrackets: true}).label(t('field.label.callToActionUrl')),
}
const schemaPreview = Joi.object(commonValidation)
const schemaSubmit = Joi.object({
  ...commonValidation,
})

export type FormRef = {
  preview: () => void
  submit: () => void
  activate: () => void
}

interface Props {
  mode: 'create' | 'edit'
  setPreview: (data: FormState) => void
  tableRef: React.RefObject<TableRef>
  onClose: () => void
  data?: ICampaign
}

export const FormTelegram = React.forwardRef<FormRef, Props>(
  ({ mode, setPreview, tableRef, onClose, data }, ref) => {
    const dispatch = useDispatch()

    const uploadRef = useRef() as MutableRefObject<HTMLInputElement>

    const [name, setName] = React.useState(data?.name || '')
    const [title, setTitle] = React.useState(data?.title || '')
    const [paragraph, setParagraph] = React.useState(data?.paragraph || '')
    const [actionName, setActionName] = React.useState(data?.actionName || '')
    const [actionUrl, setActionUrl] = React.useState(data?.actionUrl || '')
    const [activity, setActivity] = React.useState(initNumber(data?.lastActive))
    const [errors, setErrors] = React.useState<ErrorsState>(null)

    const [base64, setBase64] = React.useState(undefined)
    const [image, setImage] = React.useState<File>()

    React.useEffect(() => {
      setErrors(null)
      dispatch(clearFieldError)
    }, [
      dispatch,
      activity,
      name,
      title,
      paragraph,
      actionName,
      actionUrl,
      base64,
    ])

    const handleUpload = ({ target }: any) => {
      setErrors(null)

      const fileTypes = ['jpg', 'jpeg', 'png']
      const file = target.files[0]
      const extension = file.name.split('.').pop().toLowerCase()
      const isSuccess = fileTypes.indexOf(extension) > -1
      const isOverWeight = file.size > 1024 * 1024 * 4
      if (isOverWeight) {
        setErrors({...errors, base64: 'Photo size should be less than 4 Mb.'})
      } else {
        if (isSuccess) {
          setImage(file)

          const fileReader = new FileReader()

          fileReader.readAsDataURL(file)

          fileReader.onload = (e: any) => {
            if (e.target) {
              setBase64(e.target.result)
            }
          }
        } else {
          setErrors({...errors, base64: t('message.error.profilePicture')})
        }
      }
    }

    const handleDelete = () => {
      if (uploadRef && uploadRef.current) {
        uploadRef.current.value = ''
      }
      setBase64(undefined)
      setImage(undefined)
      setErrors(null)
    }

    function validateBeforeRequest() {
      const validationData = {
        name: name.trim(),
        title: title.trim(),
        paragraph: paragraph.trim(),
        actionName: actionName.trim(),
        actionUrl,
      }

      return validateForm(schemaSubmit, validationData)
    }

    React.useImperativeHandle(ref, () => ({
      preview() {
        const validationData = {
          name,
          title,
          paragraph,
          actionName,
          actionUrl,
        }

        const error = validateForm(schemaPreview, validationData)

        if (error) {
          setErrors(error)
        } else {
          setErrors(null)
          setPreview({ ...validationData, createdTime: data?.createdTime })
        }
      },
      submit() {
        const error = validateBeforeRequest()
        console.log('submit', error)

        if (error) {
          setErrors(error)
        } else {
          setErrors(null)

          const formData: FormData = new FormData()
          formData.append('name', name)
          formData.append('title', title)
          formData.append('paragraph', paragraph)
          formData.append('actionName', actionName)
          formData.append('actionUrl', actionUrl)
          if (image) {
            formData.append('file', image)
          }


          if (mode === 'create') {
            dispatch(
              postTelegramCampaign(formData, () => {
                tableRef.current?.refresh()
              }),
            )
          }
          /*if (mode === 'create')
            dispatch(
              postCampaign(requestData, () => tableRef.current?.refresh()),
            )
          else
            dispatch(
              putCampaign(data?.id as number, requestData, () =>
                tableRef.current?.refresh(),
              ),
            )*/

          onClose()
        }
      },
      activate() {
        const error = validateBeforeRequest()

        if (error) setErrors(error)
        else {
          setErrors(null)

          dispatch(
            activateCampaign(data?.id as number, () =>
              tableRef.current?.refresh(),
            ),
          )
        }
      },
    }))

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <FormControl fullWidth>
              <FormLabel required>{t('field.label.campaignName')}</FormLabel>
              <OutlinedInput
                value={name}
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
              <FormError error={errors?.name} />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FormControl fullWidth>
              <FormLabel required>{t('field.label.mainTitle')}</FormLabel>
              <OutlinedInput
                value={title}
                placeholder="Title"
                onChange={(e) => setTitle(e.target.value)}
              />
              <FormError error={errors?.title} />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={2}>
            <FormControl fullWidth>
              <FormLabel>{t('field.label.image')}</FormLabel>
              <Stack direction={'row'}>
                {
                  base64 && <img alt={''} src={base64} width={100} height={100} style={{objectFit: 'cover'}} />
                }
                {
                  !base64 && <label
                    htmlFor="uploadImage"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '43px',
                      cursor: 'pointer',
                      border: '1px solid gray',
                      borderRadius: '4px',
                      background: 'none',
                    }}
                  >
                    Upload image
                  </label>
                }
                {
                  base64 && <label onClick={handleDelete} htmlFor="deleteImage" style={{cursor: 'pointer'}}>
                    <DeleteIcon color='primary'/>
                  </label>
                }
              </Stack>
              <input
                ref={uploadRef}
                type="file"
                accept="image/*"
                id="uploadImage"
                style={{ display: 'none' }}
                onChange={handleUpload}
              />
              <FormError error={errors?.base64} />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FormControl fullWidth>
              <FormLabel required>
                {t('field.label.callToActionName')}
              </FormLabel>
              <OutlinedInput
                value={actionName}
                placeholder="Button name"
                onChange={(e) => setActionName(e.target.value)}
              />
              <FormError error={errors?.actionName} />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FormControl fullWidth>
              <FormLabel required>{t('field.label.callToActionUrl')}</FormLabel>
              <OutlinedInput
                value={actionUrl}
                placeholder="https://example.com"
                onChange={(e) => setActionUrl(e.target.value)}
              />
              <FormError error={errors?.actionUrl} />
            </FormControl>
          </Grid>
        </Grid>

        <Divider variant="popup" color="primary" />

        <Stack direction="row" spacing={3} flexWrap="wrap">
          <FormControl fullWidth>
            <FormLabel required>{t('field.label.paragraph')}</FormLabel>
            <TextareaAutosize
              className="form__textarea"
              minRows={6}
              placeholder={"Enter campaign text"}
              value={paragraph}
              onChange={e => setParagraph(e.target.value)}
            />
            <FormError error={errors?.paragraph} />
          </FormControl>
        </Stack>
      </>
    )
  },
)
