import React from 'react'
import {useTranslation} from 'react-i18next'
import {Box, Button, Divider, Grid, Typography} from '@mui/material'
import payment_ from '../sass/payment.module.sass'
import {PrivatePage} from '../component/PagePrivate'

interface IPaymentItem {
  data: {
    icon: JSX.Element
    status: string
    title: string
    text: string
    link: string
  }
}

const payProData = {
  icon: <Box className={payment_.logo_paypro}/>,
  status: 'active',
  title: 'PayPro',
  text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.',
  link: 'https://payproglobal.com/',
}

function PaymentItem({data}: IPaymentItem) {
  const {t} = useTranslation()

  return (
    <Grid className={payment_.root} container justifyContent="space-between">
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>{data.icon}</Grid>
          <Grid item><Box className={payment_.status_active}>{t('package.' + data.status)}</Box></Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={payment_.title}>{data.title}</Grid>
      <Grid item xs={12} className={payment_.text}>{data.text}</Grid>
      <Grid item xs={12}><Divider/></Grid>
      <Grid item xs={12}>
        <Box mt={3}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button target="_blank" href={data.link}>More info</Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export function PaymentPage() {
  const {t} = useTranslation()
  return (
    <PrivatePage>
      <Typography variant="semiBold" fontSize={24}>{t('payment.title')}</Typography>

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <PaymentItem data={payProData}/>
          </Grid>
        </Grid>
      </Box>
    </PrivatePage>
  )
}