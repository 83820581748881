import React from 'react'
import Joi from 'joi'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from '../store'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Link,
  OutlinedInput,
  Stack,
} from '@mui/material'
import auth_ from '../sass/auth.module.sass'
import {
  email,
  password,
  preventEmptySpaceOnKeyDown,
  preventEmptySpaceOnInput,
  ErrorsState,
  validateForm,
} from '../function/validation'
import { PublicPage } from '../component/auth/PagePublic'
import { signIn } from '../store/auth/actionCreator'
import { InputPassword } from '../component/InputPassword'
import { FormError } from '../component/FormError'
import { clearFieldError } from '../store/common/actionCreator'

const schema = Joi.object({
  email: email,
  password: password,
})

export function LoginPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { fieldErrors } = useSelector((state) => state.common)

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, email, password])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(clearFieldError)

    const error = validateForm(schema, { email, password })

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(signIn(email, password, navigate))
    }
  }

  return (
    <PublicPage>
      <Grid className={auth_.title} item>
        {t('auth.welcome.title')}
      </Grid>

      <Grid item mt={11}>
        <form onSubmit={handleSubmit} noValidate>
          <Stack spacing={3} alignItems="center">
            <FormControl fullWidth required>
              <FormLabel>{t('field.label.email')}</FormLabel>
              <OutlinedInput
                name="email"
                value={email}
                error={Boolean(errors?.email)}
                placeholder={t('field.hint.email')}
                autoFocus
                autoComplete="on"
                onChange={(e) => preventEmptySpaceOnInput(e, setEmail)}
                onKeyDown={preventEmptySpaceOnKeyDown}
              />
              <FormError error={fieldErrors.loginEmail || errors?.email} />
            </FormControl>
            <FormControl fullWidth>
              <InputPassword
                label={t('field.label.password')}
                value={password}
                error={fieldErrors.loginPassword || errors?.password}
                placeholder={t('field.hint.password')}
                onInput={setPassword}
                required
              />
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              {t('auth.welcome.signIn')}
            </Button>
          </Stack>
        </form>
        <Stack alignItems="center" mt={1}>
          <Button onClick={() => navigate('/auth/forgot')}>
            {t('auth.welcome.forgot')}
          </Button>

          <Box mt={2}>
            <span className={auth_.hint}>
              {t('auth.welcome.noAccountYet')}{' '}
            </span>
            <Link
              variant="semiBold"
              color="primary"
              onClick={() => navigate('/auth/register')}
            >
              {t('auth.welcome.signUpNow')}
            </Link>
          </Box>
        </Stack>
      </Grid>
    </PublicPage>
  )
}
