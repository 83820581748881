import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Email as EmailIcon,
  NotificationAdd as NotificationIcon,
  Lan,
  PermIdentity,
  FollowTheSigns,
} from '@mui/icons-material'
import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material'

import Phone from '@mui/icons-material/Phone'
import Email from '@mui/icons-material/Email'
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel'
import VerifiedIcon from '@mui/icons-material/Verified'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'
import { useDispatch } from '../store'
import { PrivatePage } from '../component/PagePrivate'
import { DataTable } from '../component/datatable'
import { useTableStyles } from '../component/datatable/hook/useStyles'
import { Filter, HeaderData, TableRef } from '../component/datatable/types'
import { formatDate } from '../function/date'
import { PopupNotify } from '../component/PopupNotify'
import { showSnackbar } from '../store/common/actionCreator'
import { Subscriber } from '../store/user/reducer'
import { PopupSubscribers } from '../container/dashboard/PopupSubscriber'
import { TableStatus } from '../component/datatable/component'
import { EnumSubscriberStatus } from '../enum/statusSubscriber'
import { SvgIcon } from '../component/SvgIcon'
import { colorSecondaryDark } from '../mui'
import { PopupAsk } from '../component/PopupAsk'
import { deleteUsers } from '../store/user/actionCreator'
import table_ from '../sass/table.module.sass'
import { postBlackListIpAddress } from '../store/blacklist/actionCreator'

export function SubscribersPage() {
  const { t } = useTranslation()
  const classesTable = useTableStyles()
  const dispatch = useDispatch()

  const tableRef = React.useRef<TableRef>(null)

  const [subscriberOpen, setSubscriberOpen] = React.useState<Subscriber | null>(
    null,
  )
  const [subscribers, setSubscribers] = React.useState<Subscriber[]>([])

  const [notifySingle, setNotifySingle] = React.useState<Subscriber | null>(
    null,
  )
  const [notifyMulti, setNotifyMulti] = React.useState(false)

  const [emailSingle, setEmailSingle] = React.useState<Subscriber | null>(null)
  const [emailMulti, setEmailMulti] = React.useState(false)

  const [isDeleteUsers, setIsDeleteUsers] = React.useState(false)

  const tableHeaderData: HeaderData[] = [
    { key: 'uid', text: 'UID' },
    { key: 'subscriber', text: t('subscriber.table.subscriber') },
    { key: 'country', text: t('subscriber.table.country') },
    { key: 'renewal', text: t('subscriber.table.renewal') },
    { key: 'contact', text: t('subscriber.table.contact') },
    { key: 'status', text: t('subscriber.table.status')},
    { key: 'info', text: "Info"},
    { key: 'registrationDate', text: t('subscriber.table.registrationDate') },
    { key: 'lastActive', text: t('subscriber.table.lastActive') },
    // {
    //   key: 'action',
    //   text: t('subscriber.table.action'),
    //   style: { minWidth: 120 },
    // },
    {
      key: 'userAgent',
      text: t('subscriber.table.userAgent'),
      style: { minWidth: 120 },
    },
  ]

  function updateTable() {
    tableRef.current?.refresh()
  }

  const TableBody = (row: Subscriber) => (
    <>
      <TableCell classes={classesTable}>
        <Typography>{row.uid}</Typography>
      </TableCell>
      <TableCell classes={classesTable}>
        <Stack direction="row" alignItems="center">
          <Box>
            <MuiAvatar
              src={row.avatar || ''}
              alt={row.firstname || ''}
              sx={{ width: 30, height: 30 }}
            />
          </Box>
          <Typography noWrap ml={1}>
            {row.firstname} {row.lastname}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell classes={classesTable}>{row.country}</TableCell>
      <TableCell classes={classesTable}>
        {formatDate(row.packageEndDate, 'dd/MM/yyyy')}
      </TableCell>
      <TableCell classes={classesTable}>{row.phone || row.email}</TableCell>
      <TableCell classes={classesTable} >
      <Stack>
      <TableStatus status={row.status}  />
      </Stack>
      <Stack>
      { ( (row.userAgent?.isEmailDomainValid === false) &&
        <WorkspacePremiumIcon  sx={{ fontSize: 20 }} color={'error'}/>
        // <Box className={table_.deleted}>{t('status.user.trial')}</Box>
      )} 
      </Stack>
      </TableCell>
      <TableCell classes={classesTable}>
      {(row.registerInfo &&
          (row.registerInfo?.email_quality_score === "0.00" || 
          row.registerInfo?.email_deliverability === "UNDELIVERABLE" || 
          row.registerInfo?.email_is_disposable_email === true || 
          row.registerInfo?.email_is_smtp_valid === false 
          )) && (
         <>
              <Tooltip title={
                <span>{`E-mail score: ${row.registerInfo?.email_quality_score};\n
                E-mail deliverability: ${row.registerInfo?.email_deliverability};\n
                Disposable: ${row.registerInfo?.email_is_disposable_email};\n
                SMTP valid: ${row.registerInfo?.email_is_smtp_valid};\n
                `}</span>}>
                <Email sx={{ fontSize: 20 }} color={'error'} />
              </Tooltip>
          </>
        )}

        {(row.registerInfo && 
        row.registerInfo?.phone_valid === false) &&  (
        <>
              <Tooltip title={<span>{`Phone type: ${row.registerInfo?.phone_type};\n
                 Phone valid: ${row.registerInfo?.phone_valid};\n
                `}</span>}>
                <Phone sx={{ fontSize: 20 }} color={'error'} />
              </Tooltip>
        </>
        )} 

        {
        (row.userAgent?.isIpPhoneMatches !== null && 
          row.userAgent?.isIpPhoneMatches !== undefined && 
            row.userAgent?.isIpPhoneMatches === false) && (
        <>
              <Tooltip title={<span>{`Phone country code does not match with IP address location`}</span>}>
                <ModeOfTravelIcon sx={{ fontSize: 20 }} color={'error'} />
              </Tooltip>
        </>
        )} 

        {
          (row.registerInfo && 
            (row.registerInfo?.email_quality_score !== "0.00" && 
            row.registerInfo?.email_deliverability !== "UNDELIVERABLE" &&
            row.registerInfo?.email_is_disposable_email === false &&
            row.registerInfo?.email_is_smtp_valid === true &&
            row.registerInfo?.phone_valid === true &&
            row.userAgent?.isIpPhoneMatches !== null &&
            row.userAgent?.isIpPhoneMatches !== undefined &&
            row.userAgent?.isIpPhoneMatches === true 
            )) && (
           <>
                <Tooltip title={
                  <span>{`Looks like this user is not fake`}</span>}>
                  <VerifiedIcon sx={{ fontSize: 20 }} color={'success'} />
                </Tooltip>
            </>
          )}
        
      </TableCell>
      <TableCell classes={classesTable}>
        {formatDate(row.createdTime, 'dd/MM/yyyy HH:mm:ss')}
      </TableCell>
      <TableCell classes={classesTable}>
        {formatDate(row.sessions[0]?.lastActiveDate, 'dd/MM/yyyy') ===
        'undefined'
          ? ''
          : formatDate(row.sessions[0]?.lastActiveDate, 'dd/MM/yyyy')}
      </TableCell>
      <TableCell classes={classesTable}>
        { (!row.isDeleted && row.userAgent && row.userAgent?.handlIP && row.userAgent?.handlIP !== 'Unknown') && (
          <>
          <Tooltip title={<span>{"IP: " + row.userAgent?.handlIP}</span>}>
            <IconButton>
              <Lan sx={{ fontSize: 20 }} color={'success'}/>
            </IconButton>
          </Tooltip>
        </>
        ) 
        }
        { (!row.isDeleted && row.userAgent && row.userAgent?.handlIP && row.userAgent?.handlIP === 'Unknown') && (
          <>
          <Tooltip title={<span>IP Adress not detected</span>}>
            <IconButton>
              <Lan sx={{ fontSize: 20 }} color={'error'}/>
            </IconButton>
          </Tooltip>
        </>
        ) 
        }
        { (!row.isDeleted && row.userAgent && row.userAgent?.gaClientId && row.userAgent?.gaClientId !== 'Unknown') && (
          <> 
          <Tooltip title={<span>{"GA Client ID: " + row.userAgent?.gaClientId}</span>}>
            <IconButton>
              <PermIdentity sx={{ fontSize: 20 }} color={'success'}/>
            </IconButton>
          </Tooltip>
        </>
        ) 
        }
        { (!row.isDeleted && row.userAgent && row.userAgent?.gaClientId && row.userAgent?.gaClientId === 'Unknown') && (
          <>
          <Tooltip title={<span>{"GA Client ID not detected"}</span>}>
            <IconButton>
              <PermIdentity sx={{ fontSize: 20 }} color={'error'}/>
            </IconButton>
          </Tooltip>
        </>
        ) 
        }
        { (!row.isDeleted && row.userAgent && row.userAgent?.utmSource && row.userAgent?.utmSource !== 'Unknown') && (
          <> 
          <Tooltip title={<span>{"Referral from: " + row.userAgent?.utmSource}</span>}>
            <IconButton>
              <FollowTheSigns sx={{ fontSize: 20 }} color={'success'}/>
            </IconButton>
          </Tooltip>
        </>
        )
        }
        { (!row.isDeleted && row.userAgent && row.userAgent?.utmSource && row.userAgent?.utmSource === 'Unknown') && (
          <>
          <Tooltip title={<span>{"Natural registration or direct link"}</span>}>
            <IconButton>
              <FollowTheSigns sx={{ fontSize: 20 }} color={'warning'}/>
            </IconButton>
          </Tooltip>
        </>
        ) 
        }
        {
          (!row.isDeleted && row.userAgent && row.userAgent?.ipMatchesCount 
            && typeof row.userAgent?.ipMatchesCount === 'number' && row.userAgent?.ipMatchesCount > 0) ? (
              <Tooltip title={<span>{`This IP matches ${row.userAgent?.ipMatchesCount} times`}</span>}>
              <Badge color="error" badgeContent={row.userAgent?.ipMatchesCount}>
              <IconButton>
              <Lan sx={{ fontSize: 20 }} color={'error'} onClick={() => { dispatch(postBlackListIpAddress({ipAddress : row.userAgent?.handlIP, blockUser : true}))}}/>
              </IconButton>
              </Badge>
            </Tooltip>
            ) : ""
        }
         
      </TableCell>
    </>
  )

  const tableFilter: Filter[] = [
    {
      key: 'status',
      options: [
        { id: EnumSubscriberStatus.TRIAL, text: t('status.user.trial') },
        { id: EnumSubscriberStatus.PAID, text: t('status.user.paid') },
        { id: EnumSubscriberStatus.BLOCKED, text: t('status.user.blocked') },
        { id: EnumSubscriberStatus.DELETED, text: t('status.user.deleted') },
      ],
      placeholder: 'Status',
    },
  ]

  function handleSingleNotifyOpen(
    e: React.MouseEvent<HTMLButtonElement>,
    subscriber: Subscriber,
  ) {
    e.stopPropagation()
    setNotifySingle(subscriber)
  }

  function handleSingleEmailOpen(
    e: React.MouseEvent<HTMLButtonElement>,
    subscriber: Subscriber,
  ) {
    e.stopPropagation()
    setEmailSingle(subscriber)
  }

  function handleSelectCallback(rows: Subscriber[]) {
    rows.forEach((row) => {
      if (row.isDeleted) {
        dispatch(
          showSnackbar(
            'warning',
            'You cannot send email to deleted subscriber',
          ),
        )
        return
      }

      setSubscribers((state) => {
        const index = state.findIndex((el) => el.id === row.id)
        const newState = [...state]

        if (index > -1) {
          newState.splice(index, 1)
          return newState
        }
        return [...state, row]
      })
    })
  }

  function handleUnselectCallback(rows: Subscriber[]) {
    rows.forEach((row) => {
      setSubscribers((state) => [...state.filter((el) => el.id !== row.id)])
    })
  }

  const handleDeleteUsers = () => {
    dispatch(deleteUsers(subscribers.map((el) => el.id).join(','), updateTable))
  }

  const handleRowClick = (row: Subscriber) => {
    window.open(`/users/subscriber/${row.id}`, '_blank', 'noopener,noreferrer')
    return false
  }

  return (
    <PrivatePage>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography variant="semiBold" fontSize={24}>
          {t('subscriber.title.page')}
        </Typography>
      </Stack>

      <DataTable
        ref={tableRef}
        id="subscriber"
        className={classesTable.card}
        url="/users/clients"
        headerData={tableHeaderData}
        renderBody={TableBody}
        filters={tableFilter}
        search
        searchPlaceholder="Search by name"
        selectable
        selectNav={
          <>
            <IconButton
              onClick={() => {
                setIsDeleteUsers(true)
              }}
            >
              <SvgIcon
                name="delete"
                color={colorSecondaryDark}
                size={{ width: 24, height: 24 }}
              />
            </IconButton>
            <IconButton onClick={() => setNotifyMulti(true)}>
              <NotificationIcon />
            </IconButton>
            <IconButton onClick={() => setEmailMulti(true)}>
              <EmailIcon />
            </IconButton>
          </>
        }
        pagination
        emptyText="No subscribers with selected filters"
        onSelectCallback={handleSelectCallback}
        onUnselectCallback={handleUnselectCallback}
        //onRowClick={setSubscriberOpen}
        onRowClick={handleRowClick}
        limit={24}
      />

      {subscriberOpen && (
        <PopupSubscribers
          data={subscriberOpen}
          usage="all"
          tableRef={tableRef}
          initialStatus={subscriberOpen.status}
          onClose={() => setSubscriberOpen(null)}
        />
      )}

      {notifySingle && (
        <PopupNotify
          mode="notification"
          subscribers={[notifySingle]}
          onClose={() => setNotifySingle(null)}
        />
      )}
      {notifyMulti && (
        <PopupNotify
          mode="notification"
          subscribers={subscribers}
          onClose={() => setNotifyMulti(false)}
        />
      )}
      {emailSingle && (
        <PopupNotify
          mode="email"
          subscribers={[emailSingle]}
          onClose={() => setEmailSingle(null)}
        />
      )}
      {emailMulti && (
        <PopupNotify
          mode="email"
          subscribers={subscribers}
          onClose={() => setEmailMulti(false)}
        />
      )}
      {isDeleteUsers && (
        <PopupAsk
          onYes={handleDeleteUsers}
          onClose={() => setIsDeleteUsers(false)}
          header={'Are you sure that you want to delete this user?'}
        />
      )}
    </PrivatePage>
  )
}
