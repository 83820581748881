import React from 'react'
import Joi from 'joi'
import {t} from 'i18next'
import {
  Button, Dialog, DialogActions, DialogTitle, Divider, FormControl, FormLabel, Grid, OutlinedInput, Stack,
} from '@mui/material'
import {useDispatch} from '../../store'
import {InputDepartment} from '../../component/InputDepartment'
import {edit} from '../../store/user/actionCreator'
import {ErrorsState, latinOnly, preventCyrillicOnInput, select, validateForm} from '../../function/validation'
import {FormError} from '../../component/FormError'
import {Admin} from '../../store/user/reducer'
import {TableRef} from '../../component/datatable/types'

const schema = Joi.object({
  firstname: latinOnly.label(t('field.label.firstname')),
  lastname: latinOnly.label(t('field.label.lastname')),
  departmentId: select,
})

type Props = {
  data: Admin
  tableRef: React.RefObject<TableRef>
  onClose: () => void
}

export function PopupEditAdmin({data, tableRef, onClose}: Props) {
  const dispatch = useDispatch()

  const [firstname, setFirstname] = React.useState(data.firstname || '')
  const [lastname, setLastname] = React.useState(data.lastname || '')
  const [departmentId, setDepartmentId] = React.useState<number | string>(data.departmentId || 'null')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const requestData = {firstname, lastname, departmentId}

    const error = validateForm(schema, requestData)

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(edit(data.id, requestData, () => tableRef.current?.refresh()))
      onClose()
    }
  }

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle>{t('users.edit')}</DialogTitle>

      <Divider variant="popup" color="primary"/>

      <form onSubmit={handleSubmit} noValidate>
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={5}>
              <FormLabel>{t('field.label.firstname')}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <OutlinedInput
                  value={firstname}
                  placeholder={t('field.hint.firstname')}
                  autoFocus
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={e => preventCyrillicOnInput(e, setFirstname)}
                />
                <FormError error={errors?.firstname}/>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <FormLabel>{t('field.label.lastname')}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <OutlinedInput
                  value={lastname}
                  placeholder={t('field.hint.lastname')}
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={e => preventCyrillicOnInput(e, setLastname)}
                />
                <FormError error={errors?.lastname}/>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <FormLabel>{t('field.label.department')}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <InputDepartment value={departmentId} error={errors?.departmentId} onChange={setDepartmentId}/>
            </Grid>
          </Grid>
        </Stack>

        <Divider variant="popup" color="primary"/>

        <DialogActions>
          <Button onClick={onClose} color="primary" size="large">{t('common.close')}</Button>
          <Button type="submit" variant="contained" size="large" color="primary">{t('common.save')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}