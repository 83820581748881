import { NavigateFunction } from 'react-router-dom'
import { SettingsAction } from './actionType'
import { IUser } from './reducer'
import { E164Number } from '../../component/InputPhone'

export const setState = (key: string, value: any): SettingsAction => ({
  type: 'settings/SET_STATE',
  key,
  value,
})

export const flushState: SettingsAction = { type: 'settings/FLUSH_STATE' }

export const fetchProfile: SettingsAction = { type: 'settings/GET_PROFILE' }

export const setProfile = (data: IUser): SettingsAction => ({
  type: 'settings/SET_PROFILE',
  data,
})

export const uploadImage = (base64: string): SettingsAction => ({
  type: 'settings/UPLOAD_IMAGE',
  base64,
})

export const deleteImage: SettingsAction = { type: 'settings/DELETE_IMAGE' }

export const putData = (key: string, value: string): SettingsAction => ({
  type: 'settings/PUT_DATA',
  key,
  value,
})

export const sendEmailUpdateVerification = (
  newEmail: string,
  password: string,
  navigate: NavigateFunction,
): SettingsAction => ({
  type: 'settings/SEND_EMAIL_UPDATE_VERIFICATION',
  newEmail,
  password,
  navigate,
})

export const updatePassword = (
  oldPassword: string,
  password: string,
): SettingsAction => ({
  type: 'settings/UPDATE_PASSWORD',
  oldPassword,
  password,
})

export const updatePhone = (
  phone: E164Number,
  code: string,
  callback: () => void,
): SettingsAction => ({
  type: 'settings/UPDATE_PHONE',
  phone,
  code,
  callback,
})

export const getQr: SettingsAction = { type: 'settings/GET_QR' }

export const setQr = (qr: string | null): SettingsAction => ({
  type: 'settings/SET_QR',
  qr,
})

export const toggle2step = (
  code: string,
  is2FA: boolean,
  navigate: NavigateFunction,
): SettingsAction => ({
  type: 'settings/TOGGLE_2STEP',
  code,
  is2FA,
  navigate,
})

export const deleteUser = (navigate: NavigateFunction): SettingsAction => ({
  type: 'settings/DELETE_USER',
  navigate,
})

export const delete2FAUser = (
  code: string,
  navigate: NavigateFunction,
): SettingsAction => ({
  type: 'settings/DELETE_2FA_USER',
  code,
  navigate,
})
