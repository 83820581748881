import {t} from 'i18next'
import {differenceInSeconds} from 'date-fns'

export function useRenewal(endDate: string | Date, output: 'text' | 'number') {
  const secondsDiff = differenceInSeconds(new Date(endDate),  new Date())
  const days =  Math.ceil(secondsDiff / 60 / 60 / 24)

  if (days < 0) return '0'
  
  if (days === undefined) return '0'

  return output === 'text' ? `${days} ${t('time.days')}` : String(days)
}