import {Theme} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'

const navbarOpenWidth = 260
const navbarCloseWidth = 64

export const useAlertStyles = makeStyles({
  root: {boxShadow: 'none'},
  standardError: {
    background: '#FCD0CF',
  },
  standardSuccess: {
    backgroundColor: '#C5F2C7',
  },
})

export const useAvatarStyles = makeStyles(() => {
  const uploadDefault = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 70,
    height: 70,
    borderColor: '#D2D6EB',
    borderRadius: 0,
  }
  const uploadPseudoDefault = {
    position: 'absolute',
    content: `"+"`,
    right: 13,
    bottom: 13,
    width: 20,
    height: 20,
    lineHeight: '20px',
    color: '#EFF0F6',
    background: '#B9B9B9',
    borderRadius: '50%',
  }

  return createStyles({
    uploadBtn: {
      ...uploadDefault,
      background: '#EFF0F6',
      borderRadius: '50%',
      '&:hover': {
        '&::after': {
          ...uploadPseudoDefault,
          right: 13,
          bottom: 13,
        },
      },
    },
    uploadAvatar: {
      ...uploadDefault,
      '& img': {
        borderRadius: '50%',
      },
      '&:hover': {
        '&::after': {
          ...uploadPseudoDefault,
          right: 0,
          bottom: 0,
        },
      },
    },
  })
})

export const useMenuStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      whiteSpace: 'nowrap',
      '&.active': {
        '& .MuiDrawer-paper': {
          width: navbarOpenWidth,
        },
        '& .MuiListItemText-root': {
          padding: 0,
        },
      },
      '&.ltr': {
        '& .MuiListItem-root': {
          paddingLeft: 20,
        },
        '& .MuiListItemText-root': {
          paddingLeft: 12,
        },
      },
      '&.rtl': {
        '& .MuiListItem-root': {
          paddingRight: 19,
        },
        '& .MuiListItemText-root': {
          paddingRight: 10,
        },
      },
      '& .MuiListItemText-root': {
        transition: 'all 0.3s ease',
      },
      '& .MuiDivider-root': {
        margin: 10,
        background: 'rgba(46,151,213,0.5)',
      },
    },
    paper: {
      background: theme.palette.black.main,
      width: navbarCloseWidth,
      transition: 'ease 0.3s',
      overflowX: 'hidden',
    },
  })
})

export const useTriggerStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 21,
    color: '#949494',
    '&:hover': {
      color: '#FFF',
    },
    '&.ltr': {
      right: 13,
    },
    '&.rtl': {
      left: 9,
    },
  },
})

export const useListItemButtonStyles = makeStyles({
  root: {
    '& .MuiListItemIcon-root': {
      padding: 4,
      minWidth: 35,
    },
    '& .MuiTypography-root': {
      fontSize: 18,
      fontFamily: 'Montserrat-SemiBold',
      color: '#546b7e',
    },
    '&.rtl': {
      textAlign: 'right',
    },
    '&:hover': {
      background: 'rgba(72,187,255,0.15)',
      '& .MuiTypography-root': {
        color: '#FFF',
      },
    },
  },
  selected: {
    '& .MuiTypography-root': {
      color: '#FFF',
    },
    background: 'rgba(72,187,255,0.15) !important',
  },
})
