export const FETCH_RESULT_BY_ID = 'searcher/FETCH_RESULT_BY_ID'
export interface FetchResultBuId {
  type: typeof FETCH_RESULT_BY_ID
  id: string,
  callback: any
}

export const GET_PDF_DATA = 'searcher/GET_PDF_DATA'
export interface GetPDFData {
  type: typeof GET_PDF_DATA
  requestMonitorId: number
  apiKey: string
}

export const SET_PDF_DATA = 'searcher/SET_PDF_DATA'
export interface SetPDFData {
  type: typeof SET_PDF_DATA
  pdfData: any
}

export const GET_PDF = 'searcher/GET_PDF'
export interface GetPDF {
  type: typeof GET_PDF
  requestMonitorId: number
}

export const GET_LINK_PREVIEW = 'searcher/GET_LINK_PREVIEW'
export interface GetLinkPreview {
  type: typeof GET_LINK_PREVIEW
  link: string
  callback: (data: any) => void
}

export type SearcherAction =
  | FetchResultBuId
  | GetPDFData
  | SetPDFData
  | GetPDF
  | GetLinkPreview
