import * as React from 'react'
import Joi from 'joi'
import {Color} from 'react-color'
import {t} from 'i18next'
import {Close as CloseIcon} from '@mui/icons-material'
import {
  Button, Dialog, DialogActions, DialogTitle, Divider, FormControl, FormLabel, IconButton, OutlinedInput, Stack,
} from '@mui/material'
import {FormError} from '../../component/FormError'
import {ErrorsState, preventEmptySpaceOnInput, validateForm} from '../../function/validation'
import {InputColor} from '../package/InputColor'
import {genRandomHex} from '../../function/common'
import {TicketType} from '../../store/support/reducer'
import {capitalize} from '../../function/string'
import {postTicketType, putTicketType} from '../../store/support/actionCreator'
import {useDispatch} from '../../store'

const schema = Joi.object({
  name:  Joi.string().alphanum().required().max(15).label(t('field.label.name')),
  color: Joi.string().required(),
})

type Props = {
  mode: 'create' | 'edit'
  onSubmit: (name: string, color: Color) => void
  onClose: () => void
  data?: TicketType
}

export const PopupTicketTypeCRUD = ({mode, onSubmit, onClose, data}: Props) => {
  const dispatch = useDispatch()

  const [name, setName] = React.useState(capitalize(data?.name) || '')
  const [color, setColor] = React.useState<Color>(data?.color || genRandomHex(6))
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  function handleUpdateList() {
    onSubmit(name, color)
  }

  function handleSubmit() {
    const error = validateForm(schema, {name, color})

    if (error) setErrors(error)
    else {
      setErrors(null)

      if (mode === 'create') dispatch(postTicketType(name, color, handleUpdateList))
      else dispatch(putTicketType({id: data?.id as number, name, color}, handleUpdateList))

      onClose()
    }
  }

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle sx={{textAlign: 'left'}}>
        {mode === 'create' ? 'Create ticket type' : `Edit ticket type: ${data?.name}`}

        <IconButton onClick={onClose} className='button__close'><CloseIcon/></IconButton>
      </DialogTitle>

      <Stack spacing={2}>
        {/** Name */}
        <FormControl fullWidth>
          <FormLabel>{t('field.label.name')}</FormLabel>
          <OutlinedInput
            value={name}
            placeholder={t('field.hint.name')}
            onChange={e => preventEmptySpaceOnInput( e, setName)}
            autoFocus
          />
          <FormError error={errors?.name}/>
        </FormControl>
        {/** Color */}
        <FormControl>
          <InputColor color={color} onChange={color => setColor(color.hex)}/>
          <FormError error={errors?.color}/>
        </FormControl>
      </Stack>

      <Divider variant="popup" color="primary"/>

      <DialogActions sx={{justifyContent: 'right'}}>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
        <Button variant="contained" onClick={handleSubmit}>{t('common.save')}</Button>
      </DialogActions>
    </Dialog>
  )
}