import * as React from 'react'
import { Box, Card, CardContent, CardMedia, CircularProgress, IconButton, Link, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from '../../store'
import { getLinkPreview } from '../../store/searcher/actionCreator'
import emptyImage from '../../asset/icon/defaultUser.png'
import sprite_ from '../../sass/sprite.module.sass'

interface Props {
  link: string
  title: string
  style?: object,
  defaultIcon?: string,
}

type LinkData = {
  success: boolean
  url: string
  title?: string
  siteName?: string
  description?: string
  images?: string[]
  mediaType?: string
  contentType?: string
  videos?: string[],
  favicons?: string[],
}

export function LinkPreview({ link, title, style = {}, defaultIcon = '' }: Props) {

  const dispatch = useDispatch()

  //state
  const [loading, setLoading] = useState(false)
  const [previewData, setPreviewData] = useState<LinkData | null>(null)

  //effects
  useEffect(() => {
    setLoading(true)
    if (link) {
      link = link.replace('https://m.vk.com', 'https://vk.com')
    }
    dispatch(getLinkPreview(link, (ogData: any) => {
      //console.log('ogData', ogData)
      if (ogData.title && ogData.title?.indexOf('Log in') >= 0) {
        ogData.title = title
      }
      //if (ogData.success /*&& ogData.title !== ''*/) {
        setPreviewData(ogData)
      //}
      setLoading(false)
    }))
  }, [])

  return (
    <>
      {
        loading
          ? <CircularProgress size={20} />
          : (
            previewData
              ? <Stack sx={{ display: 'flex', flexDirection: 'row', borderRadius: '4px', position: 'relative', width: '100%', border: '1px solid #d3d3d38a', ...style  }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', margin: '0 5px', flex: "1", overflow: 'hidden'}}>
                  <Typography variant="bold" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Link target="_blank" href={previewData.url || link}>{previewData.title || title}</Link>
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontSize: '10px', lineHeight: '1.5', maxHeight: '50px', overflow: 'hidden'}}>{previewData.description}</Typography>
                </Box>
                {
                  (previewData?.images && previewData?.images?.length > 0)
                    ? <CardMedia
                      component="img"
                      sx={{ width: 75, height: 75, flex: '0 0 75px' }}
                      image={previewData.images[0]}
                      alt={previewData.title}
                    />
                    : <CardMedia
                      component="img"
                      sx={{ width: 75, height: 75, flex: '0 0 75px' }}
                      image={emptyImage}
                      alt={previewData.title}
                    />
                }
                {
                  (previewData && previewData?.favicons && previewData?.favicons?.length > 0)
                  ? <img alt={''} src={ previewData?.favicons[0]} style={{width: '20px', height: '20px', position: 'absolute', right: '5px', top: '5px'}} />
                  : <Box className={sprite_[`social__small_${defaultIcon}`]} sx={{width: '20px', height: '20px', position: 'absolute', right: '5px', top: '5px'}} />
                }
              </Stack>
              : <Link target="_blank" href={link}>
                <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{title}</Typography>
              </Link>
          )
      }
    </>
  )
}
