export interface ServiceData {
  name: string
  count: number
}

export const CHECK_PACKAGE_DATA = 'package/CHECK_PACKAGE_DATA'
export interface CheckPackageData {
  type: typeof CHECK_PACKAGE_DATA
  productId: number
  uid: string
  apiRequestsLimits : number
  apiRequestsInterval : number
  onSuccess: () => void
}

export const POST_PACKAGE = 'package/POST_PACKAGE'
export interface PostPackage {
  type: typeof POST_PACKAGE
  data: object
  callback: () => void
}

export const GET_PACKAGE = 'package/GET_PACKAGE'
export interface GetPackage {
  type: typeof GET_PACKAGE
}

export const SET_PACKAGE = 'package/SET_PACKAGE'
export interface SetPackage {
  type: typeof SET_PACKAGE
  data: object[]
}

export const DELETE_PACKAGE = 'package/DELETE_PACKAGE'
export interface DeletePackage {
  type: typeof DELETE_PACKAGE
  id: number
  callback: () => void
}

export const PUT_PACKAGE = 'package/PUT_PACKAGE'
export interface PutPackage {
  type: typeof PUT_PACKAGE
  data: object
  id: number
  callback: () => void
}

export const POST_WORKFLOW = 'package/POST_WORKFLOW'
export interface PostWorkflow {
  type: typeof POST_WORKFLOW
  data: object
  callback: () => void
}

export const PUT_WORKFLOW = 'package/PUT_WORKFLOW'
export interface PutWorkflow {
  type: typeof PUT_WORKFLOW
  data: object
  id: number
  callback: () => void
}

export const DELETE_WORKFLOW = 'package/DELETE_WORKFLOW'
export interface DeleteWorkflow {
  type: typeof DELETE_WORKFLOW
  id: number
  callback: () => void
}

export const GET_PACKAGE_RULES = 'package/GET_PACKAGE_RULES'
export interface GetPackageRules {
  type: typeof GET_PACKAGE_RULES
}
export const SET_PACKAGE_RULES = 'package/SET_PACKAGE_RULES'
export interface SetPackageRules {
  type: typeof SET_PACKAGE_RULES
  data: object[]
}

export type PackageAction =
  | CheckPackageData
  | PostPackage
  | GetPackage
  | SetPackage
  | PutPackage
  | DeletePackage
  | PostWorkflow
  | PutWorkflow
  | DeleteWorkflow
  | GetPackageRules
  | SetPackageRules