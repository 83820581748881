import {combineReducers, createStore, applyMiddleware} from 'redux'
import {useDispatch as _useDispatch, useSelector as _useSelector, TypedUseSelectorHook} from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import {all} from 'redux-saga/effects'
import {CommonState, commonReducer} from './common/reducer'
import {AuthState, authReducer} from './auth/reducer'
import {DashboardState, dashboardReducer} from './dashboard/reducer'
import {MarketingState, marketingReducer} from './marketing/reducer'
import {PackageState, packageReducer} from './package/reducer'
import {UserState, userReducer} from './user/reducer'
import {ApikeyState, apikeyReducer} from './apikey/reducer'
import {SupportState, supportReducer} from './support/reducer'
import {SettingsState, settingsReducer} from './settings/reducer'
import { searcherReducer } from './searcher/reducer'
import {watchCommon} from './common/saga'
import {watchAuth} from './auth/saga'
import {watchDashboard} from './dashboard/saga'
import {watchMarketing} from './marketing/saga'
import {watchPackage} from './package/saga'
import {watchUser} from './user/saga'
import {watchApikey} from './apikey/saga'
import {watchSupport} from './support/saga'
import {watchSettings} from './settings/saga'
import { watchSearcher } from './searcher/saga'
import { BlackListPhonesState, blacklistReducer, BlackListState, blacklistPhonesReducer, BlackListIpAddressState, blacklistIpAddressReducer } from './blacklist/reducer'
import { watchBlackList } from './blacklist/saga'

// type Action =
//   | CommonAction
//   | AuthAction
//   | DashboardAction
//   | MarketingAction
//   | PackageAction
//   | UserAction
//   | SettingsAction

type Dispatch = typeof store.dispatch

export type State = {
  auth: AuthState
  common: CommonState
  dashboard: DashboardState
  marketing: MarketingState
  package: PackageState
  user: UserState
  apikey: ApikeyState
  support: SupportState
  settings: SettingsState
  blackList : BlackListState
  blackListPhones : BlackListPhonesState
  blackListIpAddress : BlackListIpAddressState
}

const reducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  dashboard: dashboardReducer,
  marketing: marketingReducer,
  package: packageReducer,
  user: userReducer,
  apikey: apikeyReducer,
  support: supportReducer,
  settings: settingsReducer,
  searcher: searcherReducer,
  blackList: blacklistReducer,
  blackListPhones : blacklistPhonesReducer,
  blackListIpAddress: blacklistIpAddressReducer,
})

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
  reducer,
  applyMiddleware(sagaMiddleware),
)

export const useSelector: TypedUseSelectorHook<State> = _useSelector

export const useDispatch = () => _useDispatch<Dispatch>()

function* rootSaga() {
  yield all([
    watchAuth(),
    watchCommon(),
    watchDashboard(),
    watchMarketing(),
    watchPackage(),
    watchUser(),
    watchApikey(),
    watchSupport(),
    watchSettings(),
    watchSearcher(),
    watchBlackList(),
  ])
}

sagaMiddleware.run(rootSaga)
