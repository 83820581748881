import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Button, Divider, Grid, Hidden, Stack, Typography } from '@mui/material'
import { useButtonStyles } from '../../style/button'
import { SvgIcon } from '../../component/SvgIcon'
import { colorPrimary, colorSecondary } from '../../mui'
import { useDispatch, useSelector } from '../../store'
import { getRefreshedToken } from '../../store/auth/selector'
import { Usage } from '../../store/dashboard/reducer'
import { SubscriberType } from '../../store/marketing/reducer'
import {
  changeTime,
  changeUsage,
  getUsage,
} from '../../store/dashboard/actionCreator'
import { Profile } from '../common/Profile'
import { Notification } from '../common/Notification'
import { DashboardPDF } from './PDFDashboard'
import { formatDate } from '../../function/date'
import { HeaderRef, Time } from '../../page/DashboardPage'
import { Chart } from 'chart.js'
import { api } from '../../function/axios'
import { PopupAsk } from '../../component/PopupAsk'

const stylesButtonGroup = {
  height: 40,
  border: '1px solid #838383',
  borderRadius: '5px',
}
const stylesDivider = { height: 26, backgroundColor: '#838383' }
const stylesButton = { minWidth: 40, borderColor: '#838383' }

interface Props {
  usage: Usage
  lookupChartRef: React.MutableRefObject<Chart | null>
}

export const HeaderDashboard = React.forwardRef<HeaderRef, Props>(
  ({ usage, lookupChartRef }, ref) => {
    const { t } = useTranslation()
    const buttonClasses = useButtonStyles()
    const dispatch = useDispatch()
    const refreshedToken = useSelector(getRefreshedToken)
    const {
      subscribersOffset: offset,
      subscribersSort: sort,
      dashboard,
    } = useSelector((state) => state.dashboard)
    const user = useSelector((state) => state.settings)
    const time = useSelector((state) => state.dashboard.time)
    const [subscriber, setSubscriber] = React.useState<SubscriberType>('all')
    const handleRequest = React.useCallback(() => {
      dispatch(
        getUsage(
          `/admin-dashboard?usage=${usage}&subscribers=${subscriber}&time=${time}&offset=${offset}&limit=7${sort}`,
        ),
      )
    }, [dispatch, offset, sort, subscriber, time, usage])

    React.useEffect(() => {
      if (refreshedToken) handleRequest()
    }, [refreshedToken, handleRequest])

    React.useImperativeHandle(ref, () => ({
      refreshRequest() {
        handleRequest()
      },
    }))

    return (
      <>
        {/** Uncomment for Dashboard PDF development */}
        {/*<PDFViewer width="1000" height="600" showToolbar={false}>
        <DashboardPDF
          usage={usage}
          subscriber={subscriber}
          time={time}
          avatar={user.avatar}
          lookupChartRef={lookupChartRef}
          dashboard={dashboard}
        />
      </PDFViewer>*/}

        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          p={2}
          bgcolor="#FFF"
        >
          <Grid item>
            <Stack direction="row" alignItems="center" sx={stylesButtonGroup}>
              <Button
                className={buttonClasses.header}
                onClick={() => dispatch(changeUsage('all'))}
              >
                {usage === 'all' ? (
                  <Typography variant="black" color="primary" ml={1}>
                    {t('header.allUsage')}
                  </Typography>
                ) : (
                  <Typography>{t('header.allUsage')}</Typography>
                )}
              </Button>
              <Divider orientation="vertical" sx={stylesDivider} />
              <Button
                className={buttonClasses.header}
                onClick={() => dispatch(changeUsage('web'))}
              >
                <SvgIcon
                  name="web"
                  color={usage === 'web' ? colorPrimary : '#000'}
                  size={{ width: 20, height: 20 }}
                />
                <Hidden xlDown>
                  {usage === 'web' ? (
                    <Typography variant="black" color="primary" ml={1}>
                      {t('header.webUsage')}
                    </Typography>
                  ) : (
                    <Typography ml={1}>{t('header.webUsage')}</Typography>
                  )}
                </Hidden>
              </Button>
              <Divider orientation="vertical" sx={stylesDivider} />
              <Button
                className={buttonClasses.header}
                onClick={() => dispatch(changeUsage('api'))}
              >
                <SvgIcon
                  name="api"
                  color={usage === 'api' ? colorPrimary : '#000'}
                  size={{ width: 20, height: 20 }}
                />
                <Hidden xlDown>
                  {usage === 'api' ? (
                    <Typography variant="black" color="primary" ml={1}>
                      {t('header.apiUsage')}
                    </Typography>
                  ) : (
                    <Typography ml={1}>{t('header.apiUsage')}</Typography>
                  )}
                </Hidden>
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" sx={stylesButtonGroup}>
              <Button
                className={buttonClasses.header}
                onClick={() => setSubscriber('all')}
              >
                {subscriber === 'all' ? (
                  <Typography variant="black" color="primary" ml={1}>
                    <Hidden xlDown>{t('header.allSubscribers')}</Hidden>
                    <Hidden xlUp>{t('common.all')}</Hidden>
                  </Typography>
                ) : (
                  <Typography>
                    <Hidden xlDown>{t('header.allSubscribers')}</Hidden>
                    <Hidden xlUp>{t('common.all')}</Hidden>
                  </Typography>
                )}
              </Button>
              <Divider orientation="vertical" sx={stylesDivider} />
              <Button
                className={buttonClasses.header}
                onClick={() => setSubscriber('gold')}
              >
                {subscriber === 'gold' ? (
                  <Typography variant="black" color="primary" ml={1}>
                    {t('subscriber.type.gold')}{' '}
                    <Hidden xlDown>{t('subscriber.type.title')}</Hidden>
                  </Typography>
                ) : (
                  <Typography>
                    {t('subscriber.type.gold')}{' '}
                    <Hidden xlDown>{t('subscriber.type.title')}</Hidden>
                  </Typography>
                )}
              </Button>
              <Divider orientation="vertical" sx={stylesDivider} />
              <Button
                className={buttonClasses.header}
                onClick={() => setSubscriber('silver')}
              >
                {subscriber === 'silver' ? (
                  <Typography variant="black" color="primary" ml={1}>
                    {t('subscriber.type.silver')}{' '}
                    <Hidden xlDown>{t('subscriber.type.title')}</Hidden>
                  </Typography>
                ) : (
                  <Typography>
                    {t('subscriber.type.silver')}{' '}
                    <Hidden xlDown>{t('subscriber.type.title')}</Hidden>
                  </Typography>
                )}
              </Button>
              <Divider orientation="vertical" sx={stylesDivider} />
              <Button
                className={buttonClasses.header}
                onClick={() => setSubscriber('bronze')}
              >
                {subscriber === 'bronze' ? (
                  <Typography variant="black" color="primary" ml={1}>
                    {t('subscriber.type.bronze')}{' '}
                    <Hidden xlDown>{t('subscriber.type.title')}</Hidden>
                  </Typography>
                ) : (
                  <Typography>
                    {t('subscriber.type.bronze')}{' '}
                    <Hidden xlDown>{t('subscriber.type.title')}</Hidden>
                  </Typography>
                )}
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" sx={stylesButtonGroup}>
              <Button
                className={buttonClasses.header}
                onClick={() => dispatch(changeTime('year'))}
              >
                {time === 'year' ? (
                  <Typography variant="black" color="primary">
                    1 {t('time.year')}
                  </Typography>
                ) : (
                  <Typography>1 {t('time.year')}</Typography>
                )}
              </Button>
              <Divider orientation="vertical" sx={stylesDivider} />
              <Button
                className={buttonClasses.header}
                onClick={() => dispatch(changeTime('month'))}
              >
                {time === 'month' ? (
                  <Typography variant="black" color="primary">
                    1 {t('time.month')}
                  </Typography>
                ) : (
                  <Typography>1 {t('time.month')}</Typography>
                )}
              </Button>
              <Divider orientation="vertical" sx={stylesDivider} />
              <Button
                className={buttonClasses.header}
                onClick={() => dispatch(changeTime('week'))}
              >
                {time === 'week' ? (
                  <Typography variant="black" color="primary">
                    1 {t('time.week')}
                  </Typography>
                ) : (
                  <Typography>1 {t('time.week')}</Typography>
                )}
              </Button>
              <Divider orientation="vertical" sx={stylesDivider} />
              <Button
                className={buttonClasses.header}
                onClick={() => dispatch(changeTime('day'))}
              >
                {time === 'day' ? (
                  <Typography variant="black" color="primary">
                    1 {t('time.day')}
                  </Typography>
                ) : (
                  <Typography>1 {t('time.day')}</Typography>
                )}
              </Button>
            </Stack>
          </Grid>
          {/* <Grid item>
            {dashboard ? (
              <PDFDownloadLink
                document={
                  <DashboardPDF
                    usage={usage}
                    subscriber={subscriber}
                    time={time}
                    avatar={user.avatar}
                    lookupChartRef={lookupChartRef}
                    dashboard={dashboard}
                  />
                }
                fileName={`usage-${formatDate(
                  new Date(),
                  'dd.MM.yyyy-HH:mm',
                )}.pdf`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  className={buttonClasses.header}
                  variant="outlined"
                  color="secondary"
                  sx={stylesButton}
                >
                  <SvgIcon
                    name="download"
                    color={colorPrimary}
                    size={{ width: 25, height: 25 }}
                  />
                </Button>
              </PDFDownloadLink>
            ) : (
              <Button
                className={buttonClasses.header}
                variant="outlined"
                color="secondary"
                sx={stylesButton}
                disabled
              >
                <SvgIcon
                  name="download"
                  color={colorSecondary}
                  size={{ width: 25, height: 25 }}
                />
              </Button>
            )}
          </Grid> */}
          <Grid item style={{ marginLeft: 'auto' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Notification />
              <Profile />
            </Stack>
          </Grid>
        </Grid>
      </>
    )
  },
)
