import React from 'react'
import {Snackbar as MuiSnackbar, Alert} from '@mui/material'
import {Check as CheckIcon, ErrorOutline as ErrorIcon} from '@mui/icons-material'
import {useDispatch, useSelector} from '../store'
import {hideSnackbar} from '../store/common/actionCreator'
import {useAlertStyles} from '../style/common'
import {getSnackbar} from '../store/common/selector'

export function Snackbar() {
  const data = useSelector(getSnackbar)
  const dispatch = useDispatch()
  const alertClasses = useAlertStyles()

  const handleClose = (event?: any, reason?: string) => {
    if (reason === 'clickaway') return
    dispatch(hideSnackbar)
  }

  const isSuccess = data.severity === 'success'
  const icon = isSuccess ? <CheckIcon/> : <ErrorIcon/>

  return (
    <MuiSnackbar
      open={data.isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    >
      <Alert
        severity={data.severity}
        color={data.severity}
        classes={alertClasses}
        icon={icon}
        elevation={6}
        variant="standard"
        onClose={handleClose}
      >
        <span style={{textTransform: 'capitalize'}}>{data.severity}! </span>
        {data.message}
      </Alert>
    </MuiSnackbar>
  )
}
