import * as React from 'react'
import {t} from 'i18next'
import {OutlinedInput, Stack, TextareaAutosize} from '@mui/material'
import {IFeature} from '../../store/package/reducer'
import {TypeFormError} from '../../function/validation'
import {FormError} from '../FormError'

const infoLimit = 100

interface FeatureProps {
  index: number
  feature: IFeature
  onChange: (index: number, key: 'title' | 'subTitle' | 'info', value: string) => void
  error: TypeFormError
}

export function Feature({index, feature, onChange, error}: FeatureProps) {
  function handleTextareaChange(value: string) {
    if (value.length < infoLimit) {
      onChange(index, 'info', value)
    }
  }

  function handleTextareaPast(e: React.ClipboardEvent) {
    const value = e.clipboardData.getData('Text')
    onChange(index, 'info', value.slice(0, infoLimit))
  }

  return (
    <Stack spacing={1}>
      <OutlinedInput
        value={feature.title}
        placeholder={t('field.hint.title')}
        // inputProps={{
        //   maxLength: 22,
        // }}
        onChange={e => onChange(index, 'title', e.target.value)}
        onBlur={e => onChange(index, 'title', e.target.value.trim())}
      />
      <OutlinedInput
        value={feature.subTitle}
        placeholder={t('field.hint.subtitle')}
        // inputProps={{
        //   maxLength: 30,
        // }}
        onChange={e => onChange(index, 'subTitle', e.target.value)}
        onBlur={e => onChange(index, 'subTitle', e.target.value.trim())}

      />
      <TextareaAutosize
        className="form__textarea"
        minRows={2}
        placeholder={t('field.hint.info')}
        value={feature.info}
        onChange={e => handleTextareaChange(e.target.value)}
        onPaste={handleTextareaPast}
        onBlur={e => onChange(index, 'info', e.target.value.trim())}
      />

      <FormError error={error}/>
    </Stack>
  )
}
