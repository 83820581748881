import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material"
import { useDispatch, useSelector } from "../../store"
import { PopupChangePassword } from "./PopupChangePassword"
import { PopupChangePhone } from "./PopupChangePhone"
import { InputPassword } from "../../component/InputPassword"
import { PopupChangeEmail } from "./PopupChangeEmail"
import { putData } from "../../store/settings/actionCreator"
import { showSnackbar } from "../../store/common/actionCreator"
import { preventCyrillicOnInput } from "../../function/validation"

export const FormPersonalInfo = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.settings)

  React.useEffect(() => {
    setFirstname(user.firstname)
    setLastname(user.lastname)
    setEmail(user.email)
    setPhone(user.phone)
    setPassword(user.password)
  }, [user])

  const [firstname, setFirstname] = React.useState(user.firstname)
  const [lastname, setLastname] = React.useState(user.lastname)
  const [email, setEmail] = React.useState(user.email)
  const [phone, setPhone] = React.useState(user.phone)
  const [password, setPassword] = React.useState("")

  const [changePasswordOpen, setChangePasswordOpen] = React.useState(false)
  const [changePhoneOpen, setChangePhoneOpen] = React.useState(false)
  const [changeEmailOpen, setChangeEmailOpen] = React.useState(false)

  const handleClickChangeEmail = () => {
    if (user.is2FAEnabled)
      dispatch(showSnackbar("error", t("settings.turnOff2FaBeforeChangeEmail")))
    else setChangeEmailOpen(true)
  }

  return (
    <>
      <Typography variant="semiBold" fontSize={18}>
        {t("settings.personalInformation")}
      </Typography>

      <Stack spacing={2} mt={3}>
        {/** Firstname */}
        <FormControl>
          <FormLabel>{t("field.label.firstname")}</FormLabel>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <OutlinedInput
                value={firstname}
                placeholder="Michael"
                inputProps={{
                  maxLength: 50,
                }}
                fullWidth
                onChange={(e) => preventCyrillicOnInput(e, setFirstname)}
              />
            </Grid>
            {user.firstname !== firstname && (
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch(putData("firstname", firstname))}
                >
                  {t("common.save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </FormControl>
        {/** Lastname */}
        <FormControl>
          <FormLabel>{t("field.label.lastname")}</FormLabel>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <OutlinedInput
                value={lastname}
                placeholder="Jones"
                inputProps={{
                  maxLength: 50,
                }}
                fullWidth
                onChange={(e) => preventCyrillicOnInput(e, setLastname)}
              />
            </Grid>
            {user.lastname !== lastname && (
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  onClick={() => dispatch(putData("lastname", lastname))}
                >
                  {t("common.save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </FormControl>
        {/** Change email */}
        <FormControl>
          <FormLabel>{t("field.label.email")}</FormLabel>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <OutlinedInput
                value={email}
                placeholder="Lucy_Kirlin80@gmail.com"
                readOnly
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button color="primary" onClick={handleClickChangeEmail}>
                {t("settings.changeEmail")}
              </Button>
              {changeEmailOpen && (
                <PopupChangeEmail onClose={() => setChangeEmailOpen(false)} />
              )}
            </Grid>
          </Grid>
        </FormControl>
        {/** Change password */}
        <FormControl>
          <FormLabel>{t("field.label.password")}</FormLabel>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputPassword
                value={password}
                onInput={setPassword}
                placeholder={t("field.hint.password")}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                color="primary"
                onClick={() => setChangePasswordOpen(true)}
              >
                {t("settings.changePassword")}
              </Button>
              {changePasswordOpen && (
                <PopupChangePassword
                  onClose={() => setChangePasswordOpen(false)}
                />
              )}
            </Grid>
          </Grid>
        </FormControl>
        {/** Change phone number */}
        <FormControl>
          <FormLabel>{t("field.label.phone")}</FormLabel>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <OutlinedInput
                placeholder="+890000000000"
                value={phone}
                readOnly
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button color="primary" onClick={() => setChangePhoneOpen(true)}>
                {t("settings.changePhoneNumber")}
              </Button>
              {changePhoneOpen && (
                <PopupChangePhone onClose={() => setChangePhoneOpen(false)} />
              )}
            </Grid>
          </Grid>
        </FormControl>
      </Stack>
    </>
  )
}
