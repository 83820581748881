import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import 'react-phone-number-input/style.css'
import NumberFormat from 'react-number-format'
import { Replay as AgainIcon } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Stack,
} from '@mui/material'
import { useDispatch, useSelector } from '../../store'
import { updatePhone } from '../../store/settings/actionCreator'
import { E164Number, InputPhone } from '../../component/InputPhone'
import { getPhoneCode, setPhoneVerify } from '../../store/auth/actionCreator'
import {
  ErrorsState,
  notRequired,
  phone,
  validateForm,
} from '../../function/validation'
import { FormError } from '../../component/FormError'
import { clearFieldError } from '../../store/common/actionCreator'

const schema = Joi.object({
  phone: phone.label(t('field.label.phone')),
  code: notRequired,
})

type Props = {
  onClose: () => void
}

export const PopupChangePhone = ({ onClose }: Props) => {
  const dispatch = useDispatch()

  const { phoneVerify } = useSelector((state) => state.auth)

  const [phone, setPhone] = React.useState<E164Number | undefined>('')
  const [code, setCode] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const fieldErrors = useSelector((state) => state.common.fieldErrors)

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, phone, code])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const error = validateForm(schema, { phone, code })

    if (error) setErrors(error)
    else {
      setErrors(null)

      if (phoneVerify && phone) {
        dispatch(updatePhone(String(phone), code, () => handleClose()))
      } else {
        dispatch(getPhoneCode(phone as E164Number))
      }
    }
  }

  const handleClose = () => {
    onClose()
    dispatch(setPhoneVerify(false))
  }

  return (
    <Dialog open={true} fullWidth onClose={handleClose}>
      <DialogTitle>{t('settings.changePhoneNumber')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <form onSubmit={handleSubmit} noValidate>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <Grid container>
              <Grid item xs={5}>
                <FormLabel required>{t('field.label.newPhone')}</FormLabel>
              </Grid>
              <Grid item xs={7}>
                <InputPhone
                  value={phone}
                  error={errors?.phone || fieldErrors?.phone}
                  onChange={setPhone}
                />
                <FormError error={errors?.phone || fieldErrors?.phone} />
              </Grid>
            </Grid>
          </FormControl>
          {phoneVerify && (
            <FormControl className="form__phone">
              <Grid container>
                <Grid item xs={5}>
                  <FormLabel required>
                    {t('field.label.verificationCode')}
                  </FormLabel>
                </Grid>
                <Grid item xs={7}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <FormControl className="form__phone">
                      <NumberFormat
                        value={code}
                        onValueChange={(values) => setCode(values.value)}
                        format="### ###"
                        mask="_"
                        required
                        autoFocus
                        style={{
                          textAlign: 'center',
                          borderColor: fieldErrors?.phoneCode ? 'red' : '',
                        }}
                      />
                    </FormControl>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      startIcon={<AgainIcon />}
                      onClick={() =>
                        dispatch(getPhoneCode(phone as E164Number))
                      }
                    >
                      {t('auth.register.sendAgain')}
                    </Button>
                  </Stack>
                  <FormError
                    error={errors?.phoneCode || fieldErrors?.phoneCode}
                  />
                </Grid>
              </Grid>
            </FormControl>
          )}
        </Stack>

        <Divider variant="popup" color="primary" />

        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleClose}
          >
            {t('common.close')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
