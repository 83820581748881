import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Avatar, IconButton, Menu, MenuItem} from '@mui/material'
import {useDispatch, useSelector} from '../../store'
import {signOut} from '../../store/auth/actionCreator'
import {SvgIcon} from '../../component/SvgIcon'

export function Profile() {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector(state => state.settings)

  const imgUrl = user.avatar
  let fullName = ''

  if (user.avatar === null) {
    if (user.firstname && user.lastname) {
      fullName = `${user.firstname} ${user.lastname}`
    }
  }

  const [anchorAvatar, setAnchorAvatar] = React.useState<null | HTMLElement>(null)

  const handleSettingsClick = () => {
    setAnchorAvatar(null)
    navigate('/settings')
  }

  const handleSignOutClick = () => {
    setAnchorAvatar(null)
    dispatch(signOut(navigate))
  }

  return (
    <>
      <IconButton onClick={e => setAnchorAvatar(e.currentTarget)} sx={{padding: 0, width: 40, height: 40}}>
        {imgUrl
          ? <Avatar src={imgUrl} alt={fullName} sx={{width: 30, height: 30}}/>
          : <SvgIcon name="profile" size={{width: 30, height: 30}}/>}
      </IconButton>

      <Menu
        anchorEl={anchorAvatar}
        open={Boolean(anchorAvatar)}
        onClose={() => setAnchorAvatar(null)}
        keepMounted
      >
        <MenuItem onClick={handleSettingsClick}>{t('header.editProfile')}</MenuItem>
        <MenuItem onClick={handleSignOutClick}>{t('header.logout')}</MenuItem>
      </Menu>
    </>
  )
}