import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Home as HomeIcon,
  Wallet as PaymentIcon,
  Activity as MarketIcon,
  Bag2 as PackagesIcon,
  People as UserManagementIcon,
  Password as ApikeyIcon,
  InfoSquare as SupportIcon,
  Setting as SettingIcon,
  ChevronUpCircle as UpIcon,
  ChevronDownCircle as DownIcon,
} from 'react-iconly'
import {
  MenuOpen as MenuIcon,
  Circle as CircleIcon,
  ManageAccounts as UserIcon,
  Person as ClientIcon,
} from '@mui/icons-material'
import {
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {
  useMenuStyles,
  useListItemButtonStyles,
  useTriggerStyles,
} from '../../style/common'
import { useSelector } from '../../store'
import { getIsRtl } from '../../store/common/selector'
import { colorSecondary } from '../../mui'

type Props = {
  navbarOpen: boolean
  setNavbarOpen: (status: boolean) => void
}

interface ChildrenMenuItem {
  label: string
  url: string
  icon: JSX.Element
  iconActive: JSX.Element
}

interface MenuItem {
  label: string
  icon: JSX.Element
  iconActive: JSX.Element
  url: string
  children?: ChildrenMenuItem[]
}

export function Menu({ navbarOpen, setNavbarOpen }: Props) {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const menuClasses = useMenuStyles()
  const listItemButtonClasses = useListItemButtonStyles()
  const triggerClasses = useTriggerStyles()

  const isRtl = useSelector(getIsRtl)

  const data1: MenuItem[] = [
    {
      label: t('menu.dashboard'),
      icon: <HomeIcon set="bold" primaryColor="dimgrey" size={25} />,
      iconActive: <HomeIcon set="bold" primaryColor="white" size={25} />,
      url: '/',
    },
    {
      label: t('menu.payments'),
      icon: (
        <PaymentIcon
          size={25}
          set="bulk"
          primaryColor="dimgrey"
          secondaryColor="lightgrey"
        />
      ),
      iconActive: <PaymentIcon size={25} set="bold" primaryColor="white" />,
      url: '/payment',
    },
    {
      label: t('menu.marketing'),
      icon: (
        <MarketIcon
          size={25}
          set="bulk"
          primaryColor="dimgrey"
          secondaryColor="lightgrey"
        />
      ),
      iconActive: <MarketIcon size={25} set="bold" primaryColor="white" />,
      url: '/marketing',
    },
    {
      label: t('menu.packages'),
      icon: (
        <PackagesIcon
          size={25}
          set="bulk"
          primaryColor="lightgrey"
          secondaryColor="dimgrey"
        />
      ),
      iconActive: <PackagesIcon size={25} set="bold" primaryColor="white" />,
      url: '/package',
    },
    {
      label: t('menu.users'),
      icon: (
        <UserManagementIcon
          set="bulk"
          primaryColor="dimgrey"
          secondaryColor="lightgrey"
        />
      ),
      iconActive: <UserManagementIcon set="bold" primaryColor="white" />,
      url: '/users',
      children: [
        {
          label: t('menu.subscribers'),
          url: '/subscribers',
          icon: <ClientIcon color="secondary" />,
          iconActive: <ClientIcon color="white" />,
        },
        {
          label: t('menu.users'),
          url: '/admins',
          icon: <UserIcon color="secondary" />,
          iconActive: <UserIcon color="white" />,
        },
      ],
    },
  ]
  const data2: MenuItem[] = [
    {
      label: t('menu.apikey'),
      icon: (
        <ApikeyIcon
          size={25}
          set="bulk"
          primaryColor="lightgrey"
          secondaryColor="dimgrey"
        />
      ),
      iconActive: <ApikeyIcon size={25} set="bold" primaryColor="white" />,
      url: '/apikey',
    },
    {
      label: t('menu.support'),
      icon: (
        <SupportIcon
          set="bulk"
          size={25}
          primaryColor="lightgrey"
          secondaryColor="dimgrey"
        />
      ),
      iconActive: <SupportIcon set="bold" size={25} primaryColor="white" />,
      url: '/support',
    },
    {
      label: t('menu.settings'),
      icon: <SettingIcon set="bold" primaryColor="dimgrey" size={25} />,
      iconActive: <SettingIcon set="bold" primaryColor="white" size={25} />,
      url: '/settings',
    },
  ]

  const initMenu = React.useMemo(() => {
    return '/' + location.pathname.split('/')[1]
  }, [location.pathname])
  const initSubmenu = React.useMemo(() => {
    const urlPart = location.pathname.split('/')[2]
    const url = urlPart ? '/' + urlPart : undefined
    return { open: Boolean(url), url: url }
  }, [location.pathname])

  const [menu, setMenu] = React.useState(initMenu)
  const [submenu, setSubmenu] = React.useState(initSubmenu)

  let navbarClassName = navbarOpen ? 'active' : ''
  navbarClassName = `${navbarClassName} ${isRtl ? 'rtl' : 'ltr'}`
  const logoClassName = navbarOpen
    ? 'menuHeader__logo'
    : 'menuHeader__logo_inactive'
  const rtlClassName = isRtl ? 'rtl' : 'ltr'

  const handleMenuClick = (
    e: React.MouseEvent<HTMLDivElement>,
    el: MenuItem,
  ) => {
    e.stopPropagation()

    setMenu(el.url)

    if (el.children === undefined) {
      navigate(el.url)
      setSubmenu(initSubmenu)
    } else {
      setSubmenu((state) => ({ ...state, open: !state.open }))
    }
  }

  const handleSubmenuClick = (menuUrl: string, submenuUrl: string) => {
    navigate(menuUrl + submenuUrl)
    setSubmenu({ open: true, url: submenuUrl })
  }

  React.useEffect(() => {
    //console.log(menu)
    //console.log(submenu)
  }, [menu, submenu])
  return (
    <Drawer
      classes={menuClasses}
      className={navbarClassName}
      anchor={isRtl ? 'right' : 'left'}
      onClose={() => setNavbarOpen(false)}
      variant="permanent"
    >
      <Grid
        className="menuHeader"
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <div onClick={() => navigate('/')} className={logoClassName} />
        </Grid>
        <Grid item>
          <IconButton
            className={rtlClassName}
            classes={triggerClasses}
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <MenuIcon style={{ transform: navbarOpen ? '' : 'scaleX(-1)' }} />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <List>
        {data1.map((el) => (
          <div key={el.url}>
            <ListItemButton
              className={rtlClassName}
              classes={listItemButtonClasses}
              onClick={(event) => handleMenuClick(event, el)}
              selected={
                (menu === el.url && !submenu.open) ||
                (submenu.url === undefined && menu === el.url)
              }
            >
              <ListItemIcon>
                {menu === el.url ? el.iconActive : el.icon}
              </ListItemIcon>
              <ListItemText primary={el.label} />
              {el.children &&
                (submenu.open ? (
                  <UpIcon
                    set="bulk"
                    primaryColor="lightgrey"
                    secondaryColor="dimgrey"
                  />
                ) : (
                  <DownIcon
                    set="bulk"
                    primaryColor="lightgrey"
                    secondaryColor="dimgrey"
                  />
                ))}
            </ListItemButton>

            {el.children && (
              <Collapse in={submenu.open} timeout="auto" unmountOnExit>
                <List className="menuSub" component="div" disablePadding>
                  {el.children.map((childEl) => {
                    const isSubmenuActive = submenu.url === childEl.url
                    return (
                      <ListItemButton
                        key={childEl.url}
                        className={rtlClassName}
                        classes={listItemButtonClasses}
                        onClick={() => handleSubmenuClick(el.url, childEl.url)}
                        selected={submenu.url === childEl.url}
                      >
                        <ListItemIcon>
                          {navbarOpen ? (
                            <CircleIcon
                              sx={{
                                fontSize: 12,
                                color: isSubmenuActive
                                  ? '#FFF'
                                  : colorSecondary,
                              }}
                            />
                          ) : submenu.url === childEl.url ? (
                            childEl.iconActive
                          ) : (
                            childEl.icon
                          )}
                        </ListItemIcon>
                        <ListItemText primary={childEl.label} />
                      </ListItemButton>
                    )
                  })}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>

      <Divider />

      <List>
        {data2.map((el) => (
          <ListItemButton
            key={el.url}
            className={rtlClassName}
            classes={listItemButtonClasses}
            onClick={(event) => handleMenuClick(event, el)}
            selected={menu === el.url}
          >
            <ListItemIcon>
              {menu === el.url ? el.iconActive : el.icon}
            </ListItemIcon>
            <ListItemText primary={el.label} />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  )
}
