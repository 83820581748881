import { SearcherAction } from './actionType'
import { SearchResult } from './reducer'

export const fetchResultById = (
  id: string,
  callback: (data: SearchResult) => void,
): SearcherAction => ({
  type: 'searcher/FETCH_RESULT_BY_ID',
  id,
  callback,
})

export const getPDFData = (
  requestMonitorId: number,
  apiKey: string,
): SearcherAction => ({
  type: 'searcher/GET_PDF_DATA',
  requestMonitorId,
  apiKey,
})

export const getPDF = (requestMonitorId: number): SearcherAction => ({
  type: 'searcher/GET_PDF',
  requestMonitorId,
})

export const getLinkPreview = (link: string, callback: (ogData: any) => void): SearcherAction => ({
  type: 'searcher/GET_LINK_PREVIEW',
  link,
  callback,
})