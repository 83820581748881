import React from 'react'
import {useTranslation} from 'react-i18next'
import {Add as AddIcon} from '@mui/icons-material'
import { Avatar, Box, Button, Divider, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import {PrivatePage} from '../component/PagePrivate'
import { CampaignCreate } from '../container/marketing/campaign/CampaignCreate'
import { TelegramCreate } from '../container/marketing/telegram/TelegramCreate'
import {useTableStyles} from '../component/datatable/hook/useStyles'
import {CampaignFrequency, CampaignPage, CampaignType, ICampaign, ITelegramCampaign} from '../store/marketing/reducer'
import {HeaderData, TableRef} from '../component/datatable/types'
import {DataTable} from '../component/datatable'
import {FormState} from '../function/validation'
import {PopupPreview} from '../container/marketing/campaign/PopupPreview'
import {PopupCampaign} from '../container/marketing/campaign/PopupCampaign'
import {PopupAsk} from '../component/PopupAsk'
import {useDispatch} from '../store'
import {deleteCampaign} from '../store/marketing/actionCreator'
import {colorSecondaryDark} from '../mui'
import {SvgIcon} from '../component/SvgIcon'
import { formatDate } from '../function/date'
import { PopupTgPreview } from '../container/marketing/campaign/PopupTgPreview'


function getTypeName(type: CampaignType) {
  switch (type) {
    case 'bottom-right':
      return 'Bottom-right corner'
    default:
      return 'Bottom-right corner'
  }
}

function getFrequencyName(type: CampaignFrequency) {
  switch (type) {
    case 'once-a-day':
      return 'Once a day'
    default:
      return 'Every login'
  }
}

function urlToLabel(url: CampaignPage) {
  const string = url === '/' ? 'dashboard' : url.slice(1)
  const letter1 = string[0].toUpperCase()
  return (letter1 + string.slice(1)).replaceAll('/', ' ')
}

interface RenderInlineInfoProps {
  data: string[] | null
  capitalize?: boolean
  urlLikeWord?: boolean
}

function RenderInlineInfo({data, capitalize, urlLikeWord}: RenderInlineInfoProps) {
  const textTransform = capitalize ? 'capitalize' : 'none'

  return (
    <>
      {data?.map((el, idx) => {
        const length = data?.length
        const isLast = (length === idx + 1) && (length !== 1)

        const label = urlLikeWord ? urlToLabel(el as CampaignPage) : el

        if (isLast) return <Typography key={idx} textTransform={textTransform} component="span"> & {label}</Typography>
        else {
          switch (idx) {
            case 0:
              return <Typography key={idx} textTransform={textTransform} component="span">{label}</Typography>
            default:
              return <Typography key={idx} textTransform={textTransform} component="span">, {label}</Typography>
          }
        }
      })}
    </>
  )
}

export function MarketingPage() {
  const {t} = useTranslation()
  const classesTable = useTableStyles()
  const dispatch = useDispatch()

  const tableRef = React.useRef<TableRef>(null)
  const telegramTableRef = React.useRef<TableRef>(null)

  const [campaignCreate, setCampaignCreate] = React.useState(false)
  
  const [campaignEdit, setCampaignEdit] = React.useState<ICampaign | null>(null)
  const [showTgCampaign, setShowTgCampaign] = React.useState<ITelegramCampaign | null>(null)

  const [campaignDelete, setCampaignDelete] = React.useState<number | null>(null)
  const [preview, setPreview] = React.useState<FormState | null>(null)

  const [telegramCampaignCreate, setTelegramCampaignCreate] = React.useState(false)

  const tableHeaderData: HeaderData[] = [
    {key: 'campaignName', text: t('marketing.table.campaignName')},
    {key: 'popupType', text: t('marketing.table.popupType')},
    {key: 'pageDisplayRules', text: t('marketing.table.pageDisplayRules')},
    {key: 'targetingCriteria', text: t('marketing.table.targetingCriteria')},
    {key: 'frequencyRules', text: t('marketing.table.frequencyRules')},
    {key: 'actions', text: t('marketing.table.actions')},
  ]

  const telegramTableHeaderData: HeaderData[] = [
    {key: 'telegramCampaignName', text: t('marketing.table.telegramCampaignName')},
    {key: 'telegramMainTitle', text: t('marketing.table.telegramMainTitle')},
    {key: 'telegramCampaignImage', text: t('marketing.table.telegramCampaignImage')},
    {key: 'telegramRedirectLink', text: t('marketing.table.telegramRedirectLink')},
    {key: 'telegramCreatedTime', text: t('marketing.table.telegramCreatedTime')},
    // {key: 'telegramUserSeen', text: t('marketing.table.telegramUserSeen')},
  ]

  const TableBody = (row: ICampaign, idx: number) => (
    <TableRow
      key={row.id}
      className={idx % 2 !== 0 ? classesTable.rowEven : ''}
      onClick={() => setCampaignEdit(row)}
      hover
    >
      <TableCell classes={classesTable}>
        <Typography variant="bold">{row.name}</Typography>
        <Stack direction="row" spacing={2} mt={1}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <SvgIcon name="eye" size={{width: 17, height: 12}}/>
            <Typography color="secondary.dark">{row.viewCount}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <SvgIcon name="click" size={{width: 21, height: 18}}/>
            <Typography color="secondary.dark">{row.actionCount}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <SvgIcon name="percent" size={{width: 14, height: 12}}/>
            <Typography color="secondary.dark">{row.percent}</Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell classes={classesTable}>{getTypeName(row.type)}</TableCell>
      <TableCell classes={classesTable}>
        <Typography variant="bold">Pages: </Typography>
        <RenderInlineInfo data={row.pages} urlLikeWord/>
      </TableCell>
      <TableCell classes={classesTable}>
        <Box py={1}>
          {row.packages && (
            <Box>
              <Typography variant="bold" component="span">Plans: </Typography>
              <RenderInlineInfo data={row.packages} capitalize/>
            </Box>
          )}
          {row.ltvRange && (
            <Box>
              <Typography variant="bold" component="span">LTV range: </Typography>
              {row.ltvRange.from}-{row.ltvRange.to}€
            </Box>
          )}
          {row.balanceRange && (
            <Box>
              <Typography variant="bold" component="span">Balance range: </Typography>
              {row.balanceRange.from}-{row.balanceRange.to}€
            </Box>
          )}
          {(row.lastActive || row.lastActive === 0) && (
            <Box>
              <Typography variant="bold" component="span">Last active: </Typography>
              {row.lastActive}+ days ago
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell classes={classesTable}>{getFrequencyName(row.frequency)}</TableCell>
      <TableCell classes={classesTable}>
        <Tooltip title={<span>{t('common.delete')}</span>}>
          <IconButton onClick={e => handleDelete(e, row.id)}>
            <SvgIcon name="delete" color={colorSecondaryDark} size={{width: 18, height: 16}}/>
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )

  const TelegramTableBody = (row: ITelegramCampaign, idx: number) => (
    <TableRow
      key={row.id}
      className={idx % 2 !== 0 ? classesTable.rowEven : ''}
      onClick={() => setShowTgCampaign(row)}
      hover
    >
      <TableCell classes={classesTable}>
        <Typography variant="bold">{row.name}</Typography>
      </TableCell>
      <TableCell classes={classesTable}>{row.title}</TableCell>
      <TableCell classes={classesTable}>
        {/* <Typography variant="bold">{row.image} </Typography> */}
        { row.image ? <Avatar src={row.image} /> : <Typography variant="bold">{row.image} </Typography> }
      </TableCell>
      <TableCell classes={classesTable}>
      {row.actionUrl}
      </TableCell>
      <TableCell classes={classesTable}>
      {formatDate(row.createdTime, 'dd/MM/yyyy HH:mm:ss')}
      </TableCell>
      {/* <TableCell classes={classesTable}>
        <Typography variant="bold">{row.usersCount} </Typography>
      </TableCell> */}
    </TableRow>
  )

  function handleDelete(event: React.MouseEvent<HTMLElement>, id: number) {
    event.stopPropagation()
    setCampaignDelete(id)
  }

  return (
    <PrivatePage>
      {!campaignCreate && (
        <Button
          variant="contained"
          startIcon={<AddIcon style={{position: 'relative', right: -5, fontSize: 11}}/>}
          onClick={() => setCampaignCreate(true)}
          sx={{paddingLeft: '11px'}}
        >{t('marketing.newCampaign')}</Button>
      )}

      {campaignCreate && (
        <CampaignCreate setPreview={setPreview} tableRef={tableRef} onClose={() => setCampaignCreate(false)}/>
      )}

      <DataTable
        ref={tableRef}
        id="campaign"
        className={classesTable.card}
        title={t('marketing.table.title')}
        url="/marketing-campaign"
        headerData={tableHeaderData}
        renderBody={TableBody}
        emptyText="No active campaigns yet, please create new campaign"
        pagination
      />

      <Divider sx={{width: '100%'}} />


      {!telegramCampaignCreate && (
        <Button
          variant="contained"
          startIcon={<AddIcon style={{position: 'relative', right: -5, fontSize: 11}}/>}
          onClick={() => setTelegramCampaignCreate(true)}
          sx={{paddingLeft: '11px'}}
        >{t('marketing.newTelegramCampaign')}</Button>
      )}

      {telegramCampaignCreate && (
        <TelegramCreate setPreview={setPreview} tableRef={telegramTableRef} onClose={() => setTelegramCampaignCreate(false)}/>
      )}

      <DataTable
        ref={telegramTableRef}
        id="telegramCampaign"
        className={classesTable.card}
        title={t('marketing.table.titleTelegram')}
        url="/marketing-tg-campaign"
        headerData={telegramTableHeaderData}
        renderBody={TelegramTableBody}
        emptyText="No active campaigns yet, please create new campaign"
        pagination
      />

    {showTgCampaign && <PopupTgPreview data={showTgCampaign} onClose={() => setShowTgCampaign(null)}/>}
      

     {campaignEdit && (
        <PopupCampaign
          data={campaignEdit}
          setPreview={setPreview}
          tableRef={tableRef}
          onClose={() => setCampaignEdit(null)}
        />
      )}
      {campaignDelete && (
        <PopupAsk
          onYes={() => dispatch(deleteCampaign(campaignDelete, () => tableRef.current?.refresh()))}
          onClose={() => setCampaignDelete(null)}
        />
      )}
      {preview && <PopupPreview data={preview} onClose={() => setPreview(null)}/>}
    </PrivatePage>
  )
}