import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  CommonState,
  TableBodyData,
  TableBodyRow,
} from '../store/common/reducer'
import { getPercentSize } from '../function/common'
import { useSelector } from '../store'
import { subscriberTicketsTableId } from '../container/dashboard/SubscriberTickets'
import { failedSubscriberRequestsTableId } from '../container/dashboard/FailedSubscriberRequests'
import { HeaderData } from '../component/datatable/types'
import { Subscriber } from '../store/user/reducer'

export interface Breakdown {
  web: {
    width: string
    value: number
  }
  api: {
    width: string
    value: number
  }
}

export interface SubscriberTickets {
  tableHeader: HeaderData[]
  tableHeaderPDF: HeaderData[]
  tableBodyData: TableBodyRow[] | undefined
}

export interface FailedSubscriberRequests {
  tableHeaderData: HeaderData[]
  tableData: TableBodyData | undefined
}

export function useSubscriber(data: Subscriber, isPdf = false) {
  const { t } = useTranslation()

  const { tables }: CommonState = useSelector((state) => state.common)

  const subscriberTicketsList = useSelector((state) => state.user.tickets)
  const failedSubscriberRequestsList = useSelector(
    (state) => state.user.failedRequests,
  )

  //console.log('tables', tables)
  const tableDataSubscriberTickets = tables
    ? tables[subscriberTicketsTableId]
    : undefined
  const tableDataFailedSubscriberRequests = tables
    ? tables[failedSubscriberRequestsTableId]
    : undefined

  const lookupChartData = data.counters ? data.counters.map(counter => counter.counter) : [0]

  const subscriberTicketsTableHeaderCommonData: HeaderData[] = [
    { key: 'id', text: t('subscriber.ticketsTable.id') },
    { key: 'title', text: t('subscriber.ticketsTable.title') },
    {
      key: 'status',
      text: t('subscriber.ticketsTable.status'),
      style: { width: 150 },
    },
    { key: 'priority', text: t('subscriber.ticketsTable.priority') },
  ]

  const subscriberTicketsTableHeaderData: HeaderData[] = [
    ...subscriberTicketsTableHeaderCommonData,
    { key: 'action', text: '' },
  ]

  const failedSubscriberRequestsTableHeaderData: HeaderData[] = [
    { key: 'usage', text: '' },
    { key: 'type', text: t('subscriber.failedTable.type') },
    { key: 'source', text: t('subscriber.failedTable.source') },
    { key: 'input', text: t('subscriber.failedTable.input') },
    { key: 'reason', text: t('subscriber.failedTable.reason') },
    { key: 'reqId', text: t('subscriber.failedTable.reqId') },
  ]

  const lookupChartTopValue: number = Math.max(
    ...lookupChartData.map((el) => el),
  )

  const breakdownTopValue = React.useMemo(() => {
    if (!tableDataFailedSubscriberRequests) return 0
    if (
      tableDataFailedSubscriberRequests.webUsagesCount === undefined ||
      tableDataFailedSubscriberRequests.apiUsagesCount === undefined
    )
      return 0

    return Math.max(
      ...[
        tableDataFailedSubscriberRequests.webUsagesCount,
        tableDataFailedSubscriberRequests.apiUsagesCount,
      ].map((el) => el),
    )
  }, [tableDataFailedSubscriberRequests])

  const webUsage = tableDataFailedSubscriberRequests?.webUsagesCount || 0
  const apiUsage = tableDataFailedSubscriberRequests?.apiUsagesCount || 0

  const breakdown: Breakdown = React.useMemo(() => {
    return {
      web: {
        value: webUsage,
        width: getPercentSize(webUsage, breakdownTopValue),
      },
      api: {
        value: apiUsage,
        width: getPercentSize(apiUsage, breakdownTopValue),
      },
    }
  }, [webUsage, apiUsage, breakdownTopValue])

  const subscriberTickets: SubscriberTickets = {
    tableHeader: subscriberTicketsTableHeaderData,
    tableHeaderPDF: subscriberTicketsTableHeaderCommonData,
    tableBodyData: isPdf
      ? subscriberTicketsList
      : tableDataSubscriberTickets?.list,
  }

  //console.log(tableDataFailedSubscriberRequests)
  const failedSubscriberRequests: FailedSubscriberRequests = {
    tableHeaderData: failedSubscriberRequestsTableHeaderData,
    tableData: isPdf
      ? {
          ...tableDataFailedSubscriberRequests,
          list: failedSubscriberRequestsList,
        }
      : tableDataFailedSubscriberRequests,
  }

  return {
    lookupChartTopValue,
    breakdown,
    subscriberTickets,
    failedSubscriberRequests,
  }
}
