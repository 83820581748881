import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Stack, TableCell, TableRow, Typography } from "@mui/material"
import { useTableStyles } from "../../component/datatable/hook/useStyles"
import { DataTable } from "../../component/datatable"
import { useSubscriber } from "../../hook/useSubscriber"
import dashboard_ from "../../sass/dashboard.module.sass"
import { SvgIcon } from "../../component/SvgIcon"
import { FailedSubscriberRequest, Usage } from "../../store/dashboard/reducer"
import { Subscriber } from "../../store/user/reducer"

export const failedSubscriberRequestsTableId = "failedSubscriberRequests"

interface Props {
  data: Subscriber
  usage: Usage
}

export function FailedSubscriberRequests({ data, usage }: Props) {
  const { t } = useTranslation()
  const classes = useTableStyles()

  const { failedSubscriberRequests } = useSubscriber(data)
  const TableBody = (row: FailedSubscriberRequest, idx: number) => (
    <TableRow key={row.id} className={idx % 2 !== 0 ? classes.rowEven : ""}>
      <TableCell classes={classes}>
        <Stack>
          <SvgIcon
            name={row.requestMonitor.usage}
            size={{ width: 17, height: 17 }}
          />
        </Stack>
      </TableCell>
      <TableCell classes={classes}>{row.requestMonitor.type}</TableCell>
      <TableCell classes={classes}>{row.from}</TableCell>
      <TableCell classes={classes}>{row.criteria}</TableCell>
      <TableCell classes={classes}>{row.failReason}</TableCell>
      <TableCell classes={classes}>{row.requestId.slice(0, 7)}</TableCell>
    </TableRow>
  )

  return (
    <>
      <Typography variant="semiBold" fontSize={18}>
        {t("dashboard.failedSubscriberRequests")}
      </Typography>

      <Box className={dashboard_.card_column} mt={1}>
        <DataTable
          id={failedSubscriberRequestsTableId}
          url={`/admin-dashboard/subscriber?id=${data.id}&usage=${usage}`}
          headerData={failedSubscriberRequests.tableHeaderData}
          renderBody={TableBody}
          size="small"
          limit={3}
          pagination
          emptyText="No failed requests from this subscriber"
          loadingIndication={true}
        />
      </Box>
    </>
  )
}
