import { call, put, takeEvery } from "redux-saga/effects"
import { api, Response } from "../../function/axios"
import * as actionCreator from "./actionCreator"
import * as actionType from "./actionType"
import { TableBodyRow } from "./reducer"
import { handleError } from "../../function/error"

function* fetchTableData(action: actionType.FetchTable) {
  try {
    yield put(actionCreator.setTableLoading(true))
    const { data }: Response = yield call(api, {
      method: "GET",
      url: action.url,
      params: action.params,
    })

    if (Array.isArray(data)) {
      const newData = {
        list: data as TableBodyRow[],
        count: null,
      }
      yield put(actionCreator.setTable(newData, action.tableId))
    } else {
      yield put(actionCreator.setTable(data, action.tableId))
    }
    yield put(actionCreator.setTableLoading(false))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* deleteTableData(action: actionType.DeleteTableData) {
  try {
    const response: Response = yield call(api, {
      method: "DELETE",
      url: `${action.url}?ids=${action.ids.toString()}`,
    })

    yield call(action.callback)
    yield put(actionCreator.showSnackbar("success", response.data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getSubscriber(action: actionType.GetSubscriber) {
  try {
    const { data }: Response = yield call(api, {
      method: "GET",
      url: "/admin-dashboard/subscriber",
      params: {
        id: action.id,
        offset: action?.offset,
        limit: action?.limit,
        usage: action?.usage,
        time: action?.time,
      },
    })
    yield put({ type: "common/GET_SUBSCRIBER_SUCCEEDED", payload: data })
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchCommon() {
  yield takeEvery(actionType.GET_SUBSCRIBER, getSubscriber)
  yield takeEvery(actionType.FETCH_TABLE, fetchTableData)
  yield takeEvery(actionType.DELETE_TABLE_DATA, deleteTableData)
}
