import * as React from 'react'
import {t} from 'i18next'
import {Button, Card, Stack, CardHeader, CardContent} from '@mui/material'
import {FormCampaign, FormRef} from '../../../component/marketing/FormCampaign'
import {FormState} from '../../../function/validation'
import {TableRef} from '../../../component/datatable/types'
import { FormTelegram } from '../../../component/marketing/FormTelegram'

interface Props {
  setPreview: React.Dispatch<React.SetStateAction<FormState | null>>
  tableRef: React.RefObject<TableRef>
  onClose: () => void
}

export function TelegramCreate({setPreview, tableRef, onClose}: Props) {
  const formRef = React.useRef<FormRef>(null)

  return (
    <Card>
      <CardHeader title={t('marketing.createTelegram')}/>

      <CardContent sx={{mt: 2}}>
        <FormTelegram ref={formRef} mode="create" tableRef={tableRef} onClose={onClose} setPreview={setPreview}/>

        <Stack direction="row" justifyContent="flex-end" mt={2} spacing={2}>
          <Button variant="outlined" size="small" onClick={onClose}>{t('common.cancel')}</Button>
          {/*<Button variant="outlined" size="small" onClick={() => formRef.current?.preview()}>
            {t('marketing.previewPopup')}
          </Button>*/}
          <Button variant="contained" size="small" onClick={() => formRef.current?.submit()}>
            {t('marketing.startCampaign')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
}