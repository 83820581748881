import {NavigateFunction} from 'react-router-dom'
import {E164Number} from '../../component/InputPhone'

export const FLUSH_STATE = 'auth/FLUSH_STATE'
export interface FlushState {
  type: typeof FLUSH_STATE
}

export const GET_DEPARTMENT = 'auth/GET_DEPARTMENT'
export interface GetDepartment {
  type: typeof GET_DEPARTMENT
}

export const SET_DEPARTMENT = 'auth/SET_DEPARTMENT'
export interface SetDepartment {
  type: typeof SET_DEPARTMENT
  data: object[]
}

export const GET_PHONE_CODE = 'auth/GET_PHONE_CODE'
export interface GetPhoneCode {
  type: typeof GET_PHONE_CODE
  phone: E164Number
}

export const SET_PHONE_VERIFY = 'auth/SET_PHONE_VERIFY'
export interface SetPhoneVerify {
  type: typeof SET_PHONE_VERIFY
  value: boolean
}

export const SIGN_UP = 'auth/SIGN_UP'
export interface SignUp {
  type: typeof SIGN_UP
  email: string
  password: string
  department: string
  navigate: NavigateFunction
}

export const SIGN_IN = 'auth/SIGN_IN'
export interface SignIn {
  type: typeof SIGN_IN
  email: string
  password: string
  navigate: NavigateFunction
}

export const REQUEST_FORGOT = 'auth/REQUEST_FORGOT'
export interface RequestForgot {
  type: typeof REQUEST_FORGOT
  email: string
  navigate: NavigateFunction
}

export const SIGN_BY_2FA = 'auth/SIGN_BY_2FA'
export interface SignBy2Fa {
  type: typeof SIGN_BY_2FA
  code: string
  navigate: NavigateFunction
}

export const UPDATE_PASSWORD = 'auth/UPDATE_PASSWORD'
export interface UpdatePassword {
  type: typeof UPDATE_PASSWORD
  password: string
  oobCode: string
  navigate: NavigateFunction
}

export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN'
export interface RefreshToken {
  type: typeof REFRESH_TOKEN
  token?: string
  redirect?: () => void
}

export const SET_REFRESHED_TOKEN = 'auth/SET_REFRESHED_TOKEN'
export interface SetRefreshedToken {
  type: typeof SET_REFRESHED_TOKEN
  token: string
}

export const SIGN_OUT = 'auth/SIGN_OUT'
export interface SignOut {
  type: typeof SIGN_OUT
  navigate?: NavigateFunction
}

export type AuthAction =
  | FlushState
  | SignUp
  | GetDepartment
  | SetDepartment
  | GetPhoneCode
  | SetPhoneVerify
  | SignIn
  | SignBy2Fa
  | RequestForgot
  | UpdatePassword
  | RefreshToken
  | SetRefreshedToken
  | SignOut
