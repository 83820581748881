import { BlackListAction } from "./actionType"

//Emails
export interface BlackList {
    id : number
    email: string
  }

  export type BlackListState = {
    blackList: BlackList[] | undefined | null
  }

  const initialState: BlackListState = {
    blackList : undefined,
  }

  export function blacklistReducer(state = initialState, action: BlackListAction) {
    switch (action.type) {
      case 'blacklist/SET_BLACKLIST':
        return {
          ...state,
          blackList: action.data,
        }
      default:
        return state
    }
  }

  //Phones
  export interface BlackListPhones {
    id : number
    phone: string
  }

  export type BlackListPhonesState = {
    blackListPhones: BlackListPhones[] | undefined | null
  }

  const initialPhonesState: BlackListPhonesState = {
    blackListPhones : undefined,
  }

  export function blacklistPhonesReducer(state = initialPhonesState, action: BlackListAction) {
    switch (action.type) {
      case 'blacklist/SET_BLACKLIST_PHONES':
        return {
          ...state,
          blackListPhones: action.data,
        }
      default:
        return state
    }
  }

  //IP Address
  export interface BlackListIpAddress {
    id : number
    ipAddress: string
  }

  export type BlackListIpAddressState = {
    blackListIpAddress: BlackListIpAddress[] | undefined | null
  }

  const initialIpAddressState: BlackListIpAddressState = {
    blackListIpAddress : undefined,
  }

  export function blacklistIpAddressReducer(state = initialIpAddressState, action: BlackListAction) {
    switch (action.type) {
      case 'blacklist/SET_BLACKLIST_IP_ADDRESS':
        return {
          ...state,
          blackListIpAddress: action.data,
        }
      default:
        return state
    }
  }