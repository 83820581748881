import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
} from '@mui/material'

interface Props {
  onClose: () => void
  onYes?: () => void
  header?: React.ReactNode
  children?: React.ReactNode
  actions?: React.ReactNode
}

export const PopupAsk = ({
  onYes,
  header,
  onClose,
  children,
  actions,
}: Props) => {
  const { t } = useTranslation()

  const handleYes = () => {
    if (onYes) {
      onYes()
    }

    onClose()
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{header ? header : t('common.sure')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      {children && (
        <>
          {children}
          <Divider variant="popup" color="primary" />
        </>
      )}

      <DialogActions>
        {actions ? (
          actions
        ) : (
          <>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={onClose}
            >
              {t('common.no')}
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleYes}
            >
              {t('common.yes')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
