import React from 'react'
import { Grid, Typography, Link, Box } from '@mui/material'


interface MailRevealProps {
  mailRevealData: any // TypeScript type "any" for dynamic data
}

function getMailReveal(response : any) {
  if (response.apiDataEntities && Array.isArray(response.apiDataEntities)) {
    for (const entity of response.apiDataEntities) {
      if (entity.data && entity.data.mail_reveal) {
        return entity.data.mail_reveal // Return the found mail_reveal object
      }
    }
  }
  return null
}

const MailReveal: React.FC<MailRevealProps> = ({ mailRevealData }) => {
  const filteredArray = getMailReveal(mailRevealData)

  const success = filteredArray && filteredArray.success? filteredArray.success : null
  const email = filteredArray && filteredArray.email? filteredArray.email : null
  const person = filteredArray && filteredArray.person? filteredArray.person : null
  const company= filteredArray && filteredArray.company? filteredArray.company : null

return (
  
  <Box>
    {success ? (
      <Grid container spacing={3}>
        {/* Left Column */}
        <br />
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            Person Information
          </Typography>
          <Typography><strong>Email:</strong> {email}</Typography><br />
          <Typography><strong>Name:</strong> {person?.firstName} {person?.lastName}</Typography><br />
          <Typography><strong>Headline:</strong> {person?.headline}</Typography><br />
          <Typography><strong>Location:</strong> {person?.location}</Typography><br />
          {person?.photoUrl && (
            <Box component="img" src={person?.photoUrl} alt={`${person?.firstName} ${person?.lastName}`} sx={{ width: 150, borderRadius: '50%' }} />
          )}
          <Typography variant="h6" gutterBottom>
            LinkedIn Profile
          </Typography>
          <Link href={person?.linkedInUrl} target="_blank" rel="noopener noreferrer">
            {person?.linkedInUrl}
          </Link><br />

          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Company Information
          </Typography>
          <Typography><strong>Company Name:</strong> {company?.name}</Typography><br />
          <Typography><strong>Description:</strong> {company?.description}</Typography><br />
          <Link href={company?.linkedInUrl} target="_blank" rel="noopener noreferrer">
            {company?.linkedInUrl}
          </Link><br />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Work History
          </Typography>
          {person?.positions?.positionHistory?.map((position: any, index: number) => (
            <Box key={index} mb={2}>
              <Typography><strong>{position.title}</strong> at {position.companyName}</Typography><br />
              <Typography><strong>Location:</strong> {position.companyLocation}</Typography><br />
              <Typography><strong>Description:</strong> {position.description}</Typography><br />
              <Typography><strong>Start Date:</strong> {position.startEndDate?.start?.month}/{position.startEndDate?.start?.year}</Typography><br />
              {position.startEndDate?.end && (
                <><Typography><strong>End Date:</strong> {position.startEndDate.end.month}/{position.startEndDate.end.year}</Typography><br /></>
              )}
              <Link href={position.linkedInUrl} target="_blank" rel="noopener noreferrer">
                LinkedIn Company
              </Link><br />
            </Box>
          ))}
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Education History
          </Typography>
          {person?.schools?.educationHistory?.map((education: any, index: number) => (
            <Box key={index} mb={2}>
              <Typography><strong>{education.degreeName}</strong> in {education.fieldOfStudy}</Typography><br />
              <Typography><strong>School:</strong> {education.schoolName}</Typography><br />
              <Typography><strong>Dates:</strong> {education.startEndDate?.start?.year} - {education.startEndDate?.end?.year}</Typography><br />
              <Link href={education.linkedInUrl} target="_blank" rel="noopener noreferrer">
                LinkedIn School
              </Link><br />
            </Box>
          ))}

        </Grid>
      </Grid>
    ) : (
      <Typography variant="body1">Failed to retrieve data.</Typography>
    )}
  </Box>
)
}

export default MailReveal