import { Search } from '@mui/icons-material'
import { IconButton, InputBase, Paper } from '@mui/material'
import * as React from 'react'

interface SearchBarProps {
    callback: Function | null | undefined,
    list: any[] | null | undefined,
    valName: string | null | undefined,
}

const SearchBar = (props: SearchBarProps) => {
    const [searched, setSearched] = React.useState("")
    const {callback, list, valName} = props 

    const handleSearch = (event: React.FormEvent) => {
        event.preventDefault()
        // Handle the search logic here
        if (callback && list){
            if (searched !== ""){
                const searchedLowerCase = searched.toLowerCase()
                const filteredList = list.filter((value) => {
                    const valueLowerCase = valName ? value[valName].toLowerCase() : value.toLowerCase()
                    return (valueLowerCase === searchedLowerCase || valueLowerCase.includes(searchedLowerCase))
                })
                callback(filteredList)
            }
            else callback(list)
        }
    }

    return (
        <Paper 
            component="form"
            onSubmit={handleSearch}
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 500 }}
            variant="outlined"
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search..."
                inputProps={{ 'aria-label': 'search google maps' }}
                value={searched}
                onChange={(e) => setSearched(e.target.value)}
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                <Search />
            </IconButton>
        </Paper>
    )
}

export default SearchBar