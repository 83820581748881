import { AlertColor } from "@mui/material"
import { CommonAction } from "./actionType"
import { TableBodyData } from "./reducer"

export const setLoading = (status: boolean): CommonAction => ({
  type: "common/SET_LOADING",
  status,
})

export const setTableLoading = (status: boolean): CommonAction => ({
  type: "common/SET_TABLE_LOADING",
  status,
})

export const setLanguage = (language: string): CommonAction => ({
  type: "common/SET_LANGUAGE",
  language,
})

export const showSnackbar = (
  severity: AlertColor,
  message?: string,
): CommonAction => ({
  type: "common/SHOW_SNACKBAR",
  severity,
  message,
})

export const hideSnackbar: CommonAction = { type: "common/HIDE_SNACKBAR" }

export const setFieldError = (
  key: string,
  error: string | undefined,
): CommonAction => ({
  type: "common/SET_FIELD_ERROR",
  key,
  error,
})

export const clearFieldError: CommonAction = {
  type: "common/CLEAR_FIELD_ERROR",
}

export const fetchTable = (
  url: string,
  params: Record<string, any>,
  tableId: string,
): CommonAction => ({
  type: "common/FETCH_TABLE",
  url,
  params,
  tableId,
})

export const setTable = (
  data: TableBodyData,
  tableId: string,
): CommonAction => ({
  type: "common/SET_TABLE",
  data,
  tableId,
})

export const deleteTableData = (
  url: string,
  ids: string[],
  callback: () => void,
): CommonAction => ({
  type: "common/DELETE_TABLE_DATA",
  url,
  ids,
  callback,
})

export const getSubscriber = (
  id: number,
  offset: number,
  limit: number,
  usage: string,
  time: "day" | "week" | "month" | "year",
): CommonAction => ({
  type: "common/GET_SUBSCRIBER_REQUEST",
  id,
  offset,
  limit,
  usage,
  time,
})
