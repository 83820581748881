import { E164Number } from '../../component/InputPhone'
import { IUser } from './reducer'
import { NavigateFunction } from 'react-router-dom'

export const SET_STATE = 'settings/SET_STATE'
export interface SetState {
  type: typeof SET_STATE
  key: string
  value: string
}

export const FLUSH_STATE = 'settings/FLUSH_STATE'
export interface FlushState {
  type: typeof FLUSH_STATE
}

export const GET_PROFILE = 'settings/GET_PROFILE'
export interface GetProfile {
  type: typeof GET_PROFILE
}

export const SET_PROFILE = 'settings/SET_PROFILE'
export interface SetProfile {
  type: typeof SET_PROFILE
  data: IUser
}

export const UPLOAD_IMAGE = 'settings/UPLOAD_IMAGE'
export interface UploadImage {
  type: typeof UPLOAD_IMAGE
  base64: string
}

export const DELETE_IMAGE = 'settings/DELETE_IMAGE'
export interface DeleteImage {
  type: typeof DELETE_IMAGE
}

export const PUT_DATA = 'settings/PUT_DATA'
export interface PutData {
  type: typeof PUT_DATA
  key: string
  value: string
}

export const SEND_EMAIL_UPDATE_VERIFICATION =
  'settings/SEND_EMAIL_UPDATE_VERIFICATION'
export interface SendEmailUpdateVerification {
  type: typeof SEND_EMAIL_UPDATE_VERIFICATION
  newEmail: string
  password: string
  navigate: NavigateFunction
}

export const UPDATE_PASSWORD = 'settings/UPDATE_PASSWORD'
export interface UpdatePassword {
  type: typeof UPDATE_PASSWORD
  oldPassword: string
  password: string
}

export const UPDATE_PHONE = 'settings/UPDATE_PHONE'
export interface UpdatePhone {
  type: typeof UPDATE_PHONE
  code: string
  phone: any
  callback: () => void
}

export const GET_QR = 'settings/GET_QR'
export interface GetQr {
  type: typeof GET_QR
}

export const SET_QR = 'settings/SET_QR'
export interface SetQr {
  type: typeof SET_QR
  qr: string | null
}

export const TOGGLE_2STEP = 'settings/TOGGLE_2STEP'
export interface Toggle2step {
  type: typeof TOGGLE_2STEP
  code: string
  is2FA: boolean
  navigate: NavigateFunction
}

export const DELETE_USER = 'settings/DELETE_USER'
export interface DeleteUser {
  type: typeof DELETE_USER
  navigate: NavigateFunction
}

export const DELETE_2FA_USER = 'settings/DELETE_2FA_USER'
export interface Delete2FAUser {
  type: typeof DELETE_2FA_USER
  code: string
  navigate: NavigateFunction
}

export type SettingsAction =
  | SetState
  | FlushState
  | GetProfile
  | SetProfile
  | UploadImage
  | DeleteImage
  | PutData
  | SendEmailUpdateVerification
  | UpdatePassword
  | UpdatePhone
  | GetQr
  | SetQr
  | Toggle2step
  | DeleteUser
  | Delete2FAUser
