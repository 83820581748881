import {format, isValid} from 'date-fns'
import { CommonState } from '../store/common/reducer'
import { store } from '../store'

export function formatDate(isoDate: string | number | Date, dateFormat: string): string {
  return isValid(new Date(isoDate)) ? format(new Date(isoDate), dateFormat) : String(isoDate)
}

export function getNow() {
  const commonState: CommonState = store.getState().common
  const timeFormatWithSeconds = commonState.timeFormatWithSeconds

  return format(new Date(), `yyyy-MM-dd-${timeFormatWithSeconds}`)
}

export function toDate(datetime: any) {
  return isValid(new Date(datetime)) ? format(new Date(datetime), 'dd.MM.yyyy HH:mm:ss') : datetime
}

export function getTodayDay() {
  return format(new Date(), 'dd MMM')
}

export function fromUnix(unix: number) {
  return new Date(unix)
}