import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const useAccordionStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 11,
    border: '1px solid rgba(46, 151, 213, 0.5)',
    borderRadius: 10,
    boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.1)',
    position: 'static',
    '& .MuiTypography-text, & .MuiTypography-bold': {
      [theme.breakpoints.only("xs")]: {
        fontSize: '10px!important',
      },
    },
    '& MuiGrid-item': {
      [theme.breakpoints.only("xs")]: {
        lineHeight: '10px',
      },
    },
  },
}))

export const usePostObserverDialog = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiDialogTitle-root': {
      padding: '24px 24px 0',
    },
    '& .Mui-expanded': {
      margin: 0,
    },
    '& .MuiDialogActions-root': {
      padding: '0 24px 24px',
    },
  },
  paper: {
    padding: 0,
  },
}))
