import {AES, enc} from 'crypto-js'
import {LocalStorage} from '../enum/storage'

const secret: any = process.env.REACT_APP_CRYPT_SECRET

if (secret === undefined) {
  console.error('No installed REACT_APP_CRYPT_SECRET environment')
}

export function encrypt(value: any) {
  const stringed = JSON.stringify(value)
  return AES.encrypt(stringed, secret).toString()
}

export function decrypt(value: string) {
  const decrypted = AES.decrypt(value, secret)
  return JSON.parse(decrypted.toString(enc.Utf8))
}

export function setCryptLocalStorage(key: string, value: any) {
  const encrypted = encrypt(value)
  localStorage.setItem(key, encrypted)
}

export function setAuthLocalStorageArray(key: string, value: any) {
  const uid = localStorage.getItem(LocalStorage.UID)
  localStorage.setItem(`${key}-${uid}`, JSON.stringify(value))
}

export function addAuthLocalStorageItemToArray(key: string, value: any) {
  const existingStorage = getAuthLocalStorageArray(key)
  const newStorage = [...existingStorage, value]

  setAuthLocalStorageArray(key, newStorage)
}

export function getAuthLocalStorageArray(key: string) {
  const uid = localStorage.getItem(LocalStorage.UID)
  let storage: any = localStorage.getItem(`${key}-${uid}`)

  if (storage) storage = JSON.parse(storage)
  else storage = []

  return storage
}
