import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import {
  Add as AddIcon,
  Close as CloseIcon,
  ExpandMore as SelectIcon,
} from '@mui/icons-material'
import {
  Alert,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { FormError } from '../FormError'
import { InputCheckbox } from '../InputCheckbox'
import {
  ErrorsState,
  FormState,
  latinOnly,
  select,
  validateForm,
} from '../../function/validation'
import {
  CampaignFrequency,
  CampaignPage,
  CampaignType,
  ICampaign,
  SubscriberType,
} from '../../store/marketing/reducer'
import { useDispatch, useSelector } from '../../store'
import {
  activateCampaign,
  postCampaign,
  putCampaign,
} from '../../store/marketing/actionCreator'
import { getPackageRules } from '../../store/package/actionCreator'
import { useMarketingCheckboxStyles } from '../../style/checkbox'
import { initNumber } from '../../function/number'
import { TableRef } from '../datatable/types'
import { clearFieldError } from '../../store/common/actionCreator'

type Criteria = 'ltv' | 'balanceRange' | 'lastActive'

const optionTypes: Array<{ key: CampaignType; label: string }> = [
  { key: 'bottom-right', label: 'Bottom-right corner' },
  { key: 'center-overlay', label: 'Center overlay' },
]
const optionFrequencies: Array<{ key: CampaignFrequency; label: string }> = [
  { key: 'once-a-day', label: 'Once a day' },
  { key: 'every-login', label: 'Every login' },
]
const optionSubscribers: Array<{ key: SubscriberType; label: string }> = [
  { key: 'gold', label: t('subscriber.type.gold') },
  { key: 'silver', label: t('subscriber.type.silver') },
  { key: 'bronze', label: t('subscriber.type.bronze') },
]
const optionPages: Array<{ key: CampaignPage; label: string }> = [
  { key: '/', label: t('userMenu.dashboard') },
  { key: '/search', label: t('userMenu.search') },
  { key: '/packages', label: t('userMenu.packages') },
  { key: '/developer', label: t('userMenu.developer') },
  { key: '/apikey', label: t('userMenu.apikey') },
  { key: '/support', label: t('userMenu.support') },
]
const optionCriteria: Array<{ key: Criteria; label: string }> = [
  { key: 'ltv', label: t('marketing.criteria.ltv') },
  { key: 'balanceRange', label: t('marketing.criteria.balanceRange') },
  { key: 'lastActive', label: t('marketing.criteria.lastActive') },
]

const commonValidation = {
  name: Joi.string(),//.max(55).label(t('field.label.campaignName')),
  title: Joi.string(),//.max(255).label(t('field.label.mainTitle')),
  paragraph: Joi.string(),//.max(500).label(t('field.label.paragraph')),
  actionName: Joi.string(),//.max(25).label(t('field.label.callToActionName')),
  actionUrl: Joi.string(),//.uri().label(t('field.label.callToActionUrl')),
  type: select.label(t('field.label.popupType')),
  durationHour: Joi.number()
    .integer()
    .min(0)
    .max(999)
    .label(t('field.label.hours')),
  durationMinute: Joi.number()
    .integer()
    .max(59)
    .label(t('field.label.minutes')),
  showCountdown: Joi.boolean(),
}
const schemaPreview = Joi.object(commonValidation)
const schemaSubmit = Joi.object({
  ...commonValidation,
  isOnlyFreePackageSelected: Joi.allow(),
  isLtv: Joi.allow(),
  isBalanceRange: Joi.allow(),
  isLastActive: Joi.allow(),
  frequency: select.label(t('field.label.frequencyRules')),
  subscribers: Joi.any()
    .label(t('field.label.subscribersRules'))
    .when('isOnlyFreePackageSelected', {
      is: true,
      then: Joi.any(),
      otherwise: Joi.array().required().min(1),
    }),
  pages: Joi.array().required().min(1).label(t('field.label.pageDisplayRules')),
  packages: Joi.array()
    .required()
    .min(1)
    .label(t('field.label.packageDisplayRules')),
  ltvFrom: Joi.any()
    .label(t('From'))
    .when('isLtv', {
      is: true,
      then: Joi.number().required().min(0).max(999999),
      otherwise: Joi.allow(),
    }),
  ltvTo: Joi.any()
    .label(t('To'))
    .when('isLtv', {
      is: true,
      then: Joi.number()
        .required()
        .min(1)
        .max(999999)
        .greater(Joi.ref('ltvFrom')),
      otherwise: Joi.allow(),
    })
    .messages({ 'number.greater': `"To" must be more than "From"` }),
  balanceFrom: Joi.any()
    .label(t('From'))
    .when('isBalanceRange', {
      is: true,
      then: Joi.number().required().min(0).max(999),
      otherwise: Joi.allow(),
    }),
  balanceTo: Joi.any()
    .label(t('To'))
    .when('isBalanceRange', {
      is: true,
      then: Joi.number()
        .required()
        .min(1)
        .max(999)
        .greater(Joi.ref('balanceFrom')),
      otherwise: Joi.allow(),
    })
    .messages({ 'number.greater': `"To" must be more than "From"` }),
  activity: Joi.any()
    .label(t('Last active'))
    .when('isLastActive', {
      is: true,
      then: Joi.number().required().min(1).max(999),
      otherwise: Joi.allow(),
    }),
})

function initCriteria(data: ICampaign | undefined): Criteria[] {
  if (data === undefined) return []

  const output: Criteria[] = []

  if (data.ltvRange) output.push('ltv')
  if (data.balanceRange) output.push('balanceRange')
  if (data.lastActive || data.lastActive === 0) output.push('lastActive')

  return output
}

export type FormRef = {
  preview: () => void
  submit: () => void
  activate: () => void
}

interface Props {
  mode: 'create' | 'edit'
  setPreview: (data: FormState) => void
  tableRef: React.RefObject<TableRef>
  onClose: () => void
  data?: ICampaign
}

export const FormCampaign = React.forwardRef<FormRef, Props>(
  ({ mode, setPreview, tableRef, onClose, data }, ref) => {
    const checkboxClasses = useMarketingCheckboxStyles()
    const dispatch = useDispatch()

    const { packageRules } = useSelector((state) => state.package)

    React.useEffect(() => {
      dispatch(getPackageRules)
    }, [dispatch])

    const [name, setName] = React.useState(data?.name || '')
    const [title, setTitle] = React.useState(data?.title || '')
    const [paragraph, setParagraph] = React.useState(data?.paragraph || '')
    const [actionName, setActionName] = React.useState(data?.actionName || '')
    const [actionUrl, setActionUrl] = React.useState(data?.actionUrl || '')
    const [type, setType] = React.useState(data?.type || 'null')
    const [durationHour, setDurationHour] = React.useState(
      initNumber(data?.duration.hours) || '24',
    )
    const [durationMinute, setDurationMinute] = React.useState(
      initNumber(data?.duration.minutes) || '0',
    )
    const [showCountdown, setShowCountdown] = React.useState(
      data?.showCountdown || false,
    )
    const [frequency, setFrequency] = React.useState(data?.frequency || 'null')
    const [subscribers, setSubscribers] = React.useState<SubscriberType[]>(
      data?.subscribers || [],
    )
    const [pages, setPages] = React.useState<string[]>(data?.pages || [])
    const [packages, setPackages] = React.useState<string[]>(
      data?.packages || [],
    )
    const [criteria, setCriteria] = React.useState<Criteria[]>(
      initCriteria(data),
    )
    const [criteriaSelect, setCriteriaSelect] = React.useState(
      criteria.length < 3,
    )
    const [ltvFrom, setLtvFrom] = React.useState(
      initNumber(data?.ltvRange?.from),
    )
    const [ltvTo, setLtvTo] = React.useState(initNumber(data?.ltvRange?.to))
    const [balanceFrom, setBalanceFrom] = React.useState(
      initNumber(data?.balanceRange?.from),
    )
    const [balanceTo, setBalanceTo] = React.useState(
      initNumber(data?.balanceRange?.to),
    )
    const [activity, setActivity] = React.useState(initNumber(data?.lastActive))
    const [errors, setErrors] = React.useState<ErrorsState>(null)

    React.useEffect(() => {
      setErrors(null)
      dispatch(clearFieldError)
    }, [
      dispatch,
      activity,
      balanceTo,
      balanceFrom,
      ltvTo,
      ltvFrom,
      criteriaSelect,
      criteria,
      name,
      title,
      paragraph,
      actionName,
      actionUrl,
      type,
      durationHour,
      durationMinute,
      showCountdown,
      subscribers,
      pages,
      packages,
    ])
    const isFreePackageSelected = React.useMemo(() => {
      return packageRules?.some(
        (el) => el.isFree && packages.includes(el.title),
      )
    }, [packageRules, packages])
    const isOnlyFreePackageSelected =
      isFreePackageSelected && packages.length === 1
    const isFreeAndPaidPackageSelected =
      isFreePackageSelected && packages.length > 1
    const freeCriteria = React.useMemo(() => {
      if (isFreePackageSelected) {
        const availableCriteria = optionCriteria.filter(
          (el) => el.key !== 'ltv',
        )
        return availableCriteria.filter((el) => !criteria.includes(el.key))
      }

      return optionCriteria.filter((el) => !criteria.includes(el.key))
    }, [criteria, isFreePackageSelected])

    React.useEffect(() => {
      if (isOnlyFreePackageSelected) setSubscribers([])

      if (isFreePackageSelected)
        setCriteria((state) => [...state.filter((el) => el !== 'ltv')])
    }, [isOnlyFreePackageSelected, isFreePackageSelected, packages])

    function handleSubscriberChange(e: React.SyntheticEvent<Element, Event>) {
      const key = (e.target as HTMLInputElement).name as SubscriberType

      if (key === 'all') {
        const fullState = optionSubscribers.map((el) => el.key)
        setSubscribers((state) =>
          state.length === fullState.length ? [] : fullState,
        )
      } else {
        setSubscribers((state) => {
          if (state.includes(key)) return state.filter((el) => el !== key)
          else return [...state, key]
        })
      }
    }

    function handlePageChange(e: React.SyntheticEvent<Element, Event>) {
      const key: string = (e.target as HTMLInputElement).name

      if (key === 'all') {
        const fullState = optionPages.map((el) => el.key)
        setPages((state) =>
          state.length === fullState.length ? [] : fullState,
        )
      } else {
        setPages((state) => {
          if (state.includes(key)) return state.filter((el) => el !== key)
          else return [...state, key]
        })
      }
    }

    function handlePackageChange(e: React.SyntheticEvent<Element, Event>) {
      const key: string = (e.target as HTMLInputElement).name

      if (key === 'all') {
        const allPackages: string[] = packageRules?.map((el) => el.title) || []
        setPackages((state) =>
          state.length === allPackages.length ? [] : allPackages,
        )
      } else {
        setPackages((state) => {
          if (state.includes(key)) return state.filter((el) => el !== key)
          else return [...state, key]
        })
      }
    }

    function handleCriteriaSelect(e: SelectChangeEvent) {
      const value = e.target.value as Criteria

      setCriteria((state) => [...state, value])
      setCriteriaSelect(false)
    }

    function handleCriteriaDelete(criteria: Criteria) {
      setCriteria((state) => [...state.filter((el) => el !== criteria)])
    }

    function validateBeforeRequest() {
      const validationData = {
        name: name.trim(),
        title: title.trim(),
        paragraph: paragraph.trim(),
        actionName: actionName.trim(),
        actionUrl,
        type,
        durationHour,
        durationMinute,
        showCountdown,
        frequency,
        subscribers,
        pages,
        packages,
        ltvFrom,
        ltvTo,
        balanceFrom,
        balanceTo,
        isOnlyFreePackageSelected,
        activity,
        isLtv: criteria.includes('ltv'),
        isBalanceRange: criteria.includes('balanceRange'),
        isLastActive: criteria.includes('lastActive'),
      }

      return validateForm(schemaSubmit, validationData)
    }

    React.useImperativeHandle(ref, () => ({
      preview() {
        const validationData = {
          name,
          title,
          paragraph,
          actionName,
          actionUrl,
          type,
          durationHour,
          durationMinute,
          showCountdown,
        }

        const error = validateForm(schemaPreview, validationData)

        if (error) setErrors(error)
        else {
          setErrors(null)
          setPreview({ ...validationData, createdTime: data?.createdTime })
        }
      },
      submit() {
        const error = validateBeforeRequest()

        if (error) setErrors(error)
        else {
          setErrors(null)

          const requestData: FormState = {
            name,
            title,
            paragraph,
            actionName,
            actionUrl,
            type,
            frequency,
            subscribers,
            showCountdown,
            packages,
            pages,
          }

          requestData.ltvRange = criteria.includes('ltv')
            ? { from: Number(ltvFrom), to: Number(ltvTo) }
            : null
          requestData.balanceRange = criteria.includes('balanceRange')
            ? {
                from: Number(balanceFrom),
                to: Number(balanceTo),
              }
            : null
          requestData.lastActive = criteria.includes('lastActive')
            ? Number(activity)
            : null
          requestData.duration = {
            hours: Number(durationHour),
            minutes: Number(durationMinute),
          }

          if (mode === 'create')
            dispatch(
              postCampaign(requestData, () => tableRef.current?.refresh()),
            )
          else
            dispatch(
              putCampaign(data?.id as number, requestData, () =>
                tableRef.current?.refresh(),
              ),
            )

          onClose()
        }
      },
      activate() {
        const error = validateBeforeRequest()

        if (error) setErrors(error)
        else {
          setErrors(null)

          dispatch(
            activateCampaign(data?.id as number, () =>
              tableRef.current?.refresh(),
            ),
          )
        }
      },
    }))

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FormControl fullWidth>
              <FormLabel required>{t('field.label.campaignName')}</FormLabel>
              <OutlinedInput
                value={name}
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
              <FormError error={errors?.name} />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FormControl fullWidth>
              <FormLabel required>{t('field.label.mainTitle')}</FormLabel>
              <OutlinedInput
                value={title}
                placeholder="Title"
                onChange={(e) => setTitle(e.target.value)}
              />
              <FormError error={errors?.title} />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} xl={4}>
            <FormControl fullWidth>
              <FormLabel required>{t('field.label.paragraph')}</FormLabel>
              <OutlinedInput
                value={paragraph}
                placeholder="Paragraph"
                onChange={(e) => setParagraph(e.target.value)}
              />
              <FormError error={errors?.paragraph} />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FormControl fullWidth>
              <FormLabel required>
                {t('field.label.callToActionName')}
              </FormLabel>
              <OutlinedInput
                value={actionName}
                placeholder="Button name"
                onChange={(e) => setActionName(e.target.value)}
              />
              <FormError error={errors?.actionName} />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FormControl fullWidth>
              <FormLabel required>{t('field.label.callToActionUrl')}</FormLabel>
              <OutlinedInput
                value={actionUrl}
                placeholder="https://example.com"
                onChange={(e) => setActionUrl(e.target.value)}
              />
              <FormError error={errors?.actionUrl} />
            </FormControl>
          </Grid>
        </Grid>

        <Divider variant="popup" color="primary" />

        <Stack direction="row" spacing={3} flexWrap="wrap">
          <Stack width={235} spacing={2}>
            <FormControl fullWidth>
              <FormLabel required>{t('field.label.popupType')}</FormLabel>
              <Select
                value={type}
                IconComponent={SelectIcon}
                renderValue={(selected) =>
                  selected === 'null' ? (
                    <em>{t('field.hint.popupType')}</em>
                  ) : (
                    optionTypes.filter((el) => el.key === selected)[0]?.label
                  )
                }
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value="null" disabled>
                  <em>{t('field.hint.popupType')}</em>
                </MenuItem>
                {optionTypes.map((el) => (
                  <MenuItem key={el.key} value={el.key}>
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
              <FormError error={errors?.type} />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel required>{t('field.label.frequencyRules')}</FormLabel>
              <Select
                value={frequency}
                IconComponent={SelectIcon}
                renderValue={(selected) =>
                  selected === 'null' ? (
                    <em>{t('field.hint.frequencyRules')}</em>
                  ) : (
                    optionFrequencies.filter((el) => el.key === selected)[0]
                      ?.label
                  )
                }
                onChange={(e) => setFrequency(e.target.value)}
              >
                <MenuItem value="null" disabled>
                  <em>{t('field.hint.frequencyRules')}</em>
                </MenuItem>
                {optionFrequencies.map((el) => (
                  <MenuItem key={el.key} value={el.key}>
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
              <FormError error={errors?.frequency} />
            </FormControl>
            <FormLabel required>{t('field.label.duration')}</FormLabel>
            <Stack spacing={1}>
              <FormControl fullWidth>
                <OutlinedInput
                  type="number"
                  value={durationHour}
                  endAdornment={
                    <InputAdornment position="end">Hours</InputAdornment>
                  }
                  inputProps={{
                    min: 0,
                  }}
                  onChange={(e) => setDurationHour(e.target.value)}
                />
                <FormError error={errors?.durationHour} />
              </FormControl>
              <FormControl fullWidth required>
                <OutlinedInput
                  type="number"
                  value={durationMinute}
                  endAdornment={
                    <InputAdornment position="end">Minutes</InputAdornment>
                  }
                  inputProps={{
                    min: 0,
                  }}
                  onChange={(e) => setDurationMinute(e.target.value)}
                />
                <FormError error={errors?.durationMinute} />
              </FormControl>
            </Stack>
            <FormControl fullWidth>
              <FormLabel>{t('field.label.showCountdown')}</FormLabel>
              <Select
                value={showCountdown}
                IconComponent={SelectIcon}
                onChange={(e) => setShowCountdown(e.target.value === 'true')}
              >
                <MenuItem value="false">No</MenuItem>
                <MenuItem value="true">Yes</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack width={140 + 150 + 180 + 8 * 5}>
            <Stack direction="row" spacing={3}>
              <Stack width={140}>
                <FormLabel required>
                  {t('field.label.subscribersRules')}
                </FormLabel>
                <FormControlLabel
                  classes={checkboxClasses}
                  name="all"
                  checked={optionSubscribers.length === subscribers.length}
                  control={<InputCheckbox />}
                  label={String(t('subscriber.type.all'))}
                  onChange={handleSubscriberChange}
                  disabled={isOnlyFreePackageSelected}
                />
                {optionSubscribers.map((el) => (
                  <FormControlLabel
                    key={el.key}
                    classes={checkboxClasses}
                    name={el.key}
                    checked={subscribers.includes(el.key)}
                    control={<InputCheckbox />}
                    label={el.label}
                    onChange={handleSubscriberChange}
                    disabled={isOnlyFreePackageSelected}
                  />
                ))}
                <FormError error={errors?.subscribers} />
              </Stack>
              <Stack width={150}>
                <FormLabel required>
                  {t('field.label.pageDisplayRules')}
                </FormLabel>
                <FormControlLabel
                  classes={checkboxClasses}
                  name="all"
                  checked={optionPages.length === pages.length}
                  control={<InputCheckbox />}
                  label={String(t('userMenu.all'))}
                  onChange={handlePageChange}
                />
                {optionPages.map((el) => (
                  <FormControlLabel
                    key={el.key}
                    classes={checkboxClasses}
                    name={el.key}
                    checked={pages.includes(el.key)}
                    control={<InputCheckbox />}
                    label={el.label}
                    onChange={handlePageChange}
                  />
                ))}
                <FormError error={errors?.pages} />
              </Stack>
              <Stack width={180}>
                <FormLabel required>
                  {t('field.label.packageDisplayRules')}
                </FormLabel>
                <FormControlLabel
                  classes={checkboxClasses}
                  name="all"
                  checked={packageRules?.length === packages?.length}
                  control={<InputCheckbox />}
                  label={String(t('marketing.allPackages'))}
                  onChange={handlePackageChange}
                />
                {packageRules?.map((el) => (
                  <FormControlLabel
                    key={el.id}
                    classes={checkboxClasses}
                    name={el.title}
                    checked={packages?.includes(el.title)}
                    control={<InputCheckbox />}
                    label={
                      <>
                        <span>{el.title}</span>
                        {el.isFree && <sup>Free</sup>}
                      </>
                    }
                    onChange={handlePackageChange}
                  />
                ))}
                <FormError error={errors?.packages} />
              </Stack>
            </Stack>
            {isOnlyFreePackageSelected && (
              <Alert severity="warning">
                <Typography variant="bold" color="inherit">
                  {t('marketing.warning.isOnlyFreePackageSelected')}
                </Typography>
              </Alert>
            )}
            {isFreeAndPaidPackageSelected && (
              <Alert severity="warning">
                <Typography variant="bold" color="inherit">
                  {t('marketing.warning.isFreeAndPaidPackageSelected')}
                </Typography>
              </Alert>
            )}
          </Stack>
          <Stack width={540} flexGrow={1} spacing={1.5}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <FormLabel>{t('field.label.targetingCriteria')}</FormLabel>
              {freeCriteria.length > 0 && (
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => setCriteriaSelect(true)}
                >
                  <AddIcon color="white" fontSize="small" />
                </IconButton>
              )}
            </Stack>
            {criteria.map((el) => (
              <Grid container key={el}>
                <Grid item xs={4}>
                  {isFreeAndPaidPackageSelected && el === 'ltv' ? null : (
                    <FormControl fullWidth>
                      <OutlinedInput
                        value={
                          optionCriteria.filter((oc) => oc.key === el)[0].label
                        }
                        disabled
                      />
                      <FormError error={errors?.[el]} />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={8} pl={1.5}>
                  <Stack direction="row" alignItems="center">
                    {!isFreeAndPaidPackageSelected && el === 'ltv' && (
                      <Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1.5}
                        >
                          <Typography>from</Typography>
                          <OutlinedInput
                            type="number"
                            value={ltvFrom}
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{ margin: 0, height: '100%' }}
                              >
                                <Divider orientation="vertical" />
                                <Typography ml={1}>€</Typography>
                              </InputAdornment>
                            }
                            inputProps={{
                              min: 0,
                              max: 999999,
                            }}
                            placeholder="0"
                            onChange={(e) => setLtvFrom(e.target.value)}
                          />
                          <Typography>to</Typography>
                          <OutlinedInput
                            type="number"
                            value={ltvTo}
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{ margin: 0, height: '100%' }}
                              >
                                <Divider orientation="vertical" />
                                <Typography ml={1}>€</Typography>
                              </InputAdornment>
                            }
                            inputProps={{
                              min: 1,
                              max: 999999,
                            }}
                            placeholder="999999"
                            onChange={(e) => setLtvTo(e.target.value)}
                          />
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => handleCriteriaDelete(el)}
                          >
                            <CloseIcon color="white" fontSize="small" />
                          </IconButton>
                        </Stack>
                        <FormError error={errors?.ltvFrom} />
                        <FormError error={errors?.ltvTo} />
                      </Stack>
                    )}
                    {el === 'balanceRange' && (
                      <Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1.5}
                        >
                          <Typography>from</Typography>
                          <OutlinedInput
                            type="number"
                            value={balanceFrom}
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{ margin: 0, height: '100%' }}
                              >
                                <Divider orientation="vertical" />
                                <Typography ml={1}>€</Typography>
                              </InputAdornment>
                            }
                            inputProps={{
                              min: 0,
                              max: 999,
                            }}
                            placeholder="0"
                            onChange={(e) => setBalanceFrom(e.target.value)}
                          />
                          <Typography>to</Typography>
                          <OutlinedInput
                            type="number"
                            value={balanceTo}
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{ margin: 0, height: '100%' }}
                              >
                                <Divider orientation="vertical" />
                                <Typography ml={1}>€</Typography>
                              </InputAdornment>
                            }
                            inputProps={{
                              min: 1,
                              max: 999,
                            }}
                            placeholder="999"
                            onChange={(e) => setBalanceTo(e.target.value)}
                          />
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => handleCriteriaDelete(el)}
                          >
                            <CloseIcon color="white" fontSize="small" />
                          </IconButton>
                        </Stack>
                        <FormError error={errors?.balanceFrom} />
                        <FormError error={errors?.balanceTo} />
                      </Stack>
                    )}
                    {el === 'lastActive' && (
                      <Stack width={300}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1.5}
                        >
                          <Typography minWidth={80}>more than</Typography>
                          <OutlinedInput
                            type="number"
                            value={activity}
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{ margin: 0, height: '100%' }}
                              >
                                <Divider orientation="vertical" />
                                <Typography ml={1}>days ago</Typography>
                              </InputAdornment>
                            }
                            inputProps={{
                              min: 1,
                              max: 999,
                            }}
                            placeholder="365"
                            onChange={(e) => setActivity(e.target.value)}
                          />
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => handleCriteriaDelete(el)}
                          >
                            <CloseIcon color="white" fontSize="small" />
                          </IconButton>
                        </Stack>
                        <FormError error={errors?.activity} />
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            ))}
            {criteriaSelect && freeCriteria.length > 0 && (
              <Grid container>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Select
                      value="null"
                      IconComponent={SelectIcon}
                      renderValue={(selected) => {
                        return selected === 'null' ? (
                          <em>{t('field.hint.targetingCriteria')}</em>
                        ) : (
                          selected
                        )
                      }}
                      onChange={handleCriteriaSelect}
                    >
                      <MenuItem value="null" disabled>
                        <em>{t('field.hint.targetingCriteria')}</em>
                      </MenuItem>
                      {freeCriteria.map((el) => (
                        <MenuItem key={el.key} value={el.key}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </Stack>
        </Stack>
      </>
    )
  },
)
