import React, { MouseEventHandler } from 'react'
import Linkify from 'react-linkify'
import { format } from 'date-fns'
import { Avatar, Box, Grid, Link, Typography, useTheme } from '@mui/material'
import { colorSecondaryLight } from '../../mui'
import { store } from '../../store'
import { TypographyVariant } from '../../type/mui'
import { getByBase64 } from '../../function/image'
import { CommonState } from '../../store/common/reducer'
import { truncate } from '../../function/string'
import emptyImage from '../../asset/icon/defaultUser.png'

export const imageKeys = [
  'thumbnail_link',
  'image_url',
  'user_comment_avatar_url',
  'photoUrl',
  'thumbnail',
  'avatar',
  'friendImageUrl',
  'gift_image_url',
  'group_thumbnail_url',
  'profileImageUrl',
  'school_image_url',
  'thumbnail_url',
]

export const nameLocalData = {
  vk: ['last_online_status'],
}

export const linkKeys = ['family_members']

export function getLabel(key: string) {
  const letter1 = key[0].toUpperCase()
  return (letter1 + key.slice(1)).replaceAll('_', ' ') + ' '
}

export function getObjectValue(object: any, key: string): any {
  const date = ['createdTime', 'online']
  const phone = ['criteria']
  const commonState: CommonState = store.getState().common
  const timeFormatWithSeconds = commonState.timeFormatWithSeconds

  let value

  // eslint-disable-next-line array-callback-return
  Object.keys(object).some((k) => {
    if (k === key) {
      value = object[k]

      if (date.includes(key) && value !== 0) {
        value = format(new Date(value), `dd/MM/yyyy ${timeFormatWithSeconds}`)
      } else {
        value = object[k]
      }

      return true
    }

    if (object[k] && typeof object[k] === 'object') {
      value = getObjectValue(object[k], key)
      return value !== undefined
    }
  })
  return value
}

export function getCorsImageUrl(url: string | undefined) {
  return url === 'NO AVATAR'
    ? ''
    : `${
        process.env.REACT_APP_API_BASE_URL
      }/searcher/image?url=${url?.replaceAll('&', '%26')}`
}

interface RenderImageProps {
  _key: string
  val: string
  imgHeight?: number | string,
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined,
}

export function RenderImage({ _key, val, imgHeight, onClick }: RenderImageProps) {
  let src = val

  if (val.match(/http|mycdn/)) {
    if (!val.startsWith('http')) {
      src = 'https://' + val
    }
  } else if (val === 'NO AVATAR') return <span>NO AVATAR</span>
  else if (val === '') return <span>null</span>
  else {
    src = getByBase64(val)
  }

  return (
    <a href={src} target="_blank" rel="noreferrer" style={{ display: 'block' }} onClick={onClick}>
      <img
        src={src}
        alt=""
        width="100%"
        height={imgHeight}
        style={{ objectFit: 'cover' }}
        onError={(e: any) => e.target.src = emptyImage}
      />
    </a>
  )
}

interface RenderLabelProps {
  label: string
  fontSize?: number
}

export function RenderLabel({ label, fontSize }: RenderLabelProps) {
  return (
    // если цифра и меньше 100
    !isNaN(label as any) && Number(label) < 100 ? (
      <Typography>- </Typography>
    ) : // если цифра и больше 100
    !isNaN(label as any) ? (
      <Typography fontSize={16}>{label}: </Typography>
    ) : imageKeys.includes(label) ? null : (
      <Typography fontSize={fontSize}>{getLabel(label)}</Typography>
    )
  )
}

interface RenderValueProps {
  label: string
  value?: string
  variant?: TypographyVariant
  source?: string
}

export function getHastagLink(
  hashtag: string,
  source: string | undefined,
): string {
  hashtag = hashtag.slice(1)
  let hashtagLink: string
  switch (source) {
    case 'vk':
      hashtagLink = `https://m.vk.com/feed?section=search&q=%23${hashtag}`
      break
    case 'twitter':
      hashtagLink = `https://twitter.com/hashtag/${hashtag}?src=hashtag_click`
      break
    case 'fb':
      hashtagLink = `https://www.facebook.com/hashtag/${hashtag}`
      break
    case 'instagram':
      console.log(123)
      hashtagLink = `https://www.instagram.com/explore/tags/${hashtag}`
      break
    default:
      hashtagLink = hashtag
      break
  }
  return hashtagLink
}

export function getVkLink(link: string): string {
  if (link.startsWith('https')) {
    return link
  }
  return `https://vk.com/${link}`
}

export const getJSXByWord = (
  word: string,
  source: string | undefined,
  idx: any,
) => {
  if (word.startsWith('#')) {
    return (
      <React.Fragment key={idx}>
        <Link key={idx} target="_blank" href={getHastagLink(word, source)}>
          {word}
        </Link>
      </React.Fragment>
    )
  }
  if (
    word.startsWith('@') &&
    (source === 'twitter' || source === 'instagram')
  ) {
    const mentionUrls = {
      twitter: `https://twitter.com/${word}`,
      instagram: `https://www.instagram.com/${word.substr(1)}`,
    }
    return (
      <React.Fragment key={idx}>
        <Link key={idx} target="_blank" href={`${mentionUrls[source]}`}>
          {word}
        </Link>
      </React.Fragment>
    )
  }

  if (
    word.startsWith('[id') ||
    word.startsWith('[club') ||
    word.startsWith('[https')
  ) {
    console.log(word)
    let [link, name] = word.split('|')
    link = link.replace(/-/g, '').replace('[', '')
    name = name.replace(/-/g, ' ').replace(']', '')
    return (
      <React.Fragment key={idx}>
        <Link key={idx} target="_blank" href={getVkLink(link)}>
          {name}
        </Link>
      </React.Fragment>
    )
  }
  return ' ' + word
}

export function RenderValue({
  label,
  value,
  variant,
  source,
}: RenderValueProps) {
  const linkComponentDecorator = (
    decoratedHref: string,
    decoratedText: string,
    key: number,
  ) => (
    <a target="blank" href={decoratedHref} key={key}>
      {truncate(decoratedText, 30)}
    </a>
  )

  let lines: any = value?.replace(/# /g, '#')
  lines = lines?.replace(/\s+(?=[^[\]]*\])/g, '-').split('\n')

  return (
    <>
      {value ? (
        imageKeys.includes(label) ? (
          <Avatar src={value} />
        ) : (
          <Linkify componentDecorator={linkComponentDecorator}>
            {lines.map((line: any, idx: number) =>
              line === '' ? (
                <br key={idx} />
              ) : (
                <React.Fragment key={idx}>
                  <Typography variant={variant ? variant : 'semiBold'}>
                    {line?.split(' ').map((word: string, idx: number) => {
                      return getJSXByWord(word, source, idx)
                    })}
                  </Typography>
                </React.Fragment>
              ),
            )}
          </Linkify>
        )
      ) : (
        <Typography variant="semiBold">-</Typography>
      )}
    </>
  )
}

interface RenderProfileDataProps {
  data: object
  isRecursive?: boolean
  fullData?: any
}

const customFields = {
  vk: {
    'Personal information': [
      'last_online_status',
      'profileSex',
      'total_friends_count',
    ],
  },
}

export function RenderProfileData({
  data,
  isRecursive,
  fullData,
}: RenderProfileDataProps) {
  const theme = useTheme()

  return (
    <Grid container>
      {Object.entries(data).map(([sectionName, sectionData]) => (
        <Grid
          key={sectionName}
          item
          p={1}
          borderBottom={
            isRecursive ? 'none' : `1px solid ${colorSecondaryLight}`
          }
          xs={isRecursive ? 6 : 12}
          sx={{
            [theme.breakpoints.only("xs")]: {
              '& *': {
                fontSize: '12px!important',
              },
            },
          }}
        >
          <RenderLabel label={sectionName} fontSize={18}  />

          {Object.entries(customFields).map(
            ([customNetwork, customFieldObject]) => {
              if (customNetwork === fullData.from) {
                return Object.entries(customFieldObject).map(
                  ([customField, customValue]) => {
                    if (customField === sectionName) {
                      return customValue.map((el) => {
                        const value = getObjectValue(fullData, el).toString()

                        return (
                          !!value && (
                            <Box key={customField + el + value} sx={{
                              [theme.breakpoints.only("xs")]: {
                                '& *': {
                                  fontSize: '10px!important',
                                },
                              },
                            }}>
                              <RenderLabel label={el} />
                              <RenderValue label={el} value={value} />
                            </Box>
                          )
                        )
                      })
                    }
                    return null
                  },
                )
              }
              return null
            },
          )}

          {sectionData === null ? (
            <RenderValue key={sectionName} label={sectionName} value="-" />
          ) : typeof sectionData === 'string' ? (
            <RenderValue
              key={sectionName}
              label={sectionName}
              value={sectionData}
            />
          ) : (
            Object.entries(sectionData).map(([key, val]) =>
              typeof val === 'string' ? (
                <Box key={key}>
                  <RenderLabel label={key} />
                  <RenderValue key={key} label={key} value={val} />
                </Box>
              ) : typeof val === 'object' && val ? (
                <>
                  <RenderProfileData
                    key={key}
                    data={val}
                    fullData={fullData}
                    isRecursive
                  />
                </>
              ) : (
                <Box key={key}>
                  <RenderLabel label={key} />
                  <RenderValue key={key} label={key} value="-" />
                </Box>
              ),
            )
          )}
        </Grid>
      ))}
    </Grid>
  )
}
