import React from 'react'
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import {
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  useTheme,
  Link,
} from '@mui/material'
import { Phone, SearchResult } from '../../store/searcher/reducer'
import { ResultTitle, useAccordionStyles } from '../../style/result'
import { prepareDeepWebResult } from '../../store/searcher/service'
import { getLinkToProfile, getTitle, isHttpUrl } from '../../function/string'


export interface Name {
  app: Phone
  name: string
}

type Props = {
  apiData: SearchResult
}

export const DeepWebCommon = (props: Props) => {
  //const
  const accordionClasses: any = useAccordionStyles()
  const theme = useTheme()
  const {apiData} = props
  const data = prepareDeepWebResult(apiData)
  console.log('prepared', data)

  //hooks
  const initState = React.useMemo(() => {
    return data.data ? data.data.map((_) => true) : []
  }, [data.data])

  //state
  const [accordionOpen, setAccordionOpen] = React.useState<boolean[]>(initState)

  //handlers
  const handleChangeAccordion =
    (idx: number) => (event: React.SyntheticEvent, expanded: boolean) => {
      setAccordionOpen((state) => {
        const newState = [...state]
        newState[idx] = expanded
        return newState
      })
    }

  return (
    <>
      {
        data && data.data && data?.data?.length > 0 && <ResultTitle sx={{marginTop: '20px'}}>Pluto results:</ResultTitle>
      }
      <Stack>
        <Stack direction="row" alignItems="flex-start" spacing={7} sx={{
          [theme.breakpoints.only("xs")]: {
            flexDirection: 'column',
          },
        }}>
          <Stack style={{width: '100%'}} sx={{
            [theme.breakpoints.only("xs")]: {
              marginLeft: '0!important',
              marginTop: '10px!important',
            },
          }}>
            <Stack>
              {
                data.data?.map((dataEl, idx) => (
                  <Accordion
                    classes={accordionClasses}
                    key={idx}
                    square
                    expanded={accordionOpen[idx]}
                    onChange={handleChangeAccordion(idx)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        {/*<Box
                        className={sprite_['social__small_' + dataEl.from]}
                      />*/}
                        <Typography
                          variant="semiBold"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {/*{dataEl.from} - */}{data.criteria}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Divider
                        sx={{
                          margin: '-5px 0px 15px 0px',
                          border: `0.1px solid rgba(46, 151, 213, 0.3)`,
                        }}
                      />
                      <Grid container columnSpacing={2}>
                        {
                          Object.entries(dataEl).filter(el => el[1] && (el[1] + '')?.trim()).map(([label, value]) => {
                            if (typeof value !== 'object') {
                              return <Grid item xs={6} key={label}>
                                <Grid container={(value + '').length < 35} sx={{display: 'flex'}}>
                                  <Grid item xs={5}>
                                    <Typography variant="text" fontSize={14}>
                                      {getTitle(label || '')}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    {
                                      getLinkToProfile(label, value) !== ""
                                        ? <Link key={idx} target="_blank" href={getLinkToProfile(label, value)}>{value}</Link>
                                        : (
                                          isHttpUrl(value)
                                            ? <Link key={idx} target="_blank" href={value}>open</Link>
                                            : <Typography variant="bold" sx={{ wordBreak: 'break-all' }}>{value || '-'}</Typography>
                                        )
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>
                            }
                          })
                        }
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>

  )
}


