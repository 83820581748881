import * as React from 'react'
import {Step, StepLabel, Stepper as MuiStepper} from '@mui/material'

interface StepperProps {
  steps: string[]
  activeStep: number
}

export function Stepper({steps, activeStep}: StepperProps) {
  return (
    <MuiStepper activeStep={activeStep}>
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {}
        const labelProps: { optional?: React.ReactNode } = {}

        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        )
      })}
    </MuiStepper>
  )
}