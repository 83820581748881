import { UserAction } from './actionType'
import { EnumSubscriberStatus } from '../../enum/statusSubscriber'

export type SubscriberStatus = `${EnumSubscriberStatus}`

interface Quota {
  quotaProfilerLookups : string
	quotaProfilerCrawlers : string
  quotaProfilerCases : string
  quotaProfilerPeriodic : string
	quotaWebintCases : string
	quotaWebintCrawlers : string
  quotaWebintPeriodic : string
  quotaWebintMonitoring : string  
}

interface RegisterInfo {
  email_deliverability: string,
  email_quality_score: string,
  email_is_disposable_email:boolean,
  email_is_smtp_valid :boolean | null,
  phone_type:string,
  phone_valid:boolean
}

interface AdditionalInformation {
	utmCampaign: string,
	utmSource: string,
	utmTerm: string,
	utmContent: string,
	utmMedium: string,
	handlCustom1: string,
	gaClientId: string,
	handlIP: string,
  isIpPhoneMatches: boolean
  isEmailDomainValid? : boolean
  ipMatchesCount? : number
  isProxy? : string
}


export interface SearchCategoryCounter {
  label: string,
  counter: number,
  percetage: number | null,
}

export interface Subscriber {
  id: number
  uid: string
  avatar: string | null
  firstname: string | null
  lastname: string | null
  country: string | null
  phone: string | null
  email: string
  ltv: number
  packageBalance: number
  annualBonus: number | null
  counters: SearchCategoryCounter[]
  package: {
    id: number
    title: string
    price: number
    creditsPerUnit : number | null
  } | null
  packageEndDate: Date | string
  status: SubscriberStatus
  sessions: Array<{ id: number; lastActiveDate: string }>
  isAutoRenewal: boolean
  isDeleted: boolean
  isBlocked: boolean
  createdTime: string
  userType?: string
  additionalPackageEndDate: Date | string
  additionalPackage : {
    id: number
    title: string
    price: number
  } | null
  additionalPackageQouta : Quota | null
  irbisproUpgrade: boolean | null
  registerInfo: RegisterInfo | null
  userAgent: AdditionalInformation | null
  fullUsage: {web: {total: number, typeCount: any}, api: {total: number, typeCount: any}} | null
}

export interface Admin extends Subscriber {
  department: { name: string }
  role: { name: string }
  isBlocked: boolean
  departmentId: number
}

export interface UserState {
  admins: Admin[] | null
  tickets?: any
  failedRequests?: any
}

const initialState: UserState = {
  admins: null,
  tickets: [],
  failedRequests: [],
}

export function userReducer(state = initialState, action: UserAction) {
  switch (action.type) {
    case 'user/SET_ADMINS':
      return {
        ...state,
        admins: action.data,
      }
    case 'user/SET_ALL_USER_TICKETS':
      return {
        ...state,
        tickets: action.tickets,
      }
    case 'user/SET_ALL_USER_FAILED':
      return {
        ...state,
        failedRequests: action.failedRequests,
      }
    default:
      return state
  }
}
