import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from '../../store'
import {Popup2FAInput} from './Popup2FAInput'
import {Popup2FAQr} from './Popup2FAQr'
import {useSettingsStyles} from '../../style/settings'
import {Button, Stack, Typography} from '@mui/material'

export const FormOther = () => {
  const {t} = useTranslation()
  const classes = useSettingsStyles()

  const {twoFactorQr, is2FAEnabled} = useSelector(state => state.settings)

  const [twoFAQrOpen, setTwoFAQrOpen] = React.useState(false)
  const [twoFAInputOpen, setTwoFAInputOpen] = React.useState(false)

  React.useEffect(() => {
    if (twoFactorQr) setTwoFAQrOpen(true)
  }, [twoFactorQr])

  const handle2FAButton = (switchStatus: boolean) => {
    const nowEnabled = is2FAEnabled
    const nowDisabled = !is2FAEnabled
    const turnOn = switchStatus
    const turnOff = !switchStatus

    if (nowDisabled && turnOn) setTwoFAQrOpen(true)
    else if (nowEnabled && turnOff) setTwoFAInputOpen(true)
  }

  // const handleChangeLanguage = async (lang: string) => {
  //   await i18n.changeLanguage(lang)
  //   dispatch(setLanguage(lang))
  // }

  return (
    <Stack alignItems="flex-start">
      <Typography variant="semiBold" fontSize={18} mb={3}>{t('settings.other')}</Typography>

      <table cellPadding={5}>
        <tbody>
        <tr>
          <td className={classes.label}>{t('field.label.2stepVerification')}</td>
          <td>
            <Stack direction="row" width="100%">
              <Button
                style={{padding: '6px 0', minWidth: 'unset', width: 44}}
                variant={is2FAEnabled ? 'contained' : 'text'}
                size="large"
                fullWidth
                onClick={() => handle2FAButton(true)}
              >{t('common.on')}</Button>
              <Button
                style={{padding: '6px 0', minWidth: 'unset', width: 44}}
                variant={!is2FAEnabled ? 'contained' : 'text'}
                size="large"
                fullWidth
                onClick={() => handle2FAButton(false)}
              >{t('common.off')}</Button>
            </Stack>

            {twoFAQrOpen && (
              <Popup2FAQr
                qrCode={twoFactorQr}
                handleClose={() => setTwoFAQrOpen(false)}
                handle2FAInputOpen={() => setTwoFAInputOpen(true)}
              />
            )}

            {twoFAInputOpen && <Popup2FAInput mode="toggle2FA" onClose={() => setTwoFAInputOpen(false)}/>}
          </td>
        </tr>
        {/*<tr>
          <td className={classes.label}>{t('field.label.language')}</td>
          <td>
            <FormControl variant="outlined" fullWidth>
              <Select
                value={language}
                onChange={(e: SelectChangeEvent<any>) => handleChangeLanguage(e.target.value)}
                IconComponent={SelectIcon}
              >
                <MenuItem value="en">EN</MenuItem>
              </Select>
            </FormControl>
          </td>
        </tr>*/}
        </tbody>
      </table>
    </Stack>
  )
}