import { makeStyles } from '@mui/styles'

const color1 = '#FFF'
const color2 = '#C4C4C4'

export const useInProgressStyles = makeStyles({
  root: {
    height: 9,
    border: '1px solid #C4C4C4',
  },
  bar: {
    backgroundSize: '20px 20px',
    animation: '$bar-animation 3s linear infinite',
    background: `linear-gradient(135deg, ${color1} 25%, ${color2} 25%, ${color2} 50%, ${color1} 50%, ${color1} 75%, ${color2} 75%)`,
  },
  '@keyframes bar-animation': {
    '0%': {
      backgroundPosition: '0px 0px',
    },
    '100%': {
      backgroundPosition: '50px 50px',
    },
  },
})
