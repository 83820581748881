export const GET_USAGE = 'dashboard/GET_USAGE'
export interface GetUsage {
  type: typeof GET_USAGE
  url: string
}

export const SET_USAGE = 'dashboard/SET_USAGE'
export interface SetUsage {
  type: typeof SET_USAGE
  data: object | null
}

export const SET_SUBSCRIBER_OFFSET = 'dashboard/SET_SUBSCRIBER_OFFSET'
export interface SetSubscriberOffset {
  type: typeof SET_SUBSCRIBER_OFFSET
  offset: number
}

export const SET_SUBSCRIBER_SORT = 'dashboard/SET_SUBSCRIBER_SORT'
export interface SetSubscriberSort {
  type: typeof SET_SUBSCRIBER_SORT
  key: string
  value: string
}

export const PATCH_USER_PACKAGE = 'dashboard/PATCH_USER_PACKAGE'
export interface PatchUserPackage {
  type: typeof PATCH_USER_PACKAGE
  userId: number
  packageId: number
  callback: () => void
}

export const PATCH_USER_ADDITIONAL_PACKAGE = 'dashboard/PATCH_USER_ADDITIONAL_PACKAGE'
export interface PatchUserAdditionalPackage {
  type: typeof PATCH_USER_ADDITIONAL_PACKAGE
  userId: number
  packageId: number
  callback: () => void
}

export const PUT_BALANCE = 'dashboard/PUT_BALANCE'
export interface PutBalance {
  type: typeof PUT_BALANCE
  id: number
  value: number
  callback: () => void
}

export const PUT_ANNUAL_BONUS = 'dashboard/PUT_ANNUAL_BONUS'
export interface PutAnnualBonus {
  type: typeof PUT_ANNUAL_BONUS
  id: number
  value: number
  callback: () => void
}

export const PUT_QUOTA = 'dashboard/PUT_QUOTA'
export interface PutQuota {
  type: typeof PUT_QUOTA
  uid: string
  value: object
  callback: () => void
}

export const PUT_RENEWAL = 'dashboard/PUT_RENEWAL'
export interface PutRenewal {
  type: typeof PUT_RENEWAL
  id: number
  days: number
  callback: () => void
}

export const CHANGE_TIME = 'dashboard/CHANGE_TIME'

export interface ChangeTime {
  type: typeof CHANGE_TIME
  time: 'day' | 'week' | 'month' | 'year'
}

export const CHANGE_USAGE = 'dashboard/CHANGE_USAGE'

export interface ChangeUsage {
  type: typeof CHANGE_USAGE
  usage: 'all' | 'web' | 'api'
}

export const SET_SUBSCRIBER_IRBISPRO_UPGRADE = 'dashboard/SET_SUBSCRIBER_IRBISPRO_UPGRADE'

export interface SetSubscriberIrbisProUpgrade {
  type: typeof SET_SUBSCRIBER_IRBISPRO_UPGRADE,
  id: number,
  upgrade: boolean | null,
  callback?: Function,
}

export type DashboardAction =
  | GetUsage
  | SetUsage
  | SetSubscriberSort
  | SetSubscriberOffset
  | PatchUserPackage
  | PutBalance
  | PutAnnualBonus
  | PutRenewal
  | ChangeTime
  | ChangeUsage
  | PatchUserAdditionalPackage
  | PutQuota
  | SetSubscriberIrbisProUpgrade
