import React from 'react'
import { useTranslation } from 'react-i18next'
import CountUp from 'react-countup'
import { Box, Stack, Typography } from '@mui/material'
import { useSubscriber } from '../../hook/useSubscriber'
import dashboard_ from '../../sass/dashboard.module.sass'
import { Subscriber } from '../../store/user/reducer'
import { store, useDispatch, useSelector } from '../../store'

type Props = {
  data: Subscriber
}

export function UsageBreakdown({ data }: Props) {
  const { t } = useTranslation()
  const subscriber = useSelector((state) => state.common.subscriber)

  const { breakdown } = useSubscriber(subscriber)

  const webWidth = breakdown.web.width
  const apiWidth = breakdown.api.width

  return (
    <>
      <Typography variant="semiBold" fontSize={18}>
        {t('subscriber.usageBreakdown')}
      </Typography>

      <Box
        className={dashboard_.card_column}
        mt={1}
        sx={{ padding: '16px !important' }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Stack
            px={4}
            justifyContent="center"
            width={webWidth}
            minWidth="25%"
            height={25}
            bgcolor="#D1E0F8"
          >
            <Typography
              variant="semiBold"
              noWrap
              lineHeight="18px"
              sx={{ color: '#FFF' }}
            >
              {t('dashboard.webUsage')}
            </Typography>
          </Stack>
          <Typography variant="semiBold" color="primary" ml={1}>
            <CountUp
              end={subscriber.webUsagesCount}
              separator=","
              duration={0.5}
            />
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ marginTop: '3px' }}
        >
          <Stack
            px={4}
            justifyContent="center"
            width={apiWidth}
            minWidth="25%"
            height={25}
            bgcolor="#D1E0F8"
          >
            <Typography
              variant="semiBold"
              noWrap
              lineHeight="18px"
              sx={{ color: '#FFF' }}
            >
              {t('dashboard.apiUsage')}
            </Typography>
          </Stack>
          <Typography variant="semiBold" color="primary" ml={1}>
            <CountUp
              end={subscriber.apiUsagesCount}
              separator=","
              duration={0.5}
            />
          </Typography>
        </Stack>
      </Box>
    </>
  )
}
