import React from 'react'
import {Pagination as MuiPagination, PaginationItem, Stack} from '@mui/material'
import {useTablePaginationStyles} from '../hook/useStyles'
import {ArrowBack as IconLeft, ArrowForward as IconRight} from '@mui/icons-material'

type Props = {
  page: number
  setPage: (page: number) => void
  pageCount: number | undefined
  setParams: (state: Record<string, any>) => void
  limit: number
}

export function Pagination({page, setPage, pageCount, setParams, limit}: Props) {
  const classes = useTablePaginationStyles()

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page)
    setParams((state: Record<string, any>) => ({...state, offset: (page - 1) * limit}))
  }

  return (
    <Stack direction="row" justifyContent="flex-end" mt={2} width="100%">
      <MuiPagination
        classes={classes}
        count={pageCount}
        page={page}
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
            components={{previous: IconLeft, next: IconRight}}
            {...item}
          />
        )}
        onChange={handlePageChange}
      />
    </Stack>
  )
}