import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import {
    DeleteOutline as DeleteIcon, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, Search,
} from '@mui/icons-material'

import {
    TableCell,
    TableRow,
    TableContainer,
    Table,
    TableBody,
    Paper,
    TableFooter,
    TablePagination,
    Box,
    IconButton,
    TableHead,
  } from '@mui/material'
import { useTheme } from '@emotion/react'

import { BlackList, BlackListPhones } from '../../store/blacklist/reducer'
import SearchBar from '../../component/datatable/component/SearchBar'
import { PopupAskDelete } from '../../component/datatable/component/PopupAskDelete'
import { deleteBlackList, deleteBlackListIpAddress, deleteBlackListPhones } from '../../store/blacklist/actionCreator'
import { useDispatch } from 'react-redux'


interface TablePaginationActionProps {
  count: number,
  page: number,
  rowsPerPage: number,
  onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionProps) {
  const theme = useTheme()
  const {count, page, rowsPerPage, onPageChange} = props
  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => { onPageChange(event, 0)}
  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => { onPageChange(event, page - 1) }
  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => { onPageChange(event, page + 1) }
  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
  
  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </Box>
  )
}
type BlackListType = 'email' | 'phone' | 'ipAddress'

interface TableProps {
    rows: any[],
    type: BlackListType,
}

export default function BlackListTableWithPagination(props: TableProps) {
  const dispatch = useDispatch()
  const blackListType = props.type
  const [rows, setRows] = React.useState<any[]>(props.rows)
  console.log(blackListType, rows)
  const [filteredRows, setFilteredRows] = React.useState<any[]>(rows)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [deleteId, setDeleteId] = React.useState<number>()
  const [sureDeleteOpen, setSureDeleteOpen] = React.useState(false)
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,
    ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  function handleAskDelete(id: number) {
    console.log(id)
    setDeleteId(id)

    setSureDeleteOpen(true)
  }

  function handleDelete() {
    setSureDeleteOpen(false)
  }

  // this makes updates based on the selector that gets updated
  React.useEffect(()=> {
    if (rows !== props.rows){
      setRows(props.rows)
      setFilteredRows(props.rows)
    }
  }, [props.rows])

  return (
    <>
    <SearchBar callback={setFilteredRows} list={rows} valName={blackListType}/>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableBody>
          {(rowsPerPage > 0
            ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filteredRows
          ).map((row) => (
            <TableRow key={row.id}>
              <TableCell align='left'>
                {row[blackListType]}
              </TableCell>
              <TableCell align='right'>
                <IconButton onClick={() => handleAskDelete(row.id)} size="small">
                  <DeleteIcon />
                </IconButton>
                {sureDeleteOpen && deleteId && <PopupAskDelete 
                  onYes={() => {
                    switch(blackListType){
                      case 'email':
                        dispatch(deleteBlackList({id: deleteId}))
                        break
                      case 'phone':
                        dispatch(deleteBlackListPhones({id: deleteId}))
                        break
                      case 'ipAddress':
                          dispatch(deleteBlackListIpAddress({id: deleteId}))
                          break
                    }
                  }}
                  onClose={handleDelete}
                />}
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        {filteredRows.length > 0 && <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>}
      </Table>
    </TableContainer>
    </>
  )
}