import {Color} from 'react-color'
import {SupportAction} from './actionType'
import {Admin, Subscriber} from '../user/reducer'
import {EnumTicketStatus} from '../../enum/statusTicket'
import {getAuthLocalStorageArray} from '../../function/storage'
import {LocalStorage} from '../../enum/storage'

export interface TicketType {
  id: number
  name: string
  color: Color
}
export type TicketPriority = 'low' | 'medium' | 'high'
export type TicketEstimate = { days: number, hours: number }
export type TicketStatus = `${EnumTicketStatus}`
export interface Attachment {
  fileName: string
  url: string
  isObjectUrl?: boolean
}

export interface Ticket {
  id: number
  title: string
  ticketType: TicketType | null
  summary: string
  status: TicketStatus
  user: Subscriber
  assignee: Admin | null
  priority: TicketPriority
  due: TicketEstimate | null
  attachments: Attachment[] | null
  createdTime: string
  startedTime: string |  null
}

export interface Comment {
  id: number
  context: string
  attachment: Attachment | null
  createdTime: string
  user: {
    roleId: number
    avatar: string
    firstname: string
    lastname: string
  }
}

export interface Notification {
  id: number
  type?: 'admin' | 'search'
  title: string
  text: string
  isRed?: boolean
}

export type SupportState = {
  viewOne: Ticket | null
  ticketTypes: TicketType[] | null
  notifications: Notification[]
}

const redNotifications = getAuthLocalStorageArray(LocalStorage.RED_NOTIFICATION)

const initialState: SupportState = {
  viewOne: null,
  ticketTypes: null,
  notifications: [],
}

export function supportReducer(state = initialState, action: SupportAction) {
  switch (action.type) {
    case 'support/TOGGLE_VIEW_ONE':
      return {
        ...state,
        viewOne: action.data,
      }
    case 'support/SET_TICKET_TYPES':
      return {
        ...state,
        ticketTypes: action.data,
      }
    case 'support/SET_NOTIFICATION':
      return {
        ...state,
        notifications: action.data.map(el => ({...el, isRed: redNotifications.includes(el.id)})),
      }
    case 'support/ADD_NOTIFICATION':
      return {
        ...state,
        notifications: [...state.notifications, ...action.data],
      }
    case 'common/ADD_NEW_NOTIFICATION':
      return {
        ...state,
        notifications: [...action.data, ...state.notifications],
      }
    case 'support/READ_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.map(el => el.id === action.id ? ({...el, isRed: true}) : el),
      }
    case 'common/CLEAR_NOTIFICATIONS_SUCCESS':
      console.log(action)
      return {
        ...state,
        notifications: [],
      }
    default:
      return state
  }
}
