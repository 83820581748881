import {SettingsAction} from './actionType'
import {Role} from '../../enum/role'

export type Department = 'HR'

export interface IUser {
  uid: string
  balance: number
  avatar: string | null
  email: string
  firstname: string
  lastname: string
  phone: string
  is2FAEnabled: null | boolean
  department: { name: Department } | null
}

export interface SettingsState extends IUser {
  provider: string | null
  role: `${Role}` | null
  password: string
  twoFactorQr: string | null
}

const initialState: SettingsState = {
  uid: '',
  provider: null,
  role: null,
  balance: 0,
  avatar: null,
  email: '',
  password: '',
  firstname: '',
  lastname: '',
  phone: '',
  is2FAEnabled: null,
  department: null,
  twoFactorQr: null,
}

export function settingsReducer(state = initialState, action: SettingsAction) {
  switch (action.type) {
    case 'settings/SET_STATE':
      return {
        ...state,
        [action.key]: action.value,
      }
    case 'settings/FLUSH_STATE':
      return {
        ...state,
        ...initialState,
      }
    case 'settings/SET_PROFILE': // TODO simplify like in espy-user
      return {
        ...state,
        balance: action.data.balance || 0,
        avatar: action.data.avatar || null,
        email: action.data.email || '',
        firstname: action.data.firstname || '',
        lastname: action.data.lastname || '',
        phone: action.data.phone || '',
        is2FAEnabled: action.data.is2FAEnabled,
        department: action.data.department,
      }
    case 'settings/SET_QR':
      return {
        ...state,
        twoFactorQr: action.qr,
      }
    default:
      return state
  }
}
