import React from 'react'
import {useTranslation} from 'react-i18next'
import {Grid, Link, Typography} from '@mui/material'

const agreeingStyle = {fontSize: 16, color: '#000'}
const agreeingLinkStyle = {...agreeingStyle, textDecorationColor: '#000'}

export function Agreement() {
  const {t} = useTranslation()

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item style={{textAlign: 'center'}}>
        <Typography style={agreeingStyle}>
          <span>{t('auth.register.licence.text')} </span>
          <Link variant="underlined" href="#" target="_blank" style={agreeingLinkStyle}>
            {t('auth.register.licence.privacy')}
          </Link>
          <span> {t('common.and')} </span>
          <Link variant="underlined" href="#" target="_blank" style={agreeingLinkStyle}>
            {t('auth.register.licence.terms')}
          </Link>.
        </Typography>
      </Grid>
    </Grid>
  )
}